/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-imports */
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { RowBetween } from 'components/Row'
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import {
  COINCOLORS,
  COLORS,
  convertToPercents,
  tooltipFormatter as tooltipFormatter_,
  tooltipLabelFormatter as tooltipLabelFormatter_,
} from '../helpers'
import { useTokenStats } from '../hooks/dataProvider'
import { useChartViewState } from '../hooks/useChartViewState'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

const convertToPercentsHandler = (data: any) => convertToPercents(data, { totalKey: 'all', ignoreKeys: [] })

function getTokenColor(index: number) {
  return COINCOLORS[index % COINCOLORS.length]
}

interface Props {
  from: any
  to: any
  chainId: number
  chartHeight: any
  yaxisWidth: any
}
export function PoolCompositionChart(props: Props) {
  const { from, to, chainId, chartHeight } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }

  const [tokenStatsData, tokenStatsLoading] = useTokenStats({ from, to, chainId })

  const chartLegendItems =
    tokenStatsData && tokenStatsData.tokenSymbols
      ? tokenStatsData.tokenSymbols.map((token: any, i: any) => ({
          key: token,
          color: getTokenColor(i),
          fillOpacity: 0.5,
        }))
      : []

  const data = tokenStatsData ? tokenStatsData.poolAmountUsd : null

  const csvFields = chartLegendItems.map((item: any) => ({ key: item.key, name: item.name }))

  const controls = {
    convertToPercents: convertToPercentsHandler,
  }

  const {
    viewState,
    togglePercentView,
    formattedData,
    yaxisTickFormatter: defaultYaxisTickFormatter,
    itemsUnit: defaultItemUnit,
  } = useChartViewState({ controls, data })

  const htmlItems = (chartLegendItems || []).map((item: any, i: any) => {
    const props = {
      dataKey: item.key,
      stackId: 'a',
      name: item.name || item.key,
      fill: item.color || COLORS[i % COLORS.length],
      stroke: item.color || COLORS[i % COLORS.length],
      dot: item.dot || false,
      key: 'item-' + i,
      unit: item.unit || defaultItemUnit,
      strokeWidth: item.strokeWidth,
      yAxisId: item.yAxisId,
    }

    return <Area connectNulls type="monotone" {...props} isAnimationActive={false} />
  })

  return (
    <DarkCard minHeight={600}>
      <RowBetween>
        <Row>
          <ThemedText.Body>
            <Trans>Pool Composition</Trans>
          </ThemedText.Body>
          <ButtonEmpty width="fit-content">
            <CsvLink fields={csvFields} name="Pool Composition" data={formattedData} />
          </ButtonEmpty>
        </Row>
        {controls && (
          <ButtonEmpty width="fit-content">
            <FontAwesomeIcon icon={faPercent} onClick={togglePercentView} border={viewState.isPercentsView} />
          </ButtonEmpty>
        )}
      </RowBetween>
      {tokenStatsLoading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <AreaChart data={formattedData} syncId="syncFlp">
            <CartesianGrid strokeDasharray="10 10" key="a" />,
            <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter_} minTickGap={30} key="b" />,
            <YAxis domain={[0, 'auto']} dataKey="all" tickFormatter={defaultYaxisTickFormatter} key="c" />
            <Tooltip
              formatter={tooltipFormatter_}
              labelFormatter={tooltipLabelFormatter_}
              contentStyle={contentStyle}
              key="d"
            />
            <Legend key="e" />
            {htmlItems}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </DarkCard>
  )
}
