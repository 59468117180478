import { format as formatDateFn } from 'date-fns'
import { enIN, es } from 'date-fns/locale'

export function formatDateTime(time: number) {
  return formatDateFn(time * 1000, 'dd MMM yyyy, h:mm a')
}

export function formatDateTimeLang(time: number, lang: boolean) {
  return formatDateFn(time * 1000, 'dd MMM yyyy', { locale: lang ? es : enIN })
}

export function formatDate(time: number) {
  return formatDateFn(time * 1000, 'dd MMM yyyy')
}

export function formatDateLang(time: number, lang: boolean) {
  return formatDateFn(time * 1000, 'dd MMM yyyy', { locale: lang ? es : enIN })
}

export function formatDay(time: number) {
  return formatDateFn(time * 1000, 'dd')
}

export function formatMonth(time: number) {
  return formatDateFn(time * 1000, 'MMM')
}

export function formatMonthLang(time: number, lang: boolean) {
  return formatDateFn(time * 1000, 'MMM', { locale: lang ? es : enIN })
}

export function formatYear(time: number) {
  return formatDateFn(time * 1000, 'yyyy')
}

export function getTimeRemaining(time: number) {
  const now = parseInt(String(Date.now() / 1000))
  if (time < now) {
    return '0h 0m'
  }
  const diff = time - now
  const hours = parseInt(String(diff / (60 * 60)))
  const minutes = parseInt(String((diff - hours * 60 * 60) / 60))
  return `${hours}h ${minutes}m`
}

export function isValidTimestamp(timestamp: any) {
  return new Date(timestamp).getTime() > 0
}
