/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import Token from 'abis/erc20.json'
import FlpManager from 'abis/FlpManager.json'
import FlpPool from 'abis/JlpPool.json'
import Reader from 'abis/Reader.json'
import Vault from 'abis/Vault.json'
import FalconBlackLogo from 'assets/images/falcon-black-logo.png'
import FalconWhiteLogo from 'assets/images/falcon-white-logo.png'
import { ButtonDropdownText, ButtonLight, ButtonPrimary, ButtonTap } from 'components/Button'
import { DarkCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { Input as NumericalInput } from 'components/NumericalInput'
import { AutoRow, RowBetween, RowCenter } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getBlocksPerYear, getChainName, IS_NETWORK_DISABLED, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { getNativeToken, getToken, getTokens, getWhitelistedTokens } from 'constants/tokens'
import { getTokenInfo, getUsd, useInfoTokens } from 'domain/tokens'
import { BigNumber, ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useJlpPoolContract, useTokenContract } from 'hooks/useContract'
import { useChainId } from 'lib/chains'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  getBuyGlpFromAmount,
  getBuyGlpToAmount,
  getSellGlpFromAmount,
  getSellGlpToAmount,
  GLP_COOLDOWN_DURATION,
  GLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  USDG_DECIMALS,
} from 'lib/legacy'
import { useLocalStorageByChainId } from 'lib/localStorage'
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from 'lib/numbers'
import { parse } from 'qs'
import { useEffect, useState } from 'react'
import { HelpCircle } from 'react-feather'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import { useDarkModeManager, useUserSlippage } from 'state/user/hooks'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { FeesRow } from './FeesRow'
import { TokenModal } from './TokenModal'

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
`};
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.primary1};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `};
`

const ResponsiveTable = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.bg0};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    gap: 1rem;
  `};
`

const ResponsiveGrid = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 0.8fr 0.8fr 1fr 1.5fr 0.5fr 1fr;
  gap: 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.primary1};
  padding: 1rem;
`

const Row = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const ResponsiveRowBetween = styled(RowBetween)`
  margin-top: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    align-items: flex-start;
  `};
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

export function BuySellLP({ location: { search } }: RouteComponentProps) {
  const { isActive, account, provider } = useActiveWeb3React()
  const { chainId } = useChainId()
  const history = useHistory()
  const toggleWalletModal = useWalletModalToggle()
  const [isDark] = useDarkModeManager()

  //Token Modal
  const [openModal, setOpenModal] = useState(0)

  const savedSlippageAmount = useUserSlippage()

  useEffect(() => {
    if (!search) return
    if (search.length < 1) return

    const parsed = parse(search, {
      parseArrays: false,
      ignoreQueryPrefix: true,
    })

    const redeem = parsed.redeem
    const buying = redeem ? false : true
    setIsBuying(buying)
  }, [search])

  const switchSwapOption = (buying: boolean) => {
    if (buying) {
      history.push(`${history.location.pathname}`)
    } else {
      history.push(`${history.location.pathname}?redeem=true`)
    }
    setIsBuying(buying)
  }
  const [isBuying, setIsBuying] = useState(true)
  const nativeTokenSymbol = getNativeToken(chainId).symbol

  const swapLabel = isBuying ? 'BuyFlp' : 'SellFlp'

  const tokens = getTokens(chainId)
  const whitelistedTokens = getWhitelistedTokens(chainId)
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped)

  const visibleTokens = tokenList.filter((t) => !t.isTempHidden)
  const [swapValue, setSwapValue] = useState('')
  const [flpValue, setFlpValue] = useState('')
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address`,
    ZERO_ADDRESS
  )

  const [isApproving, setIsApproving] = useState(false)
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true)
  const [feeBasisPoints, setFeeBasisPoints] = useState('')
  const [modalError, setModalError] = useState(false)

  const readerAddress = getContract(chainId, 'Reader')
  const vaultAddress = getContract(chainId, 'Vault')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const usdfAddress = getContract(chainId, 'USDF')
  const flpAddress = getContract(chainId, 'FLP')
  const flpManagerAddress = getContract(chainId, 'FlpManager')
  const JlpPoolAddress = getContract(chainId, 'JlpPool')
  const tokensForBalanceAndSupplyQuery = [flpAddress, usdfAddress]

  const tokenAddresses = tokens.map((token) => token.address)
  const { data: tokenBalances }: any = useSWR(
    [
      `FlpSwap:getTokenBalances:${isActive}`,
      chainId,
      readerAddress,
      'getTokenBalances',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, Reader, [tokenAddresses]),
    }
  )

  const { data: balancesAndSupplies }: any = useSWR(
    [
      `FlpSwap:getTokenBalancesWithSupplies:${isActive}`,
      chainId,
      readerAddress,
      'getTokenBalancesWithSupplies',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, Reader, [tokensForBalanceAndSupplyQuery]),
    }
  )

  const { data: aums }: any = useSWR([`GlpSwap:getAums:${isActive}`, chainId, flpManagerAddress, 'getAums'], {
    fetcher: contractFetcher(provider, FlpManager),
  })

  const { data: totalTokenWeights }: any = useSWR(
    [`FlpSwap:totalTokenWeights:${isActive}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: contractFetcher(provider, Vault),
    }
  )

  const { data: nativeTokenPrices }: any = useSWR(
    [`StakeV2:nativeTokenPrice:${isActive}`, chainId, vaultAddress, 'getMaxPrice', nativeTokenAddress],
    {
      fetcher: contractFetcher(provider, Vault),
    }
  )
  let nativeTokenPrice = BigNumber.from(0)
  if (nativeTokenPrices) {
    nativeTokenPrice = nativeTokenPrices
  }

  const tokenAllowanceAddress = swapTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : swapTokenAddress
  const { data: tokenAllowance }: any = useSWR(
    [isActive, chainId, tokenAllowanceAddress, 'allowance', account || PLACEHOLDER_ACCOUNT, flpManagerAddress],
    {
      fetcher: contractFetcher(provider, Token),
    }
  )

  let flpBalance: any
  let lastPurchaseTime: any

  const { data: walletInfo }: any = useSWR(
    [`FlpSwap:getWalletInfo:${isActive}`, chainId, JlpPoolAddress, 'getWalletInfo', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  if (walletInfo) {
    flpBalance = walletInfo[0]
    lastPurchaseTime = walletInfo[1]
  }

  const { data: rewardsXblockLp }: any = useSWR(
    [`FlpPool:getRewardsXblock:${isActive}`, chainId, JlpPoolAddress, 'getRewardsXblock'],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  let FlpRewardsXblock = BigNumber.from(0)
  if (rewardsXblockLp) {
    FlpRewardsXblock = rewardsXblockLp
  }

  /*  const { data: flpBalance }: any = useSWR(
    [isActive, chainId, flpAddress, 'balanceOf', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(provider, Token),
    }
  ) */

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined
  const inCooldownWindow = redemptionTime && Date.now() / 1000 < redemptionTime

  const flpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0)
  const usdfSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0)

  let aum
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1]
  }
  const flpPrice =
    aum && aum.gt(0) && flpSupply.gt(0)
      ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(flpSupply)
      : expandDecimals(1, USD_DECIMALS)
  let flpBalanceUsd
  if (flpBalance) {
    flpBalanceUsd = flpBalance.mul(flpPrice).div(expandDecimals(1, GLP_DECIMALS))
  }
  const flpSupplyUsd = flpSupply.mul(flpPrice).div(expandDecimals(1, GLP_DECIMALS))

  const maxSellAmount = flpBalance

  const { infoTokens } = useInfoTokens(provider, chainId, isActive, tokenBalances, undefined)
  const swapToken = getToken(chainId, swapTokenAddress)
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0)

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals)
  const flpAmount = parseValue(flpValue, GLP_DECIMALS)

  const needApproval =
    isBuying && swapTokenAddress !== ZERO_ADDRESS && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance)

  const swapUsdMin = getUsd(swapAmount ?? BigNumber.from(0), swapTokenAddress, false, infoTokens)
  const flpUsdMax = flpAmount && flpPrice ? flpAmount.mul(flpPrice).div(expandDecimals(1, GLP_DECIMALS)) : undefined

  let isSwapTokenCapReached = false
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDG_DECIMALS, USD_DECIMALS)
    )
  }

  const onSwapValueChange = (value: string) => {
    setAnchorOnSwapAmount(true)
    setSwapValue(value)
  }

  const onFlpValueChange = (value: string) => {
    setAnchorOnSwapAmount(false)
    setFlpValue(value)
  }

  const onSelectSwapToken = (token: any) => {
    setSwapTokenAddress(token.address)
    setIsWaitingForApproval(false)
  }

  const blocksXyear = getBlocksPerYear(chainId)

  const nativeToken = getTokenInfo(infoTokens, ZERO_ADDRESS)

  const totalRewardPricePerYearLP = nativeTokenPrice
    ? nativeTokenPrice.mul(FlpRewardsXblock).mul(blocksXyear)
    : BigNumber.from(0)

  const totalApr = flpSupplyUsd.eq(0) ? BigNumber.from(0) : totalRewardPricePerYearLP.div(flpSupplyUsd).mul(100)

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setFlpValue('')
          setFeeBasisPoints('')
          return
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          )
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS)
          setFlpValue(nextValue)
          setFeeBasisPoints(feeBps)
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          )
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS)
          setFlpValue(nextValue)
          setFeeBasisPoints(feeBps)
        }

        return
      }

      if (!flpAmount) {
        setSwapValue('')
        setFeeBasisPoints('')
        return
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps }: any = getBuyGlpFromAmount(
            flpAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          )
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals)
          setSwapValue(nextValue)
          setFeeBasisPoints(feeBps)
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps }: any = getSellGlpToAmount(
            flpAmount,
            swapTokenAddress,
            infoTokens,
            flpPrice,
            usdfSupply,
            totalTokenWeights
          )

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals)
          setSwapValue(nextValue)
          setFeeBasisPoints(feeBps)
        }
      }
    }

    updateSwapAmounts()
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    flpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    flpPrice,
    usdfSupply,
    totalTokenWeights,
  ])

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true)
      setSwapValue(formatAmountFree(swapTokenBalance ?? BigNumber.from(0), swapToken.decimals, swapToken.decimals))
      return
    }

    setAnchorOnSwapAmount(false)
    setFlpValue(formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS))
  }

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      if (isBuying) return [t`FLP buy disabled, pending ${getChainName(chainId)} upgrade`]
      return [t`FLP sell disabled, pending ${getChainName(chainId)} upgrade`]
    }

    if (!isBuying && inCooldownWindow) {
      return [t`Redemption time not yet reached`]
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [t`Enter an amount`]
    }
    if (!flpAmount || flpAmount.eq(0)) {
      return [t`Enter an amount`]
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)
      if (
        /* !savedShouldDisableValidationForTesting && */
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [t`Insufficient ${swapTokenInfo.symbol} balance`]
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDG_DECIMALS)
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount)
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [t`${swapTokenInfo.symbol} pool exceeded, try different token`, true]
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && flpAmount && flpAmount.gt(maxSellAmount)) {
        return [t`Insufficient FLP balance`]
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [t`Insufficient liquidity`]
      }
    }

    return [false]
  }

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      return false
    }
    if (!isActive) {
      return true
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return false
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false
    }
    if (isApproving) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (isBuying && isSwapTokenCapReached) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    if (!account) {
      return t`Connect Wallet`
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return error
    }
    if (isBuying && isSwapTokenCapReached) {
      return t`Max Capacity for ${swapToken.symbol} Reached`
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`
    }
    if (isApproving) {
      return t`Approving ${swapToken.symbol}...`
    }
    if (needApproval) {
      return t`Approve ${swapToken.symbol}`
    }

    if (isSubmitting) {
      return isBuying ? t`Buying...` : t`Selling...`
    }

    return isBuying ? t`Buy FLP` : t`Sell FLP`
  }
  const tokenContract = useTokenContract(swapToken.address)

  const approveFromToken = async () => {
    setIsApproving(true)
    try {
      const tx = await tokenContract?.approve(flpManagerAddress, ethers.constants.MaxUint256)
      await tx?.wait()
      setIsApproving(false)
    } catch (error) {
      console.log(error)
      setIsApproving(false)
    }
  }

  const jlpPoolContract = useJlpPoolContract()
  const buyFlp = async () => {
    setIsSubmitting(true)

    const minFlp = flpAmount?.mul(BASIS_POINTS_DIVISOR /*  - savedSlippageAmount */).div(BASIS_POINTS_DIVISOR)

    /* const method = swapTokenAddress === ZERO_ADDRESS ? 'mintAndStakeGlpETH' : 'mintAndStakeGlp'
    const params = swapTokenAddress === ZERO_ADDRESS ? [0, minFlp] : [swapTokenAddress, swapAmount, 0, minFlp]
    const value = swapTokenAddress === ZERO_ADDRESS ? swapAmount : 0 */

    try {
      if (swapTokenAddress === ZERO_ADDRESS) {
        const tx = await jlpPoolContract?.stakeETH(0, minFlp ?? 0, { value: swapAmount })
        await tx?.wait()
      } else {
        const tx = await jlpPoolContract?.stake(swapTokenAddress, swapAmount ?? 0, 0, minFlp ?? 0)
        await tx?.wait()
      }
      setIsSubmitting(false)
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
    }
  }

  const sellFlp = async () => {
    setIsSubmitting(true)

    const minOut = swapAmount?.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)

    /* const method = swapTokenAddress === ZERO_ADDRESS ? 'unstakeAndRedeemGlpETH' : 'unstakeAndRedeemGlp'
    const params =
      swapTokenAddress === ZERO_ADDRESS ? [flpAmount, minOut, account] : [swapTokenAddress, flpAmount, minOut, account] */

    try {
      if (swapTokenAddress === ZERO_ADDRESS) {
        const tx = await jlpPoolContract?.withdrawnETH(flpAmount ?? 0, minOut ?? 0, account ?? ZERO_ADDRESS)
        await tx?.wait()
      } else {
        const tx = await jlpPoolContract?.withdrawn(
          swapTokenAddress,
          flpAmount ?? 0,
          minOut ?? 0,
          account ?? ZERO_ADDRESS
        )
        await tx?.wait()
      }
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const onClickPrimary = () => {
    if (!account) {
      toggleWalletModal()
      return
    }

    if (needApproval) {
      approveFromToken()
      return
    }

    const [, modal] = getError()

    if (modal) {
      setModalError(true)
      return
    }

    if (isBuying) {
      buyFlp()
    } else {
      sellFlp()
    }
  }

  let payBalance = '$0.00'
  let receiveBalance = '$0.00'
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`
    }
    if (flpUsdMax) {
      receiveBalance = `$${formatAmount(flpUsdMax, USD_DECIMALS, 2, true)}`
    }
  } else {
    if (flpUsdMax) {
      payBalance = `$${formatAmount(flpUsdMax, USD_DECIMALS, 2, true)}`
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`
    }
  }

  const selectToken = (token: any) => {
    setAnchorOnSwapAmount(false)
    setSwapTokenAddress(token.address)
    helperToast.success(t`✔️ ${token.symbol} selected in order form`)
  }

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, '-')
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += '%'
  }

  return (
    <PageWrapper>
      <ThemedText.TitlePage textAlign="center">
        <Trans>Buy/Sell</Trans> FLP
      </ThemedText.TitlePage>
      <ThemedText.SubTitle mb="1rem" textAlign="center">
        <Trans>Purchase FLP tokens to earn</Trans> {nativeTokenSymbol}{' '}
        <Trans>fees from swaps and leverages trading</Trans>.
      </ThemedText.SubTitle>
      <Wrapper>
        <DarkCard height="fit-content" minHeight={isMobile ? '350px' : '390px'}>
          <AutoRow gap="0.2rem">
            <img src={isDark ? FalconWhiteLogo : FalconBlackLogo} alt="falcon-logo" width={48} height={48} />
            <ThemedText.Body>FLP</ThemedText.Body>
          </AutoRow>
          <Divider />
          <AutoColumn gap="0.2rem">
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>${formatAmount(flpPrice, USD_DECIMALS, 3, true)}</ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Wallet</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(flpBalance, GLP_DECIMALS, 4, true)} FLP ($
                {formatAmount(flpBalanceUsd, USD_DECIMALS, 2, true)})
              </ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Staked</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(flpBalance, GLP_DECIMALS, 4, true)} FLP ($
                {formatAmount(flpBalanceUsd, USD_DECIMALS, 2, true)})
              </ThemedText.SubHeader>
            </RowBetween>
          </AutoColumn>
          <Divider />
          <AutoColumn gap="0.2rem">
            <RowBetween>
              <ThemedText.SubHeader color="text2">APR</ThemedText.SubHeader>
              <ThemedText.SubHeader>{formatAmount(totalApr, GLP_DECIMALS, 2, true)}%</ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Total Supply</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {' '}
                {formatAmount(flpSupply, GLP_DECIMALS, 4, true)} FLP ($
                {formatAmount(flpSupplyUsd, USD_DECIMALS, 2, true)})
              </ThemedText.SubHeader>
            </RowBetween>
          </AutoColumn>
        </DarkCard>

        <DarkCard height="fit-content" minHeight={isMobile ? '350px' : '390px'}>
          <Container>
            <ButtonTap
              active={isBuying}
              fontSize={isMobile ? '12px' : '16px'}
              width="fit-content"
              onClick={() => switchSwapOption(true)}
            >
              <Trans>Buy</Trans> FLP
            </ButtonTap>
            <ButtonTap
              active={!isBuying}
              fontSize={isMobile ? '12px' : '16px'}
              width="fit-content"
              onClick={() => switchSwapOption(false)}
            >
              <Trans>Sell</Trans> FLP
            </ButtonTap>
          </Container>
          {isBuying && (
            <LightCard $borderRadius="8px" mt="1rem">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={swapValue}
                  onUserInput={(val: string) => {
                    onSwapValueChange(val)
                  }}
                />
                {swapValue !== formatAmountFree(swapTokenBalance ?? 0, swapToken.decimals, swapToken.decimals) && (
                  <ButtonLight width="fit-content" padding="10px" height="10px" onClick={fillMaxAmount}>
                    Max
                  </ButtonLight>
                )}
                <div>
                  <ButtonDropdownText ml="0.5rem" onClick={() => setOpenModal(1)}>
                    <ThemedText.MediumHeader>{swapToken.symbol}</ThemedText.MediumHeader>
                  </ButtonDropdownText>
                </div>
              </RowBetween>
              <ResponsiveRowBetween>
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Pay</Trans> {payBalance}
                </ThemedText.Body>
                <ThemedText.Body onClick={fillMaxAmount} fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Balance</Trans>: {formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}
                </ThemedText.Body>
              </ResponsiveRowBetween>
            </LightCard>
          )}

          {!isBuying && (
            <LightCard $borderRadius="8px" mt="1rem">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={flpValue}
                  onUserInput={(val: string) => {
                    onFlpValueChange(val)
                  }}
                />
                {flpValue !== formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS) && (
                  <ButtonLight width="fit-content" padding="10px" height="10px" onClick={fillMaxAmount}>
                    Max
                  </ButtonLight>
                )}
                <ThemedText.MediumHeader mr="1rem" ml="0.5rem">
                  FLP
                </ThemedText.MediumHeader>
              </RowBetween>
              <RowBetween mt="0.5rem">
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Pay</Trans> {payBalance}
                </ThemedText.Body>
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Available</Trans>: {formatAmount(maxSellAmount, GLP_DECIMALS, 4, true)}
                </ThemedText.Body>
              </RowBetween>
            </LightCard>
          )}
          {/* <ButtonChange
            isMobile={isMobile}
            src={RocketDownIcon}
            alt="rocket-icon"
            onClick={() => {
              setIsBuying(!isBuying)
              switchSwapOption(!isBuying)
            }}
          /> */}
          {isBuying && (
            <LightCard $borderRadius="8px" mt="1rem">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={flpValue}
                  onUserInput={(val: string) => {
                    onFlpValueChange(val)
                  }}
                />
                <ThemedText.MediumHeader mr="1rem" ml="0.5rem">
                  FLP
                </ThemedText.MediumHeader>
              </RowBetween>
              <ResponsiveRowBetween>
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Receive</Trans> {receiveBalance}
                </ThemedText.Body>
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Balance</Trans>: {formatAmount(flpBalance, GLP_DECIMALS, 4, true)}
                </ThemedText.Body>
              </ResponsiveRowBetween>
            </LightCard>
          )}
          {!isBuying && (
            <LightCard $borderRadius="8px" mt="1rem">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={swapValue}
                  onUserInput={(val: string) => {
                    onSwapValueChange(val)
                  }}
                />
                <ButtonDropdownText ml="0.5rem" onClick={() => setOpenModal(2)}>
                  <ThemedText.MediumHeader>{swapToken.symbol}</ThemedText.MediumHeader>
                </ButtonDropdownText>
              </RowBetween>
              <RowBetween mt="0.5rem">
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Receive</Trans> {receiveBalance}
                </ThemedText.Body>
                <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
                  <Trans>Balance</Trans>: {formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}
                </ThemedText.Body>
              </RowBetween>
            </LightCard>
          )}
          <RowBetween>
            <ThemedText.SubHeader color="text2" mt="0.5rem">
              {Number(feeBasisPoints) > 50 ? <Trans>WARNING: High Fees</Trans> : <Trans>Fees</Trans>}
            </ThemedText.SubHeader>
            {isBuying && (
              <ThemedText.SubHeader>
                {isBuying && isSwapTokenCapReached ? 'NA' : feePercentageText}
              </ThemedText.SubHeader>
            )}
            {!isBuying && <ThemedText.SubHeader>{feePercentageText}</ThemedText.SubHeader>}
          </RowBetween>
          <RowCenter>
            <ButtonPrimary
              width="fit-content"
              mt="1rem"
              size="40px"
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled()}
            >
              {getPrimaryText()}
            </ButtonPrimary>
          </RowCenter>
        </DarkCard>
      </Wrapper>

      <ThemedText.TitlePage textAlign="center" mt="2rem">
        <Trans>Save on Fees</Trans>
      </ThemedText.TitlePage>
      <ThemedText.SubTitle mb="2rem" textAlign="center">
        {isBuying && (
          <>
            <Trans>Fees may vary depending on which asset you use to buy FLP</Trans>.
            <br />{' '}
            <Trans>
              Enter the amount of FLP you want to purchase in the order form, then check here to compare fees
            </Trans>
            .
          </>
        )}
        {!isBuying && (
          <>
            <Trans>Fees may vary depending on which asset you sell FLP for</Trans>.
            <br />{' '}
            <Trans>Enter the amount of FLP you want to redeem in the order form, then check here to compare fees</Trans>
            .
          </>
        )}
      </ThemedText.SubTitle>
      <ResponsiveTable>
        {!isMobile && (
          <ResponsiveGrid>
            <ThemedText.MediumHeader color="text2">Token</ThemedText.MediumHeader>
            <ThemedText.MediumHeader color="text2">
              <Trans>Price</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader color="text2">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Available amount to deposit into FLP</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Available</Trans>
                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader color="text2">
              <Trans>Wallet</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader color="text2">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Fees will be shown once you have entered an amount in the order form</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Fees</Trans>
                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.MediumHeader>
          </ResponsiveGrid>
        )}
        {visibleTokens.map((token, index) => {
          let tokenFeeBps: any
          if (isBuying) {
            const { feeBasisPoints: feeBps }: any = getBuyGlpFromAmount(
              flpAmount,
              token.address,
              infoTokens,
              flpPrice,
              usdfSupply,
              totalTokenWeights
            )
            tokenFeeBps = feeBps
          } else {
            const { feeBasisPoints: feeBps }: any = getSellGlpToAmount(
              flpAmount,
              token.address,
              infoTokens,
              flpPrice,
              usdfSupply,
              totalTokenWeights
            )
            tokenFeeBps = feeBps
          }

          const tokenInfo = getTokenInfo(infoTokens, token.address)
          let managedUsd = BigNumber.from(0)
          if (tokenInfo && tokenInfo.managedUsd) {
            managedUsd = tokenInfo.managedUsd
          }
          let availableAmountUsd
          if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
            availableAmountUsd = tokenInfo.availableAmount
              .mul(tokenInfo.minPrice)
              .div(expandDecimals(1, token.decimals))
          }
          let balanceUsd
          if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
            balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals))
          }

          let amountLeftToDeposit = bigNumberify(0)
          if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
            amountLeftToDeposit = tokenInfo.maxUsdgAmount
              .sub(tokenInfo.usdgAmount ?? 0)
              .mul(expandDecimals(1, USD_DECIMALS))
              .div(expandDecimals(1, USDG_DECIMALS))
          }
          if (amountLeftToDeposit?.lte(0)) {
            amountLeftToDeposit = bigNumberify(0)
          }
          const isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdgAmount ?? 0)

          function renderFees() {
            switch (true) {
              case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                /* return (
                  <MouseoverTooltip
                    handle="NA"
                    position="right-bottom"
                    renderContent={() => (
                      <Trans>
                        Max pool capacity reached for {tokenInfo.symbol}. Please mint FLP using another token
                      </Trans>
                    )}
                  />
                ) */
                return 'NA'
              case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                return `${formatAmount(tokenFeeBps, 2, 2, true, '-')}${
                  tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? '%' : ''
                }`
              default:
                return ''
            }
          }
          return (
            <FeesRow
              key={index}
              isBuying={isBuying}
              token={token}
              tokenInfo={tokenInfo}
              amountLeftToDeposit={amountLeftToDeposit}
              availableAmountUsd={availableAmountUsd}
              balanceUsd={balanceUsd}
              managedUsd={managedUsd}
              renderFees={renderFees()}
              selectToken={selectToken}
            />
          )
        })}
      </ResponsiveTable>
      <TokenModal
        label={t`Pay`}
        chainId={chainId}
        tokenAddress={swapTokenAddress}
        onSelectToken={onSelectSwapToken}
        tokens={whitelistedTokens}
        infoTokens={infoTokens}
        isOpen={openModal === 1}
        onDismiss={() => setOpenModal(0)}
      />
      <TokenModal
        label={t`Receive`}
        chainId={chainId}
        tokenAddress={swapTokenAddress}
        onSelectToken={onSelectSwapToken}
        tokens={whitelistedTokens}
        infoTokens={infoTokens}
        isOpen={openModal === 2}
        onDismiss={() => setOpenModal(0)}
      />
    </PageWrapper>
  )
}
