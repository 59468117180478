import ConnectedAccountBlocked from 'components/ConnectedAccountBlocked'
import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useModalOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

export default function TopLevelModals() {
  const blockedAccountModalOpen = useModalOpen(ApplicationModal.BLOCKED_ACCOUNT)
  const { account } = useActiveWeb3React()

  useAccountRiskCheck(account)
  const open = Boolean(blockedAccountModalOpen && account)
  return (
    <>
      <ConnectedAccountBlocked account={account} isOpen={open} />
    </>
  )
}
