import { Trans } from '@lingui/macro'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getContract } from 'constants/contracts'
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from 'constants/ui'
import { getTokenInfo, getUsd } from 'domain/tokens'
import {
  DECREASE,
  getExchangeRate,
  getExchangeRateDisplay,
  getOrderError,
  INCREASE,
  SWAP,
  USD_DECIMALS,
} from 'lib/legacy'
import { formatAmount } from 'lib/numbers'
import { useCallback, useState } from 'react'
import { CheckSquare, ChevronDown, ChevronUp, Edit, Eye, Square, XSquare } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  width: 100%;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 0.2fr' : '0.5fr 0.5fr 1.5fr 1fr 1fr 0.5fr 0.55fr')};
`

const ButtonChecked = styled(ButtonEmpty)`
  width: fit-content;
  height: 10px;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const ButtonAction = styled(ButtonEmpty)`
  width: fit-content;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;

  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary1};
  }
`

const ButtonOptions = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.bg4)};
  cursor: pointer;
  padding: 8px;
  border-radius: 24px;

  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    background-color: ${({ theme, isMobile }) => (isMobile ? 'transparent' : theme.primary1)};
    color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.white)};
  }
  &:active {
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 16px;
  padding: 16px;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

interface OrdersProps {
  chainId: number
  account: any
  order: any
  infoTokens: any
  cancelOrderIdList: any
  setCancelOrderIdList: any
  positionsMap: any
  setEditingOrder: any
  handleCancelOrder: any
}

export function Orders({
  chainId,
  account,
  order,
  infoTokens,
  cancelOrderIdList,
  setCancelOrderIdList,
  positionsMap,
  setEditingOrder,
  handleCancelOrder,
}: OrdersProps) {
  const onCancelClick = useCallback(
    (order: any) => {
      handleCancelOrder(order)
    },
    [handleCancelOrder]
  )

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order)
    },
    [setEditingOrder]
  )

  const [showMore, setShowMore] = useState(false)

  if (order.type === SWAP) {
    const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
    const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress)
    const toTokenInfo = getTokenInfo(
      infoTokens,
      order.path[order.path.length - 1],
      order.shouldUnwrap,
      nativeTokenAddress
    )

    const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo)
    const orderId = `${order.type}-${order.index}`

    return (
      <Container>
        <ResponsiveGrid isMobile={isMobile}>
          {!isMobile && (
            <>
              <ButtonChecked
                onClick={() => {
                  setCancelOrderIdList((prevState: any) => {
                    if (prevState.includes(orderId)) {
                      return prevState.filter((i: any) => i !== orderId)
                    } else {
                      return prevState.concat(orderId)
                    }
                  })
                }}
              >
                {cancelOrderIdList?.includes(orderId) ? <CheckSquare width={18} /> : <Square width={18} />}
              </ButtonChecked>
              <ThemedText.SubHeader>
                <Trans>Limit</Trans>
              </ThemedText.SubHeader>
            </>
          )}
          <>
            <ThemedText.SubHeader>
              Swap{' '}
              {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdg ? 2 : 4,
                true
              )}{' '}
              {fromTokenInfo.symbol} for{' '}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                true
              )}{' '}
              {toTokenInfo.symbol}
            </ThemedText.SubHeader>
            {!isMobile && (
              <>
                <ThemedText.SubHeader>
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader>
                        <Trans>
                          {() => `
                            You will receive at least ${formatAmount(
                              order.minOut,
                              toTokenInfo.decimals,
                              toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                              true
                            )} ${
                            toTokenInfo.symbol
                          } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                          `}
                        </Trans>
                      </ThemedText.SubHeader>
                    }
                  >
                    <AutoRow>
                      {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                      <WrapperIcon>
                        <Eye width={14} />
                      </WrapperIcon>
                    </AutoRow>
                  </MouseoverTooltip>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)}
                </ThemedText.SubHeader>
              </>
            )}
          </>
          {!isMobile ? (
            <>
              <ButtonAction onClick={() => onEditClick(order)}>
                <Trans>Edit</Trans>
              </ButtonAction>
              <ButtonAction onClick={() => onCancelClick(order)}>
                <Trans>Cancel</Trans>
              </ButtonAction>
            </>
          ) : (
            <ButtonOptions isMobile={isMobile} onClick={() => setShowMore(!showMore)}>
              {showMore ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
            </ButtonOptions>
          )}
        </ResponsiveGrid>
        {isMobile && showMore && (
          <Wrapper>
            <AutoColumn gap="0.5rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Type</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>Limit</ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader>
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader>
                        <Trans>
                          {() => `
                            You will receive at least ${formatAmount(
                              order.minOut,
                              toTokenInfo.decimals,
                              toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                              true
                            )} ${
                            toTokenInfo.symbol
                          } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                          `}
                        </Trans>
                      </ThemedText.SubHeader>
                    }
                  >
                    <AutoColumn>
                      <AutoRow>
                        <ThemedText.SubHeader color="text2">
                          <Trans>Price</Trans>
                        </ThemedText.SubHeader>
                        <WrapperIcon>
                          <Eye width={14} />
                        </WrapperIcon>
                      </AutoRow>
                    </AutoColumn>
                  </MouseoverTooltip>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Mark Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo)}
                </ThemedText.SubHeader>
              </RowBetween>
              <AutoRow justify="center" gap="0.25rem">
                <ButtonPrimary width="7rem" size="10px" onClick={() => onEditClick(order)}>
                  <Edit width={16} />
                  <ThemedText.SubHeader ml="0.25rem">
                    <Trans>Edit</Trans>
                  </ThemedText.SubHeader>
                </ButtonPrimary>
                <ButtonPrimary width="7rem" size="10px" onClick={() => onCancelClick(order)}>
                  <XSquare width={16} />
                  <ThemedText.SubHeader ml="0.25rem">
                    <Trans>Cancel</Trans>
                  </ThemedText.SubHeader>
                </ButtonPrimary>
              </AutoRow>
            </AutoColumn>
          </Wrapper>
        )}
      </Container>
    )
  }

  const indexToken = getTokenInfo(infoTokens, order.indexToken)

  // Longs Increase: max price
  // Longs Decrease: min price
  // Short Increase: min price
  // Short Decrease: max price
  const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong)

  const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice
  const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
  const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol

  const error = getOrderError(account, order, positionsMap)
  const orderId = `${order.type}-${order.index}`
  const orderText = (
    <ThemedText.SubHeader>
      {order.type === INCREASE ? 'Increase' : 'Decrease'} {indexTokenSymbol} {order.isLong ? 'Long' : 'Short'}
      &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
      {error && <div className="Exchange-list-item-error">{error}</div>}
    </ThemedText.SubHeader>
  )

  const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken)
  const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens)

  return (
    <Container>
      <ResponsiveGrid isMobile={isMobile}>
        {!isMobile && (
          <>
            <ButtonChecked
              onClick={() => {
                setCancelOrderIdList((prevState: any) => {
                  if (prevState.includes(orderId)) {
                    return prevState.filter((i: any) => i !== orderId)
                  } else {
                    return prevState.concat(orderId)
                  }
                })
              }}
            >
              {cancelOrderIdList?.includes(orderId) ? <CheckSquare width={18} /> : <Square width={18} />}
            </ButtonChecked>
            <ThemedText.SubHeader>
              <Trans>{order.type === INCREASE ? 'Limit' : 'Trigger'}</Trans>
            </ThemedText.SubHeader>
          </>
        )}
        <ThemedText.SubHeader>
          {order.type === DECREASE ? (
            orderText
          ) : (
            <MouseoverTooltip
              text={
                <AutoColumn justify="center">
                  <ThemedText.SubHeader color="text2">Collateral:</ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    {`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                      order.purchaseTokenAmount,
                      collateralTokenInfo.decimals,
                      4,
                      true
                    )}
                           ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})`}
                  </ThemedText.SubHeader>
                </AutoColumn>
              }
            >
              <AutoRow>
                {orderText}
                <WrapperIcon>
                  <Eye width={14} />
                </WrapperIcon>
              </AutoRow>
            </MouseoverTooltip>
          )}
        </ThemedText.SubHeader>

        {!isMobile && (
          <>
            <ThemedText.SubHeader>
              {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>{formatAmount(markPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
          </>
        )}
        {!isMobile ? (
          <>
            <ButtonAction onClick={() => onEditClick(order)}>
              <Trans>Edit</Trans>
            </ButtonAction>
            <ButtonAction onClick={() => onCancelClick(order)}>
              <Trans>Cancel</Trans>
            </ButtonAction>
          </>
        ) : (
          <ButtonOptions isMobile={isMobile} onClick={() => setShowMore(!showMore)}>
            {showMore ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
          </ButtonOptions>
        )}
      </ResponsiveGrid>
      {isMobile && showMore && (
        <Wrapper>
          <AutoColumn gap="0.5rem">
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Type</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{order.type === INCREASE ? 'Limit' : 'Trigger'}</ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
              </ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Mark Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{formatAmount(markPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
            </RowBetween>
            <AutoRow justify="center" gap="0.25rem">
              <ButtonPrimary width="7rem" size="10px" onClick={() => onEditClick(order)}>
                <Edit width={16} />
                <ThemedText.SubHeader ml="0.25rem">
                  <Trans>Edit</Trans>
                </ThemedText.SubHeader>
              </ButtonPrimary>
              <ButtonPrimary width="7rem" size="10px" onClick={() => onCancelClick(order)}>
                <XSquare width={16} />
                <ThemedText.SubHeader ml="0.25rem">
                  <Trans>Cancel</Trans>
                </ThemedText.SubHeader>
              </ButtonPrimary>
            </AutoRow>
          </AutoColumn>
        </Wrapper>
      )}
    </Container>
  )
}
