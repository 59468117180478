import READER_ABI from 'abis/OrderBookReader.json'
import { ORDER_BOOK_ADDRESS, ORDER_BOOK_READER_ADDRESS } from 'constants/addresses'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

import { UserOrders } from '../types'
import { parseDecreaseOrdersData, parseIncreaseOrdersData, parseSwapOrdersData } from './utils'

/**
 * Gets all public data of Sales contract
 */
const useWalletOrders = (chainId: number, orders: any[] | undefined): UserOrders => {
  const { account } = useActiveWeb3React()
  const orderBookAddress = ORDER_BOOK_ADDRESS[chainId]
  const orderBookReaderAddress = ORDER_BOOK_READER_ADDRESS[chainId]

  const [state, setState] = useState<{ orders: any[] }>({ orders: [] })

  const swapOrdersKeys = orders ? orders[0] : []
  const increaseOrdersKeys = orders ? orders[1] : []
  const decreaseOrdersKeys = orders ? orders[2] : []

  const fetchOrdersData = useCallback(async () => {
    const userCalls = [
      {
        address: orderBookReaderAddress,
        name: 'getSwapOrders',
        params: [orderBookAddress, swapOrdersKeys],
      },
      {
        address: orderBookReaderAddress,
        name: 'getIncreaseOrders',
        params: [orderBookAddress, increaseOrdersKeys],
      },
      {
        address: orderBookReaderAddress,
        name: 'getDecreaseOrders',
        params: [orderBookAddress, decreaseOrdersKeys],
      },
    ]

    const [SwapOrders, IncreaseOrders, DecreaseOrders] = await multicall(chainId, READER_ABI, userCalls)

    const swapOrdersData = parseSwapOrdersData(chainId, SwapOrders, account, swapOrdersKeys)
    const increaseOrdersData = parseIncreaseOrdersData(chainId, IncreaseOrders, account, increaseOrdersKeys)
    const decreaseOrdersData = parseDecreaseOrdersData(chainId, DecreaseOrders, account, decreaseOrdersKeys)

    const userOrders = [...swapOrdersData, ...increaseOrdersData, ...decreaseOrdersData]

    setState((prev) => ({
      ...prev,
      orders: userOrders,
    }))

    /* setState([...swapOrdersData, ...increaseOrdersData, ...decreaseOrdersData]) */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, orders])

  useEffect(() => {
    if (account) {
      fetchOrdersData()
    }
  }, [account, fetchOrdersData, orders])

  return { ...state, fetchOrdersData }
}

export default useWalletOrders
