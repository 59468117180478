import { SupportedChainId } from './chains'

export const XGMT_EXCLUDED_ACCOUNTS = [
  '0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5',
  '0xd9b1c23411adbb984b1c4be515fafc47a12898b2',
  '0xa633158288520807f91ccc98aa58e0ea43acb400',
  '0xffd0a93b4362052a336a7b22494f1b77018dd34b',
]

const CONTRACTS: any = {
  [SupportedChainId.BSC]: {
    NATIVE_TOKEN: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    Vault: '0x9a990F0cb944fB902f390DEa93F076704C3bf866',
    Router: '0x905d3a98565a6CD208eeb0906b2a90867242CeCb',
    VaultReader: '0x9Cc252695fA58eC6D5791482159a892F5D63f1d1',
    Reader: '0x055090d7B83b2F589dFBd336cc610Db15FB8C328',
    FlpManager: '0xf2a1Ec8bE8F9aB59876ec9561D5aBD4bD48cE8Eb',
    RewardRouter: '0x4faeCd1a0f5eCbBA9c5f2C23Bbb97601f932e056',
    RewardReader: '0xA3F03Fdd7Db4209c593E7c54556f854afad578AE',
    FLP: '0x700D5c08F2d3084956193707E1F0C01A08A44CBc',
    FALCON: '0xeaF63C706bBf597673560c0e69c16D5418C6f699',
    USDF: '0xb2296E3e7eCa2475A09cf20711682F53A0e3e0B9',

    OrderBook: '0x38817262B563e4b6ce0F3B0c8484F4e1B635ee46',
    OrderBookReader: '0x813f1c1CE2ecE5Bf9019F4EcE66B4c331498FEfe',

    PositionRouter: '0x758dFC6621247C3D54A302f2c73A84a358DC2320',
    PositionManager: '0xC18fe504b9EC06Aa61c13062ee804ffF02f9A1C5',

    ReferralStorage: '0xB9336d33e3eA0E5C028bf8f01bB89B964E97B834',
    ReferralReader: '0x80f145CE38b572779D6D34fCa9900b037F2F5674',

    FalconPool: '0xac4A3a007956195a0c2855cFd142D698C668A740',
    JlpPool: '0x3f903fA87Bf5F271E80EE58B18d39d8E30dd085c',

    Distributor: '0xEb4Cd39194b46c4C0a575B0b17F68444b03D9fD9',
    FalconPair: '0x6BD47B1aA440E1F883fB9eBF97a0D5D93327bBd5',
  },
  [SupportedChainId.BSC_TESTNET]: {
    NATIVE_TOKEN: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    Vault: '0xb3D02d1599678925D94514e163893196F417C2d9',
    Router: '0x48715E2e7511657Da3F02a39064ff676af148914',
    VaultReader: '0x9Ee0F5BA0CabB344B6581A66880103E7bD158412',
    Reader: '0xa4Fa8A8089C1CDd79F0B53190965DA61a45ACADd',
    FlpManager: '0xE5B2d2EaC43e905c8ca2Cd499aacF0AA71993699',
    FLP: '0x6cAa7dF53504d2860DdB5DD3F17C626ecd513247',
    FALCON: '0x874c3DEd49BDAA174e352bc741A491702FdF3025',
    USDF: '0x2eA9BCbb1337fAD763C71BCe60C4D20f3F1c3453',

    OrderBook: '0xD8f4e6F5D026391A0239F76bD4A1ac14bF3B7B0A',
    OrderBookReader: '0x87d8059c6fFD7B68e6239Bd3eE8AA944AeF10FDa',

    PositionRouter: '0x0B28f2E367C867a4E5780BC48DAf5dF44342A2E3',
    PositionManager: '0x118835c7A29aBe10f73Bcc005c3D06a4Ff848D64',

    ReferralStorage: '0xe29F5Afe9ABA68DeCecb247ed90C0c7B47670FD0',
    ReferralReader: '0x3A919DdcC55FC2Ccd4d81A2D0B96080CF3026024',

    FalconPool: '0x2635B6A5Df785c1048e41Db253AdF9095C4eb159',
    JlpPool: '0x9B4e40C8D8bd5291732157470458ae90Bc29460e',

    Distributor: '0x7DfF3078a5775f3A923C66269A171E9EAeFD5ABd',
    FalconPair: '0x6BD47B1aA440E1F883fB9eBF97a0D5D93327bBd5',
  },
}

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`)
  }

  return CONTRACTS[chainId][name]
}
