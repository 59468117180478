import { Trans } from '@lingui/macro'
import FalconBlackLogo from 'assets/images/falcon-black-logo.png'
import FalconWhiteLogo from 'assets/images/falcon-white-logo.png'
import RocketIco from 'assets/images/home/rocket.png'
/* import { DarkCard } from 'components/Card' */
import { AutoColumn } from 'components/Column'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

/* import { isMobile } from 'utils/userAgent' */
import { Community } from './components/Community'
import { Features } from './components/Features'
import { Partners } from './components/Partners'

/* import TopMoversTokens from './components/TopMoversTokens' */

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-20px, -20px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const RocketWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export function Home() {
  const [isDark] = useDarkModeManager()
  return (
    <PageWrapper>
      <ThemedText.TitlePage textAlign="center">
        <Trans>Decentralized Perpetual Exchange</Trans>
      </ThemedText.TitlePage>
      <ThemedText.SubTitle mt="1rem" textAlign="center">
        <Trans>Trade top cryptocurrencies with up to 10x leverage directly from your wallet</Trans>
      </ThemedText.SubTitle>

      {/* <ResponsiveGrid isMobile={isMobile}>
        <DarkCard>
          <AutoColumn gap="sm">
            <ThemedText.LargeHeader color="text1" textAlign="center">
              $10000000
            </ThemedText.LargeHeader>
            <ThemedText.Body textAlign="center">
              <Trans>Total Trading Volume</Trans>
            </ThemedText.Body>
          </AutoColumn>
        </DarkCard>
        <DarkCard>
          <AutoColumn gap="sm">
            <ThemedText.LargeHeader color="text1" textAlign="center">
              $200K
            </ThemedText.LargeHeader>
            <ThemedText.Body textAlign="center">
              <Trans>Open Interest</Trans>
            </ThemedText.Body>
          </AutoColumn>
        </DarkCard>
        <DarkCard>
          <AutoColumn gap="sm">
            <ThemedText.LargeHeader color="text1" textAlign="center">
              1000
            </ThemedText.LargeHeader>
            <ThemedText.Body textAlign="center">
              <Trans>Total Users</Trans>
            </ThemedText.Body>
          </AutoColumn>
        </DarkCard>
      </ResponsiveGrid> */}
      <RocketWrapper>
        <img src={RocketIco} alt="rocket-icon" width={100} />
      </RocketWrapper>

      <Features />

      <Partners />

      <Community />

      <AutoColumn justify="center" gap="lg">
        <img src={isDark ? FalconWhiteLogo : FalconBlackLogo} alt="logo" width={64} />
        <ThemedText.Body>© {new Date().getFullYear()} SpaceDex</ThemedText.Body>
      </AutoColumn>
    </PageWrapper>
  )
}
