/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { AutoRow, RowBetween, RowCenter } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getConstant } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { getNativeToken, getToken, getWrappedToken } from 'constants/tokens'
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from 'constants/ui'
import { cancelDecreaseOrder, handleCancelOrder } from 'domain/legacy'
import { BigNumber } from 'ethers'
import { formatDateTime, getTimeRemaining } from 'lib/dates'
import {
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DECREASE,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  getExchangeRate,
  getExchangeRateDisplay,
  getProfitPrice,
  INCREASE,
  LIMIT,
  MIN_PROFIT_TIME,
  PRECISION,
  USD_DECIMALS,
} from 'lib/legacy'
import { expandDecimals, formatAmount, formatAmountFree } from 'lib/numbers'
import { useCallback, useMemo, useState } from 'react'
import { AlertTriangle, ArrowRight, CheckSquare, Square } from 'react-feather'
import { FaArrowDown } from 'react-icons/fa'
import { useUserSlippage } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100) // 1%;

function getSpread(fromTokenInfo: any, toTokenInfo: any, isLong: boolean, nativeTokenAddress: string) {
  if (fromTokenInfo && fromTokenInfo.maxPrice && toTokenInfo && toTokenInfo.minPrice) {
    const fromDiff = fromTokenInfo.maxPrice.sub(fromTokenInfo.minPrice).div(2)
    const fromSpread = fromDiff.mul(PRECISION).div(fromTokenInfo.maxPrice.add(fromTokenInfo.minPrice).div(2))
    const toDiff = toTokenInfo.maxPrice.sub(toTokenInfo.minPrice).div(2)
    const toSpread = toDiff.mul(PRECISION).div(toTokenInfo.maxPrice.add(toTokenInfo.minPrice).div(2))

    let value = fromSpread.add(toSpread)

    const fromTokenAddress = fromTokenInfo.isNative ? nativeTokenAddress : fromTokenInfo.address
    const toTokenAddress = toTokenInfo.isNative ? nativeTokenAddress : toTokenInfo.address

    if (isLong && fromTokenAddress === toTokenAddress) {
      value = fromSpread
    }

    return {
      value,
      isHigh: value.gt(HIGH_SPREAD_THRESHOLD),
    }
  }

  return null
}

interface ConfirmationModalProps {
  fromToken: any
  fromTokenInfo: any
  toToken: any
  toTokenInfo: any
  isSwap: boolean
  isLong: boolean
  isMarketOrder: boolean
  orderOption: any
  isShort: boolean
  toAmount: BigNumber | undefined
  fromAmount: any
  isHigherSlippageAllowed: any
  setIsHigherSlippageAllowed: any
  onConfirmationClick: any
  setIsConfirming: any
  shortCollateralAddress: any
  hasExistingPosition: any
  leverage: any
  existingPosition: any
  existingLiquidationPrice: any
  displayLiquidationPrice: any
  shortCollateralToken: any
  isPendingConfirmation: any
  triggerPriceUsd: any
  triggerRatio: BigNumber
  fees: any
  feesUsd: any
  isSubmitting: any
  fromUsdMin: any
  toUsdMax: any
  nextAveragePrice: any
  collateralTokenAddress: any
  feeBps: any
  chainId: any
  orders: any
  library: any
  setPendingTxns: any
  pendingTxns: any
  minExecutionFee: any
  minExecutionFeeUSD: any
  minExecutionFeeErrorMessage: any
  onDismiss: () => void
  isOpen: boolean
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    fromToken,
    fromTokenInfo,
    toToken,
    toTokenInfo,
    isSwap,
    isLong,
    isMarketOrder,
    orderOption,
    isShort,
    toAmount,
    fromAmount,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    onConfirmationClick,
    setIsConfirming,
    shortCollateralAddress,
    hasExistingPosition,
    leverage,
    existingPosition,
    existingLiquidationPrice,
    displayLiquidationPrice,
    shortCollateralToken,
    isPendingConfirmation,
    triggerPriceUsd,
    triggerRatio,
    fees,
    feesUsd,
    isSubmitting,
    fromUsdMin,
    toUsdMax,
    nextAveragePrice,
    collateralTokenAddress,
    feeBps,
    chainId,
    orders,
    library,
    setPendingTxns,
    pendingTxns,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    isOpen,
    onDismiss,
  } = props

  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')
  const savedSlippageAmount = useUserSlippage()
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false)
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] = useState(false)
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false)

  const onCancelOrderClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns })
    },
    [library, pendingTxns, setPendingTxns, chainId]
  )

  let minOut: any
  let fromTokenUsd: any
  let toTokenUsd: any

  let collateralAfterFees = fromUsdMin
  if (feesUsd) {
    collateralAfterFees = fromUsdMin.sub(feesUsd)
  }

  if (isSwap) {
    minOut = toAmount?.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)

    fromTokenUsd = fromTokenInfo ? formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true) : 0
    toTokenUsd = toTokenInfo ? formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true) : 0
  }

  const getTitle = () => {
    if (!isMarketOrder) {
      return t`Confirm Limit Order`
    }
    if (isSwap) {
      return t`Confirm Swap`
    }
    return isLong ? t`Confirm Long` : t`Confirm Short`
  }
  const title = getTitle()

  const existingOrder = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId)
    for (const order of orders) {
      if (order.type !== INCREASE) continue
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address
      if (order.isLong === isLong && sameToken) {
        return order
      }
    }
  }, [orders, chainId, isLong, toToken.address, toToken.isNative])

  const existingOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId)
    return orders.filter((order: any) => {
      if (order.type !== INCREASE) return false
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address
      return order.isLong === isLong && sameToken
    })
  }, [orders, chainId, isLong, toToken.address, toToken.isNative])

  const existingTriggerOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId)
    return orders.filter((order: any) => {
      if (order.type !== DECREASE) return false
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address
      return order.isLong === isLong && sameToken
    })
  }, [orders, chainId, isLong, toToken.address, toToken.isNative])

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    if (isSwap) return []
    return existingTriggerOrders.filter((order: any) => {
      if (order.triggerAboveThreshold) {
        return existingPosition?.markPrice.gte(order.triggerPrice)
      } else {
        return existingPosition?.markPrice.lte(order.triggerPrice)
      }
    })
  }, [existingPosition, existingTriggerOrders, isSwap])

  const getError = () => {
    if (!isSwap && hasExistingPosition && !isMarketOrder) {
      const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition)
      if (hasProfit && delta.eq(0)) {
        return t`Invalid price, see warning`
      }
    }
    if (isMarketOrder && hasPendingProfit && !isProfitWarningAccepted) {
      return t`Forfeit profit not checked`
    }
    return false
  }

  const getPrimaryText = () => {
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return t`Accept confirmation of trigger orders`
    }

    if (!isPendingConfirmation) {
      const error = getError()
      if (error) {
        return error
      }

      if (isSwap) {
        return title
      }
      const action = isMarketOrder ? (isLong ? t`Long` : t`Short`) : t`Create Order`

      if (
        isMarketOrder &&
        MIN_PROFIT_TIME > 0 &&
        hasExistingPosition &&
        existingPosition.delta.eq(0) &&
        existingPosition.pendingDelta.gt(0)
      ) {
        return isLong ? `${t`Forfeit profit and`} ${action}` : t`Forfeit profit and Short`
      }

      return isMarketOrder && MIN_PROFIT_TIME > 0 ? `${t`Accept minimum and`} ${action}` : action
    }

    if (!isMarketOrder) {
      return t`Creating Order...`
    }
    if (isSwap) {
      return t`Swapping...`
    }
    if (isLong) {
      return t`Longing...`
    }
    return t`Shorting...`
  }

  const isPrimaryEnabled = () => {
    if (getError()) {
      return false
    }
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return false
    }
    return !isPendingConfirmation && !isSubmitting
  }

  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const spread = getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress)
  // it's meaningless for limit/stop orders to show spread based on current prices
  const showSpread = isMarketOrder && !!spread

  let allowedSlippage = savedSlippageAmount
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT
  }

  const renderSpreadWarning = useCallback(() => {
    if (!isMarketOrder) {
      return null
    }

    if (spread && spread.isHigh) {
      return (
        <ThemedText.Yellow mb="0.5rem">
          <Trans>The spread is</Trans> {`>`}{' '}
          <Trans>1%, please ensure the trade details are acceptable before confirming</Trans>
        </ThemedText.Yellow>
      )
    }

    return null
  }, [isMarketOrder, spread])

  const renderFeeWarning = useCallback(() => {
    if (orderOption === LIMIT || !feeBps || feeBps <= 60) {
      return null
    }

    if (isSwap) {
      return (
        <ThemedText.Yellow mb="0.5rem">
          <Trans>Fees are high to swap from</Trans> {fromToken.symbol} <Trans>to</Trans> {toToken.symbol}.
        </ThemedText.Yellow>
      )
    }

    if (!collateralTokenAddress) {
      return null
    }

    const collateralToken = getToken(chainId, collateralTokenAddress)
    return (
      <ThemedText.Error error>
        <Trans>Fees are high to swap from</Trans> {fromToken.symbol} <Trans>to</Trans> {collateralToken.symbol}. <br />
        {collateralToken.symbol} <Trans>is needed for collateral</Trans>.
      </ThemedText.Error>
    )
  }, [feeBps, isSwap, collateralTokenAddress, chainId, fromToken.symbol, toToken.symbol, orderOption])

  const hasPendingProfit =
    MIN_PROFIT_TIME > 0 && existingPosition && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0)

  const renderMinProfitWarning = useCallback(() => {
    if (MIN_PROFIT_TIME === 0) {
      return null
    }
    if (!isSwap) {
      if (hasExistingPosition) {
        const minProfitExpiration = Number(existingPosition.lastIncreasedTime) + MIN_PROFIT_TIME
        if (isMarketOrder && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0)) {
          const profitPrice = getProfitPrice(existingPosition.markPrice, existingPosition)
          return (
            <ThemedText.Yellow mb="0.5rem">
              <Trans>Increasing this position at the current price will forfeit a</Trans>&nbsp;
              <a
                href="https://space-dex.gitbook.io/spcdex/trading#minimum-price-change"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>pending profit</Trans>
              </a>{' '}
              <Trans>of</Trans> {existingPosition.deltaStr}.<br />
              <br />
              <Trans>Profit price</Trans>: {existingPosition.isLong ? '>' : '<'} $
              {formatAmount(profitPrice, USD_DECIMALS, 2, true)}. <Trans>This rule only applies for the next</Trans>{' '}
              {getTimeRemaining(minProfitExpiration)}, <Trans>until</Trans> {formatDateTime(minProfitExpiration)}.
            </ThemedText.Yellow>
          )
        }
        if (!isMarketOrder) {
          const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition)
          if (hasProfit && delta.eq(0)) {
            const profitPrice = getProfitPrice(existingPosition.markPrice, existingPosition)
            return (
              <ThemedText.Yellow mb="0.5rem">
                <Trans>This order will forfeit a</Trans>&nbsp;
                <a
                  href="https://space-dex.gitbook.io/spcdex/trading#minimum-price-change"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>profit</Trans>
                </a>{' '}
                <Trans>of</Trans> {existingPosition.deltaStr}.<br />
                <Trans>Profit price</Trans>: {existingPosition.isLong ? '>' : '<'} $
                {formatAmount(profitPrice, USD_DECIMALS, 2, true)}.<Trans>This rule only applies for the next</Trans>{' '}
                {getTimeRemaining(minProfitExpiration)}, <Trans>until</Trans> {formatDateTime(minProfitExpiration)}.
              </ThemedText.Yellow>
            )
          }
        }
      }

      return (
        <ThemedText.Yellow mb="0.5rem">
          <Trans>A minimum price change of</Trans>&nbsp;
          <a
            href="https://space-dex.gitbook.io/spcdex/trading#minimum-price-change"
            target="_blank"
            rel="noopener noreferrer"
          >
            1.5%
          </a>{' '}
          <Trans>is required for a position to be in profit. This only applies for the first</Trans>{' '}
          {MIN_PROFIT_TIME / 60 / 60} <Trans>hours after increasing a position</Trans>.
        </ThemedText.Yellow>
      )
    }

    return null
  }, [isSwap, hasExistingPosition, existingPosition, isMarketOrder, triggerPriceUsd])

  const renderExistingOrderWarning = useCallback(() => {
    if (isSwap || !existingOrder) {
      return
    }
    const indexToken = getToken(chainId, existingOrder.indexToken)
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    )
    if (existingOrders?.length > 1) {
      return (
        <div>
          <div className="Confirmation-box-info">
            <span>
              <Trans>You have multiple existing Increase</Trans> {existingOrder.isLong ? t`Long` : t`Short`}{' '}
              {indexToken.symbol} <Trans>limit orders</Trans>{' '}
            </span>
            <span onClick={() => setIsLimitOrdersVisible((p) => !p)} className="view-orders">
              ({isLimitOrdersVisible ? t`hide` : t`view`})
            </span>
          </div>
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingOrders.map((order: any) => {
                const { account, index, type, triggerAboveThreshold, triggerPrice } = order
                const id = `${account}-${index}`
                const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
                const indexToken = getToken(chainId, order.indexToken)

                return (
                  <li key={id} className="font-sm">
                    <p>
                      {type === INCREASE ? t`Increase` : t`Decrease`} {indexToken.symbol} {isLong ? t`Long` : t`Short`}{' '}
                      &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                    </p>
                    <button onClick={() => onCancelOrderClick(order)}>
                      <Trans>Cancel</Trans>
                    </button>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )
    }
    return (
      <ThemedText.SubHeader mb="0.5rem">
        <Trans>You have an active Limit Order to Increase</Trans> {existingOrder.isLong ? t`Long` : t`Short`}{' '}
        {sizeInToken} {indexToken.symbol} (${formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}){' '}
        <Trans>at price</Trans> ${formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
      </ThemedText.SubHeader>
    )
  }, [existingOrder, isSwap, chainId, existingOrders, isLong, isLimitOrdersVisible, onCancelOrderClick])

  const renderExistingTriggerErrors = useCallback(() => {
    if (isSwap || decreaseOrdersThatWillBeExecuted?.length < 1) {
      return
    }
    const existingTriggerOrderLength = decreaseOrdersThatWillBeExecuted.length
    const orderText = existingTriggerOrderLength > 1 ? 'orders' : 'order'
    return (
      <>
        <ThemedText.SubHeader mb="0.5rem">
          <Trans>You have</Trans> {existingTriggerOrderLength > 1 ? `${existingTriggerOrderLength}` : 'an'}{' '}
          <Trans>active trigger</Trans> {orderText}{' '}
          <Trans>that might execute immediately after you open this position</Trans>. <Trans>Please cancel the</Trans>{' '}
          {orderText} <Trans>or accept the confirmation to continue</Trans>.
        </ThemedText.SubHeader>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map((order: any) => {
            const { account, index, type, triggerAboveThreshold, triggerPrice } = order
            const id = `${account}-${index}`
            const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
            const indexToken = getToken(chainId, order.indexToken)
            return (
              <li key={id} className="font-sm">
                <p>
                  {type === INCREASE ? t`Increase` : t`Decrease`} {indexToken.symbol} {isLong ? t`Long` : t`Short`}
                  &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                </p>
                <button
                  onClick={() =>
                    cancelDecreaseOrder(chainId, library, index, {
                      successMsg: t`Order cancelled`,
                      failMsg: t`Cancel failed`,
                      sentMsg: t`Cancel submitted`,
                      pendingTxns,
                      setPendingTxns,
                    })
                  }
                >
                  <Trans>Cancel</Trans>
                </button>
              </li>
            )
          })}
        </ul>
      </>
    )
  }, [decreaseOrdersThatWillBeExecuted, isSwap, chainId, library, pendingTxns, setPendingTxns, isLong])

  const renderExistingTriggerWarning = useCallback(() => {
    if (
      isSwap ||
      existingTriggerOrders.length < 1 ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingOrderWarning()
    ) {
      return
    }
    const existingTriggerOrderLength = existingTriggerOrders.length
    return (
      <ThemedText.SubHeader mb="0.5rem">
        <Trans>You have</Trans> {existingTriggerOrderLength > 1 ? `${existingTriggerOrderLength}` : 'an'}{' '}
        <Trans>active trigger</Trans> {existingTriggerOrderLength > 1 ? t`orders` : t`order`}{' '}
        <Trans>that could impact this position</Trans>.
      </ThemedText.SubHeader>
    )
  }, [existingTriggerOrders, isSwap, decreaseOrdersThatWillBeExecuted, renderExistingOrderWarning])

  const renderMain = useCallback(() => {
    if (isSwap) {
      return (
        <Header>
          <ThemedText.Body>
            <Trans>Pay</Trans>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
            {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)})
          </ThemedText.Body>
          <FaArrowDown />
          <ThemedText.Body>
            <Trans>Receive</Trans>&nbsp;{formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
            {formatAmount(toUsdMax, USD_DECIMALS, 2, true)})
          </ThemedText.Body>
        </Header>
      )
    }

    return (
      <Header>
        <ThemedText.Body>
          <Trans>Pay</Trans>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
          {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)})
        </ThemedText.Body>
        <FaArrowDown />
        <ThemedText.Body>
          {isLong ? t`Long` : t`Short`}&nbsp;
          {formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
          {formatAmount(toUsdMax, USD_DECIMALS, 2, true)})
        </ThemedText.Body>
      </Header>
    )
  }, [isSwap, fromAmount, fromToken, toToken, fromUsdMin, toUsdMax, isLong, toAmount])

  const SWAP_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, 'SWAP_ORDER_EXECUTION_GAS_FEE')
  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, 'INCREASE_ORDER_EXECUTION_GAS_FEE')
  const executionFee = isSwap ? SWAP_ORDER_EXECUTION_GAS_FEE : INCREASE_ORDER_EXECUTION_GAS_FEE
  const renderExecutionFee = useCallback(() => {
    if (isMarketOrder) {
      return null
    }
    return (
      <RowBetween>
        <ThemedText.SubHeader color="text2">
          <Trans>Execution Fee</Trans>
        </ThemedText.SubHeader>
        <ThemedText.SubHeader>
          {formatAmount(executionFee, 18, 4)} {getNativeToken(chainId).symbol}
        </ThemedText.SubHeader>
      </RowBetween>
    )
  }, [isMarketOrder, executionFee, chainId])

  const renderAvailableLiquidity = useCallback(() => {
    let availableLiquidity = BigNumber.from(0)
    const riskThresholdBps = 5000
    let isLiquidityRisk = false
    const token = isSwap || isLong ? toTokenInfo : shortCollateralToken

    if (!token || !token.poolAmount || !token.availableAmount) {
      return null
    }

    if (isSwap) {
      const poolWithoutBuffer = token.poolAmount.sub(token.bufferAmount)
      availableLiquidity = token.availableAmount.gt(poolWithoutBuffer) ? poolWithoutBuffer : token.availableAmount
      isLiquidityRisk = availableLiquidity
        .mul(riskThresholdBps)
        .div(BASIS_POINTS_DIVISOR)
        .lt(toAmount ?? 0)
    } else {
      if (isShort) {
        availableLiquidity = token.availableAmount

        let adjustedMaxGlobalShortSize

        if (toTokenInfo.maxAvailableShort && toTokenInfo.maxAvailableShort.gt(0)) {
          adjustedMaxGlobalShortSize = toTokenInfo.maxAvailableShort
            .mul(expandDecimals(1, token.decimals))
            .div(expandDecimals(1, USD_DECIMALS))
        }

        if (adjustedMaxGlobalShortSize && adjustedMaxGlobalShortSize.lt(token.availableAmount)) {
          availableLiquidity = adjustedMaxGlobalShortSize
        }

        const sizeTokens = toUsdMax.mul(expandDecimals(1, token.decimals)).div(token.minPrice)
        isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(sizeTokens)
      } else {
        availableLiquidity = token.availableAmount
        isLiquidityRisk = availableLiquidity
          .mul(riskThresholdBps)
          .div(BASIS_POINTS_DIVISOR)
          .lt(toAmount ?? 0)
      }
    }

    if (!availableLiquidity) {
      return null
    }

    return (
      <RowBetween>
        <ThemedText.SubHeader color="text2">
          <Trans>Available Liquidity</Trans>
        </ThemedText.SubHeader>
        <ThemedText.SubHeader>
          <AutoColumn>
            <AutoRow>
              {formatAmount(availableLiquidity, token.decimals, token.isStable ? 0 : 2, true)} {token.symbol}
              {isLiquidityRisk && (
                <MouseoverTooltip
                  text={
                    <ThemedText.SubHeader>
                      <Trans>
                        The order will only execute if the price conditions are met and there is sufficient liquidity
                      </Trans>
                    </ThemedText.SubHeader>
                  }
                >
                  <WrapperIcon>
                    <AlertTriangle width={14} color="#FF8F00" />
                  </WrapperIcon>
                </MouseoverTooltip>
              )}
            </AutoRow>
          </AutoColumn>
        </ThemedText.SubHeader>
      </RowBetween>
    )
  }, [toTokenInfo, shortCollateralToken, isShort, isLong, isSwap, toAmount, toUsdMax])

  const renderMarginSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderMinProfitWarning()}
          {renderExistingOrderWarning()}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()}
          {minExecutionFeeErrorMessage && (
            <ThemedText.Yellow mb="0.5rem">{minExecutionFeeErrorMessage}</ThemedText.Yellow>
          )}
          {hasPendingProfit && isMarketOrder && (
            <RowBetween>
              <ThemedText.SubHeader>
                <Trans>Forfeit profit</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader
                width="fit-content"
                onClick={() => setIsProfitWarningAccepted(!isProfitWarningAccepted)}
                color="primary1"
              >
                {isProfitWarningAccepted ? <CheckSquare /> : <Square />}
              </ThemedText.SubHeader>
            </RowBetween>
          )}
          <AutoColumn gap="0.2rem">
            {orderOption === LIMIT && renderAvailableLiquidity()}
            {isShort && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Collateral In</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>{getToken(chainId, shortCollateralAddress).symbol}</ThemedText.SubHeader>
              </RowBetween>
            )}
            {isLong && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Collateral In</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>{toTokenInfo.symbol}</ThemedText.SubHeader>
              </RowBetween>
            )}
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Leverage</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                <RowCenter>
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <>
                      {formatAmount(existingPosition.leverage, 4, 2)}x
                      <ArrowRight width={14} />
                    </>
                  )}
                  {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                  {!toAmount && leverage && leverage.gt(0) && `-`}
                  {leverage && leverage.eq(0) && `-`}
                </RowCenter>
              </ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Liq. Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                <RowCenter>
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <>
                      ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                      <ArrowRight width={14} />
                    </>
                  )}
                  {toAmount &&
                    displayLiquidationPrice &&
                    `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                  {!toAmount && displayLiquidationPrice && `-`}
                  {!displayLiquidationPrice && `-`}
                </RowCenter>
              </ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Fees</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>${formatAmount(feesUsd, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Collateral</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>${formatAmount(collateralAfterFees, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
            </RowBetween>
            {showSpread && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Spread</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%
                </ThemedText.SubHeader>
              </RowBetween>
            )}
            {isMarketOrder && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Entry Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  <RowCenter>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                        <ArrowRight width={14} />
                      </div>
                    )}
                    {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                    {!nextAveragePrice && `-`}
                  </RowCenter>
                </ThemedText.SubHeader>
              </RowBetween>
            )}
            {!isMarketOrder && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Limit Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader> ${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
              </RowBetween>
            )}
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Borrow Fee</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {' '}
                {isLong && toTokenInfo && formatAmount(toTokenInfo.fundingRate, 4, 4)}
                {isShort && shortCollateralToken && formatAmount(shortCollateralToken.fundingRate, 4, 4)}
                {((isLong && toTokenInfo && toTokenInfo.fundingRate) ||
                  (isShort && shortCollateralToken && shortCollateralToken.fundingRate)) &&
                  '% / 1h'}
              </ThemedText.SubHeader>
            </RowBetween>
            {!isMarketOrder && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Execution Fee</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}
                </ThemedText.SubHeader>
              </RowBetween>
            )}
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Allowed Slippage</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{formatAmount(allowedSlippage, 2, 2)}%</ThemedText.SubHeader>
            </RowBetween>
            {isMarketOrder && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Allow up to 1% slippage</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader
                  width="fit-content"
                  onClick={() => setIsHigherSlippageAllowed(!isHigherSlippageAllowed)}
                  color="primary1"
                >
                  {isHigherSlippageAllowed ? <CheckSquare /> : <Square />}
                </ThemedText.SubHeader>
              </RowBetween>
            )}
            {decreaseOrdersThatWillBeExecuted.length > 0 && (
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>I am aware of the trigger orders</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader
                  width="fit-content"
                  onClick={() => setIsTriggerWarningAccepted(!isTriggerWarningAccepted)}
                  color="primary1"
                >
                  {isTriggerWarningAccepted ? <CheckSquare /> : <Square />}
                </ThemedText.SubHeader>
              </RowBetween>
            )}
            {renderExecutionFee()}
          </AutoColumn>
        </div>
      </>
    )
  }, [
    renderMain,
    renderMinProfitWarning,
    shortCollateralAddress,
    isShort,
    isLong,
    toTokenInfo,
    nextAveragePrice,
    toAmount,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
    showSpread,
    spread,
    displayLiquidationPrice,
    existingLiquidationPrice,
    feesUsd,
    leverage,
    renderExecutionFee,
    shortCollateralToken,
    chainId,
    renderFeeWarning,
    hasPendingProfit,
    isProfitWarningAccepted,
    renderAvailableLiquidity,
    orderOption,
    collateralAfterFees,
    renderExistingOrderWarning,
    renderExistingTriggerWarning,
    renderExistingTriggerErrors,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    allowedSlippage,
    isTriggerWarningAccepted,
    decreaseOrdersThatWillBeExecuted,
    minExecutionFee,
    nativeTokenSymbol,
    minExecutionFeeErrorMessage,
  ])

  const renderSwapSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderSpreadWarning()}
          {orderOption === LIMIT && renderAvailableLiquidity()}
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Min. Receive</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol}
            </ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromTokenInfo, toTokenInfo)}
            </ThemedText.SubHeader>
          </RowBetween>
          {!isMarketOrder && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Limit Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {getExchangeRateDisplay(triggerRatio, fromTokenInfo, toTokenInfo)}
              </ThemedText.SubHeader>
            </RowBetween>
          )}
          {showSpread && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Spread</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(spread?.value.mul(100), USD_DECIMALS, 2, true)}%
              </ThemedText.SubHeader>
            </RowBetween>
          )}
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Fees</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              {formatAmount(feeBps, 2, 2, true)}% ({formatAmount(fees, fromTokenInfo.decimals, 4, true)}{' '}
              {fromTokenInfo.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
            </ThemedText.SubHeader>
          </RowBetween>
          {renderExecutionFee()}
          {fromTokenUsd && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                {fromTokenInfo.symbol} <Trans>Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{fromTokenUsd} USD</ThemedText.SubHeader>
            </RowBetween>
          )}
          {toTokenUsd && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                {toTokenInfo.symbol} <Trans>Price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{toTokenUsd} USD</ThemedText.SubHeader>
            </RowBetween>
          )}
        </div>
      </>
    )
  }, [
    renderMain,
    renderSpreadWarning,
    fromTokenInfo,
    toTokenInfo,
    orderOption,
    showSpread,
    spread,
    feesUsd,
    feeBps,
    renderExecutionFee,
    fromTokenUsd,
    toTokenUsd,
    triggerRatio,
    fees,
    isMarketOrder,
    minOut,
    renderFeeWarning,
    renderAvailableLiquidity,
  ])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        {isSwap && renderSwapSection()}
        {!isSwap && renderMarginSection()}
        <ButtonPrimary mt="1rem" onClick={onConfirmationClick} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  )
}
