/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { DarkCard } from 'components/Card'
import { RowBetween, RowCenter } from 'components/Row'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { COLORS, convertToPercents, tooltipFormatter, tooltipLabelFormatter, yaxisFormatter } from '../helpers'
import { useChartViewState } from '../hooks/useChartViewState'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

const convertToPercentsHandler = (data: any) => {
  convertToPercents(data, { ignoreKeys: ['cumulative', 'movingAverageAll'], totalKey: 'all' })
}

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}
export function FeesChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }
  const csvFields = [
    { key: 'swap', name: 'Swap' },
    { key: 'margin', name: 'Margin trading' },
    { key: 'mint', name: 'Mint FLP' },
    { key: 'burn', name: 'Burn FLP' },
    { key: 'liquidation', name: 'Liquidation' },
    { key: 'cumulative', name: 'Cumulative' },
  ]
  const controls = {
    convertToPercents: convertToPercentsHandler,
  }

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({ controls, data })
  return (
    <DarkCard minHeight={600}>
      <RowBetween>
        <Row>
          <ThemedText.Body>
            <Trans>Fees</Trans>
          </ThemedText.Body>
          <CsvLink fields={csvFields} name="Fees" data={formattedData} />
        </Row>
        {/* {controls && (
          <ButtonEmpty width="fit-content">
            <FontAwesomeIcon icon={faPercent} onClick={togglePercentView} border={viewState.isPercentsView} />
          </ButtonEmpty>
        )} */}
      </RowBetween>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart data={formattedData} syncId="syncA">
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />

              <YAxis
                dataKey="all"
                interval="preserveStartEnd"
                tickCount={viewState.isPercentsView ? undefined : 7}
                tickFormatter={viewSettings.yaxisTickFormatter}
                width={yaxisWidth}
              />

              <YAxis
                dataKey="cumulative"
                orientation="right"
                yAxisId="right"
                tickFormatter={yaxisFormatter}
                width={yaxisWidth}
              />

              <Tooltip
                formatter={tooltipFormatter}
                labelFormatter={tooltipLabelFormatter}
                contentStyle={contentStyle}
              />

              <Legend />
              <Bar
                unit={viewSettings.itemsUnit}
                isAnimationActive={false}
                type="monotone"
                dataKey="swap"
                stackId="a"
                name="Swap"
                fill={COLORS[0]}
              />
              <Bar
                unit={viewSettings.itemsUnit}
                isAnimationActive={false}
                type="monotone"
                dataKey="mint"
                stackId="a"
                name="Mint FLP"
                fill={COLORS[1]}
              />
              <Bar
                unit={viewSettings.itemsUnit}
                isAnimationActive={false}
                type="monotone"
                dataKey="burn"
                stackId="a"
                name="Burn FLP"
                fill={COLORS[2]}
              />
              <Bar
                unit={viewSettings.itemsUnit}
                isAnimationActive={false}
                type="monotone"
                dataKey="liquidation"
                stackId="a"
                name="Liquidation"
                fill={COLORS[3]}
              />
              <Bar
                unit={viewSettings.itemsUnit}
                isAnimationActive={false}
                type="monotone"
                dataKey="margin"
                stackId="a"
                name="Margin trading"
                fill={COLORS[4]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                strokeWidth={3}
                dot={false}
                stroke={COLORS[12]}
                dataKey="cumulative"
                yAxisId="right"
                name="Cumulative"
              />
            </ComposedChart>
          </ResponsiveContainer>
          <RowCenter mt="1rem">
            <ThemedText.SubHeader>
              <Trans>
                Collected fees. USD value is calculated with token price at the moment of swap, trade, minting or
                redeeming FLP
              </Trans>
            </ThemedText.SubHeader>
          </RowCenter>
        </>
      )}
    </DarkCard>
  )
}
