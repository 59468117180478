/* eslint-disable no-restricted-imports */
import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client'
import * as ethers from 'ethers'
import { chain, maxBy, minBy, sortBy, sumBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { shortenAddress } from 'utils'

const { JsonRpcProvider } = ethers.providers

const NOW_TS = Math.floor(Date.now() / 1000)
const FIRST_DATE_TS = Math.floor(+new Date(1675036800))
const MOVING_AVERAGE_DAYS = 7
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS

const providers: any = {
  56: new JsonRpcProvider('https://bsc-dataseed.binance.org'),
  97: new JsonRpcProvider('https://rpc.ankr.com/bsc_testnet_chapel'),
}

export const tokenDecimals: any = {
  // BSC
  '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 18, // WBNB
  '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 18, // BTC
  '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 18, // ETH
  '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0': 18, // BUSD

  // BSC Testnet
  '0xae13d989dac2f0debff460ac112a837c89baa7cd': 18, // WBNB
  '0x0a2e97563cbb06d59176fdb4e49faaf9102907ca': 18, // BTC
  '0xd5a513e85adb5545d8acf600fd2bacf84e861a1d': 18, // ETH
  '0x27067f1c61e6778d1dc5ea5057885334c366016e': 18, // BUSD
}

export const tokenSymbols: any = {
  // BSC
  '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 'BNB',
  '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 'BTC',
  '0xf97f4df75117a78c1a5a0dbb814af92458539fb4': 'ETH',
  '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0': 'BUSD',

  // BSC Testnet
  '0xae13d989dac2f0debff460ac112a837c89baa7cd': 'BNB',
  '0x0a2e97563cbb06d59176fdb4e49faaf9102907ca': 'BTC',
  '0xd5a513e85adb5545d8acf600fd2bacf84e861a1d': 'ETH',
  '0x27067f1c61e6778d1dc5ea5057885334c366016e': 'BUSD',
}

const knownSwapSources: any = {
  56: {
    '0xc298c9fbd54b814f6c1df525475a2011e6a737b0': 'SpaceDex', // Router
    '0xc2e8619a6bad7282974ea33d78764805b76d8a60': 'SpaceDex', // FlpPool
    '0x2acf2de56702130134ce297ce1835046c8b06310': 'SpaceDex', // PositionManager
  },
  97: {
    '0xc298c9fbd54b814f6c1df525475a2011e6a737b0': 'SpaceDex', // Router
    '0xc2e8619a6bad7282974ea33d78764805b76d8a60': 'SpaceDex', // FlpPool
    '0x2acf2de56702130134ce297ce1835046c8b06310': 'SpaceDex', // PositionManager
  },
}

function getSwapSource(chainId: number, source: string, top: any) {
  if (knownSwapSources[chainId][source]) {
    return knownSwapSources[chainId][source]
  }
  if (!top.has(source)) {
    return 'Other'
  }
  return shortenAddress(source, 4)
}

function fillNa(arr: any) {
  const prevValues: any = {}
  let keys: any
  if (arr.length > 0) {
    keys = Object.keys(arr[0])
    delete keys.timestamp
    delete keys.id
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key]
        }
      } else {
        prevValues[key] = el[key]
      }
    }
  }
  return arr
}

function getImpermanentLoss(change: any) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1
}

function getChainSubgraph(chainId: number) {
  switch (chainId) {
    case 56:
      return 'ticowork/spcdex-chapel'
    case 97:
      return 'ticowork/spcdex-chapel'
    default:
      return 'ticowork/spcdex-chapel'
  }
}

const defaultFetcher = (url: any) => fetch(url).then((res) => res.json())
export function useRequest(url: any, defaultValue?: any, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(() => {
    const asynFetch = async () => {
      try {
        setLoading(true)
        const data = await fetcher(url)
        setData(data)
      } catch (ex) {
        console.error(ex)
        setError(ex)
      }
      setLoading(false)
    }
    asynFetch()
  }, [fetcher, url])

  return [data, loading, error]
}

export function useCoingeckoPrices(symbol: string | number, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    BNB: 'binancecoin',
  }[symbol]

  const now = Date.now() / 1000
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`

  const [res, loading, error] = useRequest(url)

  const data = useMemo(() => {
    if (!res || res.length === 0 || !res.prices) {
      return null
    }

    const ret = res.prices.map((item: any) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for GLP we store last price of the day
      const timestamp = item[0] - 1
      const groupTs = parseInt((timestamp / 1000 / 86400).toString()) * 86400
      return {
        timestamp: groupTs,
        value: item[1],
      }
    })
    return ret
  }, [res])

  return [data, loading, error]
}

export function useGraph(querySource: string, { subgraph = '', subgraphUrl = '', chainId = 97 } = {}) {
  const query = gql(querySource)

  if (subgraphUrl === '') {
    if (subgraph === '') {
      subgraph = getChainSubgraph(chainId)
    }
    subgraphUrl = `https://api.thegraph.com/subgraphs/name/${subgraph}`
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  })
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [querySource, setLoading])

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((ex) => {
        console.warn('Subgraph request failed error: %s subgraphUrl: %s', ex.message, subgraphUrl)
        setError(ex)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySource, setData, setError, setLoading])

  return [data, loading, error]
}

export function useTotalVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: total }
      subgraphError: allow
    ) {
      swap,
      burn,
      mint,
      margin,
      liquidation
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const totalSwap = Number(graphData.volumeStats[0].swap) / 1e30
    const totalBurn = Number(graphData.volumeStats[0].burn) / 1e30
    const totalMint = Number(graphData.volumeStats[0].mint) / 1e30
    const totalMargin = Number(graphData.volumeStats[0].margin) / 1e30
    const totalLig = Number(graphData.volumeStats[0].liquidation) / 1e30

    const total = totalSwap + totalBurn + totalMint + totalMargin + totalLig
    return total
  }, [graphData])

  return [data, loading, error]
}

export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const groups = graphData.fundingRates.reduce((memo: any, item: any) => {
      const symbol = tokenSymbols[item.token]
      if (symbol === 'MIM') {
        return memo
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      }
      const group = memo[item.timestamp]
      const timeDelta = ((item.endTimestamp - item.startTimestamp) / 3600) * 3600

      let fundingRate = 0
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate
        const divisor = timeDelta / 86400
        fundingRate = (fundingDelta / divisor / 10000) * 365
      }
      group[symbol] = fundingRate
      return memo
    }, {})

    return fillNa(sortBy(Object.values(groups), 'timestamp'))
  }, [graphData])

  return [data, loading, error]
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      timestamp
    }
  }`
  const [feesData, loading, error] = useGraph(feesQuery, {
    chainId,
  })

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null
    }

    const chartData = sortBy(feesData.feeStats, 'id').map((item: any) => {
      const ret: any = { timestamp: item.timestamp || item.id }

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30
        }
      })

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0)
      return ret
    })

    let cumulative = 0
    const cumulativeByTs: any = {}
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp: number) => {
        const all = sumBy(values, 'all')
        cumulative += all

        let movingAverageAll
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
        }

        const ret: any = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        }
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop)
        })
        cumulativeByTs[timestamp] = cumulative
        return ret
      })
      .value()
      .filter((item: any) => item.timestamp >= from)
  }, [PROPS, feesData, from])

  return [feesChartData, loading, error]
}

export function useFlpData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const timestampProp = 'timestamp'
  const query = `{
    jlpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdj
      jlpSupply
      distributedUsd
      distributedEth
    }
  }`
  const [data, loading, error] = useGraph(query, { chainId })

  let cumulativeDistributedUsdPerFlp = 0
  let cumulativeDistributedEthPerFlp = 0
  const glpChartData = useMemo(() => {
    if (!data) {
      return null
    }

    let prevFlpSupply: any
    let prevAum: any

    let ret = sortBy(data.jlpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1]

        const aum = Number(item.aumInUsdj) / 1e18
        const flpSupply = Number(item.jlpSupply) / 1e18

        const distributedUsd = Number(item.distributedUsd) / 1e30
        const distributedUsdPerFlp = distributedUsd / flpSupply || 0
        cumulativeDistributedUsdPerFlp += distributedUsdPerFlp

        const distributedEth = Number(item.distributedEth) / 1e18
        const distributedEthPerFlp = distributedEth / flpSupply || 0
        cumulativeDistributedEthPerFlp += distributedEthPerFlp

        const flpPrice = aum / flpSupply
        const timestamp = parseInt(item[timestampProp])

        const newItem = {
          timestamp,
          aum,
          flpSupply,
          flpPrice,
          cumulativeDistributedEthPerFlp,
          cumulativeDistributedUsdPerFlp,
          distributedUsdPerFlp,
          distributedEthPerFlp,
        }

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem
        } else {
          memo.push(newItem)
        }

        return memo
      }, [])
      .map((item: any) => {
        let { flpSupply, aum } = item
        if (!flpSupply) {
          flpSupply = prevFlpSupply
        }
        if (!aum) {
          aum = prevAum
        }
        item.flpSupplyChange = prevFlpSupply ? ((flpSupply - prevFlpSupply) / prevFlpSupply) * 100 : 0
        if (item.flpSupplyChange > 1000) {
          item.flpSupplyChange = 0
        }
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0
        if (item.aumChange > 1000) {
          item.aumChange = 0
        }
        prevFlpSupply = flpSupply
        prevAum = aum
        return item
      })

    ret = fillNa(ret)
    return ret
  }, [data])

  return [glpChartData, loading, error]
}

export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const timestampProp = 'timestamp'
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret: any = { timestamp: item[timestampProp] }
      let all = 0
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    const cumulativeByTs: any = {}
    return ret.map((item: any) => {
      cumulative += item.all

      let movingAverageAll
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  return [data, loading, error]
}

export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod = 86400 }) {
  const [feesData, feesLoading] = useFeesData({ from, to })
  const [flpData, flpLoading] = useFlpData({ from, to })
  const [volumeData, volumeLoading] = useVolumeData({ from, to })

  const dailyCoef = 86400 / groupPeriod

  const data = useMemo(() => {
    if (!feesData || !flpData || !volumeData) {
      return null
    }

    const ret = feesData.map((feeItem: any, i: any) => {
      const glpItem = flpData[i]
      const volumeItem = volumeData[i]
      let apr = feeItem?.all && glpItem?.aum ? (feeItem.all / glpItem.aum) * 100 * 365 * dailyCoef : 0
      if (apr > 10000) {
        apr = 0
      }
      let usage = volumeItem?.all && glpItem?.aum ? (volumeItem.all / glpItem.aum) * 100 * dailyCoef : 0
      if (usage > 10000) {
        usage = 0
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      }
    })
    const averageApr = ret.reduce((memo: any, item: any) => item.apr + memo, 0) / ret.length
    ret.forEach((item: any) => (item.averageApr = averageApr))
    const averageUsage = ret.reduce((memo: any, item: any) => item.usage + memo, 0) / ret.length
    ret.forEach((item: any) => (item.averageUsage = averageUsage))
    return ret
  }, [dailyCoef, feesData, flpData, volumeData])

  return [data, feesLoading || flpLoading || volumeLoading]
}

export function useFlpPerformanceData(
  flpData: any,
  feesData: any,
  { from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}
) {
  const [btcPrices] = useCoingeckoPrices('BTC', { from })
  const [ethPrices] = useCoingeckoPrices('ETH', { from })
  const [bnbPrices] = useCoingeckoPrices('BNB', { from })

  const flpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !bnbPrices || !flpData || !feesData) {
      return null
    }

    if (flpData.length === 0 || feesData.length === 0) {
      return null
    }

    const flpDataById = flpData.reduce((memo: any, item: any) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    const feesDataById = feesData.reduce((memo: any, item: any) => {
      memo[item.timestamp] = item
      return memo
    })

    let BTC_WEIGHT = 0
    let ETH_WEIGHT = 0
    let BNB_WEIGHT = 0

    switch (chainId) {
      case 56:
        BTC_WEIGHT = 0.2
        ETH_WEIGHT = 0.2
        BNB_WEIGHT = 0.2
        break
      case 97:
        BTC_WEIGHT = 0.2
        ETH_WEIGHT = 0.2
        BNB_WEIGHT = 0.2
        break

      default:
        BTC_WEIGHT = 0.2
        ETH_WEIGHT = 0.2
        BNB_WEIGHT = 0.2
        break
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - BNB_WEIGHT
    const FLP_START_PRICE = flpDataById[btcPrices[0].timestamp]?.flpPrice || 1

    const btcFirstPrice = btcPrices[0]?.value
    const ethFirstPrice = ethPrices[0]?.value
    const bnbFirstPrice = bnbPrices[0]?.value

    let indexBtcCount = (FLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice
    let indexEthCount = (FLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice
    let indexBnbCount = (FLP_START_PRICE * BNB_WEIGHT) / bnbFirstPrice
    let indexStableCount = FLP_START_PRICE * STABLE_WEIGHT

    const lpBtcCount = (FLP_START_PRICE * 0.5) / btcFirstPrice
    const lpEthCount = (FLP_START_PRICE * 0.5) / ethFirstPrice
    const lpBnbCount = (FLP_START_PRICE * 0.5) / bnbFirstPrice

    const ret = []
    let cumulativeFeesPerFlp = 0
    let lastFlpItem
    let lastFeesItem

    let prevEthPrice = 3400
    let prevBnbPrice = 600
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value
      const ethPrice = ethPrices[i]?.value || prevEthPrice
      const bnbPrice = bnbPrices[i]?.value || prevBnbPrice
      prevBnbPrice = bnbPrice
      prevEthPrice = ethPrice

      const timestampGroup = parseInt((btcPrices[i].timestamp / 86400).toString()) * 86400
      const flpItem: any = flpDataById[timestampGroup] || lastFlpItem
      lastFlpItem = flpItem

      const flpPrice = flpItem?.flpPrice
      const flpSupply = flpItem?.flpSupply

      const feesItem: any = feesDataById[timestampGroup] || lastFeesItem
      lastFeesItem = feesItem

      const dailyFees = feesItem?.all

      const syntheticPrice =
        indexBtcCount * btcPrice + indexEthCount * ethPrice + indexBnbCount * bnbPrice + indexStableCount

      // rebalance each day. can rebalance each X days
      if (i % 1 === 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice
        indexBnbCount = (syntheticPrice * BNB_WEIGHT) / bnbPrice
        indexStableCount = syntheticPrice * STABLE_WEIGHT
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + FLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice))
      const lpEthPrice =
        (lpEthCount * ethPrice + FLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice))
      const lpBnbPrice =
        (lpBnbCount * bnbPrice + FLP_START_PRICE / 2) * (1 + getImpermanentLoss(bnbPrice / bnbFirstPrice))

      if (dailyFees && flpSupply) {
        const INCREASED_FLP_REWARDS_TIMESTAMP = 1635714000
        const FLP_REWARDS_SHARE = timestampGroup >= INCREASED_FLP_REWARDS_TIMESTAMP ? 0.7 : 0.5
        const collectedFeesPerFlp = (dailyFees / flpSupply) * FLP_REWARDS_SHARE
        cumulativeFeesPerFlp += collectedFeesPerFlp
      }

      let flpPlusFees = flpPrice
      if (flpPrice && flpSupply && cumulativeFeesPerFlp) {
        flpPlusFees = flpPrice + cumulativeFeesPerFlp
      }

      let flpApr
      let flpPlusDistributedUsd
      let flpPlusDistributedEth
      if (flpItem) {
        if (flpItem.cumulativeDistributedUsdPerFlp) {
          flpPlusDistributedUsd = flpPrice + flpItem.cumulativeDistributedUsdPerFlp
          // glpApr = glpItem.distributedUsdPerGlp / glpPrice * 365 * 100 // incorrect?
        }
        if (flpItem.cumulativeDistributedEthPerFlp) {
          flpPlusDistributedEth = flpPrice + flpItem.cumulativeDistributedEthPerFlp * ethPrice
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpBnbPrice,
        flpPrice,
        btcPrice,
        ethPrice,
        flpPlusFees,
        flpPlusDistributedUsd,
        flpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexBnbCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        BNB_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((flpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: ((flpPlusFees / lpEthPrice) * 100).toFixed(2),
        performanceLpEthDistributedUsd: ((flpPlusDistributedUsd / lpEthPrice) * 100).toFixed(2),
        performanceLpEthDistributedEth: ((flpPlusDistributedEth / lpEthPrice) * 100).toFixed(2),

        performanceLpBtcCollectedFees: ((flpPlusFees / lpBtcPrice) * 100).toFixed(2),

        performanceLpBnbCollectedFees: ((flpPlusFees / lpBnbPrice) * 100).toFixed(2),

        performanceSynthetic: ((flpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: ((flpPlusFees / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticDistributedUsd: ((flpPlusDistributedUsd / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticDistributedEth: ((flpPlusDistributedEth / syntheticPrice) * 100).toFixed(2),

        flpApr,
      })
    }

    return ret
  }, [btcPrices, ethPrices, bnbPrices, flpData, feesData, chainId])

  return [flpPerformanceChartData]
}

export function useTradersData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainId }
  )
  const [feesData] = useFeesData({ from, to, chainId })
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {}
    }

    let feesCumulative = 0
    return feesData.reduce((memo: any, { timestamp, margin: fees }: { timestamp: any; margin: any }) => {
      feesCumulative += fees
      memo[timestamp] = {
        fees,
        feesCumulative,
      }
      return memo
    }, {})
  }, [feesData])

  let ret = null
  let currentPnlCumulative = 0
  let currentProfitCumulative = 0
  let currentLossCumulative = 0
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map((dataItem) => {
        const longOpenInterest = dataItem.longOpenInterest / 1e30
        const shortOpenInterest = dataItem.shortOpenInterest / 1e30
        const openInterest = longOpenInterest + shortOpenInterest

        // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
        // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

        const profit = dataItem.profit / 1e30
        const loss = dataItem.loss / 1e30
        const profitCumulative = dataItem.profitCumulative / 1e30
        const lossCumulative = dataItem.lossCumulative / 1e30
        const pnlCumulative = profitCumulative - lossCumulative
        const pnl = profit - loss
        currentProfitCumulative += profit
        currentLossCumulative -= loss
        currentPnlCumulative += pnl
        return {
          longOpenInterest,
          shortOpenInterest,
          openInterest,
          profit,
          loss: -loss,
          profitCumulative,
          lossCumulative: -lossCumulative,
          pnl,
          pnlCumulative,
          timestamp: dataItem.timestamp,
          currentPnlCumulative,
          currentLossCumulative,
          currentProfitCumulative,
        }
      })
    : null

  if (data && data.length > 0) {
    const maxProfit = maxBy(data, (item) => item.profit)?.profit ?? 0
    const maxLoss = minBy(data, (item) => item.loss)?.loss ?? 0
    const maxProfitLoss = Math.max(maxProfit, -maxLoss)

    const maxPnl = maxBy(data, (item) => item.pnl)?.pnl ?? 0
    const minPnl = minBy(data, (item) => item.pnl)?.pnl ?? 0
    const maxCurrentCumulativePnl = maxBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ?? 0
    const minCurrentCumulativePnl = minBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ?? 0

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative
    const currentLossCumulative = data[data.length - 1].currentLossCumulative
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(Math.abs(maxCurrentCumulativePnl), Math.abs(minCurrentCumulativePnl)),
    }

    ret = {
      data,
      stats,
    }
  }

  return [ret, loading]
}

function getSwapSourcesFragment(skip = 0, from: number, to: number) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `
}

export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const { a, b, c, d, e } = graphData
    const all = [...a, ...b, ...c, ...d, ...e]

    const totalVolumeBySource = all.reduce((acc: any, item: any) => {
      const source = knownSwapSources[chainId][item.source] || item.source
      if (!acc[source]) {
        acc[source] = 0
      }
      acc[source] += item.swap / 1e30
      return acc
    }, {})
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a: any, b: any) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    )

    const ret = chain(all)
      .groupBy((item: any) => (item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            const source = getSwapSource(chainId, item.source, topVolumeSources)
            if (item.swap !== 0) {
              const volume = item.swap / 1e30
              memo[source] = memo[source] || 0
              memo[source] += volume
              all += volume
            }
            return memo
          }, {}),
        }

        retItem.all = all

        return retItem
      })
      .sortBy((item) => item.timestamp)
      .value()

    return ret
  }, [chainId, graphData])

  return [data, loading, error]
}

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = 56 } = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const prevUniqueCountCumulative: any = {}
  let cumulativeNewUserCount = 0
  const data = graphData
    ? sortBy(graphData.userStats, 'timestamp').map((item: any) => {
        const newCountData = ['', 'Swap', 'Margin', 'MintBurn'].reduce((memo: any, type) => {
          memo[`new${type}Count`] = prevUniqueCountCumulative[type]
            ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
            : item[`unique${type}Count`]
          prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`]
          return memo
        }, {})
        cumulativeNewUserCount += newCountData.newCount
        const oldCount = item.uniqueCount - newCountData.newCount
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1)
        return {
          all: item.uniqueCount,
          uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        }
      })
    : null

  return [data, loading, error]
}

export function useLastSubgraphBlock(chainId = 56) {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainId }
  )
  const [block, setBlock] = useState(null)

  useEffect(() => {
    if (!data) {
      return
    }

    providers[chainId]
      .getBlock(data._meta.block.number)
      .then((block: any) => {
        setBlock(block)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }, [chainId, data, setBlock])

  return [block, loading, error]
}

export function useLastBlock(chainId = 56) {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    providers[chainId]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [chainId])

  return [data, loading, error]
}

export function useTokenStats({ from = FIRST_DATE_TS, to = NOW_TS, period = 'daily', chainId = 56 } = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`

  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null
    }

    const fullData: any = Object.values(graphData).reduce((memo: any, records: any) => {
      memo.push(...records)
      return memo
    }, [])

    const retrievedTokens = new Set()

    const timestampGroups = fullData.reduce((memo: any, item: any) => {
      const { timestamp, token, ...stats } = item

      const symbol = tokenSymbols[token] || token

      retrievedTokens.add(symbol)

      memo[timestamp] = memo[timestamp || 0] || {}

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      }

      return memo
    }, {})

    const poolAmountUsdRecords: any = []

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]: [any, any]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo: any, [token, stats]: [any, any]) => {
          memo.all += stats.poolAmountUsd
          memo[token] = stats.poolAmountUsd
          memo.timestamp = timestamp

          return memo
        },
        { all: 0 }
      )

      poolAmountUsdRecords.push(poolAmountUsdRecord)
    })

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    }
  }, [graphData, loading])

  return [data, loading, error]
}
