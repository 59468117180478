import { JsonRpcProvider } from '@ethersproject/providers'

import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

export const MAINNET_PROVIDER = new JsonRpcProvider(`https://bsc-dataseed.binance.org`)

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.BSC]: `https://bsc-dataseed.binance.org`,
  [SupportedChainId.BSC_TESTNET]: `https://rpc.ankr.com/bsc_testnet_chapel`,
}

export const INFURA_IPFS_URL = 'https://ipfs.infura.io:5001/api/v0'
export const INFURA_IPFS_GATEWAY = 'https://space-dex.infura-ipfs.io/ipfs'
