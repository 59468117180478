import { Trans } from '@lingui/macro'
import React from 'react'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import BarChartLoaderSVG from './BarChartLoaderSVG'
import CandleChartLoaderSVG from './CandleChartLoaderSVG'
import LineChartLoaderSVG from './LineChartLoaderSVG'

const LoadingText = styled(Box)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 80%;
  left: 0;
  right: 0;
  text-align: center;
`

const LoadingIndicator = styled(Box)`
  top: 10%;
  height: 100%;
  position: relative;
`

export const BarChartLoader: React.FC = () => {
  return (
    <LoadingIndicator>
      <BarChartLoaderSVG />
      <LoadingText>
        <ThemedText.MediumHeader color="textSubtle">
          <Trans>Loading chart data...</Trans>
        </ThemedText.MediumHeader>
      </LoadingText>
    </LoadingIndicator>
  )
}

export const LineChartLoader: React.FC = () => {
  return (
    <LoadingIndicator>
      <LineChartLoaderSVG />
      <LoadingText>
        <ThemedText.MediumHeader color="textSubtle">
          <Trans>Loading chart data...</Trans>
        </ThemedText.MediumHeader>
      </LoadingText>
    </LoadingIndicator>
  )
}

export const CandleChartLoader: React.FC = () => {
  return (
    <LoadingIndicator>
      <CandleChartLoaderSVG />
      <LoadingText>
        <ThemedText.MediumHeader color="textSubtle">
          <Trans>Loading chart data...</Trans>
        </ThemedText.MediumHeader>
      </LoadingText>
    </LoadingIndicator>
  )
}
