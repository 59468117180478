import { ZERO_ADDRESS } from 'constants/misc'
import { isValidToken } from 'constants/tokens'
import { DECREASE, INCREASE, SWAP } from 'lib/legacy'

export function _parseOrdersData(
  ordersData: any,
  keys: any,
  extractor: any,
  uintPropsLength: any,
  addressPropsLength: any,
  account?: string
) {
  if (!ordersData || ordersData.length === 0) {
    return []
  }
  const [uintProps, addressProps] = ordersData
  const count = uintProps.length / uintPropsLength

  const orders = []
  for (let i = 0; i < count; i++) {
    const sliced = addressProps
      .slice(addressPropsLength * i, addressPropsLength * (i + 1))
      .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)))

    if (sliced[0] === ZERO_ADDRESS && sliced[1] === ZERO_ADDRESS) {
      continue
    }

    const order = extractor(sliced)
    order.index = keys[i]
    order.account = account
    orders.push(order)
  }

  return orders
}

export function parseSwapOrdersData(chainId: number, swapOrdersData: any, account?: string, indexes?: any) {
  if (!swapOrdersData || swapOrdersData.length < 2) {
    return []
  }

  const extractor = (sliced: any) => {
    const triggerAboveThreshold = sliced[6].toString() === '1'
    const shouldUnwrap = sliced[7].toString() === '1'

    return {
      path: [sliced[0], sliced[1], sliced[2]].filter((address) => address !== ZERO_ADDRESS),
      amountIn: sliced[3],
      minOut: sliced[4],
      triggerRatio: sliced[5],
      triggerAboveThreshold,
      type: SWAP,
      shouldUnwrap,
    }
  }
  return _parseOrdersData(swapOrdersData, indexes, extractor, 5, 3, account).filter((order) => {
    return order.path.every((token: any) => isValidToken(chainId, token))
  })
}

export function parseDecreaseOrdersData(chainId: number, decreaseOrdersData: any, account?: string, indexes?: any) {
  const extractor = (sliced: any) => {
    const isLong = sliced[4].toString() === '1'
    return {
      collateralToken: sliced[0],
      indexToken: sliced[1],
      collateralDelta: sliced[2],
      sizeDelta: sliced[3],
      isLong,
      triggerPrice: sliced[5],
      triggerAboveThreshold: sliced[6].toString() === '1',
      type: DECREASE,
    }
  }
  return _parseOrdersData(decreaseOrdersData, indexes, extractor, 5, 2, account).filter((order) => {
    return isValidToken(chainId, order.collateralToken) && isValidToken(chainId, order.indexToken)
  })
}

export function parseIncreaseOrdersData(chainId: number, increaseOrdersData: any, account?: string, indexes?: any) {
  const extractor = (sliced: any) => {
    const isLong = sliced[5].toString() === '1'
    return {
      purchaseToken: sliced[0],
      collateralToken: sliced[1],
      indexToken: sliced[2],
      purchaseTokenAmount: sliced[3],
      sizeDelta: sliced[4],
      isLong,
      triggerPrice: sliced[6],
      triggerAboveThreshold: sliced[7].toString() === '1',
      type: INCREASE,
    }
  }
  return _parseOrdersData(increaseOrdersData, indexes, extractor, 5, 3, account).filter((order) => {
    return (
      isValidToken(chainId, order.purchaseToken) &&
      isValidToken(chainId, order.collateralToken) &&
      isValidToken(chainId, order.indexToken)
    )
  })
}
