/* eslint-disable react/prop-types */
import './Tab.css'

import cx from 'classnames'
import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import { isMobile } from 'utils/userAgent'

export default function Tab(props) {
  const { options, option, setOption, onChange, type = 'block', className, optionLabels, icons } = props
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt)
    }
    if (onChange) {
      onChange(opt)
    }
  }

  const [isDark] = useDarkModeManager()

  return (
    <div className={cx('Tab', type, className, { dark: !isDark })}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt
        return (
          <div
            className={cx('Tab-option', 'muted', { active: opt === option }, { mobile: isMobile })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            {icons && icons[opt] && <img className="Tab-option-icon" src={icons[opt]} alt={option} />}
            {label}
          </div>
        )
      })}
    </div>
  )
}
