// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowCenter } from 'components/Row'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWalletModalToggle } from 'state/application/hooks'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { ReferralCodeForm } from './ReferralCodeForm'

export function JoinReferralCode() {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <RowCenter mt="2rem">
      <DarkCard width={isMobile ? '100%' : '50%'}>
        <AutoColumn gap="1rem">
          <ThemedText.LargeHeader textAlign="center">
            <Trans>Enter Referral Code</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.Body textAlign="center">
            <Trans>Please input a referral code to benefit from fee discounts</Trans>.
          </ThemedText.Body>
          {account ? (
            <ReferralCodeForm />
          ) : (
            <ButtonPrimary onClick={toggleWalletModal}>
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          )}
        </AutoColumn>
      </DarkCard>
    </RowCenter>
  )
}
