import { Trans } from '@lingui/macro'
import BSC from 'assets/images/home/technologies/bsc.png'
import BSCLogo from 'assets/images/home/technologies/bsc-logo.png'
import Chainlink from 'assets/images/home/technologies/chainlink.png'
import Link from 'assets/images/home/technologies/link.png'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { darken } from 'polished'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  gap: 5rem;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  margin-top: 2rem;
`

const StyledExternalLink = styled(ExternalLink)`
  padding: 16px;
  width: 100%;
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.primary3};
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg6)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg6)};
    text-decoration: none;
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.bg6)};
    text-decoration: none;
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg6)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg6)};
    text-decoration: none;
  }
`

export function Partners() {
  return (
    <Container>
      <ThemedText.TitlePage textAlign="center" mt="2rem">
        <Trans>Networks and Technologies</Trans>
      </ThemedText.TitlePage>
      <ResponsiveGrid isMobile={isMobile}>
        <DarkCard>
          <RowBetween>
            <img src={BSCLogo} alt="bnb-logo" width={96} />
            <AutoColumn gap="md">
              <img src={BSC} alt="bsc-logo" width={128} />
              <StyledExternalLink href={'https://www.binance.com/'}>
                <Trans>Info</Trans>
              </StyledExternalLink>
            </AutoColumn>
          </RowBetween>
        </DarkCard>
        <DarkCard>
          <RowBetween>
            <img src={Link} alt="chainlink-logo" width={96} />
            <AutoColumn gap="md">
              <img src={Chainlink} alt="link-logo" width={128} />
              <StyledExternalLink href={'https://chain.link/'}>
                <Trans>Info</Trans>
              </StyledExternalLink>
            </AutoColumn>
          </RowBetween>
        </DarkCard>
      </ResponsiveGrid>
    </Container>
  )
}
