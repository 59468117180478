import { Trans } from '@lingui/macro'
import AnalyticsBlackIcon from 'assets/images/home/funcionalities/analytics-black.png'
import AnalyticsWhiteIcon from 'assets/images/home/funcionalities/analytics-white.png'
import BurningBlackIcon from 'assets/images/home/funcionalities/burning-black.png'
import BurningWhiteIcon from 'assets/images/home/funcionalities/burning-white.png'
import RewardsBlackIcon from 'assets/images/home/funcionalities/rewards-black.png'
import RewardsWhiteIcon from 'assets/images/home/funcionalities/rewards-white.png'
import RiskBlackIcon from 'assets/images/home/funcionalities/risk-black.png'
import RiskWhiteIcon from 'assets/images/home/funcionalities/risk-white.png'
import TradeBlackIcon from 'assets/images/home/funcionalities/trade-black.png'
import TradeWhiteIcon from 'assets/images/home/funcionalities/trade-white.png'
import TradingBlackIcon from 'assets/images/home/funcionalities/trading-black.png'
import TradingWhiteIcon from 'assets/images/home/funcionalities/trading-white.png'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  gap: 5rem;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  margin-top: 2rem;
`

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
  `};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    align-items: center;
  `};
`

const Description = styled(ThemedText.Body)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
  `};
`

export function Features() {
  const [isDark] = useDarkModeManager()
  return (
    <Container>
      <ThemedText.TitlePage textAlign="center" mt="2rem">
        <Trans>Features</Trans>
      </ThemedText.TitlePage>
      <ResponsiveGrid isMobile={isMobile}>
        <Box>
          <img src={isDark ? TradeWhiteIcon : TradeBlackIcon} alt="trade-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Perpetual DEX</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>Trade BTC, ETH, BNB and other top cryptocurrencies</Trans>.
            </Description>
          </Column>
        </Box>
        <Box>
          <img src={isDark ? BurningWhiteIcon : BurningBlackIcon} alt="burn-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Burning System</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>All functions of our system buy back and burn to reduce supply</Trans>.
            </Description>
          </Column>
        </Box>
        <Box>
          <img src={isDark ? RiskWhiteIcon : RiskBlackIcon} alt="risk-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Reduce Liquidation Risks</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>An aggregate of high-quality price feeds determine when liquidations occur</Trans>.
            </Description>
          </Column>
        </Box>
        <Box>
          <img src={isDark ? TradingWhiteIcon : TradingBlackIcon} alt="trading-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Trade Orders</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>Trade with our limit order system</Trans>.
            </Description>
          </Column>
        </Box>
        <Box>
          <img src={isDark ? RewardsWhiteIcon : RewardsBlackIcon} alt="rewards-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Save on Costs</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>Enter and exit positions with minimal margin and zero price impact</Trans>.
            </Description>
          </Column>
        </Box>
        <Box>
          <img src={isDark ? AnalyticsWhiteIcon : AnalyticsBlackIcon} alt="burn-ico" width="64px" />
          <Column>
            <ThemedText.MediumHeader>
              <Trans>Analytics</Trans>
            </ThemedText.MediumHeader>
            <Description>
              <Trans>Scan through SpaceDex analytics & historical data</Trans>.
            </Description>
          </Column>
        </Box>
      </ResponsiveGrid>
    </Container>
  )
}
