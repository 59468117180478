import { ALL_SUPPORTED_CHAIN_IDS, DEFAULT_CHAIN_ID } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export function useChainId() {
  let { chainId } = useActiveWeb3React()

  if (!chainId || !ALL_SUPPORTED_CHAIN_IDS.includes(chainId)) {
    chainId = DEFAULT_CHAIN_ID
  }

  return { chainId }
}
