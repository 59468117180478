/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-imports */
import { faRankingStar, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ADDRESS_ZERO } from '@jamonswap/v3-sdk'
import { t, Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import ERC20Abi from 'abis/erc20.json'
import ReaderAbi from 'abis/Reader.json'
import RouterAbi from 'abis/Router.json'
import VaultAbi from 'abis/Vault.json'
import { ButtonPrimary } from 'components/Button'
import { RowCenter } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { getConstant } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { USD_DECIMALS } from 'constants/misc'
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from 'constants/tokens'
import { approvePlugin } from 'domain/legacy'
import { BigNumber, BigNumberish } from 'ethers'
import { useRouterContract } from 'hooks/useContract'
import { useChainId } from 'lib/chains'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import { getPositionKey, getPositions } from 'lib/legacy'
import { useLocalStorageByChainId } from 'lib/localStorage'
import { bigNumberify, formatAmount } from 'lib/numbers'
import { getTokenInfo, useInfoTokens } from 'lib/tokens'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { CheckCircle, Clock } from 'react-feather'
import { Link } from 'react-router-dom'
import { useDarkModeManager, usePnlAfterFees, usePnlLeverage } from 'state/user/hooks'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { Chart, getChartToken } from './components/Chart'
import { History } from './components/History/History'
import { SwapBox } from './components/SwapBox'
import { LONG, SHORT, SWAP } from './config'
import useWalletOrders from './hooks/useWalletOrders'
import { getPageTitle, getPositionQuery, useMinExecutionFee } from './utils'

const PageWrapper = styled.div`
  max-width: 1500px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  `};
`

const BodyLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 70%;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const BodyRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 30%;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const Banner = styled.div<{ dark: boolean }>`
  background: ${({ dark }) =>
    dark ? 'linear-gradient(90deg, #1E356E 30%, #2172E5 100%)' : 'linear-gradient(90deg, #2172E5 30%, #93bbf2 100%)'};
  border-radius: 24px;
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `};
`

const BannerTitle = styled(ThemedText.LargeHeader)`
  color: #ffba37;
  text-shadow: rgba(255, 175, 0, 0.22) 0px 0px 10px, rgba(255, 61, 0, 0.25) 0px 0px 8px,
    rgba(255, 135, 0, 0.55) 0px 0px 18px;
`

const BannerSubTitle = styled(ThemedText.MediumHeader)`
  color: #c3c5cb;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
`

export const Trade = forwardRef((props, ref) => {
  const [isDark] = useDarkModeManager()
  const { account, isActive, provider } = useWeb3React()
  const { chainId } = useChainId()
  const currentAccount = account
  const [pendingPositions, setPendingPositions] = useState<any>([])
  const [updatedPositions, setUpdatedPositions] = useState<any>([])
  const [pendingTxns, setPendingTxns] = useState<any>([])
  const savedShowPnlAfterFees = usePnlAfterFees()
  const savedIsPnlInLeverage = usePnlLeverage()
  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useState(false)

  const showBanner = false

  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')

  const routerContract = useRouterContract()

  const vaultAddress = getContract(chainId, 'Vault')
  /* const positionRouterAddress = getContract(chainId, 'PositionRouter') */
  const positionManagerAddress = getContract(chainId, 'PositionManager')
  const readerAddress = getContract(chainId, 'Reader')
  const usdfAddress = getContract(chainId, 'USDF')

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address)

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress)

  const defaultCollateralSymbol = getConstant(chainId, 'defaultCollateralSymbol')
  const defaultTokenSelection: Record<keyof any, any> = useMemo(
    () => ({
      [SWAP]: {
        from: ADDRESS_ZERO,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: ADDRESS_ZERO,
        to: ADDRESS_ZERO,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: ADDRESS_ZERO,
      },
    }),
    [chainId, defaultCollateralSymbol]
  )

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    'Exchange-token-selection-v2',
    defaultTokenSelection
  )
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, 'Swap-option-v2', LONG)

  const fromTokenAddress = tokenSelection[swapOption].from
  const toTokenAddress = tokenSelection[swapOption].to

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
      newTokenSelection[selectedSwapOption].from = address
      setTokenSelection(newTokenSelection)
    },
    [tokenSelection, setTokenSelection]
  )

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
      newTokenSelection[selectedSwapOption].to = address
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address
        newTokenSelection[SHORT].to = address
      }
      setTokenSelection(newTokenSelection)
    },
    [tokenSelection, setTokenSelection]
  )

  const setMarket = (selectedSwapOption: string, toTokenAddress: any) => {
    setSwapOption(selectedSwapOption)
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
    newTokenSelection[selectedSwapOption].to = toTokenAddress
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress
      newTokenSelection[SHORT].to = toTokenAddress
    }
    setTokenSelection(newTokenSelection)
  }

  const [isConfirming, setIsConfirming] = useState(false)
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false)

  const tokens = getTokens(chainId)

  const tokenAddresses = tokens.map((token) => token.address)
  /* const { balances: tokenBalances } = useTokenBalances(tokenAddresses) */
  const { data: tokenBalances } = useSWR<BigNumber[]>(
    isActive && [isActive, chainId, readerAddress, 'getTokenBalances', account],
    {
      // @ts-ignore
      fetcher: contractFetcher(provider, ReaderAbi, [tokenAddresses]),
    }
  )

  const {
    data: positionData,
    error: positionDataError,
    mutate: mutatePositions,
  } = useSWR(isActive && [isActive, chainId, readerAddress, 'getPositions', vaultAddress, account], {
    fetcher: contractFetcher(provider, ReaderAbi, [
      positionQuery.collateralTokens,
      positionQuery.indexTokens,
      positionQuery.isLong,
    ]),
  })

  const positionsDataIsLoading = isActive && !positionData && !positionDataError

  const { data: fundingRateInfo } = useSWR([isActive, chainId, readerAddress, 'getFundingRates'], {
    fetcher: contractFetcher(provider, ReaderAbi, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  })

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${isActive}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: contractFetcher(provider, VaultAbi),
    }
  )

  const { data: usdfSupply } = useSWR([`Exchange:usdfSupply:${isActive}`, chainId, usdfAddress, 'totalSupply'], {
    fetcher: contractFetcher(provider, ERC20Abi),
  })

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const routerAddress = getContract(chainId, 'Router')
  const { data: orderBookApproved } = useSWR(
    isActive && [isActive, chainId, routerAddress, 'approvedPlugins', account, orderBookAddress],
    {
      fetcher: contractFetcher(provider, RouterAbi),
    }
  )

  const { data: positionRouterApproved } = useSWR(
    isActive && [isActive, chainId, routerAddress, 'approvedPlugins', account, positionManagerAddress], // PositionRouter
    {
      fetcher: contractFetcher(provider, RouterAbi),
    }
  )
  // @ts-ignore
  const { infoTokens } = useInfoTokens(provider, chainId, isActive, tokenBalances, fundingRateInfo)
  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    provider,
    isActive,
    chainId,
    infoTokens
  )

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
    const toToken = getTokenInfo(infoTokens, toTokenAddress)
    const selectedToken = getChartToken(swapOption, fromToken, toToken, chainId)
    const currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true)
    const title = getPageTitle(
      currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? '' : 'USD'}`
    )
    document.title = title
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress])

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  )

  useImperativeHandle(ref, () => ({
    onUpdatePosition(
      key: any,
      size: any,
      collateral: any,
      averagePrice: any,
      entryFundingRate: any,
      reserveAmount: any,
      realisedPnl: any
    ) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i]
        if (position.contractKey === key) {
          // @ts-ignore
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          }
          setUpdatedPositions({ ...updatedPositions })
          break
        }
      }
    },
    onClosePosition(
      key: any,
      size: any,
      collateral: any,
      averagePrice: any,
      entryFundingRate: any,
      reserveAmount: any,
      realisedPnl: any,
      e: any
    ) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i]
        if (position.contractKey === key) {
          // @ts-ignore
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          }
          setUpdatedPositions({ ...updatedPositions })
          break
        }
      }
    },

    onIncreasePosition(
      key: any,
      account: string | undefined,
      collateralToken: any,
      indexToken: string,
      collateralDelta: BigNumberish | undefined,
      sizeDelta: BigNumber | undefined,
      isLong: any,
      price: any,
      fee: any,
      e: any
    ) {
      if (account !== currentAccount) {
        return
      }

      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      let message
      if (sizeDelta?.eq(0)) {
        message = `Deposited ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD into ${tokenSymbol} ${
          isLong ? 'Long' : 'Short.'
        }`
      } else {
        message = `Increased ${tokenSymbol} ${isLong ? 'Long' : 'Short'}, +${formatAmount(
          sizeDelta ?? BigNumber.from(0),
          USD_DECIMALS,
          2,
          true
        )} USD.`
      }

      /* pushSuccessNotification(chainId, message, e) */
    },

    onDecreasePosition(
      key: any,
      account: string | undefined,
      collateralToken: any,
      indexToken: string,
      collateralDelta: BigNumberish | undefined,
      sizeDelta: BigNumber | undefined,
      isLong: any,
      price: any,
      fee: any,
      e: any
    ) {
      if (account !== currentAccount) {
        return
      }

      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      let message
      if (sizeDelta?.eq(0)) {
        message = `Withdrew ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD from ${tokenSymbol} ${
          isLong ? 'Long' : 'Short'
        }.`
      } else {
        message = `Decreased ${tokenSymbol} ${isLong ? 'Long' : 'Short'}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`
      }

      /* pushSuccessNotification(chainId, message, e) */
    },

    onCancelIncreasePosition(
      account: string | undefined,
      path: string | any[],
      indexToken: string,
      amountIn: any,
      minOut: any,
      sizeDelta: any,
      isLong: any,
      acceptablePrice: any,
      executionFee: any,
      blockGap: any,
      timeGap: any,
      e: any
    ) {
      if (account !== currentAccount) {
        return
      }
      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      const message = `Could not increase ${tokenSymbol} ${
        isLong ? 'Long' : 'Short'
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`

      /* pushErrorNotification(chainId, message, e) */

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong)
      // @ts-ignore
      pendingPositions[key] = {}
      setPendingPositions({ ...pendingPositions })
    },

    onCancelDecreasePosition(
      account: string | undefined,
      path: string | any[],
      indexToken: any,
      collateralDelta: any,
      sizeDelta: any,
      isLong: any,
      receiver: any,
      acceptablePrice: any,
      minOut: any,
      executionFee: any,
      blockGap: any,
      timeGap: any,
      e: any
    ) {
      if (account !== currentAccount) {
        return
      }
      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      const message = `Could not decrease ${tokenSymbol} ${
        isLong ? 'Long' : 'Short'
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`

      /* pushErrorNotification(chainId, message, e) */

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong)
      // @ts-ignore
      pendingPositions[key] = {}
      setPendingPositions({ ...pendingPositions })
    },
  }))

  const flagOrdersEnabled = true

  // Get orders
  const { data: userOrders }: any = useSWR(
    isActive && [isActive, chainId, readerAddress, 'getUserOrders', orderBookAddress, account],
    {
      fetcher: contractFetcher(provider, ReaderAbi),
    }
  )

  const { orders, fetchOrdersData } = useWalletOrders(chainId, userOrders)

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false)
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false)
  const [isPluginApproving, setIsPluginApproving] = useState(false)
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false)
  const [cancelOrderIdList, setCancelOrderIdList] = useState([])

  const approveOrderBook = () => {
    setIsPluginApproving(true)
    return approvePlugin(chainId, orderBookAddress, {
      library: provider,
      pendingTxns,
      setPendingTxns,
      sentMsg: t`✔️Enable orders sent.`,
      failMsg: t`❗ Enable orders failed.`,
    })
      .then(() => {
        setIsWaitingForPluginApproval(true)
      })
      .finally(() => {
        setIsPluginApproving(false)
      })
  }

  const approvePositionRouter = async ({ sentMsg, failMsg }: { sentMsg: any; failMsg: any }) => {
    setIsPositionRouterApproving(true)
    try {
      const tx = await routerContract?.approvePlugin(positionManagerAddress)
      setIsWaitingForPositionRouterApproval(true)
      await tx?.wait()
      helperToast.success(`${sentMsg}`)
      setIsPositionRouterApproving(false)
    } catch (error) {
      setIsWaitingForPositionRouterApproval(false)
      setIsPositionRouterApproving(false)
      helperToast.error(`${failMsg}`)
    }
  }

  const LIST_SECTIONS = ['Positions', flagOrdersEnabled ? 'Orders' : undefined, 'Trades'].filter(Boolean)
  // eslint-disable-next-line prefer-const
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, 'List-section-v2', LIST_SECTIONS[0])
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0]
  }

  if (!getToken(chainId, toTokenAddress)) {
    return null
  }

  const onSelectWalletToken = (token: any) => {
    setFromTokenAddress(swapOption, token.address)
  }

  const currentTimestamp = () => new Date().getTime()
  const start = 1671095513 * 1000
  const finish = 1671527513 * 1000

  const getListSection = () => {
    return (
      <History
        positionsDataIsLoading={positionsDataIsLoading}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
        setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
        approveOrderBook={approveOrderBook}
        approvePositionRouter={approvePositionRouter}
        isPluginApproving={isPluginApproving}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPluginApproval={isWaitingForPluginApproval}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        orderBookApproved={orderBookApproved}
        positionRouterApproved={positionRouterApproved}
        positions={positions}
        positionsMap={positionsMap}
        mutatePositions={mutatePositions}
        infoTokens={infoTokens}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        flagOrdersEnabled={flagOrdersEnabled}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        chainId={chainId}
        nativeTokenAddress={nativeTokenAddress}
        setMarket={setMarket}
        orders={orders}
        fetchOrdersData={fetchOrdersData}
        showPnlAfterFees={savedShowPnlAfterFees}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        usdfSupply={usdfSupply}
        totalTokenWeights={totalTokenWeights}
        savedShouldDisableValidationForTesting={false}
        cancelOrderIdList={cancelOrderIdList}
        setCancelOrderIdList={setCancelOrderIdList}
        forSingleAccount={true}
        getTokenInfo={getTokenInfo}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
      />
    )
  }

  return (
    <>
      <PageWrapper>
        {showBanner && (
          <RowCenter>
            <Banner dark={isDark}>
              <BannerTitle fontSize={isMobile ? 16 : 24}>
                <Trans>Trading Competition</Trans>
              </BannerTitle>
              {!isMobile && (
                <>
                  <BannerSubTitle>
                    {currentTimestamp() < finish ? <Clock /> : <CheckCircle />}
                    {currentTimestamp() < start ? (
                      <ThemedText.LargeHeader>
                        <Trans>Starts in</Trans>: <Countdown date={new Date(start)} />
                      </ThemedText.LargeHeader>
                    ) : start < currentTimestamp() && finish > currentTimestamp() ? (
                      <ThemedText.LargeHeader>
                        <Trans>Ends in</Trans>: <Countdown date={new Date(finish)} />
                      </ThemedText.LargeHeader>
                    ) : (
                      <ThemedText.LargeHeader>
                        <Trans>Finished</Trans>
                      </ThemedText.LargeHeader>
                    )}
                  </BannerSubTitle>
                  <ThemedText.LargeHeader color="#ffba37">
                    <FontAwesomeIcon icon={faTrophy} /> $10,000
                  </ThemedText.LargeHeader>
                </>
              )}
              <ButtonPrimary
                as={Link}
                to={`/competition`}
                width="fit-content"
                style={{ height: '10px', background: isDark ? '#1E356E' : '#CED0D9' }}
              >
                <FontAwesomeIcon icon={faRankingStar} width={isMobile ? 16 : 24} />
                <ThemedText.SubHeader ml="4px">
                  <Trans>Rating</Trans>
                </ThemedText.SubHeader>
              </ButtonPrimary>
            </Banner>
          </RowCenter>
        )}
        <Row>
          <BodyLeft>
            <Chart
              fromTokenAddress={fromTokenAddress}
              toTokenAddress={toTokenAddress}
              infoTokens={infoTokens}
              swapOption={swapOption}
              chainId={chainId}
              positions={positions}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              orders={orders}
              setToTokenAddress={setToTokenAddress}
            />
            {!isMobile && getListSection()}
          </BodyLeft>
          <BodyRight>
            <SwapBox
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              orders={orders}
              flagOrdersEnabled={flagOrdersEnabled}
              chainId={chainId}
              infoTokens={infoTokens}
              active={account !== undefined}
              library={provider}
              account={account}
              positionsMap={positionsMap}
              fromTokenAddress={fromTokenAddress}
              setFromTokenAddress={setFromTokenAddress}
              toTokenAddress={toTokenAddress}
              setToTokenAddress={setToTokenAddress}
              swapOption={swapOption}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              tokenSelection={tokenSelection}
              setTokenSelection={setTokenSelection}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
              isPendingConfirmation={isPendingConfirmation}
              setIsPendingConfirmation={setIsPendingConfirmation}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              nativeTokenAddress={nativeTokenAddress}
              savedSlippageAmount={500}
              totalTokenWeights={totalTokenWeights}
              usdgSupply={usdfSupply}
              savedShouldDisableValidationForTesting={false}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            />
            {isMobile && getListSection()}
          </BodyRight>
        </Row>
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
})

Trade.displayName = 'Trade'
