import { Trans } from '@lingui/macro'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { MouseoverTooltip } from 'components/Tooltip'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const ResponsiveFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 4rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 4rem;
  `};
`

const ResponsiveGrid = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding: 0.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  gap: 0.5rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const Card = styled(DarkCard)`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.primary2};
`

const Header = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-bottom: 1px solid ${({ theme }) => theme.primary2};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 1rem;
`

export function CopyTrading() {
  return (
    <PageWrapper>
      <ThemedText.LargeHeader mb="1rem">
        <Trans>Dashboard</Trans>
      </ThemedText.LargeHeader>
      <ResponsiveFlex>
        <DarkCard>
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Volume</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Volume</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              $100
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard>
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Profits</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Profits</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              $100
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard>
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Traders</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Traders</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              100
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard>
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Users</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Users</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              100
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
      </ResponsiveFlex>
      <ResponsiveFlex>
        <Card>
          <Header>
            <ThemedText.LargeHeader textAlign="center" color="text1">
              Top Traders
            </ThemedText.LargeHeader>
          </Header>
          <ResponsiveGrid>
            <ThemedText.Body textAlign="center">
              <Trans>Rank</Trans>
            </ThemedText.Body>
            <ThemedText.Body textAlign="center">
              <Trans>User</Trans>
            </ThemedText.Body>
            <ThemedText.Body textAlign="center">Pnl</ThemedText.Body>
          </ResponsiveGrid>
          <ResponsiveGrid>
            <ThemedText.SubHeader textAlign="center">1</ThemedText.SubHeader>
            <ThemedText.SubHeader textAlign="center">Pepito</ThemedText.SubHeader>
            <ThemedText.SubHeader textAlign="center">10%</ThemedText.SubHeader>
          </ResponsiveGrid>
        </Card>
        <Card>
          <Header>
            <ThemedText.LargeHeader textAlign="center">Top Users</ThemedText.LargeHeader>
          </Header>
          <ResponsiveGrid>
            <ThemedText.Body textAlign="center">
              <Trans>Rank</Trans>
            </ThemedText.Body>
            <ThemedText.Body textAlign="center">
              <Trans>Wallet</Trans>
            </ThemedText.Body>
            <ThemedText.Body textAlign="center">
              <Trans>Profits</Trans>
            </ThemedText.Body>
          </ResponsiveGrid>
          <ResponsiveGrid>
            <ThemedText.SubHeader textAlign="center">1</ThemedText.SubHeader>
            <ThemedText.SubHeader textAlign="center">0x00...0b</ThemedText.SubHeader>
            <ThemedText.SubHeader textAlign="center">$1000</ThemedText.SubHeader>
          </ResponsiveGrid>
        </Card>
      </ResponsiveFlex>
      <Card>
        <Header>
          <ThemedText.LargeHeader>Last Operations</ThemedText.LargeHeader>
        </Header>
        <ResponsiveGrid>
          <ThemedText.Body textAlign="center">
            <Trans>Rank</Trans>
          </ThemedText.Body>
          <ThemedText.Body textAlign="center">
            <Trans>Wallet</Trans>
          </ThemedText.Body>
          <ThemedText.Body textAlign="center">
            <Trans>Profits</Trans>
          </ThemedText.Body>
        </ResponsiveGrid>
        <ResponsiveGrid>
          <ThemedText.SubHeader textAlign="center">1</ThemedText.SubHeader>
          <ThemedText.SubHeader textAlign="center">0x00...0b</ThemedText.SubHeader>
          <ThemedText.SubHeader textAlign="center">$1000</ThemedText.SubHeader>
        </ResponsiveGrid>
      </Card>
    </PageWrapper>
  )
}
