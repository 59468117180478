// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import { useState } from 'react'
import { CheckSquare, Square, X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

interface OrdersToSProps {
  isPluginApproving: boolean
  approveOrderBook: any
  onDismiss: () => void
  isOpen: boolean
}

export function OrdersToS(props: OrdersToSProps) {
  const { isOpen, onDismiss, isPluginApproving, approveOrderBook } = props
  const [isChecked, setIsChecked] = useState(false)

  const onConfirmationClick = () => {
    approveOrderBook().then(() => {
      onDismiss()
    })
  }

  const getPrimaryText = () => {
    if (isPluginApproving) {
      return t`Enabling Orders...`
    }
    if (!isChecked) {
      return t`Accept terms to enable orders`
    }
    return t`Enable Orders`
  }

  const isPrimaryEnabled = () => {
    if (isPluginApproving) {
      return false
    }
    return isChecked
  }
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween mb="1rem">
          <ThemedText.MediumHeader>
            <Trans>Enable Orders</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <AutoColumn gap="0.2rem">
          <ThemedText.SubHeader>
            <Trans>Note that orders are not guaranteed to be executed</Trans>.
            <br />
            <br />
            <Trans>This can occur in a few situations including but not exclusive to</Trans>:
          </ThemedText.SubHeader>
          <br />
          <ThemedText.SubHeader>
            <ul>
              <li>
                <Trans>Insufficient liquidity to execute the order</Trans>
              </li>
              <li>
                <Trans>The mark price which is an aggregate of exchange prices did not reach the specified price</Trans>
              </li>
              <li>
                <Trans>The specified price was reached but not long enough for it to be executed</Trans>
              </li>
              <li>
                <Trans>No keeper picked up the order for execution</Trans>
              </li>
            </ul>
          </ThemedText.SubHeader>
          <ThemedText.SubHeader>
            <Trans>
              Additionally, trigger orders are market orders and are not guaranteed to settle at the trigger price
            </Trans>
            .
          </ThemedText.SubHeader>
          <br />
          <ThemedText.SubHeader>
            <Trans>
              Accept that orders are not guaranteed to execute and trigger orders may not settle at the trigger price
            </Trans>
          </ThemedText.SubHeader>
          <Row>
            <ThemedText.Body
              width="fit-content"
              onClick={() => setIsChecked(!isChecked)}
              color="primary1"
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Trans>Accept</Trans> {isChecked ? <CheckSquare /> : <Square />}
              </Row>
            </ThemedText.Body>
          </Row>
        </AutoColumn>
        <ButtonPrimary mt="1rem" disabled={!isPrimaryEnabled()} onClick={onConfirmationClick}>
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  )
}
