/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import Token from 'abis/erc20.json'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { RowBetween, RowCenter } from 'components/Row'
import { IS_NETWORK_DISABLED, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { BigNumber, ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useFalconPoolContract, useTokenContract } from 'hooks/useContract'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import { PLACEHOLDER_ACCOUNT } from 'lib/legacy'
import { formatAmountFree, formatKeyAmount, parseValue } from 'lib/numbers'
import { useState } from 'react'
import { X } from 'react-feather'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { ButtonText, ExternalLink, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `};
`

interface StakeModalProps {
  chainId: number
  data: any
  onDismiss: () => void
  isOpen: boolean
}

export function StakeModal({ chainId, data, onDismiss, isOpen }: StakeModalProps) {
  const { isActive, account, provider } = useActiveWeb3React()
  const [isApproving, setIsApproving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [amount, setAmount] = useState('')
  const amountMax = formatAmountFree(data.falconBalance ?? BigNumber.from(0), 18, 18)
  const max = formatKeyAmount(data, 'falconBalance', 18, 4, true)
  const [isDark] = useDarkModeManager()

  const falconPoolAddress = getContract(chainId, 'FalconPool')
  const falconAddress = getContract(chainId, 'FALCON')

  const flnAmount = parseValue(amount, 18)
  const flnMax = parseValue(amountMax, 18)

  const { data: tokenAllowance }: any = useSWR(
    [isActive, chainId, falconAddress, 'allowance', account || PLACEHOLDER_ACCOUNT, falconPoolAddress],
    {
      fetcher: contractFetcher(provider, Token),
    }
  )

  const needApproval = tokenAllowance && tokenAllowance.eq(0)
  const tokenContract = useTokenContract(falconAddress)

  const approveFromToken = async () => {
    setIsApproving(true)
    try {
      const tx = await tokenContract?.approve(falconPoolAddress, ethers.constants.MaxUint256)
      await tx?.wait()
      setIsApproving(false)
    } catch (error) {
      console.log(error)
      setIsApproving(false)
    }
  }

  const falconPoolContract = useFalconPoolContract()

  const stake = async () => {
    setIsSubmitting(true)
    try {
      const tx = await falconPoolContract?.stake(flnAmount ?? 0)
      await tx?.wait()
      setIsSubmitting(false)
      helperToast.success(t`✔️ Staked FLN!`)
      onDismiss()
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      helperToast.error(t`❗ Stake error`)
    }
  }

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      return false
    }
    if (isApproving) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (!amount) {
      return false
    }
    if (flnAmount?.eq(0)) {
      return false
    }
    if (flnAmount?.gt(flnMax ?? 0)) {
      return false
    }
    return true
  }

  const getPrimaryText = () => {
    if (!amount) {
      return t`Enter Amount`
    }
    if (flnAmount?.eq(0)) {
      return t`Enter Amount`
    }
    if (flnAmount?.gt(flnMax ?? 0)) {
      return t`Insufficient balance`
    }
    if (isApproving) {
      return t`Approving FLN...`
    }
    if (needApproval) {
      return t`Approve FLN`
    }

    if (isSubmitting) {
      return t`Staking...`
    }

    return t`Stake`
  }

  const onClickPrimary = () => {
    if (needApproval) {
      approveFromToken()
      return
    }

    stake()
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Stake</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        <LightCard $borderRadius="8px" mt="1rem" mb="1rem">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={amount}
              onUserInput={(val) => {
                setAmount(val)
              }}
            />
            <ThemedText.MediumHeader mr="2rem">FLN</ThemedText.MediumHeader>
          </RowBetween>
          <RowBetween mt="0.5rem">
            <ThemedText.Body>
              <Trans>Stake</Trans>
            </ThemedText.Body>
            <ThemedText.Body onClick={() => setAmount(amountMax)} style={{ cursor: 'pointer' }}>
              <Trans>Max</Trans>: {max}
            </ThemedText.Body>
          </RowBetween>
        </LightCard>
        <ButtonPrimary mb="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </ButtonPrimary>
        <ButtonText
          as={ExternalLink}
          href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x37D39950f9C753d62529DbF68fCb4DCa4004fBFd"
          width="fit-content"
          style={{
            height: '10px',
            fontSize: isMobile ? '12px' : '16px',
            color: isDark ? '#fff' : '#000',
            textDecoration: 'none',
          }}
        >
          <RowCenter>
            <Trans>Buy</Trans> Falcon
            <ExternalLinkIcon width={16} />
          </RowCenter>
        </ButtonText>
      </ModalWrapper>
    </Modal>
  )
}
