import DarkLogo from 'assets/images/falcon-black-logo.png'
import WhiteLogo from 'assets/images/falcon-white-logo.png'
import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { css, keyframes } from 'styled-components/macro'

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div<{ mobile: boolean }>`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.mobile
      ? css`
          height: 60vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 80px;
  }
`

const LocalLoader = ({ mobile }: { mobile: boolean }) => {
  const [darkMode] = useDarkModeManager()

  const logoSrc = darkMode ? WhiteLogo : DarkLogo

  return (
    <Wrapper mobile={mobile}>
      <AnimatedImg>
        <img src={logoSrc} alt="loading-icon" />
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader
