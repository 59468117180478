/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import DistributorAbi from 'abis/FalconPoolDistributor.json'
import FlpManager from 'abis/FlpManager.json'
import FlpPool from 'abis/JlpPool.json'
import Reader from 'abis/Reader.json'
import Vault from 'abis/Vault.json'
import { ButtonPrimary } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween, RowCenter } from 'components/Row'
import { getBlocksPerYear, getChainName, getConstant } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { BigNumber } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDistributorContract } from 'hooks/useContract'
import { useChainId } from 'lib/chains'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import { getBalanceAndSupplyData, getProcessedData, GLP_DECIMALS, PLACEHOLDER_ACCOUNT, USD_DECIMALS } from 'lib/legacy'
import { formatKeyAmount } from 'lib/numbers'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { CustomLightSpinner, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { ClaimFalconModal } from './components/ClaimFalconModal'
import { ClaimFlpModal } from './components/ClaimFlpModal'
import { StakeModal } from './components/StakeModal'
import { UnstakeModal } from './components/UnstakeModal'
import useFlnPrice from './hooks/useFlnPrice'

/* import TopMoversTokens from './components/TopMoversTokens' */

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const Card = styled(DarkCard)`
  width: 100%;
  min-height: 400px;
  height: fit-content;
  padding: 1rem;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
`};
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.primary1};
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const Row = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 1rem;
  `};
`

export function Earn() {
  const { isActive, account, provider } = useActiveWeb3React()
  const { chainId } = useChainId()
  const chainName = getChainName(chainId)
  const [distributing, setDistributing] = useState(false)

  const readerAddress = getContract(chainId, 'Reader')
  const vaultAddress = getContract(chainId, 'Vault')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const falconAddress = getContract(chainId, 'FALCON')
  const flpAddress = getContract(chainId, 'FLP')
  const flpManagerAddress = getContract(chainId, 'FlpManager')
  const falconPoolAddress = getContract(chainId, 'FalconPool')
  const jlpPoolAddress = getContract(chainId, 'JlpPool')
  const distributorAddress = getContract(chainId, 'Distributor')

  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')

  const walletTokens = [falconAddress, flpAddress]

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${isActive}`,
      chainId,
      readerAddress,
      'getTokenBalancesWithSupplies',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, Reader, [walletTokens]),
    }
  )

  let falconEarns: any
  let falconStaked: any
  let falconTotalStaked: any
  let FalconRewardsXblock: any
  let flpEarns: any
  let flpStaked: any
  let FlpRewardsXblock: any
  const { data: walletInfo }: any = useSWR(
    [
      `FalconPool:getWalletInfo:${isActive}`,
      chainId,
      falconPoolAddress,
      'getWalletInfo',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )
  const { data: walletEarns }: any = useSWR(
    [
      `FalconPool:getPendingBal:${isActive}`,
      chainId,
      falconPoolAddress,
      'getPendingBal',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )
  if (walletEarns) {
    falconEarns = walletEarns
  }
  const { data: flnTotalStaked }: any = useSWR(
    [`FalconPool:totalStaked:${isActive}`, chainId, falconPoolAddress, 'totalStaked'],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  const { data: rewardsXblock }: any = useSWR(
    [`FalconPool:getRewardsXblock:${isActive}`, chainId, falconPoolAddress, 'getRewardsXblock'],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  const { data: distributionAvailable, mutate: mutateDistribution }: any = useSWR(
    [`Distributor:canDistribute:${isActive}`, chainId, distributorAddress, 'canDistribute'],
    {
      fetcher: contractFetcher(provider, DistributorAbi),
    }
  )

  const canDistribute = Boolean(distributionAvailable)

  const { data: walletEarnsLP }: any = useSWR(
    [`FlpPool:getPendingBal:${isActive}`, chainId, jlpPoolAddress, 'getPendingBal', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )
  if (walletEarnsLP) {
    flpEarns = walletEarnsLP
  }

  const { data: walletInfoLP }: any = useSWR(
    [`FlpPool:getWalletInfo:${isActive}`, chainId, jlpPoolAddress, 'getWalletInfo', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  const { data: rewardsXblockLp }: any = useSWR(
    [`FlpPool:getRewardsXblock:${isActive}`, chainId, jlpPoolAddress, 'getRewardsXblock'],
    {
      fetcher: contractFetcher(provider, FlpPool),
    }
  )

  if (rewardsXblock && rewardsXblockLp) {
    FalconRewardsXblock = rewardsXblock
    FlpRewardsXblock = rewardsXblockLp
  }

  if (walletInfoLP && walletInfo && flnTotalStaked) {
    falconStaked = walletInfo[0]
    flpStaked = walletInfoLP[0]
    falconTotalStaked = flnTotalStaked
  }

  const { data: aums }: any = useSWR([`StakeV2:getAums:${isActive}`, chainId, flpManagerAddress, 'getAums'], {
    fetcher: contractFetcher(provider, FlpManager),
  })

  let aum = BigNumber.from(0)
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2)
  }

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${isActive}`, chainId, vaultAddress, 'getMaxPrice', nativeTokenAddress],
    {
      fetcher: contractFetcher(provider, Vault),
    }
  )

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances)

  const falconPrice = useFlnPrice(chainId)
  const blocksXyear = getBlocksPerYear(chainId)

  const processedData: any = getProcessedData(
    balanceData,
    supplyData,
    aum,
    nativeTokenPrice,
    falconPrice,
    flpStaked,
    falconStaked,
    falconTotalStaked,
    falconEarns,
    flpEarns,
    blocksXyear,
    FalconRewardsXblock,
    FlpRewardsXblock
  )

  const toggleWalletModal = useWalletModalToggle()

  const [openModal, setOpenModal] = useState(0)

  const distributorContract = useDistributorContract()

  const canClaim = walletEarns?.gt(0)
  const canClaimLP = walletEarnsLP?.gt(0)

  const distribute = async () => {
    setDistributing(true)
    try {
      const tx = await distributorContract?.distribute()
      await tx?.wait()
      mutateDistribution()
      setDistributing(false)
      helperToast.success(t`Rewards distributed!`)
    } catch (error) {
      console.log(error)
      setDistributing(false)
      helperToast.error(t`Distribution error`)
    }
  }

  return (
    <PageWrapper>
      <ThemedText.TitlePage textAlign="center">
        <Trans>Earn</Trans>
      </ThemedText.TitlePage>
      <ThemedText.SubTitle mt="1rem" textAlign="center">
        <Trans>Stake Falcon and Falcon LP to earn rewards</Trans>.
      </ThemedText.SubTitle>
      {canDistribute && (
        <RowCenter marginY="1rem" padding="1rem">
          <DarkCard width={isMobile ? '100%' : '50%'}>
            <Row>
              <ThemedText.Body textAlign="center" fontSize={isMobile ? 14 : 16} color="#fff" ml="4px">
                <Trans>Rewards pending distribution</Trans>
              </ThemedText.Body>
              <ButtonPrimary width="fit-content" size="10px" onClick={distribute} disabled={distributing}>
                {distributing ? (
                  <>
                    <Trans>Distributing</Trans>
                    <CustomLightSpinner
                      src="/images/blue-loader.svg"
                      alt="loader"
                      size={'16px'}
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </>
                ) : (
                  <Trans>Distribute</Trans>
                )}
              </ButtonPrimary>
            </Row>
          </DarkCard>
        </RowCenter>
      )}
      <AutoColumn gap="1rem">
        <Wrapper>
          <Card>
            <ThemedText.Body>Falcon</ThemedText.Body>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  ${formatKeyAmount(processedData, 'falconPriceUsd', USD_DECIMALS, 4, true)}
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Wallet</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'falconBalance', GLP_DECIMALS, 2, true)} FLN
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'falconBalanceUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Staked</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'falconStaked', GLP_DECIMALS, 2, true)} FLN
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'falconStakedUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">APR</ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {formatKeyAmount(processedData, 'FalconApr', GLP_DECIMALS, 2, true)}%
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Rewards</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'falconEarns', GLP_DECIMALS, 4, true)} FLN
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'falconEarnsUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Total Staked</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'falconTotalStaked', GLP_DECIMALS, 2, true)} FLN
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'falconTotalStakedUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Total Supply</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'falconSupply', GLP_DECIMALS, 2, true)} FLN
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'falconSupplyUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            {account ? (
              <AutoRow gap="0.5rem" justify="center">
                <ButtonPrimary
                  onClick={() => setOpenModal(1)}
                  width="fit-content"
                  size="10px"
                  fontSize={isMobile ? '12px' : '16px'}
                >
                  <Trans>Stake</Trans>
                </ButtonPrimary>
                <ButtonPrimary
                  onClick={() => setOpenModal(2)}
                  width="fit-content"
                  size="10px"
                  fontSize={isMobile ? '12px' : '16px'}
                >
                  <Trans>Unstake</Trans>
                </ButtonPrimary>
                {canClaim && (
                  <ButtonPrimary
                    onClick={() => setOpenModal(4)}
                    width="fit-content"
                    size="10px"
                    fontSize={isMobile ? '12px' : '16px'}
                  >
                    <Trans>Claim</Trans>
                  </ButtonPrimary>
                )}
              </AutoRow>
            ) : (
              <AutoRow justify="center">
                <ButtonPrimary
                  width="fit-content"
                  size="10px"
                  fontSize={isMobile ? '12px' : '16px'}
                  onClick={toggleWalletModal}
                >
                  <Trans>Connect Wallet</Trans>
                </ButtonPrimary>
              </AutoRow>
            )}
          </Card>
          <Card>
            <ThemedText.Body>FLP ({chainName})</ThemedText.Body>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  ${formatKeyAmount(processedData, 'flpPrice', USD_DECIMALS, 3, true)}
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Wallet</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'flpBalance', GLP_DECIMALS, 2, true)} FLP
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'flpBalanceUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Staked</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'flpStaked', GLP_DECIMALS, 2, true)} FLP
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'flpStakedUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">APR</ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {formatKeyAmount(processedData, 'FlpApr', GLP_DECIMALS, 2, true)}%
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Rewards</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'flpEarns', GLP_DECIMALS, 4, true)} {nativeTokenSymbol}
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'flpEarnsUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            <AutoColumn gap="0.3rem">
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Total Staked</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'flpSupply', 18, 2, true)} FLP
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'flpSupplyUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Total Supply</Trans>
                </ThemedText.SubHeader>
                <AutoColumn>
                  <ThemedText.SubHeader textAlign="right">
                    {formatKeyAmount(processedData, 'flpSupply', 18, 2, true)} FLP
                  </ThemedText.SubHeader>
                  <ThemedText.Italic textAlign="right">
                    ($ {formatKeyAmount(processedData, 'flpSupplyUsd', USD_DECIMALS, 2, true)})
                  </ThemedText.Italic>
                </AutoColumn>
              </RowBetween>
            </AutoColumn>
            <Divider />
            <AutoRow gap="0.5rem" justify="center">
              <ButtonPrimary
                as={Link}
                to={`/buy`}
                width="fit-content"
                style={{ height: '10px', fontSize: isMobile ? '12px' : '16px' }}
              >
                <Trans>Buy</Trans> FLP
              </ButtonPrimary>
              <ButtonPrimary
                as={Link}
                to={`/buy?redeem=true`}
                width="fit-content"
                style={{ height: '10px', fontSize: isMobile ? '12px' : '16px' }}
              >
                <Trans>Sell</Trans> FLP
              </ButtonPrimary>
              {canClaimLP && (
                <ButtonPrimary
                  onClick={() => setOpenModal(3)}
                  width="fit-content"
                  size="10px"
                  fontSize={isMobile ? '12px' : '16px'}
                >
                  <Trans>Claim</Trans>
                </ButtonPrimary>
              )}
            </AutoRow>
          </Card>
        </Wrapper>
      </AutoColumn>
      {openModal === 1 && (
        <StakeModal isOpen={openModal === 1} onDismiss={() => setOpenModal(0)} chainId={chainId} data={processedData} />
      )}
      {openModal === 2 && (
        <UnstakeModal
          isOpen={openModal === 2}
          onDismiss={() => setOpenModal(0)}
          chainId={chainId}
          data={processedData}
        />
      )}
      {openModal === 3 && <ClaimFlpModal isOpen={openModal === 3} onDismiss={() => setOpenModal(0)} />}
      {openModal === 4 && <ClaimFalconModal isOpen={openModal === 4} onDismiss={() => setOpenModal(0)} />}
    </PageWrapper>
  )
}
