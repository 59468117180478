// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { getCodeError, getReferralCodeTakenStatus, getSampleReferrarStat } from 'components/Referrals/referralsHelper'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { helperToast } from 'lib/helperToast'
import { useDebounce } from 'lib/useDebounce'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledInput = styled.input`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 20px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.primary1};
  outline: none;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `};

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text2};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.secondary3};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.secondary3};
  }
`

interface ReferralCodeFormProps {
  handleCreateReferralCode: any
  recentlyAddedCodes: any
  setRecentlyAddedCodes: any
  callAfterSuccess: any
}

export function AffiliateCodeForm({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
  callAfterSuccess,
}: ReferralCodeFormProps) {
  const { account, chainId } = useActiveWeb3React()
  const [referralCode, setReferralCode] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState('')
  const [referralCodeCheckStatus, setReferralCodeCheckStatus] = useState('ok')
  const debouncedReferralCode = useDebounce(referralCode, 300)
  /* useEffect(() => {
    inputRef.current.focus()
  }, []) */

  useEffect(() => {
    let cancelled = false
    const checkCodeTakenStatus = async () => {
      if (error) {
        setReferralCodeCheckStatus('ok')
        return
      }
      const { status: takenStatus } = await getReferralCodeTakenStatus(account, debouncedReferralCode, chainId)
      // ignore the result if the referral code to check has changed
      if (cancelled) {
        return
      }
      if (takenStatus === 'none') {
        setReferralCodeCheckStatus('ok')
      } else {
        setReferralCodeCheckStatus('taken')
      }
    }
    setReferralCodeCheckStatus('checking')
    checkCodeTakenStatus()
    return () => {
      cancelled = true
    }
  }, [account, debouncedReferralCode, error, chainId])

  function getButtonError() {
    if (!debouncedReferralCode) {
      return t`Enter a code`
    }
    if (referralCodeCheckStatus === 'taken') {
      return t`Code already taken`
    }
    if (referralCodeCheckStatus === 'checking') {
      return t`Checking code...`
    }

    return false
  }

  const buttonError = getButtonError()

  function getPrimaryText() {
    if (buttonError) {
      return buttonError
    }

    if (isProcessing) {
      return t`Creating...`
    }

    return t`Create`
  }
  function isPrimaryEnabled() {
    if (buttonError) {
      return false
    }
    if (error || isProcessing) {
      return false
    }
    return true
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    setIsProcessing(true)
    const { status: takenStatus, info: takenInfo } = await getReferralCodeTakenStatus(account, referralCode, chainId)
    if (['all', 'current', 'other'].includes(takenStatus)) {
      setIsProcessing(false)
    }

    if (takenStatus === 'none' || takenStatus === 'other') {
      //@ts-ignore
      const ownerOnOtherNetwork = takenInfo[chainId === SupportedChainId.BSC ? 'ownerBsc' : 'ownerBscTestnet']
      try {
        const tx = await handleCreateReferralCode(referralCode)
        if (callAfterSuccess) {
          callAfterSuccess()
        }
        const receipt = await tx.wait()
        if (receipt.status === 1) {
          recentlyAddedCodes.push(getSampleReferrarStat(referralCode, ownerOnOtherNetwork, account))

          helperToast.success(t`✔️ Referral code created!`)
          setRecentlyAddedCodes(recentlyAddedCodes)
          setReferralCode('')
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsProcessing(false)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        /* ref={inputRef} */
        value={referralCode}
        disabled={isProcessing}
        placeholder={t`Enter a code`}
        onChange={({ target }) => {
          const { value } = target
          setReferralCode(value)
          setError(getCodeError(value))
        }}
      />
      {error && <ThemedText.MediumHeader>{error}</ThemedText.MediumHeader>}
      <ButtonPrimary mt="1rem" type="submit" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </ButtonPrimary>
    </form>
  )
}
