/* eslint-disable no-restricted-imports */
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { tooltipFormatter, tooltipLabelFormatter, yaxisFormatter } from '../helpers'
import { useChartViewState } from '../hooks/useChartViewState'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

const convertToPercentsHandler = (data: any) => {
  return data.map((item: any) => {
    const total = item.profit + Math.abs(item.loss)

    const resultItem = {
      ...item,
      profit: (item.profit / total) * 100,
      loss: (Math.abs(item.loss) / total) * 100,
      all: 100,
    }

    return resultItem
  })
}

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function TradersProfitChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }

  const controls = {
    convertToPercents: convertToPercentsHandler,
  }

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({
    controls,
    data: data?.data,
  })
  return (
    <DarkCard minHeight={600}>
      <RowBetween>
        <Row>
          <ThemedText.Body>
            <Trans>Traders Profit vs. Loss</Trans>
          </ThemedText.Body>
          <ButtonEmpty width="fit-content">
            <CsvLink
              fields={[{ key: 'profit' }, { key: 'loss' }, { key: 'profitCumulative' }, { key: 'lossCumulative' }]}
              name="Traders Profit vs. Loss"
              data={formattedData}
            />
          </ButtonEmpty>
        </Row>
        {controls && (
          <ButtonEmpty width="fit-content">
            <FontAwesomeIcon icon={faPercent} onClick={togglePercentView} border={viewState.isPercentsView} />
          </ButtonEmpty>
        )}
      </RowBetween>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          {' '}
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart data={formattedData} barGap={0} syncId="tradersId">
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
              {viewState.isPercentsView ? (
                <YAxis dataKey="all" tickFormatter={viewSettings.yaxisTickFormatter} width={yaxisWidth} />
              ) : (
                <YAxis
                  domain={[-data?.stats.maxProfitLoss * 1.05, data?.stats.maxProfitLoss * 1.05]}
                  tickFormatter={viewSettings.yaxisTickFormatter}
                  width={yaxisWidth}
                />
              )}
              <YAxis
                domain={[-data?.stats.maxProfitLoss * 1.05, data?.stats.maxProfitLoss * 1.05]}
                tickFormatter={viewSettings.yaxisTickFormatter}
                width={yaxisWidth}
              />
              <YAxis
                domain={[
                  -data?.stats.maxCurrentCumulativeProfitLoss * 1.1,
                  data?.stats.maxCurrentCumulativeProfitLoss * 1.1,
                ]}
                orientation="right"
                yAxisId="right"
                tickFormatter={yaxisFormatter}
                width={yaxisWidth}
              />
              <Tooltip
                formatter={tooltipFormatter}
                labelFormatter={tooltipLabelFormatter}
                contentStyle={contentStyle}
              />
              <Legend />
              {!viewState.isPercentsView && (
                <>
                  <Area
                    yAxisId="right"
                    type="monotone"
                    stroke="#f93333"
                    fill="#f93333"
                    fillOpacity="0.4"
                    dataKey="currentLossCumulative"
                    name="Cumulative Loss"
                    isAnimationActive={false}
                  />
                  <Area
                    yAxisId="right"
                    type="monotone"
                    stroke="#22c761"
                    fill="#22c761"
                    fillOpacity="0.4"
                    dataKey="currentProfitCumulative"
                    name="Cumulative Profit"
                    isAnimationActive={false}
                  />
                </>
              )}
              {viewState.isPercentsView ? (
                <>
                  <Bar
                    unit={viewSettings.itemsUnit}
                    type="monotone"
                    stackId="b"
                    fill="#f93333"
                    dataKey="loss"
                    name="Loss"
                    isAnimationActive={false}
                  />
                  <Bar
                    unit={viewSettings.itemsUnit}
                    type="monotone"
                    stackId="b"
                    fill="#22c761"
                    dataKey="profit"
                    name="Profit"
                    isAnimationActive={false}
                  />
                </>
              ) : (
                <>
                  <Bar type="monotone" fill="#f93333" dataKey="loss" name="Loss" isAnimationActive={false} />
                  <Bar type="monotone" fill="#22c761" dataKey="profit" name="Profit" isAnimationActive={false} />
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <AutoColumn justify="center">
            <ThemedText.SubHeader mt="1rem">
              <Trans>Considers settled (closed) positions</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              <Trans>Fees are not factored into PnL</Trans>
            </ThemedText.SubHeader>
          </AutoColumn>
        </>
      )}
    </DarkCard>
  )
}
