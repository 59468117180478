/* eslint-disable no-restricted-imports */
import LP_ABI from 'abis/lpToken.json'
import { getContract } from 'constants/contracts'
import { BigNumber } from 'ethers'
import useRefresh from 'hooks/useRefresh'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/multicall'

const BIG_DECIMALS = BigNumber.from(10).pow(18)

/**
 * Gets all public data of Pool contract
 */
const useFlnPrice = (chainId: number): BigNumber => {
  const bnbPoolAddress = getContract(chainId, 'FalconPair')
  const { slowRefresh } = useRefresh()

  const [state, setState] = useState(BigNumber.from(0))

  const fetchPriceData = useCallback(async () => {
    const userCalls = [
      {
        address: bnbPoolAddress,
        name: 'getReserves',
      },
    ]

    const [reserves] = await multicall(chainId, LP_ABI, userCalls)

    const reserve0 = BigNumber.from(reserves[0].toString())
    const reserve1 = BigNumber.from(reserves[1].toString())

    const price = reserve1.mul(BIG_DECIMALS).div(reserve0)

    setState(price)
  }, [chainId, bnbPoolAddress])

  useEffect(() => {
    fetchPriceData()
  }, [fetchPriceData, slowRefresh])

  return state
}

export default useFlnPrice
