// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useOrderBookContract } from 'hooks/useContract'
import { helperToast } from 'lib/helperToast'
import { DECREASE, getPositionForOrder, INCREASE, SWAP } from 'lib/legacy'
import { useCallback, useState } from 'react'
import { CheckSquare, Square } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { EditOrderModal } from '../Modals/EditOrderModal'
import { Orders } from './Orders'

const ButtonChecked = styled(ButtonEmpty)`
  width: fit-content;
  height: 0;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const Container = styled.div`
  width: 100%;
`
const ResponsiveGridPositions = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 1fr 0.6fr' : '0.5fr 0.5fr 1.5fr 1fr 1fr 0.5fr 0.55fr')};
`

interface OrderListProps {
  chainId: number
  orders: any
  positionsMap: any
  infoTokens: any
  cancelOrderIdList: any
  setCancelOrderIdList: any
  totalTokenWeights: any
  usdfSupply: any
  fetchOrdersData: any
}

export function OrdersList(props: OrderListProps) {
  const {
    chainId,
    orders,
    positionsMap,
    infoTokens,
    cancelOrderIdList,
    setCancelOrderIdList,
    totalTokenWeights,
    usdfSupply,
    fetchOrdersData,
  } = props
  const { account } = useActiveWeb3React()

  const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length

  const [editingOrder, setEditingOrder] = useState<any>(null)

  const orderBookContract = useOrderBookContract()

  const handleCancelOrder = useCallback(
    async (order: any) => {
      if (order.type === SWAP) {
        try {
          const tx = await orderBookContract?.cancelSwapOrder(order.index)
          helperToast.success(t`✔️ Cancel submitted.`)
          await tx?.wait()
          helperToast.success(t`✔️ Order cancelled.`)
        } catch (error) {
          helperToast.error(error.message)
        }
      } else if (order.type === INCREASE) {
        try {
          const tx = await orderBookContract?.cancelIncreaseOrder(order.index)
          helperToast.success(t`✔️ Cancel submitted.`)
          await tx?.wait()
          helperToast.success(t`✔️ Order cancelled.`)
        } catch (error) {
          helperToast.error(error.message)
        }
      } else if (order.type === DECREASE) {
        try {
          const tx = await orderBookContract?.cancelDecreaseOrder(order.index)
          helperToast.success(t`✔️ Cancel submitted.`)
          await tx?.wait()
          helperToast.success(t`✔️ Order cancelled.`)
        } catch (error) {
          helperToast.error(error.message)
        }
      }
    },
    [orderBookContract]
  )

  return orders && orders.length > 0 ? (
    <Container>
      <ResponsiveGridPositions isMobile={isMobile}>
        {!isMobile && (
          <>
            <ButtonChecked
              onClick={() => {
                if (isAllOrdersSelected) {
                  setCancelOrderIdList([])
                } else {
                  const allOrderIds = orders.map((o: any) => `${o.type}-${o.index}`)
                  setCancelOrderIdList(allOrderIds)
                }
              }}
            >
              {isAllOrdersSelected ? <CheckSquare width={18} /> : <Square width={18} />}
            </ButtonChecked>
            <ThemedText.Body color="text2">
              <Trans>Type</Trans>
            </ThemedText.Body>
          </>
        )}
        <ThemedText.Body color="text2">
          <Trans>Order</Trans>
        </ThemedText.Body>
        {!isMobile && (
          <>
            <ThemedText.Body color="text2">
              <Trans>Price</Trans>
            </ThemedText.Body>
            <ThemedText.Body color="text2">
              <Trans>Mark Price</Trans>
            </ThemedText.Body>
          </>
        )}
      </ResponsiveGridPositions>
      {orders.map((item: any, index: any) => (
        <Orders
          key={index}
          chainId={chainId}
          account={account}
          order={item}
          infoTokens={infoTokens}
          cancelOrderIdList={cancelOrderIdList}
          setCancelOrderIdList={setCancelOrderIdList}
          positionsMap={positionsMap}
          setEditingOrder={setEditingOrder}
          handleCancelOrder={handleCancelOrder}
        />
      ))}

      <EditOrderModal
        account={account}
        order={editingOrder}
        infoTokens={infoTokens}
        getPositionForOrder={getPositionForOrder}
        positionsMap={positionsMap}
        totalTokenWeights={totalTokenWeights}
        usdfSupply={usdfSupply}
        onDismiss={() => setEditingOrder(null)}
        isOpen={Boolean(editingOrder)}
        fetchOrdersData={fetchOrdersData}
      />
    </Container>
  ) : (
    <ThemedText.Body fontSize={isMobile ? 14 : 16}>
      <Trans>No open orders</Trans>
    </ThemedText.Body>
  )
}
