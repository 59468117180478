// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { getTierIdDisplay, getUSDValue, isRecentReferralCodeNotExpired } from 'components/Referrals/referralsHelper'
import { RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getNativeToken, getToken } from 'constants/tokens'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { formatDateLang, formatDateTimeLang } from 'lib/dates'
import { shortenAddress } from 'lib/legacy'
import { bigNumberify, formatAmount } from 'lib/numbers'
import { useMemo, useState } from 'react'
import { HelpCircle, PlusSquare } from 'react-feather'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { isMobile } from 'utils/userAgent'

import { AffiliatesStatsRow } from './AffiliatesStatsRow'
import { CreateReferralModal } from './CreateReferralModal'

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ResponsiveGridCodes = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 1fr 0fr' : '1fr 1fr 1fr 0.8fr')};
`

const ResponsiveGridDistribution = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  gap: 0.5rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`
const tierRebateInfo: Record<number, number> = {
  0: 5,
  1: 10,
  2: 15,
} as const

interface AffiliatesStatsProps {
  referralsData: any
  handleCreateReferralCode: any
  chainId: any
  setRecentlyAddedCodes: any
  recentlyAddedCodes: any
}

export function AffiliatesStats({
  referralsData,
  handleCreateReferralCode,
  chainId,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}: AffiliatesStatsProps) {
  const [isDark] = useDarkModeManager()
  const locale = useActiveLocale()
  const lang = locale === 'es-ES'

  const [openModal, setOpenModal] = useState(false)

  const { cumulativeStats, referrerTotalStats, distributions: rebateDistributions, referrerTierInfo } = referralsData
  const allReferralCodes = referrerTotalStats.map((c: any) => c.referralCode.trim())
  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes.filter(isRecentReferralCodeNotExpired).reduce((acc: any, cv: any) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv)
        }
        return acc
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, recentlyAddedCodes]
  )

  const tierId = referrerTierInfo?.tierId
  let referrerRebates = bigNumberify(0)
  if (cumulativeStats && cumulativeStats.totalRebateUsd && cumulativeStats.discountUsd) {
    referrerRebates = cumulativeStats.totalRebateUsd.sub(cumulativeStats.discountUsd)
  }
  return (
    <>
      <ResponsiveGrid isMobile={isMobile}>
        <DarkCard minHeight="150px">
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Amount of traders you referred</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Traders Referred</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              {cumulativeStats?.registeredReferralsCount || '0'}
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard minHeight="150px">
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Volume traded by your referred traders</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Trading Volume</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              {getUSDValue(cumulativeStats?.volume)}
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard minHeight="150px">
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Rebates earned by this account as an affiliate</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Rebates</Trans>
                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              {getUSDValue(referrerRebates, 4)}
            </ThemedText.MediumHeader>
            <ThemedText.SubHeader textAlign="center" color="text2">
              {referrerTierInfo && `Tier ${getTierIdDisplay(tierId)} (${tierRebateInfo[tierId]}% rebate)`}
            </ThemedText.SubHeader>
          </Column>
        </DarkCard>
      </ResponsiveGrid>
      <DarkCard mb="2rem">
        <RowBetween>
          <ThemedText.MediumHeader color="text1">
            <Trans>Referral Codes</Trans>
          </ThemedText.MediumHeader>
          <ButtonEmpty width="fit-content" size="10px" onClick={() => setOpenModal(true)}>
            <Row style={{ gap: '0.2rem' }}>
              <PlusSquare color={isDark ? '#fff' : '#000'} />
              <ThemedText.Body color="text1">
                <Trans>Create</Trans>
              </ThemedText.Body>
            </Row>
          </ButtonEmpty>
        </RowBetween>
        <Divider />
        <ResponsiveGridCodes isMobile={isMobile}>
          <ThemedText.Body color="text2" textAlign="center">
            <Trans>Referral Code</Trans>
          </ThemedText.Body>
          <ThemedText.Body color="text2" textAlign="center">
            <Trans>Total Volume</Trans>
          </ThemedText.Body>
          {!isMobile && (
            <>
              <ThemedText.Body color="text2" textAlign="center">
                <Trans>Traders Referred</Trans>
              </ThemedText.Body>
              <ThemedText.Body color="text2" textAlign="center">
                <Trans>Total Rebates</Trans>
              </ThemedText.Body>
            </>
          )}
        </ResponsiveGridCodes>
        {finalAffiliatesTotalStats.map((stat: any, index: any) => {
          const ownerOnOtherChain = stat?.ownerOnOtherChain
          let referrerRebate = bigNumberify(0)
          if (stat && stat.totalRebateUsd && stat.discountUsd) {
            referrerRebate = stat.totalRebateUsd.sub(stat.discountUsd)
          }
          return (
            <AffiliatesStatsRow
              key={index}
              chainId={chainId}
              ownerOnOtherChain={ownerOnOtherChain}
              referralCode={stat.referralCode}
              volume={stat.volume}
              registeredReferralsCount={stat.registeredReferralsCount}
              referrerRebate={referrerRebate}
            />
          )
        })}
      </DarkCard>
      {rebateDistributions.length > 0 ? (
        <DarkCard>
          <ResponsiveGridDistribution>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Date</Trans>
            </ThemedText.Body>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Amount</Trans>
            </ThemedText.Body>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Transaction</Trans>
            </ThemedText.Body>
          </ResponsiveGridDistribution>
          <ResponsiveGridDistribution>
            {rebateDistributions.map((rebate: any) => {
              let tokenInfo
              try {
                tokenInfo = getToken(chainId, rebate.token)
              } catch {
                tokenInfo = getNativeToken(chainId)
              }
              const explorerURL = getExplorerLink(chainId, rebate.transactionHash, ExplorerDataType.TRANSACTION)
              return (
                <>
                  <ThemedText.SubHeader textAlign="center">
                    {isMobile ? formatDateLang(rebate.timestamp, lang) : formatDateTimeLang(rebate.timestamp, lang)}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader textAlign="center">
                    {formatAmount(rebate.amount, tokenInfo.decimals, isMobile ? 2 : 6, true)} {tokenInfo.symbol}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader textAlign="center">
                    <a target="_blank" rel="noopener noreferrer" href={explorerURL}>
                      {shortenAddress(rebate.transactionHash, isMobile ? 10 : 20)}
                    </a>
                  </ThemedText.SubHeader>
                </>
              )
            })}
          </ResponsiveGridDistribution>
        </DarkCard>
      ) : (
        <DarkCard>
          <ThemedText.Body textAlign="center">
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>Rebates are airdropped weekly</Trans>
                </ThemedText.SubHeader>
              }
            >
              <Row>
                <Trans>No rebates distribution history yet</Trans>
                <WrapperIcon>
                  <HelpCircle width={16} />
                </WrapperIcon>
              </Row>
            </MouseoverTooltip>
          </ThemedText.Body>
        </DarkCard>
      )}
      <CreateReferralModal
        handleCreateReferralCode={handleCreateReferralCode}
        recentlyAddedCodes={recentlyAddedCodes}
        setRecentlyAddedCodes={setRecentlyAddedCodes}
        onDismiss={() => setOpenModal(false)}
        isOpen={openModal}
      />
    </>
  )
}
