// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import BgImage from 'assets/images/position-share-bg.png'
import SpacedexLogo from 'assets/images/spacedex-logo.png'
import { ButtonLinkPrimary, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { RowAround, RowBetween } from 'components/Row'
import { useAffiliateCodes } from 'domain/referrals'
import { toPng } from 'html-to-image'
import downloadImage from 'lib/downloadImage'
import { helperToast } from 'lib/helperToast'
import { getHomeUrl, getRootShareApiUrl, getTwitterIntentURL, USD_DECIMALS } from 'lib/legacy'
import { formatAmount } from 'lib/numbers'
import useLoadImage from 'lib/useLoadImage'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useRef, useState } from 'react'
import { Copy, Download, Twitter, X } from 'react-feather'
import { useCopyToClipboard } from 'react-use'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const ROOT_SHARE_URL = getRootShareApiUrl()
const UPLOAD_URL = ROOT_SHARE_URL + '/api/upload'
const UPLOAD_SHARE = ROOT_SHARE_URL + '/api/s'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const BackgroundImage = styled.div`
  background-image: url(${BgImage});
  aspect-ratio: 1200 / 675;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  border-radius: 2px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  gap: 0.5rem;
`

const Column = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0.5rem;
    gap: 0.2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.5rem;
    gap: 0.2rem;
  `};
`

interface ModalProps {
  account: any
  chainId: any
  positionToShare: any
  onDismiss: () => void
  isOpen: boolean
}

const config = { quality: 0.8, canvasWidth: 506, canvasHeight: 285, type: 'image/png' }

function getShareURL(imageInfo: any, ref: any) {
  if (!imageInfo) return
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`
  if (ref.success && ref.code) {
    url = url + `&ref=${ref.code}`
  }
  return url
}

export function ShareModal(props: ModalProps) {
  const { account, chainId, positionToShare, isOpen, onDismiss } = props
  const userAffiliateCode = useAffiliateCodes(chainId, account)
  const { deltaAfterFeesPercentageStr, isLong, leverage, indexToken, averagePrice, markPrice } = positionToShare

  const isPositive = deltaAfterFeesPercentageStr.indexOf('+') > -1

  const homeURL = getHomeUrl()
  const [uploadedImageInfo, setUploadedImageInfo] = useState<any>(null)
  const [uploadedImageError, setUploadedImageError] = useState('')
  const [, copyToClipboard] = useCopyToClipboard()
  const sharePositionBgImg = useLoadImage(BgImage)
  const positionRef = useRef<HTMLDivElement>(null)
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.indexToken?.symbol} trade on @spacedexF`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  )

  const handleCopy = () => {
    if (!uploadedImageInfo) return
    const url = getShareURL(uploadedImageInfo, userAffiliateCode)
    if (!url) return
    copyToClipboard(url)
    helperToast.success(t`Link copied to clipboard.`)
  }

  useEffect(() => {
    ;(async function () {
      const element = positionRef.current
      if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
        const image = await toPng(element, config)
        try {
          const imageInfo = await fetch(UPLOAD_URL, { method: 'POST', body: image }).then((res) => res.json())
          setUploadedImageInfo(imageInfo)
        } catch {
          setUploadedImageInfo(null)
          setUploadedImageError(t`Image generation error, please refresh and try again.`)
        }
      }
    })()
  }, [userAffiliateCode, sharePositionBgImg, positionToShare])

  async function handleDownload() {
    const element = positionRef.current
    if (!element) return
    const imgBlob = await toPng(element, config)
    downloadImage(imgBlob, 'share.png')
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Share Position</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        <div ref={positionRef}>
          <BackgroundImage>
            <Column>
              <img src={SpacedexLogo} alt="falcon-logo" width={150} />
              <ThemedText.SubHeader color="#fff" style={{ textTransform: 'uppercase' }}>
                {isLong ? t`Long` : t`Short`} {formatAmount(leverage, 4, 2, true)}x&nbsp;
                {indexToken.symbol} USD
              </ThemedText.SubHeader>
              <ThemedText.MediumHeader color={isPositive ? 'success' : 'error'}>
                {deltaAfterFeesPercentageStr}
              </ThemedText.MediumHeader>
              <AutoColumn justify="flex-start">
                <RowBetween>
                  <ThemedText.Small color="#fff">
                    <Trans>Entry Price</Trans>
                  </ThemedText.Small>
                  <ThemedText.Small color="#fff">
                    <Trans>Index Price</Trans>
                  </ThemedText.Small>
                </RowBetween>
                <RowBetween>
                  <ThemedText.SubHeader color="#fff">
                    ${formatAmount(averagePrice, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader color="#fff">
                    ${formatAmount(markPrice, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                </RowBetween>
              </AutoColumn>
            </Column>
            <Row>
              <QRCodeSVG
                size={isMobile ? 24 : 32}
                value={
                  userAffiliateCode.success && userAffiliateCode.code
                    ? `${homeURL}/#/?ref=${userAffiliateCode.code}`
                    : `${homeURL}`
                }
              />
              {userAffiliateCode.success && userAffiliateCode.code ? (
                <ThemedText.SubHeader color="#fff">
                  <Trans>Referral Code</Trans>: {userAffiliateCode.code}
                </ThemedText.SubHeader>
              ) : (
                <ThemedText.SubHeader color="#fff">{homeURL}</ThemedText.SubHeader>
              )}
            </Row>
          </BackgroundImage>
        </div>
        {uploadedImageError && <span className="error">{uploadedImageError}</span>}
        <RowAround mt="1rem">
          <ButtonPrimary onClick={handleCopy} size="10px" width="fit-content">
            <Copy width={18} />
            {!isMobile && (
              <ThemedText.SubHeader ml="4px">
                <Trans>Copy</Trans>
              </ThemedText.SubHeader>
            )}
          </ButtonPrimary>
          <ButtonPrimary onClick={handleDownload} size="10px" width="fit-content">
            <Download width={18} />
            {!isMobile && (
              <ThemedText.SubHeader ml="4px">
                <Trans>Download</Trans>
              </ThemedText.SubHeader>
            )}
          </ButtonPrimary>
          <ButtonLinkPrimary href={tweetLink} target="_blank" rel="noreferrer">
            <Twitter width={18} />
            {!isMobile && <ThemedText.SubHeader ml="4px">Tweet</ThemedText.SubHeader>}
          </ButtonLinkPrimary>
        </RowAround>
      </ModalWrapper>
    </Modal>
  )
}
