import useScrollPosition from '@react-hook/window-scroll'
import LogoDark from 'assets/images/spacedex-dark-logo.png'
import Logo from 'assets/images/spacedex-logo.png'
import Row from 'components/Row'
import { CHAIN_INFO } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Text } from 'rebass'
import { useDarkModeManager } from 'state/user/hooks'
import { useNativeCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { isChainAllowed } from 'utils/switchChain'
import { isMobile } from 'utils/userAgent'

import Menu from '../Menu'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import SidebarMenu from './SidebarMenu'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding: 0rem 1rem 1rem 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 48px 1fr 1fr;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding:  1rem;
  grid-template-columns: 1fr 1fr;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding:  1rem;
  grid-template-columns: 48px 1fr;
`};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-top: 0;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 0;
`};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-top: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const SpaceIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }

  position: relative;
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 5px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  justify-self: start;  
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  justify-self: center;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: row;
  justify-content: space-between;
  justify-self: center;
  z-index: 99;
  position: fixed;
  bottom: 0; right: 50%;
  transform: translate(50%,-50%);
  margin: 0 auto;
`};
`

export default function Header() {
  const { account, chainId, connector } = useActiveWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()

  const chainAllowed = chainId && isChainAllowed(connector, chainId)
  const activeChain = chainAllowed ? chainId : SupportedChainId.BSC

  const scrollY = useScrollPosition()

  const {
    /* infoLink, */
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[activeChain]

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <SidebarMenu />
      {!isMobile && (
        <HeaderLinks>
          <Title href=".">
            <SpaceIcon>
              <img src={darkMode ? Logo : LogoDark} alt="spacedex-logo" width={256} />
            </SpaceIcon>
          </Title>
        </HeaderLinks>
      )}
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol ?? ''}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElement>
          <Menu />
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
