import { InstagramIcon, TelegramIcon } from '@jamonswap/uikit'
import { Trans } from '@lingui/macro'
/* import CoinmarketcapDark from 'assets/images/home/community/coinmarketcap-dark.png'
import CoinmarketcapLight from 'assets/images/home/community/coinmarketcap-light.png'
import CoinstoreDark from 'assets/images/home/community/coinstore-dark.png'
import CoinstoreLight from 'assets/images/home/community/coinstore-light.png' */
import { ButtonEmpty } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { useState } from 'react'
import { ArrowLeft, Twitter } from 'react-feather'
import { FaNewspaper, FaYoutube } from 'react-icons/fa'
import { IoShareSocialSharp } from 'react-icons/io5'
import { useDarkModeManager, useSetSocialLinks } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { ExternalLink, ThemedText } from '../../theme'

const StyledNetworks = styled.div`
  height: 40px;
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  transition: 250ms ease color;
  background-color: ${({ theme }) => theme.bg2};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 99;
  gap: 1rem;
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const ButtonNetworks = styled(ButtonEmpty)`
  height: 40px;
  background-color: ${({ theme }) => theme.bg3};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`

const FlyoutMenu = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 99;
  padding: 12px;
  gap: 0.5rem;
  bottom: 80%;
`

const ButtonTelegram = styled(TelegramIcon)`
  width: 24px;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ItemDropdown = styled(ExternalLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
    background: ${({ theme }) => theme.bg3};
  }
`

interface Props {
  language?: string
}

export default function SocialNetworks({ language }: Props) {
  const [darkMode] = useDarkModeManager()
  const [showLinks, toggleSetShowLinks] = useSetSocialLinks()

  const [openDropdown, setOpenDropdown] = useState(false)
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown)
  }

  return (
    <>
      <RowFixed onMouseLeave={() => setOpenDropdown(false)}>
        <StyledNetworks>
          {showLinks && (
            <Row>
              <MenuItem href="https://twitter.com/spacedexF">
                <Twitter color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={24} />
              </MenuItem>
              <MenuItem href="https://www.instagram.com/spacedex.io/?r=nametag">
                <InstagramIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={24} />
              </MenuItem>
              <MenuItem href="https://www.youtube.com/channel/UCKy9Q4OxkApjZAdVXg_-D_g">
                <FaYoutube color={darkMode ? '#C4D9F8' : '#4D8FEA'} size={28} />
              </MenuItem>
              <Column>
                <ButtonTelegram onClick={handleOpenDropdown} color={darkMode ? '#C4D9F8' : '#4D8FEA'} />
                {openDropdown && (
                  <AutoColumn justify="center">
                    <FlyoutMenu onMouseLeave={() => setOpenDropdown(false)} onClick={handleOpenDropdown}>
                      <AutoColumn>
                        <ItemDropdown href="https://t.me/newspacedex">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>News</Trans>
                          </ThemedText.Body>
                          <FaNewspaper width={16} color={darkMode ? '#C4D9F8' : '#4D8FEA'} />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDexPortal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>English</Trans>
                          </ThemedText.Body>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/flags/england.png`}
                            width={16}
                            alt="english-flag"
                          />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_ESP_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Spanish</Trans>
                          </ThemedText.Body>
                          <img src={`${process.env.PUBLIC_URL}/images/flags/spain.png`} width={16} alt="spanish-flag" />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_CN">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Chinese</Trans>
                          </ThemedText.Body>
                          <img src={`${process.env.PUBLIC_URL}/images/flags/china.png`} width={16} alt="china-flag" />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_PT_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Portuguese</Trans>
                          </ThemedText.Body>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/flags/portugal.png`}
                            width={16}
                            alt="portugal-flag"
                          />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_VN_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Vietnam</Trans>
                          </ThemedText.Body>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/flags/vietnam.png`}
                            width={16}
                            alt="vietnam-flag"
                          />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_ID_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Indonesia</Trans>
                          </ThemedText.Body>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/flags/indonesia.png`}
                            width={16}
                            alt="indonesia-flag"
                          />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/SpaceDEX_TR_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Turkey</Trans>
                          </ThemedText.Body>
                          <img src={`${process.env.PUBLIC_URL}/images/flags/turkey.png`} width={16} alt="turkey-flag" />
                        </ItemDropdown>
                        <ItemDropdown href="https://t.me/spaceDEX_RUS_Portal">
                          <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                            <Trans>Russia</Trans>
                          </ThemedText.Body>
                          <img src={`${process.env.PUBLIC_URL}/images/flags/russia.png`} width={16} alt="russia-flag" />
                        </ItemDropdown>
                      </AutoColumn>
                    </FlyoutMenu>
                  </AutoColumn>
                )}
              </Column>
              {/* <MenuItem href="https://www.reddit.com/r/JamonSwap">
                <RedditIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={24} />
              </MenuItem> */}
              {/* <MenuItem href="https://github.com/spcdex-io/">
                <GithubIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={24} />
              </MenuItem> */}
              {/* <MenuItem href="https://coinmarketcap.com/currencies/jamonswap/">
                <img src={darkMode ? CoinmarketcapDark : CoinmarketcapLight} width={24} alt="coinmarketcap-logo" />
              </MenuItem>
              <MenuItem href="https://www.coinstore.com/#/spot/JAMONUSDT">
                <img src={darkMode ? CoinstoreDark : CoinstoreLight} width={24} height={28} alt="coinstore-logo" />
              </MenuItem> */}
            </Row>
          )}
          <ButtonNetworks onClick={toggleSetShowLinks}>
            {showLinks ? (
              <ArrowLeft color={darkMode ? '#4D8FEA' : '#2172E5'} />
            ) : (
              <IoShareSocialSharp color={darkMode ? '#4D8FEA' : '#2172E5'} />
            )}
          </ButtonNetworks>
        </StyledNetworks>
      </RowFixed>
    </>
  )
}
