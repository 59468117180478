import { Currency, Ether, NativeCurrency, Token } from '@jamonswap/sdk-core'
import invariant from 'tiny-invariant'

import { SupportedChainId } from './chains'

export const WBNB: Token = new Token(
  SupportedChainId.BSC,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Wrapped BNB'
)

export const WBNB_TESTNET: Token = new Token(
  SupportedChainId.BSC_TESTNET,
  '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  18,
  'WBNB',
  'Wrapped BNB'
)

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  [SupportedChainId.BSC]: WBNB,
  [SupportedChainId.BSC_TESTNET]: WBNB_TESTNET,
}

function isBNB(chainId: number): chainId is SupportedChainId.BSC | SupportedChainId.BSC_TESTNET {
  return chainId === SupportedChainId.BSC_TESTNET || chainId === SupportedChainId.BSC
}

class BNBNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isBNB(this.chainId)) throw new Error('Not bnb')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isBNB(chainId)) throw new Error('Not bnb')
    super(chainId, 18, 'BNB', 'Binance BNB')
  }
}

export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency } = {}
export function nativeOnChain(chainId: number): NativeCurrency {
  return (
    cachedNativeCurrency[chainId] ??
    (cachedNativeCurrency[chainId] = isBNB(chainId) ? new BNBNativeCurrency(chainId) : ExtendedEther.onChain(chainId))
  )
}

/* export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId: number]: string } } = {
  USDC: {
    [SupportedChainId.POLYGON]: POLYGON_TOKENS.USDC.address,
    [SupportedChainId.POLYGON_MUMBAI]: MUMBAI_TOKENS.USDC.address,
    [SupportedChainId.BSC]: BSC_TOKENS.BUSD.address,
    [SupportedChainId.BSC_TESTNET]: BSC_TESTNET_TOKENS.BUSD.address,
  },
} */
