// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonDropdownText, ButtonEmpty, ButtonLight, ButtonPrimary, ButtonTap } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { AutoRow, RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getChainName, getConstant, IS_NETWORK_DISABLED, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY } from 'constants/localStorage'
import { ZERO_ADDRESS } from 'constants/misc'
import { getTokens } from 'constants/tokens'
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from 'constants/ui'
import { invariant, useHasOutdatedUi } from 'domain/legacy'
import { getTokenAmountFromUsd, getTokenInfo, getUsd } from 'domain/tokens'
import { useOrderBookContract, usePositionManagerContract, usePositionRouterContract } from 'hooks/useContract'
import { formatDateTime, getTimeRemaining } from 'lib/dates'
import { helperToast } from 'lib/helperToast'
import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DECREASE,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_USD,
  getLiquidationPrice,
  getMarginFee,
  getNextToAmount,
  getProfitPrice,
  MARKET,
  MIN_PROFIT_TIME,
  PRECISION,
  USD_DECIMALS,
  USDG_DECIMALS,
} from 'lib/legacy'
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from 'lib/localStorage'
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from 'lib/numbers'
import { usePrevious } from 'lib/usePrevious'
import { STOP } from 'pages/Trade/config'
import { getDeltaStr, getLeverage } from 'pages/Trade/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowRight, CheckSquare, Eye, Square, X } from 'react-feather'
import { useUserSlippage } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { TokenModal } from './TokenModal'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const ButtonChecked = styled(ButtonEmpty)`
  width: fit-content;
  height: 0;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.primary1};
`

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1) // $0.10

function shouldSwap(collateralToken: any, receiveToken: any) {
  // If position collateral is WETH in contract, then position.collateralToken is { symbol: “ETH”, isNative: true, … }
  // meaning if collateralToken.isNative === true in reality position has WETH as a collateral
  // and if collateralToken.isNative === true and receiveToken.isNative === true then position’s WETH will be unwrapped and user will receive native ETH
  const isCollateralWrapped = collateralToken.isNative

  const isSameToken =
    collateralToken.address === receiveToken.address || (isCollateralWrapped && receiveToken.isWrapped)

  const isUnwrap = isCollateralWrapped && receiveToken.isNative

  return !isSameToken && !isUnwrap
}

function getSwapLimits(infoTokens: any, fromTokenAddress: any, toTokenAddress: any) {
  const fromInfo = getTokenInfo(infoTokens, fromTokenAddress)
  const toInfo = getTokenInfo(infoTokens, toTokenAddress)

  let maxInUsd: any
  let maxIn: any
  let maxOut: any
  let maxOutUsd: any

  if (!fromInfo?.maxUsdgAmount) {
    maxInUsd = bigNumberify(0)
    maxIn = bigNumberify(0)
  } else {
    maxInUsd = fromInfo.maxUsdgAmount
      .sub(fromInfo.usdgAmount ?? 1)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDG_DECIMALS))

    maxIn = maxInUsd
      .mul(expandDecimals(1, fromInfo.decimals))
      .div(fromInfo.maxPrice ?? 1)
      .toString()
  }

  if (!toInfo?.poolAmount || !toInfo?.bufferAmount) {
    maxOut = bigNumberify(0)
    maxOutUsd = bigNumberify(0)
  } else {
    maxOut = toInfo.availableAmount?.gt(toInfo.poolAmount.sub(toInfo.bufferAmount))
      ? toInfo.poolAmount.sub(toInfo.bufferAmount)
      : toInfo.availableAmount

    maxOutUsd = getUsd(maxOut, toInfo.address, false, infoTokens)
  }

  return {
    maxIn,
    maxInUsd,
    maxOut,
    maxOutUsd,
  }
}

interface EditPositionModalProps {
  pendingPositions: any
  setPendingPositions: any
  positionsMap: any
  mutatePositions: any
  positionKey: any
  account: any
  infoTokens: any
  flagOrdersEnabled: any
  savedIsPnlInLeverage: any
  chainId: any
  nativeTokenAddress: any
  orders: any
  isWaitingForPluginApproval: any
  isPluginApproving: any
  orderBookApproved: any
  setOrdersToaOpen: any
  positionRouterApproved: any
  isWaitingForPositionRouterApproval: any
  isPositionRouterApproving: any
  approvePositionRouter: any
  isHigherSlippageAllowed: any
  setIsHigherSlippageAllowed: any
  minExecutionFee: any
  minExecutionFeeErrorMessage: any
  usdfSupply: any
  totalTokenWeights: any
  onDismiss: () => void
  isOpen: boolean
}

export function ClosePositionModal(props: EditPositionModalProps) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    mutatePositions,
    positionKey,
    account,
    infoTokens,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    isWaitingForPluginApproval,
    isPluginApproving,
    orderBookApproved,
    setOrdersToaOpen,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    minExecutionFee,
    minExecutionFeeErrorMessage,
    usdfSupply,
    totalTokenWeights,
    onDismiss,
    isOpen,
  } = props
  const [openModal, setOpenModal] = useState(false)
  const savedSlippageAmount = useUserSlippage()
  //@ts-ignore
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, 'Exchange-keep-leverage'], true)
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined
  const [fromValue, setFromValue] = useState('')
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const prevIsVisible = usePrevious(isOpen)
  const positionRouterAddress = getContract(chainId, 'PositionRouter')
  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')
  const toTokens = getTokens(chainId)

  const positionRouterContract = usePositionRouterContract()
  const positionManagerContract = usePositionManagerContract()
  const orderBookContract = useOrderBookContract()

  const [savedRecieveTokenAddress, setSavedRecieveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${CLOSE_POSITION_RECEIVE_TOKEN_KEY}-${position?.indexToken?.symbol}-${position.isLong ? 'long' : 'short'}`,
    ''
  )

  const [swapToToken, setSwapToToken] = useState(() =>
    savedRecieveTokenAddress ? toTokens.find((token) => token.address === savedRecieveTokenAddress) : undefined
  )

  let allowedSlippage = savedSlippageAmount
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT
  }

  const orderOptions = [MARKET, STOP]
  const [orderOption, setOrderOption] = useState(MARKET)

  if (!flagOrdersEnabled) {
    setOrderOption(MARKET)
  }

  const needPositionRouterApproval = !positionRouterApproved && orderOption === MARKET

  const onOrderOptionChange = (option: any) => {
    setOrderOption(option)
  }

  const onTriggerPriceChange = (value: any) => {
    setTriggerPriceValue(value)
  }

  const [triggerPriceValue, setTriggerPriceValue] = useState('')
  const triggerPriceUsd = orderOption === MARKET ? bigNumberify(0) : parseValue(triggerPriceValue, USD_DECIMALS)

  const [nextDelta, nextHasProfit = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false]
    }

    if (orderOption !== STOP) {
      return [position.delta, position.hasProfit, position.deltaPercentage]
    }

    if (!triggerPriceUsd) {
      return [bigNumberify(0), false]
    }

    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(triggerPriceUsd, position)
    return [delta, hasProfit, deltaPercentage]
  }, [position, orderOption, triggerPriceUsd])

  const existingOrders = useMemo(() => {
    if (orderOption === STOP && (!triggerPriceUsd || triggerPriceUsd.eq(0))) {
      return []
    }
    if (!orders || !position) {
      return []
    }

    const ret = []
    for (const order of orders) {
      // only Stop orders can't be executed without corresponding opened position
      if (order.type !== DECREASE) continue

      // if user creates Stop-Loss we need only Stop-Loss orders and vice versa
      if (orderOption === STOP) {
        const triggerAboveThreshold = triggerPriceUsd?.gt(position.markPrice)
        if (triggerAboveThreshold !== order.triggerAboveThreshold) continue
      }

      const sameToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address
      if (order.isLong === position.isLong && sameToken) {
        ret.push(order)
      }
    }
    return ret
  }, [position, orders, triggerPriceUsd, orderOption, nativeTokenAddress])

  const existingOrder = existingOrders[0]

  const needOrderBookApproval = orderOption === STOP && !orderBookApproved

  const isSwapAllowed = orderOption === MARKET

  const { data: hasOutdatedUi } = useHasOutdatedUi()

  let collateralToken: any
  let receiveToken: any
  let maxAmount: any
  let maxAmountFormatted: any
  let maxAmountFormattedFree: any
  let fromAmount: any

  let convertedAmount: any
  let convertedAmountFormatted: any

  let nextLeverage: any
  let liquidationPrice: any
  let nextLiquidationPrice: any
  let isClosing: any
  let sizeDelta: any

  let nextCollateral: any
  let collateralDelta = bigNumberify(0)
  let receiveAmount = bigNumberify(0)
  let convertedReceiveAmount = bigNumberify(0)
  let adjustedDelta = bigNumberify(0)

  let isNotEnoughReceiveTokenLiquidity: any
  let isCollateralPoolCapacityExceeded: any

  let title: any
  let fundingFee: any
  let positionFee: any
  let swapFeeToken: any
  let swapFee: any
  let totalFees = bigNumberify(0)

  const executionFee = orderOption === STOP ? getConstant(chainId, 'DECREASE_ORDER_EXECUTION_GAS_FEE') : null

  const executionFeeUsd = getUsd(executionFee, nativeTokenAddress, false, infoTokens) || bigNumberify(0)

  if (position) {
    fundingFee = position.fundingFee
    fromAmount = parseValue(fromValue, USD_DECIMALS)
    sizeDelta = fromAmount

    title = `${t`Close`} ${position.isLong ? t`Long` : t`Short`} ${position.indexToken.symbol}`
    collateralToken = position.collateralToken
    liquidationPrice = getLiquidationPrice(position)

    if (fromAmount) {
      isClosing = position.size.sub(fromAmount).lt(DUST_USD)
      positionFee = getMarginFee(fromAmount)
    }

    if (isClosing) {
      sizeDelta = position.size
      receiveAmount = position.collateral
    } else if (orderOption === STOP && sizeDelta && existingOrders.length > 0) {
      let residualSize = position.size
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta)
      }
      if (residualSize.sub(sizeDelta).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDelta = residualSize
      }
    }

    if (sizeDelta) {
      adjustedDelta = nextDelta.mul(sizeDelta).div(position.size)
    }

    if (nextHasProfit) {
      receiveAmount = receiveAmount?.add(adjustedDelta ?? 0)
    } else {
      if (receiveAmount?.gt(adjustedDelta ?? 0)) {
        receiveAmount = receiveAmount?.sub(adjustedDelta ?? 0)
      } else {
        receiveAmount = bigNumberify(0)
      }
    }

    if (keepLeverage && sizeDelta && !isClosing) {
      collateralDelta = sizeDelta.mul(position.collateral).div(position.size)
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfit) {
        const deductions = adjustedDelta?.add(positionFee).add(fundingFee)
        if (collateralDelta?.gt(deductions ?? 0)) {
          collateralDelta = collateralDelta = collateralDelta?.sub(deductions ?? 0)
        } else {
          collateralDelta = bigNumberify(0)
        }
      }
    }

    maxAmount = position.size
    maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true)
    maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2)

    if (fromAmount && collateralToken.maxPrice) {
      convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice)
      convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true)
    }

    totalFees = totalFees?.add(positionFee || bigNumberify(0)).add(fundingFee || bigNumberify(0))

    receiveAmount = receiveAmount?.add(collateralDelta ?? 0)

    if (sizeDelta) {
      if (receiveAmount?.gt(totalFees ?? 0)) {
        receiveAmount = receiveAmount?.sub(totalFees ?? 0)
      } else {
        receiveAmount = bigNumberify(0)
      }
    }

    receiveToken = isSwapAllowed && swapToToken ? swapToToken : collateralToken

    // Calculate swap fees
    if (isSwapAllowed && swapToToken) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        convertedAmount,
        collateralToken.address,
        receiveToken.address,
        infoTokens,
        undefined,
        undefined,
        usdfSupply,
        totalTokenWeights,
        true
      )

      if (feeBasisPoints) {
        swapFee = receiveAmount?.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
        swapFeeToken = getTokenAmountFromUsd(infoTokens, collateralToken.address, swapFee)
        totalFees = totalFees?.add(swapFee || bigNumberify(0))
        receiveAmount = receiveAmount?.sub(swapFee)
      }
    }

    if (orderOption === STOP) {
      convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmount, {
        overridePrice: triggerPriceUsd,
      })
    } else {
      convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmount)
    }

    // Check swap limits (max in / max out)
    if (isSwapAllowed && shouldSwap(collateralToken, receiveToken)) {
      const collateralInfo = getTokenInfo(infoTokens, collateralToken.address)
      const receiveTokenInfo = getTokenInfo(infoTokens, receiveToken.address)

      isNotEnoughReceiveTokenLiquidity =
        receiveTokenInfo.availableAmount?.lt(convertedReceiveAmount ?? 0) ||
        receiveTokenInfo.bufferAmount?.gt(receiveTokenInfo.poolAmount?.sub(convertedReceiveAmount ?? 0) ?? 0)

      if (
        collateralInfo.maxUsdgAmount &&
        collateralInfo.maxUsdgAmount.gt(0) &&
        collateralInfo.usdgAmount &&
        collateralInfo.maxPrice
      ) {
        const usdgFromAmount = adjustForDecimals(receiveAmount, USD_DECIMALS, USDG_DECIMALS)
        const nextUsdgAmount = collateralInfo.usdgAmount.add(usdgFromAmount)

        if (nextUsdgAmount.gt(collateralInfo.maxUsdgAmount)) {
          isCollateralPoolCapacityExceeded = true
        }
      }
    }

    if (isClosing) {
      nextCollateral = bigNumberify(0)
    } else {
      if (position.collateral) {
        nextCollateral = position.collateral
        if (collateralDelta && collateralDelta.gt(0)) {
          nextCollateral = position.collateral.sub(collateralDelta)
        } else if (position.delta && position.delta.gt(0) && sizeDelta) {
          if (!position.hasProfit) {
            nextCollateral = nextCollateral.sub(adjustedDelta)
          }
        }
      }
    }

    if (fromAmount) {
      if (!isClosing && !keepLeverage) {
        nextLeverage = getLeverage({
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: nextHasProfit,
          delta: nextDelta,
          includeDelta: savedIsPnlInLeverage,
        })
        nextLiquidationPrice = getLiquidationPrice({
          isLong: position.isLong,
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          averagePrice: position.averagePrice,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          delta: nextDelta,
          hasProfit: nextHasProfit,
          includeDelta: true,
        })
      }
    }
  }

  const [deltaStr, deltaPercentageStr] = useMemo(() => {
    if (!position || !position.markPrice) {
      return ['-', '-']
    }
    if (orderOption !== STOP) {
      const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
        position.markPrice,
        position,
        fromAmount
      )
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      })
      return [deltaStr, deltaPercentageStr]
    }
    if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      return ['-', '-']
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPriceUsd,
      position,
      fromAmount
    )

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    })
    return [deltaStr, deltaPercentageStr]
  }, [position, triggerPriceUsd, orderOption, fromAmount])

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      if (orderOption === STOP) return [t`Trigger order disabled, pending ${getChainName(chainId)} upgrade`]
      return [t`Position close disabled, pending ${getChainName(chainId)} upgrade`]
    }
    if (hasOutdatedUi) {
      return t`Page outdated, please refresh`
    }
    if (!fromAmount) {
      return t`Enter an amount`
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return t`Enter an amount`
    }
    if (orderOption === STOP) {
      if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
        return t`Enter a price`
      }
      if (position.isLong && triggerPriceUsd.lte(liquidationPrice)) {
        return t`Price below Liq.Price`
      }
      if (!position.isLong && triggerPriceUsd.gte(liquidationPrice)) {
        return t`Price above Liq.Price`
      }

      if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
        return t`Invalid price, see warning`
      }
    }

    if (isNotEnoughReceiveTokenLiquidity) {
      return t`Insufficient receive token liquidity`
    }

    if (isCollateralPoolCapacityExceeded) {
      return `${collateralToken.symbol} ${t`pool exceeded, can only Receive`} ${collateralToken.symbol}`
    }

    if (!isClosing && position && position.size && fromAmount) {
      if (position.size.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return t`Leftover position below 10 USD`
      }
      if (nextCollateral && nextCollateral.lt(expandDecimals(5, USD_DECIMALS))) {
        return t`Leftover collateral below 5 USD`
      }
    }

    if (position && position.size && position.size.lt(fromAmount)) {
      return t`Max close amount exceeded`
    }

    if (nextLeverage && nextLeverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return t`Min leverage: 1.1x`
    }

    if (nextLeverage && nextLeverage.gt(30.5 * BASIS_POINTS_DIVISOR)) {
      return t`Max leverage: 30.5x`
    }

    if (hasPendingProfit && orderOption !== STOP && !isProfitWarningAccepted) {
      return t`Forfeit profit not checked`
    }
    return null
  }

  const isPrimaryEnabled = () => {
    const error = getError()
    if (error) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false
    }
    if (isPluginApproving) {
      return false
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false
    }
    if (isPositionRouterApproving) {
      return false
    }

    return true
  }

  const hasPendingProfit = MIN_PROFIT_TIME > 0 && position.delta.eq(0) && position.pendingDelta.gt(0)

  const getPrimaryText = () => {
    const error = getError()
    if (error) {
      return error
    }

    if (orderOption === STOP) {
      if (isSubmitting) return t`Creating Order...`

      if (needOrderBookApproval && isWaitingForPluginApproval) {
        return t`Enabling Orders...`
      }
      if (isPluginApproving) {
        return t`Enabling Orders...`
      }
      if (needOrderBookApproval) {
        return t`Enable Orders`
      }

      return t`Create Order`
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage...`
    }

    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`
    }

    if (needPositionRouterApproval) {
      return t`Enable Leverage`
    }

    if (hasPendingProfit) {
      return t`Close without profit`
    }
    return isSubmitting ? t`Closing...` : t`Close`
  }

  const resetForm = () => {
    setFromValue('')
    setIsProfitWarningAccepted(false)
  }

  useEffect(() => {
    if (prevIsVisible !== isOpen) {
      resetForm()
    }
  }, [prevIsVisible, isOpen])

  const onClickPrimary = async () => {
    if (needOrderBookApproval) {
      setOrdersToaOpen(true)
      return
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent`,
        failMsg: t`Enable leverage failed`,
      })
      return
    }

    setIsSubmitting(true)

    const collateralTokenAddress = position.collateralToken.isNative
      ? nativeTokenAddress
      : position.collateralToken.address
    const indexTokenAddress = position.indexToken.isNative ? nativeTokenAddress : position.indexToken.address

    if (orderOption === STOP) {
      const triggerAboveThreshold = triggerPriceUsd?.gt(position.markPrice)

      try {
        invariant(!position.isLong || indexTokenAddress === collateralTokenAddress, 'invalid token addresses')
        invariant(indexTokenAddress !== ZERO_ADDRESS, 'indexToken is 0')
        invariant(collateralTokenAddress !== ZERO_ADDRESS, 'collateralToken is 0')
        const executionFee = getConstant(chainId, 'DECREASE_ORDER_EXECUTION_GAS_FEE')

        /*  const params = [
          indexTokenAddress,
          sizeDelta,
          collateralTokenAddress,
          collateralDelta,
          position.isLong,
          triggerPriceUsd,
          triggerAboveThreshold,
        ] */

        const tx = await orderBookContract?.createDecreaseOrder(
          indexTokenAddress,
          sizeDelta,
          collateralTokenAddress,
          collateralDelta ?? 0,
          position.isLong,
          triggerPriceUsd ?? 0,
          triggerAboveThreshold ?? false,
          { value: executionFee }
        )
        helperToast.success(t`✔️ Order submitted!`)
        await tx?.wait()
        setFromValue('')
        onDismiss()
        helperToast.success(t`✔️ Order created!`)
        setIsSubmitting(false)
      } catch (error) {
        setIsSubmitting(false)
        helperToast.error(t`❗ Order creation failed!`)
      }
      return
    }

    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - allowedSlippage
      : BASIS_POINTS_DIVISOR + allowedSlippage
    const refPrice = position.isLong ? position.indexToken.minPrice : position.indexToken.maxPrice
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR)
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME
    const minProfitTimeExpired = Date.now() / 1000 > minProfitExpiration

    if (nextHasProfit && !minProfitTimeExpired && !isProfitWarningAccepted) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice
      }
    }

    const tokenAddress0 = collateralTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : collateralTokenAddress

    const path = [tokenAddress0]

    const isUnwrap = receiveToken.address === ZERO_ADDRESS
    const isSwap = receiveToken.address !== tokenAddress0

    if (isSwap) {
      if (isUnwrap && tokenAddress0 !== nativeTokenAddress) {
        path.push(nativeTokenAddress)
      } else if (!isUnwrap) {
        path.push(receiveToken.address)
      }
    }

    const withdrawETH = isUnwrap

    /* const params = [
      path, // _path
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
      ZERO_ADDRESS, // _callbackTarget
    ] */

    const successMsg = `${t`Requested decrease of`} ${position.indexToken.symbol} ${
      position.isLong ? t`Long` : t`Short`
    } ${t`by`} ${formatAmount(sizeDelta, USD_DECIMALS, 2)} USD.`

    try {
      /* const tx = await positionRouterContract?.createDecreasePosition(
        path, // _path
        indexTokenAddress, // _indexToken
        collateralDelta ?? 0, // _collateralDelta
        sizeDelta, // _sizeDelta
        position.isLong, // _isLong
        account, // _receiver
        priceLimit, // _acceptablePrice
        0, // _minOut
        minExecutionFee, // _executionFee
        withdrawETH, // _withdrawETH
        ZERO_ADDRESS, // _callbackTarget
        { value: minExecutionFee }
      ) */
      if (withdrawETH) {
        if (path.length > 1) {
          const tx = await positionManagerContract?.decreasePositionAndSwapETH(
            path, // _path
            indexTokenAddress, // _indexToken
            collateralDelta ?? 0, // _collateralDelta
            sizeDelta, // _sizeDelta
            position.isLong, // _isLong
            account, // _receiver
            priceLimit, // _acceptablePrice
            0 // _minOut
          )
          helperToast.success(t`Close submitted!`)
          await tx?.wait()
        } else {
          const tx = await positionManagerContract?.decreasePositionETH(
            collateralTokenAddress, // _collateralToken
            indexTokenAddress, // _indexToken
            collateralDelta ?? 0, // _collateralDelta
            sizeDelta, // _sizeDelta
            position.isLong, // _isLong
            account, // _receiver
            priceLimit // _acceptablePrice
          )
          helperToast.success(t`Close submitted!`)
          await tx?.wait()
        }
      } else {
        if (path.length > 1) {
          const tx = await positionManagerContract?.decreasePositionAndSwap(
            path, // _path
            indexTokenAddress, // _indexToken
            collateralDelta ?? 0, // _collateralDelta
            sizeDelta, // _sizeDelta
            position.isLong, // _isLong
            account, // _receiver
            priceLimit, // _acceptablePrice
            0 // _minOut
          )
          helperToast.success(t`Close submitted!`)
          await tx?.wait()
        } else {
          const tx = await positionManagerContract?.decreasePosition(
            collateralTokenAddress, // _collateralToken
            indexTokenAddress, // _indexToken
            collateralDelta ?? 0, // _collateralDelta
            sizeDelta, // _sizeDelta
            position.isLong, // _isLong
            account, // _receiver
            priceLimit // _acceptablePrice
          )
          helperToast.success(t`Close submitted!`)
          await tx?.wait()
        }
      }
      onDismiss()
      setFromValue('')
      const nextSize = position.size.sub(sizeDelta)
      pendingPositions[position.key] = {
        updatedAt: Date.now(),
        pendingChanges: {
          size: nextSize,
        },
      }
      setPendingPositions({ ...pendingPositions })
      await mutatePositions()
      setIsSubmitting(false)
      helperToast.success(successMsg)
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      helperToast.error(t`❗ Close failed!`)
    }
  }

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return
    }
    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken)
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    )
    const prefix = existingOrder.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
    return (
      <div className="Confirmation-box-warning">
        <Trans>You have an active order to decrease</Trans> {existingOrder.isLong ? t`Long` : t`Short`} {sizeInToken}{' '}
        {indexToken.symbol} ($
        {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) <Trans>at</Trans> {prefix}{' '}
        {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
      </div>
    )
  }, [existingOrder, infoTokens])

  function renderMinProfitWarning() {
    if (MIN_PROFIT_TIME === 0) {
      return null
    }

    if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      const minProfitExpiration = Number(position.lastIncreasedTime) + MIN_PROFIT_TIME

      if (orderOption === MARKET) {
        return (
          <div className="Confirmation-box-warning">
            <Trans>Reducing the position at the current price will forfeit a pending profit of</Trans> {deltaStr}.{' '}
            <br />
            <br />
            <Trans>Profit price</Trans>: {position.isLong ? '>' : '<'} $
            {formatAmount(profitPrice, USD_DECIMALS, 2, true)}. <Trans>This rule applies for the next</Trans>{' '}
            {getTimeRemaining(minProfitExpiration)}, <Trans>until</Trans> {formatDateTime(minProfitExpiration)}.
          </div>
        )
      }
      return (
        <div className="Confirmation-box-warning">
          <Trans>This order will forfeit a profit of</Trans> {deltaStr}. <br />
          <Trans>Profit price</Trans>: {position.isLong ? '>' : '<'} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}
          . <Trans>This rule applies for the next</Trans> {getTimeRemaining(minProfitExpiration)}, <Trans>until</Trans>{' '}
          {formatDateTime(minProfitExpiration)}.
        </div>
      )
    }
    return null
  }

  const profitPrice = getProfitPrice(orderOption === MARKET ? position.markPrice : triggerPriceUsd, position)

  let triggerPricePrefix
  if (triggerPriceUsd) {
    triggerPricePrefix = triggerPriceUsd.gt(position.markPrice) ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
  }

  const shouldShowExistingOrderWarning = false
  return (
    <>
      {' '}
      {position && (
        <Modal isOpen={isOpen} onDismiss={onDismiss}>
          <ModalWrapper>
            <RowBetween mb="1rem">
              <ThemedText.MediumHeader>
                <Trans>{title}</Trans>
              </ThemedText.MediumHeader>
              <StyledClosed>
                <X size={24} onClick={onDismiss} />
              </StyledClosed>
            </RowBetween>
            {flagOrdersEnabled && (
              <Container>
                <ButtonTap onClick={() => setOrderOption(MARKET)} active={orderOption === MARKET}>
                  <Trans>Market</Trans>
                </ButtonTap>
                <ButtonTap onClick={() => setOrderOption(STOP)} active={orderOption === STOP}>
                  <Trans>Trigger</Trans>
                </ButtonTap>
              </Container>
            )}

            <AutoColumn gap="0.5rem" style={{ marginBottom: '1rem' }}>
              <LightCard $borderRadius="8px">
                <RowBetween>
                  <StyledInput
                    className="amount"
                    type="number"
                    step={0.0}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={fromValue}
                    onUserInput={(val) => {
                      setFromValue(val)
                    }}
                  />
                  {fromValue !== maxAmountFormattedFree && (
                    <ButtonLight
                      width="fit-content"
                      padding="10px"
                      height="10px"
                      mr="0.25rem"
                      onClick={() => {
                        setFromValue(maxAmountFormattedFree)
                      }}
                    >
                      Max
                    </ButtonLight>
                  )}
                  <ThemedText.MediumHeader>USD</ThemedText.MediumHeader>
                </RowBetween>
                <RowBetween mt="0.5rem">
                  <ThemedText.Body>
                    {convertedAmountFormatted ? (
                      <>
                        <Trans>Close</Trans>: {convertedAmountFormatted} {position.collateralToken.symbol}
                      </>
                    ) : (
                      <Trans>Close</Trans>
                    )}
                  </ThemedText.Body>
                  {maxAmount && (
                    <ThemedText.Body onClick={() => setFromValue(maxAmountFormattedFree)}>
                      <Trans>Max</Trans>: {maxAmountFormatted}
                    </ThemedText.Body>
                  )}
                </RowBetween>
              </LightCard>
              {orderOption === STOP && (
                <LightCard $borderRadius="8px">
                  <RowBetween>
                    <StyledInput
                      className="amount"
                      type="number"
                      step={0.0}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={triggerPriceValue}
                      onUserInput={(val) => {
                        onTriggerPriceChange(val)
                      }}
                    />
                    <ThemedText.MediumHeader>USD</ThemedText.MediumHeader>
                  </RowBetween>
                  <RowBetween mt="0.5rem">
                    <ThemedText.Body>
                      <Trans>Price</Trans>
                    </ThemedText.Body>
                    <ThemedText.Body
                      onClick={() => {
                        setTriggerPriceValue(formatAmountFree(position.markPrice, USD_DECIMALS, 2))
                      }}
                    >
                      <Trans>Mark</Trans>: {formatAmount(position.markPrice, USD_DECIMALS, 2, true)}
                    </ThemedText.Body>
                  </RowBetween>
                </LightCard>
              )}
            </AutoColumn>
            {renderMinProfitWarning()}
            {shouldShowExistingOrderWarning && renderExistingOrderWarning()}
            <AutoColumn gap="0.2rem">
              {minExecutionFeeErrorMessage && <ThemedText.Body>{minExecutionFeeErrorMessage}</ThemedText.Body>}
              {hasPendingProfit && orderOption !== STOP && (
                <ButtonChecked onClick={() => setIsProfitWarningAccepted(!isProfitWarningAccepted)}>
                  {isProfitWarningAccepted ? <CheckSquare /> : <Square />}
                  <ThemedText.Body ml="0.2rem">
                    <Trans>Forfeit profit</Trans>
                  </ThemedText.Body>
                </ButtonChecked>
              )}
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Keep Leverage at</Trans> {formatAmount(position.leverage, 4, 2)}x
                </ThemedText.SubHeader>
                <ThemedText.SubHeader
                  width="fit-content"
                  onClick={() => setKeepLeverage(!keepLeverage)}
                  color="primary1"
                >
                  {keepLeverage ? <CheckSquare width={14} height={14} /> : <Square width={14} height={14} />}
                </ThemedText.SubHeader>
              </RowBetween>
              {orderOption === MARKET && (
                <RowBetween>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Allow up to 1% slippage</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader
                    width="fit-content"
                    onClick={() => setIsHigherSlippageAllowed(!isHigherSlippageAllowed)}
                    color="primary1"
                  >
                    {isHigherSlippageAllowed ? (
                      <CheckSquare width={14} height={14} />
                    ) : (
                      <Square width={14} height={14} />
                    )}
                  </ThemedText.SubHeader>
                </RowBetween>
              )}
              {orderOption === MARKET && (
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <AutoColumn gap="0.5rem">
                        <ThemedText.SubHeader>
                          <Trans>You can change this in the settings menu on the top right of the page</Trans>.
                        </ThemedText.SubHeader>
                        <ThemedText.SubHeader>
                          <Trans>
                            Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices
                            are volatile
                          </Trans>
                        </ThemedText.SubHeader>
                      </AutoColumn>
                    }
                  >
                    <AutoRow align="center">
                      <ThemedText.SubHeader color="text2" mr="4px">
                        <Trans>Allowed Slippage</Trans>
                      </ThemedText.SubHeader>
                      <Eye width={14} />
                    </AutoRow>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>{`${formatAmount(allowedSlippage, 2, 2)}%`}</ThemedText.SubHeader>
                </RowBetween>
              )}

              <Divider />

              {orderOption === STOP && (
                <RowBetween>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Trigger Price</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    {!triggerPriceUsd && '-'}
                    {triggerPriceUsd && `${triggerPricePrefix} ${formatAmount(triggerPriceUsd, USD_DECIMALS, 2, true)}`}
                  </ThemedText.SubHeader>
                </RowBetween>
              )}
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Mark Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Entry Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Liq. Price</Trans>
                </ThemedText.SubHeader>
                {isClosing && orderOption !== STOP && '-'}
                {(!isClosing || orderOption === STOP) && (
                  <>
                    {(!nextLiquidationPrice || nextLiquidationPrice.eq(liquidationPrice)) && (
                      <ThemedText.SubHeader>{`$${formatAmount(
                        liquidationPrice,
                        USD_DECIMALS,
                        2,
                        true
                      )}`}</ThemedText.SubHeader>
                    )}
                    {nextLiquidationPrice && !nextLiquidationPrice.eq(liquidationPrice) && (
                      <Row>
                        <ThemedText.SubHeader color="text2">
                          ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                        </ThemedText.SubHeader>
                        <ArrowRight width={16} color="grey" />
                        <ThemedText.SubHeader>
                          ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 2, true)}
                        </ThemedText.SubHeader>
                      </Row>
                    )}
                  </>
                )}
              </RowBetween>

              <Divider />

              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Size</Trans>
                </ThemedText.SubHeader>
                {position && position.size && fromAmount && (
                  <Row>
                    <ThemedText.SubHeader color="text2">
                      ${formatAmount(position.size, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                    <ArrowRight width={16} color="grey" />
                    <ThemedText.SubHeader>
                      ${formatAmount(position.size.sub(fromAmount), USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                  </Row>
                )}
                {position && position.size && !fromAmount && (
                  <ThemedText.SubHeader>${formatAmount(position.size, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                )}
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Collateral</Trans> ({collateralToken.symbol})
                </ThemedText.SubHeader>
                {nextCollateral && !nextCollateral.eq(position.collateral) ? (
                  <Row>
                    <ThemedText.SubHeader color="text2">
                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                    <ArrowRight width={16} color="grey" />
                    <ThemedText.SubHeader>${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                  </Row>
                ) : (
                  <ThemedText.SubHeader>
                    {formatAmount(position.collateral, USD_DECIMALS, 4, true)}
                  </ThemedText.SubHeader>
                )}
              </RowBetween>
              {!keepLeverage && (
                <RowBetween>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Leverage</Trans>
                  </ThemedText.SubHeader>
                  {isClosing && '-'}
                  {!isClosing &&
                    (nextLeverage ? (
                      <Row>
                        <ThemedText.SubHeader color="text2">
                          {formatAmount(position.leverage, 4, 2)}x
                        </ThemedText.SubHeader>
                        <ArrowRight width={16} color="grey" />
                        <ThemedText.SubHeader>{formatAmount(nextLeverage, 4, 2)}x</ThemedText.SubHeader>
                      </Row>
                    ) : (
                      <ThemedText.SubHeader>{formatAmount(position.leverage, 4, 2)}x</ThemedText.SubHeader>
                    ))}
                </RowBetween>
              )}

              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>PnL</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  {deltaStr} ({deltaPercentageStr})
                </ThemedText.SubHeader>
              </RowBetween>

              <RowBetween>
                <MouseoverTooltip
                  text={
                    <AutoColumn gap="0.5rem" justify="center">
                      {fundingFee && (
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader color="text2">
                            <Trans>Borrow fee</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>
                            ${formatAmount(fundingFee, USD_DECIMALS, 2, true)}
                          </ThemedText.SubHeader>
                        </AutoColumn>
                      )}
                      {positionFee && (
                        <AutoColumn justify="center">
                          {' '}
                          <ThemedText.SubHeader color="text2" mr="4px">
                            <Trans>Closing fee</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>
                            ${formatAmount(positionFee, USD_DECIMALS, 2, true)}
                          </ThemedText.SubHeader>
                        </AutoColumn>
                      )}
                      {swapFee && (
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader color="text2" mr="4px">
                            <Trans>Swap fee</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>{`${formatAmount(swapFeeToken, collateralToken.decimals, 5)} ${
                            collateralToken.symbol
                          }
                           ($${formatAmount(swapFee, USD_DECIMALS, 2, true)})`}</ThemedText.SubHeader>
                        </AutoColumn>
                      )}
                      {executionFee && (
                        <>
                          <ThemedText.SubHeader color="text2" mr="4px">
                            <Trans>Execution fee</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>{`${formatAmount(
                            executionFee,
                            18,
                            2,
                            true
                          )} ${nativeTokenSymbol} ($${formatAmount(
                            executionFeeUsd,
                            USD_DECIMALS,
                            2
                          )})`}</ThemedText.SubHeader>
                        </>
                      )}
                    </AutoColumn>
                  }
                >
                  <AutoRow align="center">
                    <ThemedText.SubHeader color="text2" mr="4px">
                      <Trans>Fees</Trans>
                    </ThemedText.SubHeader>
                    <Eye width={14} />
                  </AutoRow>
                </MouseoverTooltip>
                <ThemedText.SubHeader>
                  {totalFees ? `$${formatAmount(totalFees.add(executionFeeUsd ?? 0), USD_DECIMALS, 2, true)}` : '-'}
                </ThemedText.SubHeader>
              </RowBetween>

              <Divider />

              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Receive</Trans>
                </ThemedText.SubHeader>
                {!isSwapAllowed && receiveToken && (
                  <ThemedText.SubHeader>
                    {' '}
                    {formatAmount(convertedReceiveAmount, receiveToken.decimals, 4, true)}&nbsp;{receiveToken.symbol} ($
                    {formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
                  </ThemedText.SubHeader>
                )}
                {isSwapAllowed && receiveToken && (
                  <ButtonDropdownText onClick={() => setOpenModal(true)}>
                    <ThemedText.SubHeader>
                      {' '}
                      {formatAmount(convertedReceiveAmount, receiveToken.decimals, 4, true)}&nbsp;
                      {receiveToken.symbol} (${formatAmount(receiveAmount, USD_DECIMALS, 2, true)})
                    </ThemedText.SubHeader>
                  </ButtonDropdownText>
                )}
              </RowBetween>
            </AutoColumn>
            <ButtonPrimary mt="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </ButtonPrimary>
          </ModalWrapper>
        </Modal>
      )}
      <TokenModal
        label={t`Receive`}
        chainId={chainId}
        tokenAddress={receiveToken.address}
        onSelectToken={(token) => {
          setSwapToToken(token)
          setSavedRecieveTokenAddress(token.address)
        }}
        tokens={toTokens}
        isOpen={openModal}
        onDismiss={() => setOpenModal(false)}
      />
    </>
  )
}
