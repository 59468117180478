import { Trans } from '@lingui/macro'
import { ButtonTap } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

export function Profile() {
  const [option, setOption] = useState(0)
  return (
    <PageWrapper>
      <ThemedText.LargeHeader>Profile</ThemedText.LargeHeader>
      <Container>
        <ButtonTap width="fit-content" onClick={() => setOption(0)} active={option === 0}>
          Trader
        </ButtonTap>
        <ButtonTap width="fit-content" onClick={() => setOption(1)} active={option === 1}>
          <Trans>User</Trans>
        </ButtonTap>
      </Container>
    </PageWrapper>
  )
}
