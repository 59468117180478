// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { AffiliateCodeForm } from './AffiliateCodeForm'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

interface CreateReferralModalProps {
  handleCreateReferralCode: any
  recentlyAddedCodes: any
  setRecentlyAddedCodes: any
  onDismiss: () => void
  isOpen: boolean
}

export function CreateReferralModal({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
  onDismiss,
  isOpen,
}: CreateReferralModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Create Referral Code</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        <AffiliateCodeForm
          handleCreateReferralCode={handleCreateReferralCode}
          recentlyAddedCodes={recentlyAddedCodes}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          callAfterSuccess={onDismiss}
        />
      </ModalWrapper>
    </Modal>
  )
}
