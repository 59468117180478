/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { COLORS, tooltipFormatterNumber, tooltipLabelFormatter, yaxisFormatter } from '../helpers'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function TradersPnlChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>Traders Net PnL</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink
            fields={[
              { key: 'pnl', name: 'Net PnL' },
              { key: 'pnlCumulative', name: 'Cumulative PnL' },
            ]}
            name="Traders Net PnL"
            data={data?.data}
          />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          {' '}
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart data={data?.data} syncId="tradersId">
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
              <YAxis
                domain={[-data?.stats.maxAbsCumulativePnl * 1.05, data?.stats.maxAbsCumulativePnl * 1.05]}
                orientation="right"
                yAxisId="right"
                tickFormatter={yaxisFormatter}
                width={yaxisWidth}
                tick={{ fill: COLORS[4] }}
              />
              <YAxis
                domain={[-data?.stats.maxAbsPnl * 1.05, data?.stats.maxAbsPnl * 1.05]}
                tickFormatter={yaxisFormatter}
                width={yaxisWidth}
              />
              <Tooltip
                formatter={tooltipFormatterNumber}
                labelFormatter={tooltipLabelFormatter}
                contentStyle={contentStyle}
              />
              <Legend />
              <Bar type="monotone" fill={darkMode ? '#FFFFFF' : '#000000'} dataKey="pnl" name="Net PnL">
                {(data?.data || []).map((item: any, i: any) => {
                  return <Cell key={`cell-${i}`} fill={item.pnl > 0 ? '#22c761' : '#f93333'} />
                })}
              </Bar>
              <Line
                type="monotone"
                strokeWidth={2}
                stroke={COLORS[4]}
                dataKey="currentPnlCumulative"
                name="Cumulative PnL"
                yAxisId="right"
              />
            </ComposedChart>
          </ResponsiveContainer>
          <AutoColumn justify="center">
            <ThemedText.SubHeader mt="1rem">
              <Trans>Considers settled (closed) positions</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              <Trans>Fees are not factored into PnL</Trans>
            </ThemedText.SubHeader>
          </AutoColumn>
        </>
      )}
    </DarkCard>
  )
}
