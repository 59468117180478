// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { getReferralCodeTradeUrl, getTwitterShareUrl, getUSDValue } from 'components/Referrals/referralsHelper'
import { RowBetween, RowCenter } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { SupportedChainId } from 'constants/chains'
import { helperToast } from 'lib/helperToast'
import { useState } from 'react'
import { AlertTriangle, ChevronDown, ChevronUp, Copy, Twitter } from 'react-feather'
import { BiErrorCircle } from 'react-icons/bi'
import { useCopyToClipboard } from 'react-use'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 0.7fr 0fr' : '1fr 1fr 1fr 0.8fr')};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const ButtonOptions = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.bg4)};
  cursor: pointer;
  padding: 8px;
  border-radius: 24px;

  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    background-color: ${({ theme, isMobile }) => (isMobile ? 'transparent' : theme.primary1)};
    color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.white)};
  }
  &:active {
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 16px;
  margin: auto;
  padding: 16px;
`

interface AffiliatesStatsRowProps {
  chainId: any
  ownerOnOtherChain: any
  referralCode: any
  volume: any
  registeredReferralsCount: any
  referrerRebate: any
}

export function AffiliatesStatsRow({
  chainId,
  ownerOnOtherChain,
  referralCode,
  volume,
  registeredReferralsCount,
  referrerRebate,
}: AffiliatesStatsRowProps) {
  const [isDark] = useDarkModeManager()
  const [showMore, setShowMore] = useState(false)

  const [, copyToClipboard] = useCopyToClipboard()
  return (
    <>
      <ResponsiveGrid isMobile={isMobile}>
        <RowCenter>
          <ThemedText.SubHeader textAlign="center">{referralCode}</ThemedText.SubHeader>
          <ButtonEmpty
            width="fit-content"
            onClick={() => {
              copyToClipboard(getReferralCodeTradeUrl(referralCode))
              helperToast.success(t`✔️ Referral link copied to your clipboard`)
            }}
          >
            <Copy color={isDark ? '#fff' : '#000'} width={14} />
          </ButtonEmpty>
          <a href={getTwitterShareUrl(referralCode)} target="_blank" rel="noopener noreferrer">
            <Twitter color={isDark ? '#fff' : '#000'} width={14} />
          </a>
          {ownerOnOtherChain && !ownerOnOtherChain?.isTaken && (
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>This code is not yet registered on</Trans>{' '}
                  {chainId === SupportedChainId.BSC ? 'Bsc' : 'BscTestnet'}{' '}
                  <Trans>you will not receive rebates there</Trans>
                  <br />
                  <br />
                  <Trans>Switch your network to create this code on</Trans>{' '}
                  {chainId === SupportedChainId.BSC ? 'Bsc' : 'BscTestnet'}
                </ThemedText.SubHeader>
              }
            >
              <WrapperIcon style={{ marginLeft: '1rem' }}>
                <AlertTriangle width={16} color="#a67a09" />
              </WrapperIcon>
            </MouseoverTooltip>
          )}
          {ownerOnOtherChain && ownerOnOtherChain?.isTaken && !ownerOnOtherChain?.isTakenByCurrentUser && (
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>This code has been taken by someone else on</Trans>{' '}
                  {chainId === SupportedChainId.BSC ? 'Bsc' : 'BscTestnet'}{' '}
                  <Trans>you will not receive rebates from traders using this code on</Trans>{' '}
                  {chainId === SupportedChainId.BSC ? 'Bsc' : 'BscTestnet'}
                </ThemedText.SubHeader>
              }
            >
              <WrapperIcon style={{ marginLeft: '1rem' }}>
                <BiErrorCircle width={16} color="#D60000" />
              </WrapperIcon>
            </MouseoverTooltip>
          )}
        </RowCenter>
        <ThemedText.SubHeader textAlign="center">{getUSDValue(volume)}</ThemedText.SubHeader>
        {!isMobile ? (
          <>
            <ThemedText.SubHeader textAlign="center">{registeredReferralsCount}</ThemedText.SubHeader>
            <ThemedText.SubHeader textAlign="center">{getUSDValue(referrerRebate, 4)}</ThemedText.SubHeader>
          </>
        ) : (
          <ButtonOptions isMobile={isMobile} onClick={() => setShowMore(!showMore)}>
            {showMore ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
          </ButtonOptions>
        )}
      </ResponsiveGrid>
      {isMobile && showMore && (
        <Wrapper>
          <AutoColumn gap="1rem">
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Traders Referred</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{registeredReferralsCount}</ThemedText.SubHeader>
            </RowBetween>
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                <Trans>Total Rebates</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>{getUSDValue(referrerRebate, 4)}</ThemedText.SubHeader>
            </RowBetween>
          </AutoColumn>
        </Wrapper>
      )}
    </>
  )
}
