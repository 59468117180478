/* eslint-disable no-restricted-imports */
import 'rc-slider/assets/index.css'

import { t, Trans } from '@lingui/macro'
import { ButtonDropdownText, ButtonLight } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { Input as NumericalInput } from 'components/NumericalInput'
import { RowBetween } from 'components/Row'
/* import SettingsTab from 'components/Settings' */
import { Token } from 'domain/tokens'
import { BigNumber } from 'ethers'
import { USD_DECIMALS } from 'lib/legacy'
import { formatAmount } from 'lib/numbers'
import { useState } from 'react'
import { FaArrowsAltV } from 'react-icons/fa'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { TokenModal } from '../Modals/TokenModal'

const Container = styled(AutoColumn)`
  width: 100%;
  height: 250px;
  gap: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const ButtonChange = styled.div<{ isMobile?: boolean }>`
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  transform: ${({ isMobile }) => (isMobile ? 'translate(260%, 210%)' : 'translate(380%, 210%)')};
  background: ${({ theme }) => theme.primary1};
  border-radius: 24px;
  padding: 12px;
`

const ResponsiveRowBetween = styled(RowBetween)`
  margin-top: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    align-items: flex-start;
  `};
`

interface SectionProps {
  fromUsdMin: BigNumber | undefined
  fromBalance: BigNumber | undefined
  fromToken: Token
  fromValue: string
  onFromValueChange: (value: string) => void
  shouldShowMaxButton: boolean
  setFromValueToMaximumAvailable: () => void
  switchTokens: () => void
  toUsdMax: BigNumber | undefined
  ToLabel: string
  toBalance: BigNumber | undefined
  toToken: Token
  isSwap: boolean
  isLong: boolean
  isShort: boolean
  hasLeverageOption: boolean
  leverageOption: string | undefined
  toValue: string
  onToValueChange: (value: string) => void
  chainId: number
  onSelectFromToken: (token: any) => void
  fromTokens: Token[]
  infoTokens: any
  onSelectToToken: (token: any) => void
  toTokens: Token[]
  tokenLabel: string
}

export function FromAndToSection(props: SectionProps) {
  const {
    fromUsdMin,
    fromBalance,
    fromToken,
    fromValue,
    onFromValueChange,
    shouldShowMaxButton,
    setFromValueToMaximumAvailable,
    switchTokens,
    toUsdMax,
    ToLabel,
    toBalance,
    toToken,
    isSwap,
    isLong,
    isShort,
    hasLeverageOption,
    leverageOption,
    toValue,
    onToValueChange,
    chainId,
    onSelectFromToken,
    fromTokens,
    infoTokens,
    onSelectToToken,
    toTokens,
    tokenLabel,
  } = props

  const [isDark] = useDarkModeManager()
  //Token Modal
  const [openModal, setOpenModal] = useState(0)

  return (
    <>
      <Container>
        <LightCard $borderRadius="8px">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              max={9999999}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={fromValue}
              onUserInput={(val) => {
                onFromValueChange(val)
              }}
            />
            {shouldShowMaxButton && (
              <ButtonLight width="fit-content" padding="10px" size="10px" onClick={setFromValueToMaximumAvailable}>
                <ThemedText.SubHeader>Max</ThemedText.SubHeader>
              </ButtonLight>
            )}
            <div>
              <ButtonDropdownText onClick={() => setOpenModal(1)} ml="1rem">
                <ThemedText.MediumHeader>{fromToken.symbol}</ThemedText.MediumHeader>
              </ButtonDropdownText>
            </div>
          </RowBetween>
          <ResponsiveRowBetween>
            <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
              {fromUsdMin && (
                <>
                  <Trans>Pay</Trans>: {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD
                </>
              )}
              {!fromUsdMin && <Trans>Pay</Trans>}
            </ThemedText.Body>
            <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
              {fromBalance && (
                <div onClick={setFromValueToMaximumAvailable}>
                  <Trans>Balance</Trans>: {formatAmount(fromBalance, fromToken.decimals, 4, true)}
                </div>
              )}
            </ThemedText.Body>
          </ResponsiveRowBetween>
        </LightCard>
        <ButtonChange isMobile={isMobile} onClick={switchTokens}>
          <FaArrowsAltV size={24} />
        </ButtonChange>
        <LightCard $borderRadius="8px">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              max={9999999}
              value={toValue}
              onUserInput={(val) => {
                onToValueChange(val)
              }}
            />
            <div>
              <ButtonDropdownText onClick={() => setOpenModal(2)} ml="1rem">
                <ThemedText.MediumHeader>{toToken.symbol}</ThemedText.MediumHeader>
              </ButtonDropdownText>
            </div>
          </RowBetween>
          <ResponsiveRowBetween>
            <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
              {toUsdMax && (
                <>
                  {ToLabel}: {formatAmount(toUsdMax, USD_DECIMALS, 2, true)} USD
                </>
              )}
              {!toUsdMax && ToLabel}
            </ThemedText.Body>
            <ThemedText.Body fontSize={isMobile ? '12px' : '16px'}>
              {toBalance && isSwap && (
                <>
                  <Trans>Balance</Trans>: {formatAmount(toBalance, toToken.decimals, 4, true)}
                </>
              )}
              {(isLong || isShort) && hasLeverageOption && (
                <>
                  <Trans>Leverage</Trans>: {parseFloat(leverageOption ?? '1').toFixed(2)}x
                </>
              )}
            </ThemedText.Body>
          </ResponsiveRowBetween>
        </LightCard>
      </Container>

      <TokenModal
        label={t`Pay`}
        chainId={chainId}
        tokenAddress={fromToken.address}
        onSelectToken={onSelectFromToken}
        tokens={fromTokens}
        infoTokens={infoTokens}
        isOpen={openModal === 1}
        onDismiss={() => setOpenModal(0)}
      />
      <TokenModal
        label={tokenLabel}
        chainId={chainId}
        tokenAddress={toToken.address}
        onSelectToken={onSelectToToken}
        tokens={toTokens}
        infoTokens={infoTokens}
        isOpen={openModal === 2}
        onDismiss={() => setOpenModal(0)}
      />
    </>
  )
}
