/* eslint-disable react-hooks/exhaustive-deps */
import { ZERO_HASH } from 'constants/misc'
import { encodeReferralCode } from 'domain/referrals'
import { parse } from 'qs'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'
import { useUserReferrer } from 'state/user/hooks'
import { updateReferrer } from 'state/user/reducer'

export default function ReferralGetter({ location: { search } }: RouteComponentProps): null {
  const dispatch = useAppDispatch()
  const userReferrer = useUserReferrer()

  useEffect(() => {
    if (!search) return
    if (search.length < 1) return

    const parsed = parse(search, {
      parseArrays: false,
      ignoreQueryPrefix: true,
    })

    const ref = parsed.ref
    if (!ref) return

    const referrer = encodeReferralCode(ref.toString())
    if (!referrer) return
    if (userReferrer !== ZERO_HASH) return
    dispatch(updateReferrer({ referrer }))
  }, [dispatch, search])

  return null
}
