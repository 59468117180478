// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonEmpty, ButtonText } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoRow, RowRight } from 'components/Row'
import { cancelMultipleOrders } from 'domain/legacy'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { DECREASE, getOrderError, SWAP } from 'lib/legacy'
import { useCallback, useState } from 'react'
import { CheckSquare, Square } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { OrdersToS } from '../Modals/OrdersToS'
import { OrdersList } from './OrdersList'
import { PositionsList } from './PositionsList'
import TradeHistory from './TradeHistory'

const Page = styled.div`
  width: 100%;
`

const Wrapper = styled(DarkCard)`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const RowGrow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const ButtonChecked = styled(ButtonEmpty)`
  width: fit-content;
  height: 0;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const StyledAutoRow = styled(AutoRow)<{ isMobile?: boolean }>`
  gap: ${({ isMobile }) => (isMobile ? '0.25rem' : '0.5rem')};
  border-bottom: 2px solid ${({ theme }) => theme.primary1};
  margin-bottom: 1rem;
`

const getOrdersForPosition = (account: any, position: any, orders: any, nativeTokenAddress: any) => {
  if (!orders || orders.length === 0) {
    return []
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order: any) => {
      if (order.type === SWAP) {
        return false
      }
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true
      }
      return false
    })
    .map((order: any) => {
      order.error = getOrderError(account, order, undefined, position)
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = t`Order size is bigger than position, will only be executable if position increases`
      }
      return order
    })
}

interface HistoryProps {
  savedShouldShowPositionLines: boolean
  setSavedShouldShowPositionLines: (value: boolean) => void
  positionsDataIsLoading: boolean
  pendingPositions: any
  setPendingPositions: any
  setIsWaitingForPluginApproval: any
  setIsWaitingForPositionRouterApproval: any
  approveOrderBook: any
  approvePositionRouter: any
  isPluginApproving: any
  isPositionRouterApproving: any
  isWaitingForPluginApproval: any
  isWaitingForPositionRouterApproval: any
  orderBookApproved: any
  positionRouterApproved: any
  positions: any
  positionsMap: any
  mutatePositions: any
  infoTokens: any
  pendingTxns: any
  setPendingTxns: any
  flagOrdersEnabled: any
  savedIsPnlInLeverage: any
  chainId: any
  nativeTokenAddress: any
  setMarket: any
  showPnlAfterFees: any
  minExecutionFee: any
  minExecutionFeeUSD: any
  minExecutionFeeErrorMessage: any
  usdfSupply: any
  totalTokenWeights: any
  savedShouldDisableValidationForTesting: any
  cancelOrderIdList: any
  setCancelOrderIdList: any
  forSingleAccount: any
  getTokenInfo: any
  orders: any
  fetchOrdersData: any
}

export function History(props: HistoryProps) {
  const {
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    positionsDataIsLoading,
    pendingPositions,
    setPendingPositions,
    setIsWaitingForPluginApproval,
    setIsWaitingForPositionRouterApproval,
    approveOrderBook,
    approvePositionRouter,
    isPluginApproving,
    isPositionRouterApproving,
    isWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    orderBookApproved,
    positionRouterApproved,
    positions,
    positionsMap,
    mutatePositions,
    infoTokens,
    pendingTxns,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    setMarket,
    showPnlAfterFees,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    usdfSupply,
    totalTokenWeights,
    savedShouldDisableValidationForTesting,
    cancelOrderIdList,
    setCancelOrderIdList,
    forSingleAccount,
    getTokenInfo,
    orders,
    fetchOrdersData,
  } = props
  const { account, provider } = useActiveWeb3React()
  const [ordersToaOpen, setOrdersToaOpen] = useState(false)

  const [openSection, setOpenSection] = useState(0)
  const handleOpen = (n: number) => {
    setOpenSection(n)
  }

  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false)

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true)
      try {
        const tx = await cancelMultipleOrders(chainId, provider, cancelOrderIdList, {
          successMsg: t`Orders cancelled!`,
          failMsg: t`Cancel failed!`,
          sentMsg: t`Cancel submitted!`,
        })
        const receipt = await tx.wait()
        if (receipt.status === 1) {
          setCancelOrderIdList([])
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsCancelMultipleOrderProcessing(false)
      }
    },
    [chainId, provider, cancelOrderIdList, setCancelOrderIdList]
  )

  return (
    <Page>
      {isMobile && (
        <RowRight>
          <ButtonChecked onClick={() => setSavedShouldShowPositionLines(!savedShouldShowPositionLines)}>
            {savedShouldShowPositionLines ? <CheckSquare width={18} /> : <Square width={18} />}
            <ThemedText.Body ml="0.2rem">
              <Trans>Chart Positions</Trans>
            </ThemedText.Body>
          </ButtonChecked>
        </RowRight>
      )}
      <Wrapper>
        <StyledAutoRow>
          <ButtonText onClick={() => handleOpen(0)}>
            <ThemedText.Body fontSize={isMobile ? 14 : 16} color={openSection === 0 ? 'secondary2' : 'text1'}>
              <Trans>Positions</Trans>
            </ThemedText.Body>
            <ThemedText.SubHeader color={openSection === 0 ? 'secondary2' : 'text1'}>
              {positions.length > 0 && `(${positions.length})`}
            </ThemedText.SubHeader>
          </ButtonText>
          <ButtonText onClick={() => handleOpen(1)}>
            <ThemedText.Body fontSize={isMobile ? 14 : 16} color={openSection === 1 ? 'secondary2' : 'text1'}>
              <Trans>Orders</Trans>
            </ThemedText.Body>
            <ThemedText.SubHeader color={openSection === 1 ? 'secondary2' : 'text1'}>
              {orders.length > 0 && `(${orders.length})`}
            </ThemedText.SubHeader>
          </ButtonText>
          <RowGrow>
            <ButtonText onClick={() => handleOpen(2)}>
              <ThemedText.Body fontSize={isMobile ? 14 : 16} color={openSection === 2 ? 'secondary2' : 'text1'}>
                <Trans>Trades</Trans>
              </ThemedText.Body>
            </ButtonText>
          </RowGrow>
          {!isMobile && (
            <Row>
              {cancelOrderIdList.length > 0 && openSection === 1 && (
                <ButtonEmpty
                  width="fit-content"
                  size="10px"
                  onClick={onMultipleCancelClick}
                  disabled={isCancelMultipleOrderProcessing}
                >
                  <ThemedText.Body color="text2">
                    <Trans>Cancel {cancelOrderIdList.length}</Trans>{' '}
                    {cancelOrderIdList.length > 1 ? t`orders` : t`order`}
                  </ThemedText.Body>
                </ButtonEmpty>
              )}
              <ButtonChecked onClick={() => setSavedShouldShowPositionLines(!savedShouldShowPositionLines)}>
                {savedShouldShowPositionLines ? <CheckSquare width={18} /> : <Square width={18} />}
                <ThemedText.Body ml="0.2rem">
                  <Trans>Chart Positions</Trans>
                </ThemedText.Body>
              </ButtonChecked>
            </Row>
          )}
        </StyledAutoRow>

        {openSection === 0 && positions && (
          <PositionsList
            positionsDataIsLoading={positionsDataIsLoading}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
            approveOrderBook={approveOrderBook}
            approvePositionRouter={approvePositionRouter}
            isPluginApproving={isPluginApproving}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            orderBookApproved={orderBookApproved}
            positionRouterApproved={positionRouterApproved}
            positions={positions}
            positionsMap={positionsMap}
            mutatePositions={mutatePositions}
            infoTokens={infoTokens}
            account={account}
            flagOrdersEnabled={flagOrdersEnabled}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            setMarket={setMarket}
            orders={orders}
            showPnlAfterFees={showPnlAfterFees}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            usdfSupply={usdfSupply}
            totalTokenWeights={totalTokenWeights}
          />
        )}

        {openSection === 1 && (
          <OrdersList
            chainId={chainId}
            orders={orders}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            cancelOrderIdList={cancelOrderIdList}
            setCancelOrderIdList={setCancelOrderIdList}
            totalTokenWeights={totalTokenWeights}
            usdfSupply={usdfSupply}
            fetchOrdersData={fetchOrdersData}
          />
        )}

        {openSection === 2 && (
          <TradeHistory
            account={account}
            forSingleAccount={true}
            infoTokens={infoTokens}
            getTokenInfo={getTokenInfo}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            shouldShowPaginationButtons={true}
          />
        )}
      </Wrapper>
      <OrdersToS
        isOpen={ordersToaOpen}
        onDismiss={() => setOrdersToaOpen(false)}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    </Page>
  )
}
