/* eslint-disable no-restricted-imports */
import { Connector } from '@web3-react/types'
import { fortmatic, gnosisSafe, injected, network, walletConnect } from 'connectors'
import { CHAIN_INFO } from 'constants/chainInfo'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { ethers } from 'ethers'

export function getRpcUrls(chainId: SupportedChainId): [string] {
  switch (chainId) {
    case SupportedChainId.BSC:
      return ['https://bsc-dataseed.binance.org/']
    case SupportedChainId.BSC_TESTNET:
      return ['https://rpc.ankr.com/bsc_testnet_chapel']
    default:
  }
  // Our API-keyed URLs will fail security checks when used with external wallets.
  throw new Error('RPC URLs must use public endpoints')
}

export function isChainAllowed(connector: Connector, chainId: number) {
  switch (connector) {
    case fortmatic:
      return false
    case injected:
    case walletConnect:
    case network:
    case gnosisSafe:
      return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
    default:
      return false
  }
}

export const switchChain = async (connector: Connector, chainId: number) => {
  if (!isChainAllowed(connector, chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
  } else if (connector === walletConnect || connector === network) {
    await connector.activate(chainId)
  } else {
    const info = CHAIN_INFO[chainId]
    const addChainParameter = {
      chainId,
      chainName: info.label,
      rpcUrls: getRpcUrls(chainId),
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    }
    await connector.activate(addChainParameter)
  }
}

export const simpleRpcProvider = (chainId: SupportedChainId) => {
  const RPC_URL = getRpcUrls(chainId)
  return new ethers.providers.StaticJsonRpcProvider(RPC_URL[0])
}
