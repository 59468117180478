// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { getUsd } from 'domain/tokens'
import { helperToast } from 'lib/helperToast'
import {
  DECREASE,
  FUNDING_RATE_PRECISION,
  getLeverage,
  getLiquidationPrice,
  getOrderError,
  LONG,
  SHORT,
  SWAP,
  USD_DECIMALS,
} from 'lib/legacy'
import { bigNumberify, formatAmount } from 'lib/numbers'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { ClosePositionModal } from '../Modals/ClosePositionModal'
import { EditPositionModal } from '../Modals/EditPositionModal'
import { OrdersToS } from '../Modals/OrdersToS'
import { ShareModal } from '../Modals/ShareModal'
import { Positions } from './Positions'

const Container = styled.div`
  width: 100%;
`

const ResponsiveGridOrders = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? '1fr 1fr 0.6fr' : '0.8fr 1fr 0.8fr 1fr 1fr 1fr 0.8fr 0.6fr 0.3fr'};
  padding: 8px;
`

const getOrdersForPosition = (account: any, position: any, orders: any, nativeTokenAddress: any) => {
  if (!orders || orders.length === 0) {
    return []
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order: any) => {
      if (order.type === SWAP) {
        return false
      }
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true
      }
      return false
    })
    .map((order: any) => {
      order.error = getOrderError(account, order, undefined, position)
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = t`Order size is bigger than position, will only be executable if position increases`
      }
      return order
    })
}

interface PositionsListProps {
  account: any
  positionsDataIsLoading: boolean
  pendingPositions: any
  setPendingPositions: any
  setIsWaitingForPluginApproval: any
  setIsWaitingForPositionRouterApproval: any
  approveOrderBook: any
  approvePositionRouter: any
  isPluginApproving: any
  isPositionRouterApproving: any
  isWaitingForPluginApproval: any
  isWaitingForPositionRouterApproval: any
  orderBookApproved: any
  positionRouterApproved: any
  positions: any
  positionsMap: any
  mutatePositions: any
  infoTokens: any
  flagOrdersEnabled: any
  savedIsPnlInLeverage: any
  chainId: any
  nativeTokenAddress: any
  setMarket: any
  showPnlAfterFees: any
  minExecutionFee: any
  minExecutionFeeUSD: any
  minExecutionFeeErrorMessage: any
  usdfSupply: any
  totalTokenWeights: any
  orders: any
}

export function PositionsList(props: PositionsListProps) {
  const {
    account,
    positionsDataIsLoading,
    pendingPositions,
    setPendingPositions,
    setIsWaitingForPluginApproval,
    setIsWaitingForPositionRouterApproval,
    approveOrderBook,
    approvePositionRouter,
    isPluginApproving,
    isPositionRouterApproving,
    isWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    orderBookApproved,
    positionRouterApproved,
    positions,
    positionsMap,
    mutatePositions,
    infoTokens,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    setMarket,
    showPnlAfterFees,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    usdfSupply,
    totalTokenWeights,
    orders,
  } = props
  const [positionToEditKey, setPositionToEditKey] = useState(undefined)
  const [positionToSellKey, setPositionToSellKey] = useState(undefined)
  const [positionToShare, setPositionToShare] = useState(null)
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState<boolean | undefined>(undefined)
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState<boolean | undefined>(undefined)
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined)
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false)
  const [ordersToaOpen, setOrdersToaOpen] = useState(false)
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false)

  const editPosition = (position: any) => {
    setCollateralTokenAddress(position.collateralToken.address)
    setPositionToEditKey(position.key)
    setIsPositionEditorVisible(true)
  }

  const sellPosition = (position: any) => {
    setPositionToSellKey(position.key)
    setIsPositionSellerVisible(true)
    setIsHigherSlippageAllowed(false)
  }

  const sharePosition = (position: any) => {
    setPositionToShare(position)
    setIsPositionShareModalOpen(true)
  }

  const onPositionClick = (position: any) => {
    helperToast.success(
      `✔️ ${position.isLong ? t`Long` : t`Short`} ${position.indexToken.symbol} ${t`market selected`}`
    )
    setMarket(position.isLong ? LONG : SHORT, position.indexToken.address)
  }

  return (
    <Container>
      {isPositionEditorVisible && (
        <EditPositionModal
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          positionsMap={positionsMap}
          positionKey={positionToEditKey}
          isOpen={isPositionEditorVisible}
          onDismiss={() => setIsPositionEditorVisible(false)}
          infoTokens={infoTokens}
          account={account}
          collateralTokenAddress={collateralTokenAddress}
          getUsd={getUsd}
          getLeverage={getLeverage}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          chainId={chainId}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        />
      )}
      {ordersToaOpen && (
        <OrdersToS
          isOpen={ordersToaOpen}
          onDismiss={() => setOrdersToaOpen(false)}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionShareModalOpen && (
        <ShareModal
          account={account}
          chainId={chainId}
          positionToShare={positionToShare}
          onDismiss={() => setIsPositionShareModalOpen(false)}
          isOpen={isPositionShareModalOpen}
        />
      )}
      {isPositionSellerVisible && (
        <ClosePositionModal
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          mutatePositions={mutatePositions}
          positionKey={positionToSellKey}
          infoTokens={infoTokens}
          account={account}
          orders={orders}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          usdfSupply={usdfSupply}
          totalTokenWeights={totalTokenWeights}
          isOpen={isPositionSellerVisible}
          onDismiss={() => setIsPositionSellerVisible(false)}
        />
      )}
      {positions && (
        <>
          {positions.length > 0 && !positionsDataIsLoading && (
            <ResponsiveGridOrders isMobile={isMobile}>
              <ThemedText.Body color="text2">
                <Trans>Position</Trans>
              </ThemedText.Body>
              <ThemedText.Body color="text2">
                <Trans>Net Value</Trans>
              </ThemedText.Body>
              {!isMobile && (
                <>
                  <ThemedText.Body color="text2">
                    <Trans>Size</Trans>
                  </ThemedText.Body>
                  <ThemedText.Body color="text2">
                    <Trans>Collateral</Trans>
                  </ThemedText.Body>
                  <ThemedText.Body color="text2">
                    <Trans>Mark Price</Trans>
                  </ThemedText.Body>
                  <ThemedText.Body color="text2">
                    <Trans>Entry Price</Trans>
                  </ThemedText.Body>
                  <ThemedText.Body color="text2">
                    <Trans>Liq Price</Trans>
                  </ThemedText.Body>
                </>
              )}
            </ResponsiveGridOrders>
          )}
          {positions.length === 0 && positionsDataIsLoading && (
            <ThemedText.Body>
              <Trans>Loading</Trans>...
            </ThemedText.Body>
          )}
          {positions.length === 0 && !positionsDataIsLoading && (
            <ThemedText.Body fontSize={isMobile ? 14 : 16}>
              <Trans>No open positions</Trans>
            </ThemedText.Body>
          )}
          {positions.map((position: any, index: any) => {
            const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0)
            const positionOrders: any = getOrdersForPosition(account, position, orders, nativeTokenAddress)
            const hasOrderError = !!positionOrders.find((order: any) => order.error)
            const hasPositionProfit = position[showPnlAfterFees ? 'hasProfitAfterFees' : 'hasProfit']
            const positionDelta =
              position[showPnlAfterFees ? 'pendingDeltaAfterFees' : 'pendingDelta'] || bigNumberify(0)
            let borrowFeeUSD
            if (position.collateralToken && position.collateralToken.fundingRate) {
              const borrowFeeRate = position.collateralToken.fundingRate
                .mul(position.size)
                .mul(24)
                .div(FUNDING_RATE_PRECISION)
              borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2)
            }
            return (
              <Positions
                key={index}
                position={position}
                showPnlAfterFees={showPnlAfterFees}
                positionDelta={positionDelta}
                hasPositionProfit={hasPositionProfit}
                borrowFeeUSD={borrowFeeUSD}
                liquidationPrice={liquidationPrice}
                onPositionClick={onPositionClick}
                sellPosition={sellPosition}
                editPosition={editPosition}
                sharePosition={sharePosition}
              />
            )
          })}
        </>
      )}
    </Container>
  )
}
