import {
  faChartColumn,
  /* faFileArrowDown, */
  faHandHoldingDollar,
  faHandshake,
  faHome,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import LogoDark from 'assets/images/spacedex-dark-logo.png'
import Logo from 'assets/images/spacedex-logo.png'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { useState } from 'react'
import { stack as StackMenu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 1rem;
`

const ButtonLink = styled(ExternalLink)<{ activesection: string }>`
  padding: 8px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  border-radius: 8px;
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: ${({ theme, activesection }) => (activesection === 'true' ? theme.bg3 : 'transparent')};
  color: ${({ theme }) => theme.secondary3};
  font-size: 16px;
  font-weight: 500;
  height: 2.5rem;
  &:focus {
    background-color: ${({ theme }) => theme.bg3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  &:active {
    background-color: ${({ theme }) => theme.bg3};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

const ButtonDropdown = styled.div<{ openDropdown: string }>`
  padding: 8px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  outline: none;
  border: 1px solid transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${({ openDropdown }) => (openDropdown === 'true' ? '0px' : '8px')};
  border-bottom-right-radius: ${({ openDropdown }) => (openDropdown === 'true' ? '0px' : '8px')};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: ${({ theme, openDropdown }) => (openDropdown === 'true' ? theme.bg3 : 'transparent')};
  color: ${({ theme }) => theme.secondary3};
  font-size: 16px;
  font-weight: 500;
  height: 2.5rem;
  &:focus {
    background-color: ${({ theme }) => theme.bg3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  &:active {
    background-color: ${({ theme }) => theme.bg3};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const ButtonSection = styled(ExternalLink)<{ activesection: string; lastOption: boolean }>`
  padding: 8px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  outline: none;
  border: 1px solid transparent;
  border-bottom-left-radius: ${({ lastOption }) => (lastOption ? '8px' : '0px')};
  border-bottom-right-radius: ${({ lastOption }) => (lastOption ? '8px' : '0px')};
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: ${({ theme, activesection }) => (activesection === 'true' ? theme.bg2 : 'transparent')};
  color: ${({ theme }) => theme.secondary3};
  font-size: 16px;
  font-weight: 500;
  height: 2.5rem;
  &:focus {
    background-color: ${({ theme }) => theme.bg2};
  }
  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
  &:active {
    background-color: ${({ theme }) => theme.bg2};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export default function SidebarMenu() {
  const [isDark] = useDarkModeManager()

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      top: '1.2rem',
    },
    bmBurgerBars: {
      background: isDark ? '#2172E5' : '#93BBF2',
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '24px',
      width: '24px',
      marginTop: '4px',
    },
    bmCross: {
      background: '#bdc3c7',
    },
    bmMenuWrap: {
      position: 'fixed',
      top: 0,
      left: 0,
    },
    bmMenu: {
      background: isDark ? '#0C162D' : '#FFF',
      fontSize: '1.15em',
    },
    bmMorphShape: {
      fill: '#373a47',
    },
    bmItemList: {
      color: isDark ? '#FFFFFF' : '#000000',
      padding: '0.8em',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      top: 0,
    },
  }

  const [open, setOpen] = useState(false)
  const handleSetOpen = () => {
    setOpen(true)
  }
  const handleSetClose = () => {
    setOpen(false)
  }

  const [openDropdown, setOpenDropdown] = useState(0)

  const location = useLocation()
  let activesection = 0

  switch (location.pathname) {
    case '/home':
      activesection = 1
      break
    case '/trade':
    case '/competition':
      activesection = 2
      break
    case '/copytrading':
      activesection = 31
      break
    case '/copytrading/profile':
      activesection = 32
      break
    case '/copytrading/toptraders':
      activesection = 33
      break
    case '/earn':
    case '/buy':
      activesection = 4
      break
    case '/referrals':
      activesection = 5
      break
    case '/analytics':
      activesection = 6
      break
    default:
      activesection = 0
      break
  }

  return (
    <StackMenu styles={styles} isOpen={open} onOpen={handleSetOpen} onClose={handleSetClose}>
      <img src={isDark ? Logo : LogoDark} alt="spacedex-logo" width={150} />
      <Container>
        <AutoColumn gap="1rem">
          <ButtonLink
            as={Link}
            activesection={activesection === 1 ? 'true' : 'false'}
            id={`home-nav-link`}
            to={'/home'}
            onClick={() => {
              handleSetClose()
              setOpenDropdown(0)
            }}
          >
            <RowBetween>
              <FontAwesomeIcon icon={faHome} width="24px" />
              <Trans>Home</Trans>
            </RowBetween>
          </ButtonLink>
          <ButtonLink
            as={Link}
            activesection={activesection === 2 ? 'true' : 'false'}
            id={`trade-nav-link`}
            to={'/trade'}
            onClick={() => {
              handleSetClose()
              setOpenDropdown(0)
            }}
          >
            <RowBetween>
              <FontAwesomeIcon icon={faHandshake} width="24px" />
              Trade
            </RowBetween>
          </ButtonLink>
          {/* <AutoColumn>
            <ButtonDropdown
              openDropdown={openDropdown === 3 ? 'true' : 'false'}
              onClick={() => (openDropdown === 3 ? setOpenDropdown(0) : setOpenDropdown(3))}
            >
              <RowBetween>
                <FontAwesomeIcon icon={faFileArrowDown} width="24px" />
                Copy Trading
              </RowBetween>
            </ButtonDropdown>
            {openDropdown === 3 && (
              <Wrapper>
                <ButtonSection
                  as={Link}
                  activesection={activesection === 31 ? 'true' : 'false'}
                  lastOption={false}
                  id={`copytrading-nav-link`}
                  to={'/copytrading'}
                  onClick={handleSetClose}
                >
                  <Trans>Dashboard</Trans>
                </ButtonSection>
                <ButtonSection
                  as={Link}
                  activesection={activesection === 33 ? 'true' : 'false'}
                  lastOption={false}
                  id={`traders-nav-link`}
                  to={'/copytrading/traders'}
                  onClick={handleSetClose}
                >
                  <Trans>Traders</Trans>
                </ButtonSection>
                <ButtonSection
                  as={Link}
                  activesection={activesection === 32 ? 'true' : 'false'}
                  lastOption
                  id={`profile-nav-link`}
                  to={'/copytrading/profile'}
                  onClick={handleSetClose}
                >
                  <Trans>Profile</Trans>
                </ButtonSection>
              </Wrapper>
            )}
          </AutoColumn> */}
          {/* <MouseoverTooltip text={<Trans>soon</Trans>}>
              <ButtonDisabled>
                <RowBetween>
                  <FontAwesomeIcon icon={faClipboard} width="24px" />
                  Copy Trading
                </RowBetween>
              </ButtonDisabled>
          </MouseoverTooltip> */}
          <ButtonLink
            as={Link}
            activesection={activesection === 4 ? 'true' : 'false'}
            id={`earn-nav-link`}
            to={'/earn'}
            onClick={() => {
              handleSetClose()
              setOpenDropdown(0)
            }}
          >
            <RowBetween>
              <FontAwesomeIcon icon={faHandHoldingDollar} width="24px" />
              <Trans>Earn</Trans>
            </RowBetween>
          </ButtonLink>
          <ButtonLink
            as={Link}
            activesection={activesection === 5 ? 'true' : 'false'}
            id={`referrals-nav-link`}
            to={'/referrals'}
            onClick={() => {
              handleSetClose()
              setOpenDropdown(0)
            }}
          >
            <RowBetween>
              <FontAwesomeIcon icon={faUserPlus} width="24px" />
              <Trans>Referrals</Trans>
            </RowBetween>
          </ButtonLink>
          <ButtonLink
            as={Link}
            activesection={activesection === 6 ? 'true' : 'false'}
            id={`analytics-nav-link`}
            to={'/analytics'}
            onClick={() => {
              handleSetClose()
              setOpenDropdown(0)
            }}
          >
            <RowBetween>
              <FontAwesomeIcon icon={faChartColumn} width="24px" />
              <Trans>Analytics</Trans>
            </RowBetween>
          </ButtonLink>
        </AutoColumn>
      </Container>
    </StackMenu>
  )
}
