/* eslint-disable no-restricted-imports */
import { gql } from '@apollo/client'
import Token from 'abis/erc20.json'
import OrderBook from 'abis/OrderBook.json'
import PositionManager from 'abis/PositionManager.json'
import PositionRouter from 'abis/PositionRouter.json'
import Router from 'abis/Router.json'
import Vault from 'abis/Vault.json'
import { getServerBaseUrl, getServerUrl } from 'constants/backend'
import { getConstant, getHighExecutionFee, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { UI_VERSION } from 'constants/ui'
import { ethers } from 'ethers'
import { callContract, contractFetcher } from 'lib/contracts'
import { DECREASE, getOrderKey, INCREASE, SWAP, USD_DECIMALS } from 'lib/legacy'
import { bigNumberify, expandDecimals } from 'lib/numbers'
import { getProvider } from 'lib/rpc'
import { getStatsGraphClient, nissohGraphClient } from 'lib/subgraph/clients'
import { groupBy } from 'lodash'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { replaceNativeTokenAddress } from './tokens'
import { getUsd } from './tokens/utils'

export * from './prices'

const { AddressZero } = ethers.constants

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`)

  const [res, setRes] = useState()

  useEffect(() => {
    const graphClient = getStatsGraphClient(chainId)
    if (graphClient) {
      graphClient.query({ query }).then(setRes).catch(console.warn)
    }
  }, [setRes, query, chainId])

  return res ? res.data.orderStat : null
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`)

  const [res, setRes] = useState()

  useEffect(() => {
    getStatsGraphClient(chainId).query({ query }).then(setRes).catch(console.warn)
  }, [setRes, query, chainId])

  return res ? res.data.userStat : null
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`)
      const graphClient = getStatsGraphClient(chainId)
      if (!graphClient) {
        return
      }

      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            }
          })
          setData(_data)
        })
        .catch(console.warn)
    }
  }, [setData, chainId, account])

  return data
}

export function useAllPositions(chainId, library) {
  const count = 1000
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`)

  const [res, setRes] = useState()

  useEffect(() => {
    nissohGraphClient.query({ query }).then(setRes).catch(console.warn)
  }, [setRes, query])

  const key = res ? `allPositions${count}__` : false
  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId)
    const vaultAddress = getContract(chainId, 'Vault')
    const contract = new ethers.Contract(vaultAddress, Vault, provider)
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong)
          const position = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          }
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate)
          position.marginFee = position.size.div(1000)
          position.fee = position.fundingFee.add(position.marginFee)

          const THRESHOLD = 5000
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral)
          position.danger = collateralDiffPercent.gt(THRESHOLD)

          return position
        } catch (ex) {
          console.error(ex)
        }
      })
    )

    return ret.filter(Boolean)
  })

  return positions
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`)

  const [res, setRes] = useState()

  useEffect(() => {
    getStatsGraphClient(chainId).query({ query }).then(setRes)
  }, [setRes, query, chainId])

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId)
    const orderBookAddress = getContract(chainId, 'OrderBook')
    const contract = new ethers.Contract(orderBookAddress, OrderBook, provider)
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1)
          const method = `get${type}Order`
          const orderFromChain = await contract[method](order.account, order.index)
          const ret = {}
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val
          }
          if (order.type === 'swap') {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero)
          }
          ret.type = type
          ret.index = order.index
          ret.account = order.account
          ret.createdTimestamp = order.createdTimestamp
          return ret
        } catch (ex) {
          console.error(ex)
        }
      })
    )
  })

  return orders.filter(Boolean)
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + '____') : null
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId)
    const vaultAddress = getContract(chainId, 'Vault')
    const contract = new ethers.Contract(vaultAddress, Vault, provider)
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          )
          if (position[0].eq(0)) {
            return [null, order]
          }
          return [position, order]
        } catch (ex) {
          console.error(ex)
        }
      })
    )
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position
      return memo
    }, {})
  })

  return positions
}

export function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg)
  }
}

export function useTrades(chainId, account, forSingleAccount, afterId) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/actions?account=${account}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/actions`

  if (afterId && afterId.length > 0) {
    const urlItem = new URL(url)
    urlItem.searchParams.append('after', afterId)
    url = urlItem.toString()
  }

  const { data: trades, mutate: updateTrades } = useSWR(url && url, {
    dedupingInterval: 10000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  })

  if (trades) {
    trades.sort((item0, item1) => {
      const data0 = item0.data
      const data1 = item1.data
      const time0 = parseInt(data0.timestamp)
      const time1 = parseInt(data1.timestamp)
      if (time1 > time0) {
        return 1
      }
      if (time1 < time0) {
        return -1
      }

      const block0 = parseInt(data0.blockNumber)
      const block1 = parseInt(data1.blockNumber)

      if (isNaN(block0) && isNaN(block1)) {
        return 0
      }

      if (isNaN(block0)) {
        return 1
      }

      if (isNaN(block1)) {
        return -1
      }

      if (block1 > block0) {
        return 1
      }

      if (block1 < block0) {
        return -1
      }

      return 0
    })
  }

  return { trades, updateTrades }
}

export function useMinExecutionFee(library, active, chainId, infoTokens) {
  const positionRouterAddress = getContract(chainId, 'PositionRouter')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')

  const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, 'minExecutionFee'], {
    fetcher: contractFetcher(library, PositionRouter),
  })

  const { data: gasPrice } = useSWR(['gasPrice', chainId], {
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(library, chainId)
        if (!provider) {
          resolve(undefined)
          return
        }

        try {
          const gasPrice = await provider.getGasPrice()
          resolve(gasPrice)
        } catch (e) {
          console.error(e)
        }
      })
    },
  })

  let multiplier

  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  if (chainId === SupportedChainId.BSC || chainId === SupportedChainId.BSC_TESTNET) {
    multiplier = 65000
  }

  let finalExecutionFee = minExecutionFee

  if (gasPrice && minExecutionFee) {
    const estimatedExecutionFee = gasPrice.mul(multiplier)
    if (estimatedExecutionFee.gt(minExecutionFee)) {
      finalExecutionFee = estimatedExecutionFee
    }
  }

  const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens)
  const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS))
  const errorMessage =
    isFeeHigh &&
    `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`

  return {
    minExecutionFee: finalExecutionFee,
    minExecutionFeeUSD: finalExecutionFeeUSD,
    minExecutionFeeErrorMessage: errorMessage,
  }
}

export function useStakedGmxSupply(library, active) {
  const gmxAddressArb = getContract(SupportedChainId.BSC, 'GMX')
  const stakedGmxTrackerAddressArb = getContract(SupportedChainId.BSC, 'StakedGmxTracker')

  const { data: arbData, mutate: arbMutate } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, SupportedChainId.BSC, gmxAddressArb, 'balanceOf', stakedGmxTrackerAddressArb],
    {
      fetcher: contractFetcher(library, Token),
    }
  )

  const gmxAddressAvax = getContract(SupportedChainId.BSC_TESTNET, 'GMX')
  const stakedGmxTrackerAddressAvax = getContract(SupportedChainId.BSC_TESTNET, 'StakedGmxTracker')

  const { data: avaxData, mutate: avaxMutate } = useSWR(
    [
      `StakeV2:stakedGmxSupply:${active}`,
      SupportedChainId.BSC_TESTNET,
      gmxAddressAvax,
      'balanceOf',
      stakedGmxTrackerAddressAvax,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  )

  let data
  if (arbData && avaxData) {
    data = arbData.add(avaxData)
  }

  const mutate = () => {
    arbMutate()
    avaxMutate()
  }

  return { data, mutate }
}

export function useHasOutdatedUi() {
  const url = getServerUrl(SupportedChainId.BSC, '/ui_version')
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  })

  let hasOutdatedUi = false

  if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
    hasOutdatedUi = true
  }

  return { data: hasOutdatedUi, mutate }
}

export async function approvePlugin(
  chainId,
  pluginAddress,
  { library, pendingTxns, setPendingTxns, sentMsg, failMsg }
) {
  const routerAddress = getContract(chainId, 'Router')
  const contract = new ethers.Contract(routerAddress, Router, library.getSigner())
  return callContract(chainId, contract, 'approvePlugin', [pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  })
}

export async function createSwapOrder(
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts = {}
) {
  const executionFee = getConstant(chainId, 'SWAP_ORDER_EXECUTION_GAS_FEE')
  const triggerAboveThreshold = false
  let shouldWrap = false
  let shouldUnwrap = false
  opts.value = executionFee

  if (path[0] === AddressZero) {
    shouldWrap = true
    opts.value = opts.value.add(amountIn)
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress)

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap]

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, 'createSwapOrder', params, opts)
}

export async function createIncreaseOrder(
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, 'invalid token addresses')
  invariant(indexTokenAddress !== AddressZero, 'indexToken is 0')
  invariant(collateralTokenAddress !== AddressZero, 'collateralToken is 0')

  const fromETH = path[0] === AddressZero

  path = replaceNativeTokenAddress(path, nativeTokenAddress)
  const shouldWrap = fromETH
  const triggerAboveThreshold = !isLong
  const executionFee = getConstant(chainId, 'INCREASE_ORDER_EXECUTION_GAS_FEE')

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ]

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee
  }

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, 'createIncreaseOrder', params, opts)
}

export async function createDecreaseOrder(
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, 'invalid token addresses')
  invariant(indexTokenAddress !== AddressZero, 'indexToken is 0')
  invariant(collateralTokenAddress !== AddressZero, 'collateralToken is 0')

  const executionFee = getConstant(chainId, 'DECREASE_ORDER_EXECUTION_GAS_FEE')

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ]
  opts.value = executionFee
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, 'createDecreaseOrder', params, opts)
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index]
  const method = 'cancelSwapOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index]
  const method = 'cancelDecreaseOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index]
  const method = 'cancelIncreaseOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export function handleCancelOrder(chainId, library, order, opts) {
  let func
  if (order.type === SWAP) {
    func = cancelSwapOrder
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder
  }

  return func(chainId, library, order.index, {
    successMsg: 'Order cancelled.',
    failMsg: 'Cancel failed.',
    sentMsg: 'Cancel submitted.',
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
  })
}

export async function cancelMultipleOrders(chainId, library, allIndexes = [], opts) {
  const ordersWithTypes = groupBy(allIndexes, (v) => v.split('-')[0])
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return
    return ordersWithTypes[key].map((d) => d.split('-')[1])
  }
  // params order => swap, increase, decrease
  const params = ['Swap', 'Increase', 'Decrease'].map((key) => getIndexes(key) || [])
  const method = 'cancelMultiple'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())
  return callContract(chainId, contract, method, params, opts)
}

export async function updateDecreaseOrder(
  chainId,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold]
  const method = 'updateDecreaseOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export async function updateIncreaseOrder(
  chainId,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold]
  const method = 'updateIncreaseOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export async function updateSwapOrder(chainId, library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold]
  const method = 'updateSwapOrder'
  const orderBookAddress = getContract(chainId, 'OrderBook')
  const contract = new ethers.Contract(orderBookAddress, OrderBook, library.getSigner())

  return callContract(chainId, contract, method, params, opts)
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver]
  const positionManagerAddress = getContract(chainId, 'PositionManager')
  const contract = new ethers.Contract(positionManagerAddress, PositionManager, library.getSigner())
  return callContract(chainId, contract, method, params, opts)
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, 'executeSwapOrder', account, index, feeReceiver, opts)
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, 'executeIncreaseOrder', account, index, feeReceiver, opts)
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, 'executeDecreaseOrder', account, index, feeReceiver, opts)
}
