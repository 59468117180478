import { InstagramIcon, TelegramIcon } from '@jamonswap/uikit'
import { Trans } from '@lingui/macro'
import TwitterDark from 'assets/images/home/community/twitter-dark.png'
import TwitterLight from 'assets/images/home/community/twitter-light.png'
import YoutubeDark from 'assets/images/home/community/youtube-dark.png'
import YoutubeLight from 'assets/images/home/community/youtube-light.png'
import { ButtonEmpty } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { useState } from 'react'
import { FaNewspaper } from 'react-icons/fa'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExternalLink } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  margin-top: 2rem;
`

const FlyoutMenu = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 100;
  padding: 12px;
  gap: 0.5rem;
`

const MenuItem = styled(ExternalLink)`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ButtonDropdown = styled(ButtonEmpty)`
  width: fit-content;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ItemDropdown = styled(ExternalLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
    background: ${({ theme }) => theme.bg3};
  }
`

export function Community() {
  const [darkMode] = useDarkModeManager()

  const [openDropdown, setOpenDropdown] = useState(false)
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown)
  }
  return (
    <Container onMouseLeave={() => setOpenDropdown(false)}>
      <ThemedText.TitlePage textAlign="center" mt="2rem">
        <Trans>Community</Trans>
      </ThemedText.TitlePage>
      <ResponsiveGrid isMobile={isMobile}>
        <AutoColumn justify="center">
          <ButtonDropdown onClick={handleOpenDropdown}>
            <AutoColumn gap="sm" justify="center">
              <TelegramIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={48} />
              <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Telegram</ThemedText.MediumHeader>
            </AutoColumn>
          </ButtonDropdown>
          <AutoColumn justify="center">
            {openDropdown && (
              <FlyoutMenu onMouseLeave={() => setOpenDropdown(false)} onClick={handleOpenDropdown}>
                <AutoColumn>
                  <ItemDropdown href="https://t.me/newspacedex">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>News</Trans>
                    </ThemedText.Body>
                    <FaNewspaper width={16} color={darkMode ? '#C4D9F8' : '#4D8FEA'} />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDexPortal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>English</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/england.png`} width={16} alt="english-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_ESP_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Spanish</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/spain.png`} width={16} alt="spanish-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_CN">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Chinese</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/china.png`} width={16} alt="china-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_PT_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Portuguese</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/portugal.png`} width={16} alt="portugal-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_VN_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Vietnam</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/vietnam.png`} width={16} alt="vietnam-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_ID_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Indonesia</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/indonesia.png`} width={16} alt="indonesia-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/SpaceDEX_TR_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Turkey</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/turkey.png`} width={16} alt="turkey-flag" />
                  </ItemDropdown>
                  <ItemDropdown href="https://t.me/spaceDEX_RUS_Portal">
                    <ThemedText.Body color={darkMode ? '#C4D9F8' : '#4D8FEA'}>
                      <Trans>Russia</Trans>
                    </ThemedText.Body>
                    <img src={`${process.env.PUBLIC_URL}/images/flags/russia.png`} width={16} alt="russia-flag" />
                  </ItemDropdown>
                </AutoColumn>
              </FlyoutMenu>
            )}
          </AutoColumn>
        </AutoColumn>

        <MenuItem href="https://twitter.com/spacedexF">
          <AutoColumn gap="sm" justify="center">
            <img src={darkMode ? TwitterDark : TwitterLight} width={48} alt="twitter-logo" />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Twitter</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem>
        <MenuItem href="https://www.instagram.com/spacedex.io/?r=nametag">
          <AutoColumn gap="sm" justify="center">
            <InstagramIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={48} />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Instagram</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem>
        <MenuItem href="https://www.youtube.com/channel/UCKy9Q4OxkApjZAdVXg_-D_g">
          <AutoColumn gap="sm" justify="center">
            <img src={darkMode ? YoutubeDark : YoutubeLight} width={48} alt="youtube-logo" />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Youtube</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem>
        {/* <MenuItem href="https://www.reddit.com/r/JamonSwap">
          <AutoColumn gap="sm" justify="center">
            <RedditIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={48} />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Reedit</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem> */}
        {/* <MenuItem href="https://github.com/spcdex-io/">
          <AutoColumn gap="sm" justify="center">
            <GithubIcon color={darkMode ? '#C4D9F8' : '#4D8FEA'} width={48} />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Github</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem> */}
        {/*         <MenuItem href="https://coinmarketcap.com/currencies/jamonswap/">
          <AutoColumn gap="sm" justify="center">
            <img src={darkMode ? CoinmarketcapDark : CoinmarketcapLight} width={48} alt="coinmarketcap-logo" />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>CoinmarketCap</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem>
        <MenuItem href="https://www.coinstore.com/#/spot/JAMONUSDT">
          <AutoColumn gap="sm" justify="center">
            <img src={darkMode ? CoinstoreDark : CoinstoreLight} width={48} height={56} alt="coinstore-logo" />
            <ThemedText.MediumHeader color={darkMode ? '#C4D9F8' : '#4D8FEA'}>Coinstore</ThemedText.MediumHeader>
          </AutoColumn>
        </MenuItem> */}
      </ResponsiveGrid>
    </Container>
  )
}
