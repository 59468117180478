/* eslint-disable no-restricted-imports */
import { t } from '@lingui/macro'
import { SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { SUBGRAPH_URLS } from 'constants/endPoints'
import { ZERO_ADDRESS } from 'constants/misc'
import { useLiquidationsData } from 'domain/legacy'
import { ethers } from 'ethers'
import request, { gql } from 'graphql-request'
import { formatDateTime } from 'lib/dates'
import { BASIS_POINTS_DIVISOR, deserialize, getExchangeRateDisplay, MAX_LEVERAGE, USD_DECIMALS } from 'lib/legacy'
import { bigNumberify, formatAmount } from 'lib/numbers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ExternalLinkIcon, ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { isMobile } from 'utils/userAgent'

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const ResponsiveData = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

const Row = styled.div`
  width: 11rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
  `};
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.text1};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

const { AddressZero } = ethers.constants

function getPositionDisplay(increase: any, indexToken: any, isLong: boolean, sizeDelta: any) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : ''
  return `
    ${increase ? t`Increase` : t`Decrease`} ${symbol} ${isLong ? t`Long` : t`Short`}
    ${increase ? '+' : '-'}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`
}

function parseAddress(address: string): string {
  return ethers.utils.getAddress(address)
}

function getOrderActionTitle(action: any) {
  let actionDisplay

  if (action.startsWith('Create')) {
    actionDisplay = 'Create'
  } else if (action.startsWith('Cancel')) {
    actionDisplay = 'Cancel'
  } else {
    actionDisplay = 'Update'
  }

  return `${actionDisplay} Order`
}

function renderLiquidationTooltip(liquidationData: any, label: any) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE)
  const text =
    liquidationData.type === 'full'
      ? t`This position was liquidated as the max leverage of 100x was exceeded.`
      : t`Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account`
  return label /* (
    <MouseoverTooltip
      position="left-top"
      handle={label}
      renderContent={() => (
        <>
          {text}
          <br />
          <br />
          <StatsTooltipRow
            label="Initial collateral"
            showDollar
            value={formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label="Min required collateral"
            showDollar
            value={formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label="Borrow fee"
            showDollar
            value={formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label="PnL"
            showDollar={false}
            value={`-$${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}`}
          />
          {liquidationData.type === 'full' && (
            <StatsTooltipRow label="Liquidation fee" showDollar value={formatAmount(LIQUIDATION_FEE, 30, 2, true)} />
          )}
        </>
      )}
    />
  ) */
}

function getLiquidationData(liquidationsDataMap: any, key: any, timestamp: any) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`]
}

interface Order {
  id: string
  type: string
  account: string
  status: any
  size: BigInt
  indexToken: string
  isLong: boolean
  collateralToken: string
  collateral: BigInt
  triggerPrice: BigInt
  triggerAboveThreshold: boolean
  createdTimestamp: number
  cancelledTimestamp: number
  executedTimestamp: number
}

interface Trade {
  id: string
  action: string
  timestamp: number
  type: string
  token: string
  tokenIn: string
  tokenOut: string
  amountIn: BigInt
  amountOut: BigInt
  indexToken: string
  sizeDelta: BigInt
  collateralDelta: BigInt
  isLong: boolean
  acceptablePrice: BigInt
  isOrderExecution: boolean
  isLiquidation: boolean
  price: BigInt
  markPrice: BigInt
  executionPrice: BigInt
  key: string
  order: Order
}

interface TradeHistoryProps {
  account: any
  forSingleAccount: any
  infoTokens: any
  getTokenInfo: any
  chainId: any
  nativeTokenAddress: any
  shouldShowPaginationButtons: any
}

export default function TradeHistory(props: TradeHistoryProps) {
  const {
    account,
    forSingleAccount,
    infoTokens,
    getTokenInfo,
    chainId,
    nativeTokenAddress,
    shouldShowPaginationButtons,
  } = props
  const [history, setHistory] = useState<Trade[]>([])

  const apiUrl = SUBGRAPH_URLS[chainId as SupportedChainId].stats

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetUserHistory($address: String!) {
          userHistories(orderBy: timestamp, orderDirection: desc, first: 100, where: { account: $address }) {
            id
            action
            timestamp
            type
            token
            tokenIn
            tokenOut
            amountIn
            amountOut
            indexToken
            sizeDelta
            collateralDelta
            isLong
            acceptablePrice
            isOrderExecution
            isLiquidation
            price
            markPrice
            executionPrice
            key
            order {
              id
              type
              account
              status
              size
              indexToken
              isLong
              collateralToken
              collateral
              triggerPrice
              triggerAboveThreshold
              createdTimestamp
              cancelledTimestamp
              executedTimestamp
            }
          }
        }
      `,
      {
        address: account?.toLowerCase(),
      }
    )
      .then(({ userHistories }) => {
        if (userHistories.length > 0) {
          setHistory(userHistories)
        }
      })
      .catch(({ error }) => {
        console.log(error)
      })
  }, [account, apiUrl])

  /* const { trades, updateTrades } = useTrades(chainId, account, forSingleAccount, getAfterId()) */

  const liquidationsData: any = useLiquidationsData(chainId, account)
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null
    }
    return liquidationsData.reduce((memo: any, item: any) => {
      const liquidationKey = `${item.key}:${item.timestamp}`
      memo[liquidationKey] = item
      return memo
    }, {})
  }, [liquidationsData])

  /* useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true)
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, [updateTrades]) */

  const getMsg: any = useCallback(
    (trade: any) => {
      const tradeData = trade
      const params = trade
      const defaultMsg = ''

      if (tradeData.action === 'AddLiquidity') {
        const token = getTokenInfo(infoTokens, parseAddress(params.token), true, nativeTokenAddress)
        if (!token) {
          return defaultMsg
        }
        return `${t`Add liquidity`} ${formatAmount(params.amountIn, token.decimals, 4, true)} ${
          token.symbol
        } ${t`for`} ${formatAmount(params.amountOut, 18, 4, true)} FLP`
      }

      if (tradeData.action === 'RemoveLiquidity') {
        const token = getTokenInfo(infoTokens, parseAddress(params.token), true, nativeTokenAddress)
        if (!token) {
          return defaultMsg
        }
        return `${t`Remove liquidity`} ${formatAmount(params.amountIn, 18, 4, true)} FLP ${t`for`} ${formatAmount(
          params.amountOut,
          token.decimals,
          4,
          true
        )} ${token.symbol}`
      }

      if (tradeData.action === 'Swap') {
        const tokenIn = getTokenInfo(infoTokens, parseAddress(params.tokenIn), true, nativeTokenAddress)
        const tokenOut = getTokenInfo(infoTokens, parseAddress(params.tokenOut), true, nativeTokenAddress)
        if (!tokenIn || !tokenOut) {
          return defaultMsg
        }
        return `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${
          tokenIn.symbol
        } ${t`for`} ${formatAmount(params.amountOut, tokenOut.decimals, 4, true)} ${tokenOut.symbol}`
      }

      if (tradeData.action === 'CreateIncreasePosition') {
        const indexToken = getTokenInfo(infoTokens, parseAddress(params.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta)?.eq(0)) {
          return `${t`Request deposit into`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`}`
        }

        return `${t`Request increase`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, ${t`Acceptable Price`}: ${params.isLong ? '<' : '>'} ${formatAmount(
          params.acceptablePrice,
          USD_DECIMALS,
          2,
          true
        )} USD`
      }

      if (tradeData.action === 'CreateDecreasePosition') {
        const indexToken = getTokenInfo(infoTokens, parseAddress(params.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta)?.eq(0)) {
          return `${t`Request withdrawal from`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`}`
        }

        return `${t`Request decrease`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`}, -${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, ${t`Acceptable Price`}: ${params.isLong ? '>' : '<'} ${formatAmount(
          params.acceptablePrice,
          USD_DECIMALS,
          2,
          true
        )} USD`
      }

      if (tradeData.action === 'IncreasePosition') {
        if (params.flags?.isOrderExecution) {
          return
        }

        const indexToken = getTokenInfo(infoTokens, parseAddress(params.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        if (bigNumberify(params.sizeDelta)?.eq(0)) {
          return `${t`Deposit`} ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD ${t`into`} ${
            indexToken.symbol
          } ${params.isLong ? t`Long` : t`Short`}`
        }
        return `${t`Increase`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, ${indexToken.symbol} ${t`Price`}: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD`
      }

      if (tradeData.action === 'DecreasePosition') {
        if (params.flags?.isOrderExecution) {
          return
        }

        const indexToken = getTokenInfo(infoTokens, parseAddress(params.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        if (bigNumberify(params.sizeDelta)?.eq(0)) {
          return `${t`Withdraw`} ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD ${t`from`} ${
            indexToken.symbol
          } ${params.isLong ? t`Long` : t`Short`}`
        }
        const isLiquidation = params.flags?.isLiquidation
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp)

        if (isLiquidation && liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, t`Partial Liquidation`)} {indexToken.symbol}{' '}
              {params.isLong ? t`Long` : t`Short`}, -{formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,{' '}
              {indexToken.symbol}&nbsp; Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
            </>
          )
        }
        const actionDisplay = isLiquidation ? t`Partially Liquidated` : t`Decrease`
        return `
        ${actionDisplay} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`},
        -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} ${t`Price`}: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
      `
      }

      if (tradeData.action === 'LiquidatePosition') {
        const indexToken = getTokenInfo(infoTokens, parseAddress(params.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp)
        if (liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, t`Liquidated`)} {indexToken.symbol}{' '}
              {params.isLong ? t`Long` : t`Short`}, -{formatAmount(params.size, USD_DECIMALS, 2, true)} USD,&nbsp;
              {indexToken.symbol} {t`Price`}: ${formatAmount(params.markPrice, USD_DECIMALS, 2, true)} USD
            </>
          )
        }
        return `
        ${t`Liquidated`} ${indexToken.symbol} ${params.isLong ? t`Long` : t`Short`},
        -${formatAmount(params.size, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} ${t`Price`}: ${formatAmount(params.markPrice, USD_DECIMALS, 2, true)} USD
      `
      }

      if (['ExecuteIncreaseOrder', 'ExecuteDecreaseOrder'].includes(tradeData.action)) {
        if (!params.order) {
          return defaultMsg
        }
        const order = deserialize(params)
        const indexToken = getTokenInfo(infoTokens, parseAddress(order.indexToken), true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        const longShortDisplay = order.isLong ? t`Long` : t`Short`
        const executionPriceDisplay = formatAmount(order.executionPrice, USD_DECIMALS, 2, true)
        const sizeDeltaDisplay = `${order.type === 'increase' ? '+' : '-'}${formatAmount(
          order.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )}`

        return `
        ${t`Execute Order`}: ${order.type} ${indexToken.symbol} ${longShortDisplay}
        ${sizeDeltaDisplay} USD, ${t`Price`}: ${executionPriceDisplay} USD
      `
      }

      if (
        [
          'CreateIncreaseOrder',
          'CancelIncreaseOrder',
          'UpdateIncreaseOrder',
          'CreateDecreaseOrder',
          'CancelDecreaseOrder',
          'UpdateDecreaseOrder',
        ].includes(tradeData.action)
      ) {
        if (!params.order) {
          return defaultMsg
        }
        const order = deserialize(params.order)
        const indexToken = getTokenInfo(infoTokens, parseAddress(order.indexToken))
        if (!indexToken) {
          return defaultMsg
        }
        const increase = tradeData.action.includes('Increase')
        const priceDisplay = `${order.triggerAboveThreshold ? '>' : '<'} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          2,
          true
        )}`
        return `
        ${getOrderActionTitle(tradeData.action)}:
        ${getPositionDisplay(increase, indexToken, order.isLong, order.size)},
        ${t`Price`}: ${priceDisplay}
      `
      }

      if (tradeData.action === 'ExecuteSwapOrder') {
        if (!params.order) {
          return defaultMsg
        }
        const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
        const fromToken = getTokenInfo(
          infoTokens,
          params.tokenIn === nativeTokenAddress.toLocaleLowerCase() ? ZERO_ADDRESS : parseAddress(params.tokenIn)
        )
        const toToken = getTokenInfo(
          infoTokens,
          params.tokenOut === nativeTokenAddress.toLocaleLowerCase() ? ZERO_ADDRESS : parseAddress(params.tokenOut)
        )
        if (!fromToken || !toToken) {
          return defaultMsg
        }
        const fromAmountDisplay = formatAmount(params.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
        const toAmountDisplay = formatAmount(params.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
        return `
        ${t`Execute Order`}: Swap ${fromAmountDisplay} ${fromToken.symbol} ${t`for`} ${toAmountDisplay} ${
          toToken.symbol
        }
      `
      }

      if (['CreateSwapOrder', 'UpdateSwapOrder', 'CancelSwapOrder'].includes(tradeData.action)) {
        if (!params.order) {
          return defaultMsg
        }
        const order = deserialize(params.order)
        const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
        const fromToken = getTokenInfo(
          infoTokens,
          parseAddress(order.indexToken) === nativeTokenAddress ? AddressZero : parseAddress(order.indexToken)
        )
        const toToken = getTokenInfo(infoTokens, parseAddress(order.collateralToken))
        if (!fromToken || !toToken) {
          return defaultMsg
        }
        const amountInDisplay = fromToken
          ? formatAmount(bigNumberify(order.size), fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : ''
        const minOutDisplay = toToken
          ? formatAmount(bigNumberify(order.collateral), toToken.decimals, toToken.isStable ? 2 : 4, true)
          : ''

        return `
        ${getOrderActionTitle(tradeData.action)}:
        Swap ${amountInDisplay} ${fromToken?.symbol || ''} ${t`for`} ${minOutDisplay} ${toToken?.symbol || ''},
        ${t`Price`}: ${getExchangeRateDisplay(bigNumberify(order.triggerPrice), fromToken, toToken)}`
      }

      return defaultMsg
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, liquidationsDataMap, chainId]
  )

  const tradesWithMessages = useMemo(() => {
    if (!history) {
      return []
    }

    return history
      .map((trade: Trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade: any) => trade.msg)
  }, [history, getMsg])

  const [page, setPage] = useState(1)
  const ITEMS = isMobile ? 5 : 10

  const maxPage = tradesWithMessages.length > 0 ? Math.ceil(tradesWithMessages.length / ITEMS) : 0

  const sortedTradesWithMessages = useMemo(() => {
    return tradesWithMessages.length > 0 ? tradesWithMessages.slice(ITEMS * (page - 1), page * ITEMS) : []
  }, [tradesWithMessages, ITEMS, page])

  return (
    <div className="TradeHistory">
      {sortedTradesWithMessages.length === 0 && (
        <ThemedText.Body fontSize={isMobile ? 14 : 16}>{t`No trades yet`}</ThemedText.Body>
      )}
      {sortedTradesWithMessages.length > 0 &&
        sortedTradesWithMessages.map((trade: any, index: any) => {
          const msg = getMsg(trade)

          if (!msg) {
            return null
          }

          return (
            <ResponsiveData key={index}>
              <Row>
                <ThemedText.SubHeader color="text2">{formatDateTime(trade.timestamp)}</ThemedText.SubHeader>
                <ExternalLinkIcon href={getExplorerLink(chainId, trade.id, ExplorerDataType.TRANSACTION)} />
              </Row>
              <ThemedText.SubHeader color="text2">{msg}</ThemedText.SubHeader>
            </ResponsiveData>
          )
        })}
      {tradesWithMessages.length > ITEMS && (
        <PageButtons>
          <Arrow
            onClick={() => {
              setPage(page === 1 ? page : page - 1)
            }}
          >
            <ThemedText.Body color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.Body>
          </Arrow>

          <ThemedText.Body>
            {t`Page`} {page} {t`of`} {maxPage}
          </ThemedText.Body>
          <Arrow
            onClick={() => {
              setPage(page === maxPage ? page : page + 1)
            }}
          >
            <ThemedText.Body color={page === maxPage ? 'textDisabled' : 'primary'}> {'>'} </ThemedText.Body>
          </Arrow>
        </PageButtons>
      )}
    </div>
  )
}
