/* eslint-disable react/prop-types */
// eslint-disable-next-line no-restricted-imports
import 'react-date-range/dist/styles.css' // main css file
// eslint-disable-next-line no-restricted-imports
import 'react-date-range/dist/theme/default.css' // theme css file

import { Trans } from '@lingui/macro'
import { ButtonDropdown, ButtonEmpty } from 'components/Button'
import { AutoColumn } from 'components/Column'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import { useActiveLocale } from 'hooks/useActiveLocale'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import styled from 'styled-components/macro'

const ALL_TIME_ID = 4
const MIN_DATE = new Date('January 20, 2023 00:05:00')

const DateRangeSelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

const ButtonOptions = styled(ButtonEmpty)<{ isActive?: boolean }>`
  width: fit-content;
  height: 10px;
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text2)};
  :hover {
    color: ${({ theme }) => theme.primary1};
    cursor: pointer;
    text-decoration: none;
  }
`

const FlyoutMenu = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 100;
  padding: 12px;
  width: fit-content;
`

const DateRangeStyle = styled(DateRange)`
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 16px;
  background-color: transparent;
  .rdrMonth {
    width: 240px;
  }
  .rdrNextPrevButton {
    background: ${({ theme }) => theme.primary2};
    width: 22px;
    height: 22px;
    border-radius: 4px;
  }
  .rdrPprevButton i {
    border-color: transparent ${({ theme }) => theme.text1} transparent transparent !important;
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent ${({ theme }) => theme.text1} !important;
  }
  .rdrWeekDay {
    color: ${({ theme }) => theme.text1};
  }
  .rdrDayPassive .rdrDayNumber span {
    color: ${({ theme }) => theme.text4};
  }
  .rdrMonthAndYearPickers select {
    top: auto;
    bottom: 100%;
    color: ${({ theme }) => theme.text1};
    background: ${({ theme }) => theme.bg2};
    padding: 8px;
    :hover {
      background-color: ${({ theme }) => theme.bg3};
    }
  }
  .rdrDayNumber span {
    color: ${({ theme }) => theme.text1};
  }
  .rdrDayDisabled {
    background-color: transparent;
  }
  .rdrDayDisabled .rdrDayNumber span {
    color: ${({ theme }) => theme.text2};
  }
`

interface DateRangeSelectorProps {
  options: any
  startDate: any
  endDate: any
  onChange: any
}
export default function DateRangeSelector({ options, startDate, endDate, onChange }: DateRangeSelectorProps) {
  const [openDropdown, setOpenDropdown] = useState(false)
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown)
  }
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(2)
  const [rangeState, setRangeState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ])

  useEffect(() => {
    setRangeState([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ])
  }, [startDate, endDate])

  const onSelectItem = (option: any) => {
    if (option.id === ALL_TIME_ID) {
      onChange([null, null])
    }
    const end = new Date()
    const selectedStart = moment().subtract(option.id, 'month').toDate()
    const start = selectedStart < MIN_DATE ? MIN_DATE : selectedStart
    setSelectedDateRangeOption(option.id)
    if (option.id === ALL_TIME_ID) {
      onChange([null, null])
    } else {
      onChange([start, end])
    }
    setOpenDropdown(false)
  }

  useEffect(() => {
    let selected = false
    for (const option of options) {
      if (option.isDefault) {
        selected = true
        onSelectItem(option)
        break
      }
    }
    if (!selected) {
      onSelectItem(options[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDateRangeChange = (item: any) => {
    setRangeState([item.selection])
    const start = item.selection.startDate
    const end = item.selection.endDate
    if (start === end) {
      return
    }
    onChange([start, end])
  }

  const start = startDate && startDate.toISOString().slice(0, 10)
  const end = endDate && endDate.toISOString().slice(0, 10)

  const currentLanguage = useActiveLocale()

  return (
    <DateRangeSelectorWrapper>
      <AutoColumn justify="center">
        <ButtonDropdown onClick={handleOpenDropdown} size="10px">
          <AutoColumn gap="sm" justify="center">
            {startDate && endDate && `${start} ~ ${end}`}
            {(!startDate || !endDate) && 'All time'}
          </AutoColumn>
        </ButtonDropdown>
        <AutoColumn justify="center">
          {openDropdown && (
            <FlyoutMenu>
              <ButtonOptions isActive={selectedDateRangeOption === 1} onClick={() => onSelectItem(options[0])}>
                <Trans>Last Month</Trans>
              </ButtonOptions>
              <ButtonOptions isActive={selectedDateRangeOption === 2} onClick={() => onSelectItem(options[1])}>
                <Trans>Last 2 Months</Trans>
              </ButtonOptions>
              <ButtonOptions isActive={selectedDateRangeOption === 3} onClick={() => onSelectItem(options[2])}>
                <Trans>Last 3 Months</Trans>
              </ButtonOptions>
              <ButtonOptions isActive={selectedDateRangeOption === 4} onClick={() => onSelectItem(options[3])}>
                <Trans>All Time</Trans>
              </ButtonOptions>
              <DateRangeStyle
                editableDateInputs={true}
                onChange={onDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={rangeState}
                showDateDisplay={false}
                locale={currentLanguage === 'es-ES' ? es : en}
                weekStartsOn={1}
                minDate={MIN_DATE}
                maxDate={new Date()}
              />
            </FlyoutMenu>
          )}
        </AutoColumn>
      </AutoColumn>
    </DateRangeSelectorWrapper>
  )
}
