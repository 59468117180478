/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DateRangeSelector from 'components/DateRangeSelector'
import { getChainName } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import moment from 'moment'
import { useMemo, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { AumDailyUsageChart } from './components/AumDailyUsageChart'
import { ExistingUsersChart } from './components/ExistingUsersChart'
import { FeesChart } from './components/FeesChart'
import { FlpPerfomanceChart } from './components/FlpPerfomanceChart'
import { FlpPriceChart } from './components/FlpPriceChart'
import { FlpSupplyChart } from './components/FlpSupplyChart'
import { NewUsersChart } from './components/NewUsersChart'
import { OpenInterestChart } from './components/OpenInterestChart'
import { PerformanceAnnualizedChart } from './components/PerformanceAnnualizedChart'
import { PoolCompositionChart } from './components/PoolCompositionChart'
import { RateAnnualizedChart } from './components/RateAnnualizedChart'
import { SwapSourcesChart } from './components/SwapSourcesChart'
import { TradersPnlChart } from './components/TradersPnlChart'
import { TradersProfitChart } from './components/TradersProfitChart'
import { UniqueUsersChart } from './components/UniqueUsersChart'
import { UserActionsChart } from './components/UserActionsChart'
import { VolumeChart } from './components/VolumeChart'
import { CHART_HEIGHT, formatNumber, YAXIS_WIDTH } from './helpers'
import {
  useAumPerformanceData,
  useFeesData,
  useFlpData,
  useFlpPerformanceData,
  useFundingRateData,
  useLastBlock,
  useLastSubgraphBlock,
  useSwapSources,
  useTradersData,
  useUsersData,
  useVolumeData,
} from './hooks/dataProvider'

const PageWrapper = styled(AutoColumn)`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

const NOW = Math.floor(Date.now() / 1000)

interface DateRangeProps {
  fromValue: Date | null
  toValue: Date | null
}

export function Analytics() {
  const { chainId } = useActiveWeb3React()
  const activeChain = chainId ?? 56
  const DEFAULT_GROUP_PERIOD = 86400
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD)
  const [dataRange, setDataRange] = useState<DateRangeProps>({
    fromValue: moment().subtract(2, 'month').toDate(),
    toValue: null,
  })

  const from = dataRange.fromValue ? Math.floor(+new Date(dataRange.fromValue) / 1000) : undefined
  const to = dataRange.toValue ? Math.floor(+new Date(dataRange.toValue) / 1000) : NOW

  const params = { from, to, groupPeriod, chainId: activeChain }

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params)

  const [volumeData, volumeLoading] = useVolumeData(params)
  const [totalVolumeData /* totalVolumeLoading */] = useVolumeData({ chainId: activeChain })
  const [totalVolume, totalVolumeDelta] = useMemo(() => {
    if (!totalVolumeData) {
      return []
    }
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative
    const delta = total - totalVolumeData[totalVolumeData.length - 2]?.cumulative
    return [total, delta]
  }, [totalVolumeData])

  const [feesData, feesLoading] = useFeesData(params)
  const [totalFeesData /* totalFeesLoading */] = useFeesData({ chainId: activeChain })
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return []
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative
    const delta = total - totalFeesData[totalFeesData.length - 2]?.cumulative
    return [total, delta]
  }, [totalFeesData])

  const [flpData, flpLoading] = useFlpData(params)
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!flpData) {
      return [0, 0]
    }
    const total = flpData[flpData.length - 1]?.aum
    const delta = total - flpData[flpData.length - 2]?.aum
    return [total, delta]
  }, [flpData])

  const [aumPerformanceData, aumPerformanceLoading] = useAumPerformanceData(params)
  const [flpPerformanceData, flpPerformanceLoading] = useFlpPerformanceData(flpData, feesData, params)

  const [tradersData, tradersLoading] = useTradersData(params)
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersData) {
      return [0, 0]
    }
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest
    const delta = total - tradersData.data[tradersData.data.length - 2]?.openInterest
    return [total, delta]
  }, [tradersData])
  const [swapSources, swapSourcesLoading] = useSwapSources(params)

  const [usersData, usersLoading] = useUsersData(params)
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null]
    }
    const total = usersData[usersData.length - 1]?.uniqueCountCumulative
    const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative
    const delta = total && prevTotal ? total - prevTotal : null
    return [total, delta]
  }, [usersData])

  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock(activeChain)
  const [lastBlock]: any = useLastBlock(activeChain)

  const isObsolete = lastSubgraphBlock && lastBlock && lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600

  const onDateRangeChange = (dates: any) => {
    const [start, end] = dates
    setDataRange({ fromValue: start, toValue: end })
  }

  const dateRangeOptions = [
    {
      label: 'Last Month',
      id: 1,
    },
    {
      label: 'Last 2 Months',
      id: 2,
      isDefault: true,
    },
    {
      label: 'Last 3 Months',
      id: 3,
    },
    {
      label: 'All time',
      id: 4,
    },
  ]

  return (
    <PageWrapper>
      <ThemedText.TitlePage textAlign="center">
        <Trans>Analytics</Trans>
      </ThemedText.TitlePage>
      <ThemedText.MediumHeader mt="0.5rem" textAlign="center">
        {getChainName(activeChain)}
      </ThemedText.MediumHeader>
      {lastSubgraphBlock && lastBlock && (
        <ThemedText.SubTitle mt="0.5rem" textAlign="center" color={isObsolete ? 'warning' : ''}>
          {isObsolete && <Trans> Data is obsolete. </Trans>}
          <Trans>Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()} at block</Trans>{' '}
          {lastSubgraphBlock.number}
        </ThemedText.SubTitle>
      )}
      {lastSubgraphBlockError && (
        <ThemedText.SubTitle mt="0.5rem" textAlign="center">
          <Trans>Subgraph data is temporarily unavailable.</Trans>
        </ThemedText.SubTitle>
      )}
      <DateRangeSelector
        options={dateRangeOptions}
        startDate={dataRange.fromValue}
        endDate={dataRange.toValue}
        onChange={onDateRangeChange}
      />
      <Header>
        <DarkCard minHeight={108}>
          <Column>
            {totalVolume ? (
              <>
                <ThemedText.Body>
                  <Trans>Total Volume</Trans>
                </ThemedText.Body>
                <ThemedText.MediumHeader>{formatNumber(totalVolume, { currency: true })}</ThemedText.MediumHeader>
                <ThemedText.Italic color="success">
                  +{formatNumber(totalVolumeDelta, { currency: true, compact: true })}
                </ThemedText.Italic>
              </>
            ) : (
              <Spinner />
            )}
          </Column>
        </DarkCard>
        <DarkCard minHeight={108}>
          <Column>
            {totalFees ? (
              <>
                <ThemedText.Body>
                  <Trans>Total Fees</Trans>
                </ThemedText.Body>
                <ThemedText.MediumHeader>{formatNumber(totalFees, { currency: true })}</ThemedText.MediumHeader>
                <ThemedText.Italic color="success">
                  +{formatNumber(totalFeesDelta, { currency: true, compact: true })}
                </ThemedText.Italic>
              </>
            ) : (
              <Spinner />
            )}
          </Column>
        </DarkCard>
        <DarkCard minHeight={108}>
          <Column>
            {totalAum ? (
              <>
                <ThemedText.Body>
                  <Trans>FLP Pool</Trans>
                </ThemedText.Body>
                <ThemedText.MediumHeader>{formatNumber(totalAum, { currency: true })}</ThemedText.MediumHeader>
                <ThemedText.Italic color={totalAumDelta > 0 ? 'success' : 'error'}>
                  {totalAumDelta > 0 ? '+' : ''}
                  {formatNumber(totalAumDelta, { currency: true, compact: true })}
                </ThemedText.Italic>
              </>
            ) : (
              <Spinner />
            )}
          </Column>
        </DarkCard>
        <DarkCard minHeight={108}>
          <Column>
            {totalUsers ? (
              <>
                <ThemedText.Body>
                  <Trans>Total Users</Trans>
                </ThemedText.Body>
                <ThemedText.MediumHeader>{formatNumber(totalUsers)}</ThemedText.MediumHeader>
                <ThemedText.Italic color="success">+{formatNumber(totalUsersDelta)}</ThemedText.Italic>
              </>
            ) : (
              <Spinner />
            )}
          </Column>
        </DarkCard>
        <DarkCard minHeight={108}>
          <Column>
            {openInterest ? (
              <>
                <ThemedText.Body>
                  <Trans>Open Interest</Trans>
                </ThemedText.Body>
                <ThemedText.MediumHeader>{formatNumber(openInterest, { currency: true })}</ThemedText.MediumHeader>
                <ThemedText.Italic color={openInterestDelta > 0 ? 'success' : 'error'}>
                  {openInterestDelta > 0 ? '+' : ''}
                  {formatNumber(openInterestDelta, { currency: true, compact: true })}
                </ThemedText.Italic>
              </>
            ) : (
              <Spinner />
            )}
          </Column>
        </DarkCard>
      </Header>
      <ResponsiveRow>
        <VolumeChart data={volumeData} loading={volumeLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
        <FeesChart data={feesData} loading={feesLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
      </ResponsiveRow>
      <ResponsiveRow>
        <FlpSupplyChart data={flpData} loading={flpLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
        <PoolCompositionChart
          from={from}
          to={to}
          chainId={activeChain}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <FlpPerfomanceChart
          data={flpPerformanceData}
          loading={flpPerformanceLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
        <FlpPriceChart
          data={flpPerformanceData}
          loading={flpPerformanceLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <TradersPnlChart
          data={tradersData}
          loading={tradersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
        <TradersProfitChart
          data={tradersData}
          loading={tradersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <OpenInterestChart
          data={tradersData?.data.map((item: any) => ({ all: item.openInterest, ...item }))}
          loading={tradersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
        <RateAnnualizedChart
          data={fundingRateData}
          loading={fundingRateLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <PerformanceAnnualizedChart
          data={aumPerformanceData}
          loading={aumPerformanceLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
        <AumDailyUsageChart
          data={aumPerformanceData}
          loading={aumPerformanceLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <UniqueUsersChart data={usersData} loading={usersLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
        <NewUsersChart
          data={usersData?.map((item: any) => ({ ...item, all: item.newCount }))}
          loading={usersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <ExistingUsersChart
          data={usersData?.map((item: any) => ({ ...item, all: item.uniqueCount }))}
          loading={usersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
        <UserActionsChart
          data={usersData?.map((item: any) => ({ ...item, all: item.actionCount }))}
          loading={usersLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <SwapSourcesChart
          data={swapSources}
          loading={swapSourcesLoading}
          chartHeight={CHART_HEIGHT}
          yaxisWidth={YAXIS_WIDTH}
        />
      </ResponsiveRow>
    </PageWrapper>
  )
}
