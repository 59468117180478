import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { AutoRow, RowBetween } from 'components/Row'
import { useFalconPoolContract } from 'hooks/useContract'
import { helperToast } from 'lib/helperToast'
import { useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.primary1};
  border-radius: 16px;
  margin-bottom: 1rem;
  padding: 8px;
`

interface ClaimFlpModalProps {
  onDismiss: () => void
  isOpen: boolean
}

export function ClaimFalconModal({ onDismiss, isOpen }: ClaimFlpModalProps) {
  const [isClaiming, setIsClaiming] = useState(false)
  const [isCompounding, setIsCompounding] = useState(false)

  const poolContract = useFalconPoolContract()

  const claim = async () => {
    setIsClaiming(true)
    try {
      const tx = await poolContract?.harvest()
      await tx?.wait()
      setIsClaiming(false)
      helperToast.success(t`✔️ Claimed rewards!`)
      onDismiss()
    } catch (error) {
      console.log(error)
      setIsClaiming(false)
      helperToast.error(t`❗ Claim error`)
    }
  }

  const compound = async () => {
    setIsCompounding(true)
    try {
      const tx = await poolContract?.compound()
      await tx?.wait()
      setIsCompounding(false)
      helperToast.success(t`✔️ Claimed rewards!`)
      onDismiss()
    } catch (error) {
      console.log(error)
      setIsCompounding(false)
      helperToast.error(t`❗ Claim error`)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Claim FLN rewards</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        <Container>
          <AutoRow align="center" gap="0.2rem">
            <FontAwesomeIcon icon={faInfoCircle} />
            <ThemedText.Body>
              <Trans>Info</Trans>
            </ThemedText.Body>
          </AutoRow>
          <ThemedText.SubHeader textAlign="justify" mt="0.2rem">
            <Trans>
              Rewards can be withdrawn with the claim function or can be re-staked to generate compound interest with
              the compound function
            </Trans>
            .
          </ThemedText.SubHeader>
        </Container>
        {!isClaiming && (
          <ButtonPrimary mb="1rem" onClick={compound} disabled={isCompounding}>
            {isCompounding ? (
              <>
                <Trans>Compounding</Trans>...
              </>
            ) : (
              <Trans>Compound</Trans>
            )}
          </ButtonPrimary>
        )}
        {!isCompounding && (
          <ButtonPrimary mb="1rem" onClick={claim} disabled={isClaiming}>
            {isClaiming ? (
              <>
                <Trans>Claiming</Trans>...
              </>
            ) : (
              <Trans>Claim</Trans>
            )}
          </ButtonPrimary>
        )}
      </ModalWrapper>
    </Modal>
  )
}
