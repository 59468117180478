// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { getTierIdDisplay, getUSDValue } from 'components/Referrals/referralsHelper'
import { MouseoverTooltip } from 'components/Tooltip'
import { formatDate, formatDateTime } from 'lib/dates'
import { shortenAddress } from 'lib/legacy'
import { formatAmount } from 'lib/numbers'
import { useMemo, useState } from 'react'
import { Edit, HelpCircle } from 'react-feather'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { isMobile } from 'utils/userAgent'

import { EditReferralModal } from './EditReferralModal'

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ResponsiveGridHistory = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr 1fr 1fr' : '1fr 1fr 1fr')};
  margin-bottom: 0.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  gap: 0.5rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.text1};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

const tierDiscountInfo: Record<number, number> = {
  0: 5,
  1: 10,
  2: 10,
} as const

interface TradersStatsProps {
  referralsData: any
  chainId: any
  traderTier: any
  userReferralCodeString: string
}

export function TradersStats({ referralsData, chainId, traderTier, userReferralCodeString }: TradersStatsProps) {
  const [isDark] = useDarkModeManager()

  const [openModal, setOpenModal] = useState(false)

  const [page, setPage] = useState(1)
  const ITEMS = isMobile ? 5 : 10

  const maxPage = referralsData?.distributions.length > 0 ? Math.ceil(referralsData?.distributions.length / ITEMS) : 0

  const sortedReferralsData = useMemo(() => {
    return referralsData?.distributions.length > 0
      ? referralsData?.distributions.slice(ITEMS * (page - 1), page * ITEMS)
      : []
  }, [referralsData, ITEMS, page])

  return (
    <>
      <ResponsiveGrid isMobile={isMobile}>
        <DarkCard minHeight="150px">
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Volume traded by this account with an active referral code</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Trading Volume</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              {getUSDValue(referralsData?.referralTotalStats?.volume)}
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard minHeight="150px">
          <Column>
            <ThemedText.Body textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>Rebates earned by this account as a trader</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Total Rebates</Trans>

                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.Body>
            <ThemedText.MediumHeader color="text1" textAlign="center">
              {getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
            </ThemedText.MediumHeader>
          </Column>
        </DarkCard>
        <DarkCard minHeight="150px">
          <AutoColumn gap="sm">
            <ThemedText.Body textAlign="center">
              <Trans>Active Referral Code</Trans>
            </ThemedText.Body>
            <Row>
              <ThemedText.MediumHeader color="text1">{userReferralCodeString}</ThemedText.MediumHeader>
              <ButtonEmpty width="fit-content" onClick={() => setOpenModal(true)}>
                <Edit color={isDark ? '#fff' : '#000'} />
              </ButtonEmpty>
            </Row>
          </AutoColumn>
          {traderTier && (
            <ThemedText.SubHeader color="text2" textAlign="center">
              <MouseoverTooltip
                text={
                  <ThemedText.SubHeader>
                    <Trans>You will receive a</Trans> {tierDiscountInfo[traderTier]}{' '}
                    <Trans>% discount on your opening and closing fees</Trans>
                  </ThemedText.SubHeader>
                }
              >
                <Row>
                  <Trans>Tier</Trans> {getTierIdDisplay(traderTier)} ({tierDiscountInfo[traderTier]}%{' '}
                  <Trans>discount</Trans>)
                  <WrapperIcon>
                    <HelpCircle width={16} />
                  </WrapperIcon>
                </Row>
              </MouseoverTooltip>
            </ThemedText.SubHeader>
          )}
        </DarkCard>
      </ResponsiveGrid>
      {referralsData?.distributions.length > 0 ? (
        <DarkCard>
          <ResponsiveGridHistory>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Date</Trans>
            </ThemedText.Body>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Amount</Trans>
            </ThemedText.Body>
            <ThemedText.Body color="text2" textAlign="center">
              <Trans>Transaction</Trans>
            </ThemedText.Body>
          </ResponsiveGridHistory>
          <ResponsiveGridHistory>
            {sortedReferralsData.map((rebate: any) => {
              const explorerURL = getExplorerLink(chainId, rebate.transactionHash, ExplorerDataType.TRANSACTION)
              return (
                <>
                  <ThemedText.SubHeader textAlign="center">
                    {isMobile ? formatDate(rebate.timestamp) : formatDateTime(rebate.timestamp)}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader textAlign="center">
                    {formatAmount(rebate.amount, 18, isMobile ? 2 : 6, true)} BUSD
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader textAlign="center">
                    <a target="_blank" rel="noopener noreferrer" href={explorerURL}>
                      {shortenAddress(rebate.transactionHash, isMobile ? 10 : 20)}
                    </a>
                  </ThemedText.SubHeader>
                </>
              )
            })}
          </ResponsiveGridHistory>
          {referralsData?.distributions.length > ITEMS && (
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1)
                }}
              >
                <ThemedText.Body color={page === 1 ? 'text' : 'primary'}> {'<'} </ThemedText.Body>
              </Arrow>

              <ThemedText.Body>
                <Trans>Page</Trans> {page} <Trans>of</Trans> {maxPage}
              </ThemedText.Body>
              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1)
                }}
              >
                <ThemedText.Body color={page === maxPage ? 'textDisabled' : 'primary'}> {'>'} </ThemedText.Body>
              </Arrow>
            </PageButtons>
          )}
        </DarkCard>
      ) : (
        <DarkCard>
          <ThemedText.Body textAlign="center">
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>Rebates are airdropped weekly</Trans>
                </ThemedText.SubHeader>
              }
            >
              <Row>
                <Trans>No rebates distribution history yet</Trans>
                <WrapperIcon>
                  <HelpCircle width={16} />
                </WrapperIcon>
              </Row>
            </MouseoverTooltip>
          </ThemedText.Body>
        </DarkCard>
      )}
      <EditReferralModal isOpen={openModal} onDismiss={() => setOpenModal(false)} />
    </>
  )
}
