/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { RowCenter } from 'components/Row'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { COLORS, tooltipFormatterNumber, tooltipLabelFormatter, yaxisFormatterNumber } from '../helpers'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function FlpPerfomanceChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }

  const csvFields = [
    { key: 'syntheticPrice' },
    { key: 'flpPrice' },
    { key: 'flpPlusFees' },
    { key: 'lpBtcPrice' },
    { key: 'lpEthPrice' },
    { key: 'performanceSyntheticCollectedFees' },
    { key: 'indexBtcCount' },
    { key: 'indexEthCount' },
    { key: 'indexBnbCount' },
    { key: 'indexStableCount' },
    { key: 'BTC_WEIGHT' },
    { key: 'ETH_WEIGHT' },
    { key: 'BNB_WEIGHT' },
    { key: 'STABLE_WEIGHT' },
  ]
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>FLP Perfomance</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink fields={csvFields} name="FLP Perfomance" data={data} />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          {' '}
          <ResponsiveContainer width="100%" height={chartHeight}>
            <LineChart data={data} syncId="syncFlp">
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
              <YAxis
                dataKey="performanceSyntheticCollectedFees"
                domain={[80, 120]}
                unit="%"
                tickFormatter={yaxisFormatterNumber}
                width={yaxisWidth}
              />
              <Tooltip
                formatter={tooltipFormatterNumber}
                labelFormatter={tooltipLabelFormatter}
                contentStyle={contentStyle}
              />
              <Legend />
              <Line
                dot={false}
                isAnimationActive={false}
                type="monotone"
                unit="%"
                dataKey="performanceLpBtcCollectedFees"
                name="% LP BTC-USD (w/ fees)"
                stroke={COLORS[2]}
              />
              <Line
                dot={false}
                isAnimationActive={false}
                type="monotone"
                unit="%"
                dataKey="performanceLpEthCollectedFees"
                name="% LP ETH-USD (w/ fees)"
                stroke={COLORS[4]}
              />
              <Line
                dot={false}
                isAnimationActive={false}
                type="monotone"
                unit="%"
                dataKey="performanceLpBnbCollectedFees"
                name="% LP BNB-USD (w/ fees)"
                stroke={COLORS[5]}
              />
              <Line
                dot={false}
                isAnimationActive={false}
                type="monotone"
                unit="%"
                dataKey="performanceSyntheticCollectedFees"
                name="% Index (w/ fees)"
                stroke={COLORS[0]}
              />
            </LineChart>
          </ResponsiveContainer>
          <RowCenter mt="1rem">
            <ThemedText.SubHeader>
              <span style={{ color: COLORS[0] }}>
                <Trans>% of Index (with fees)</Trans>
              </span>{' '}
              <Trans>
                is Flp with fees / Index Price * 100. Index is a basket of 25% BTC, 25% ETH, 50% USD rebalanced
                once&nbsp;a&nbsp;day
              </Trans>
              <br />
              <span style={{ color: COLORS[4] }}>
                <Trans>% of LP ETH-USD (with fees)</Trans>
              </span>{' '}
              <Trans>is Flp Price with fees</Trans> / LP ETH-USD * 100
              <br />
            </ThemedText.SubHeader>
          </RowCenter>
        </>
      )}
    </DarkCard>
  )
}
