/* eslint-disable no-restricted-imports */
import { Web3Provider } from '@ethersproject/providers'
import Token from 'abis/erc20.json'
/* import { getExplorerUrl } from 'config/chains' */
import { ethers } from 'ethers'

/* import { helperToast } from 'lib/helperToast' */
import { InfoTokens, TokenInfo } from './types'

type Params = {
  setIsApproving: (val: boolean) => void
  library: Web3Provider | undefined
  tokenAddress: string
  spender: string
  chainId: number
  onApproveSubmitted: () => void
  getTokenInfo?: (infoTokens: InfoTokens, tokenAddress: string) => TokenInfo
  infoTokens: InfoTokens
  pendingTxns: any[]
  setPendingTxns: (txns: any[]) => void
  includeMessage?: boolean
}

export function approveTokens({
  setIsApproving,
  library,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  getTokenInfo,
  infoTokens,
  pendingTxns,
  setPendingTxns,
  includeMessage,
}: Params) {
  setIsApproving(true)
  const contract = new ethers.Contract(tokenAddress, Token, library?.getSigner())
  contract
    .approve(spender, ethers.constants.MaxUint256)
    .then(async (res: { hash: string }) => {
      /* const txUrl = getExplorerUrl(chainId) + 'tx/' + res.hash
      helperToast.success(
        <div>
          Approval submitted!{' '}
          <a href={txUrl} target="_blank" rel="noopener noreferrer">
            View status.
          </a>
          <br />
        </div>
      ) */
      if (onApproveSubmitted) {
        onApproveSubmitted()
      }
      if (getTokenInfo && infoTokens && pendingTxns && setPendingTxns) {
        const token = getTokenInfo(infoTokens, tokenAddress)
        const pendingTxn = {
          hash: res.hash,
          message: includeMessage ? `${token.symbol} Approved!` : false,
        }
        setPendingTxns([...pendingTxns, pendingTxn])
      }
    })
    .catch((e: { data: { message: string }; message: string | string[] }) => {
      console.error(e)
      let failMsg
      if (
        ['not enough funds for gas', 'failed to execute call with revert code InsufficientGasFunds'].includes(
          e.data?.message
        )
      ) {
        failMsg = (
          <div>
            There is not enough ETH in your account on Arbitrum to send this transaction.
            <br />
            <br />
            <a href={'https://arbitrum.io/bridge-tutorial/'} target="_blank" rel="noopener noreferrer">
              Bridge ETH to Arbitrum
            </a>
          </div>
        )
      } else if (e.message?.includes('User denied transaction signature')) {
        failMsg = 'Approval was cancelled'
      } else {
        failMsg = 'Approval failed'
      }
      /* helperToast.error(failMsg) */
    })
    .finally(() => {
      setIsApproving(false)
    })
}
