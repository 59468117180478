import { Percent, Token } from '@jamonswap/sdk-core'
import { SupportedLocale } from 'constants/locales'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { useCallback, useMemo } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  updateHideClosedPositions,
  updateShowSocialLinks,
  updateUserClientSideRouter,
  updateUserDarkMode,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserLocale,
  updateUserPnlAfterFees,
  updateUserPnlLeverage,
  updateUserSlippageTolerance,
} from './reducer'
import { SerializedToken } from './types'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  }
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  )
}

export function useIsDarkMode(): boolean {
  const { userDarkMode, matchesDarkMode } = useAppSelector(
    ({ user: { matchesDarkMode, userDarkMode } }) => ({
      userDarkMode,
      matchesDarkMode,
    }),
    shallowEqual
  )

  return userDarkMode === null ? matchesDarkMode : userDarkMode
}

export function useDarkModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const darkMode = useIsDarkMode()

  const toggleSetDarkMode = useCallback(() => {
    dispatch(updateUserDarkMode({ userDarkMode: !darkMode }))
  }, [darkMode, dispatch])

  return [darkMode, toggleSetDarkMode]
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale)
}

export function useUserLocaleManager(): [SupportedLocale | null, (newLocale: SupportedLocale) => void] {
  const dispatch = useAppDispatch()
  const locale = useUserLocale()

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }))
    },
    [dispatch]
  )

  return [locale, setLocale]
}

export function useIsExpertMode(): boolean {
  return useAppSelector((state) => state.user.userExpertMode)
}

export function useExpertModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const expertMode = useIsExpertMode()

  const toggleSetExpertMode = useCallback(() => {
    dispatch(updateUserExpertMode({ userExpertMode: !expertMode }))
  }, [expertMode, dispatch])

  return [expertMode, toggleSetExpertMode]
}

export function useClientSideRouter(): [boolean, (userClientSideRouter: boolean) => void] {
  const dispatch = useAppDispatch()

  const clientSideRouter = useAppSelector((state) => Boolean(state.user.userClientSideRouter))

  const setClientSideRouter = useCallback(
    (newClientSideRouter: boolean) => {
      dispatch(updateUserClientSideRouter({ userClientSideRouter: newClientSideRouter }))
    },
    [dispatch]
  )

  return [clientSideRouter, setClientSideRouter]
}

export function useSetUserSlippageTolerance(): (slippageTolerance: Percent) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (userSlippageTolerance: Percent) => {
      let value: number
      try {
        value = JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient)
      } catch (error) {
        value = 30
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      )
    },
    [dispatch]
  )
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): Percent {
  const userSlippageTolerance = useAppSelector((state) => {
    return state.user.userSlippageTolerance
  })

  return useMemo(() => new Percent(userSlippageTolerance, 10_000), [userSlippageTolerance])
}

export function useUserSlippage(): number {
  const userSlippageTolerance = useAppSelector((state) => {
    return state.user.userSlippageTolerance
  })

  return userSlippageTolerance
}

export function useUserHideClosedPositions(): [boolean, (newHideClosedPositions: boolean) => void] {
  const dispatch = useAppDispatch()

  const hideClosedPositions = useAppSelector((state) => state.user.userHideClosedPositions)

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(updateHideClosedPositions({ userHideClosedPositions: newHideClosedPositions }))
    },
    [dispatch]
  )

  return [hideClosedPositions, setHideClosedPositions]
}

/**
 * Same as above but replaces the auto with a default value
 * @param defaultSlippageTolerance the default value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(): Percent {
  const allowedSlippage = useUserSlippageTolerance()
  return allowedSlippage
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const userDeadline = useAppSelector((state) => state.user.userDeadline)
  /* const onL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId)) */
  const deadline = /* onL2 ? L2_DEADLINE_FROM_NOW : */ userDeadline

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }))
    },
    [dispatch]
  )

  return [deadline, setUserDeadline]
}

export function usePnlAfterFees(): boolean {
  return useAppSelector((state) => state.user.userPnlAfterFees)
}

export function useSetPnlAfterFees(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const pnlAfterFees = usePnlAfterFees()

  const toggleSetPnlAfterFees = useCallback(() => {
    dispatch(updateUserPnlAfterFees({ userPnlAfterFees: !pnlAfterFees }))
  }, [dispatch, pnlAfterFees])

  return [pnlAfterFees, toggleSetPnlAfterFees]
}

export function usePnlLeverage(): boolean {
  return useAppSelector((state) => state.user.userPnlLeverage)
}

export function useSetPnlLeverage(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const pnlLeverage = usePnlLeverage()

  const toggleSetPnlLeverage = useCallback(() => {
    dispatch(updateUserPnlLeverage({ userPnlLeverage: !pnlLeverage }))
  }, [dispatch, pnlLeverage])

  return [pnlLeverage, toggleSetPnlLeverage]
}

export function useUserReferrer(): string {
  return useAppSelector((state) => state.user.referrerCode)
}

export function useShowSocialLinks(): boolean {
  return useAppSelector((state) => state.user.showSocialLinks)
}

export function useSetSocialLinks(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const showLinks = useShowSocialLinks()

  const toggleSetShowLinks = useCallback(() => {
    dispatch(updateShowSocialLinks({ showSocialLinks: !showLinks }))
  }, [dispatch, showLinks])

  return [showLinks, toggleSetShowLinks]
}
