import { Contract } from '@ethersproject/contracts'
import UniswapInterfaceMulticallJson from '@jamonswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import EIP_2612 from 'abis/eip_2612.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import FLN_POOL_ABI from 'abis/FalconPool.json'
import DISTRIBUTOR_ABI from 'abis/FalconPoolDistributor.json'
import FLP_MANAGER_ABI from 'abis/FlpManager.json'
import FLP_POOL_ABI from 'abis/JlpPool.json'
import ORDERBOOK_ABI from 'abis/OrderBook.json'
import POSOTION_MANAGER_ABI from 'abis/PositionManager.json'
import POSOTION_ROUTER_ABI from 'abis/PositionRouter.json'
import REFERRAL_STORAGE_ABI from 'abis/ReferralStorage.json'
import ROUTER_ABI from 'abis/Router.json'
import {
  Erc20,
  Erc721,
  Erc1155,
  FalconPool,
  FalconPoolDistributor,
  JlpPool,
  OrderBook,
  PositionManager,
  PositionRouter,
  ReferralStorage,
  Router,
  Weth,
} from 'abis/types'
import { FlpManager } from 'abis/types'
import WETH_ABI from 'abis/weth.json'
import {
  DISTRIBUTOR_ADDRESS,
  FLN_POOL_ADDRESS,
  FLP_MANAGER_ADDRESS,
  FLP_POOL_ADDRESS,
  MULTICALL_ADDRESS,
  ORDER_BOOK_ADDRESS,
  POSITION_MANAGER_ADDRESS,
  POSITION_ROUTER_ADDRESS,
  REFERRAL_STORAGE_ADDRESS,
  ROUTER_ADDRESS,
} from 'constants/addresses'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokensCopy'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { UniswapInterfaceMulticall } from 'types/v3'

import { getContract } from '../utils'

const { abi: MulticallABI } = UniswapInterfaceMulticallJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useFlpManagerContract() {
  return useContract<FlpManager>(FLP_MANAGER_ADDRESS, FLP_MANAGER_ABI, true)
}

export function useRouterContract() {
  return useContract<Router>(ROUTER_ADDRESS, ROUTER_ABI, true)
}

export function usePositionRouterContract() {
  return useContract<PositionRouter>(POSITION_ROUTER_ADDRESS, POSOTION_ROUTER_ABI, true)
}

export function usePositionManagerContract() {
  return useContract<PositionManager>(POSITION_MANAGER_ADDRESS, POSOTION_MANAGER_ABI, true)
}

export function useOrderBookContract() {
  return useContract<OrderBook>(ORDER_BOOK_ADDRESS, ORDERBOOK_ABI, true)
}

export function useReferralStorageContract() {
  return useContract<ReferralStorage>(REFERRAL_STORAGE_ADDRESS, REFERRAL_STORAGE_ABI, true)
}

export function useJlpPoolContract() {
  return useContract<JlpPool>(FLP_POOL_ADDRESS, FLP_POOL_ABI, true)
}

export function useFalconPoolContract() {
  return useContract<FalconPool>(FLN_POOL_ADDRESS, FLN_POOL_ABI, true)
}
export function useDistributorContract() {
  return useContract<FalconPoolDistributor>(DISTRIBUTOR_ADDRESS, DISTRIBUTOR_ABI, true)
}
