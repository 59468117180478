/* eslint-disable no-restricted-imports */
import OrderBook from 'abis/OrderBook.json'
import { DEFAULT_CHAIN_ID /* , getExplorerUrl */, getConstant } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { ethers } from 'ethers'
import { gql } from 'graphql-request'
import { getStatsGraphClient } from 'lib/subgraph/clients'
import { getMostAbundantStableToken } from 'lib/tokens'
import { getTokenInfo } from 'lib/tokens/utils'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { isValidTimestamp } from './dates'
import { bigNumberify, expandDecimals, formatAmount } from './numbers'
import { getProvider } from './rpc'

const { AddressZero } = ethers.constants

const PENDING_POSITION_VALID_DURATION = 600 * 1000
const UPDATED_POSITION_VALID_DURATION = 60 * 1000

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address

export const MIN_PROFIT_TIME = Number(10800)

export const USDG_ADDRESS = getContract(DEFAULT_CHAIN_ID, 'USDF')
export const MAX_LEVERAGE = 19 * 10000

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000
export const SECONDS_PER_YEAR = 31536000
export const USDG_DECIMALS = 18
export const USD_DECIMALS = 30
export const BASIS_POINTS_DIVISOR = 10000
export const DEPOSIT_FEE = 30
export const DUST_BNB = '2000000000000000'
export const DUST_USD = expandDecimals(1, USD_DECIMALS)
export const PRECISION = expandDecimals(1, 30)
export const GLP_DECIMALS = 18
export const GMX_DECIMALS = 18
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18)

export const TAX_BASIS_POINTS = 50
export const STABLE_TAX_BASIS_POINTS = 5
export const MINT_BURN_FEE_BASIS_POINTS = 10
export const SWAP_FEE_BASIS_POINTS = 10
export const STABLE_SWAP_FEE_BASIS_POINTS = 5
export const MARGIN_FEE_BASIS_POINTS = 10

export const LIQUIDATION_FEE = expandDecimals(2, USD_DECIMALS)

export const TRADES_PAGE_SIZE = 100

export const GLP_COOLDOWN_DURATION = 15 * 60
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27) // 0.993
export const FUNDING_RATE_PRECISION = 1000000

export const SWAP = 'Swap'
export const INCREASE = 'Increase'
export const DECREASE = 'Decrease'
export const LONG = 'Long'
export const SHORT = 'Short'

export const MARKET = 'Market'
export const LIMIT = 'Limit'
export const STOP = 'Stop'
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP]
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT]
export const SWAP_OPTIONS = [LONG, SHORT, SWAP]
export const DEFAULT_SLIPPAGE_AMOUNT = 30
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100

export const REFERRAL_CODE_QUERY_PARAM = 'ref'
export const MAX_REFERRAL_CODE_LENGTH = 20

export const MIN_PROFIT_BIPS = 0

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
  }
  return
}

function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return
  }
  const { key } = position

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key]
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return
    }

    position.hasPendingChanges = true
    position.pendingChanges = pendingChanges
  }
}

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, 'positionReaderPropsLength')
  const positions = []
  const positionsMap = {}
  if (!positionData) {
    return { positions, positionsMap }
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, 'NATIVE_TOKEN'))
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, 'NATIVE_TOKEN'))
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i])
    let contractKey
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i])
    }

    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    }

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key]
      position.size = updatedPosition.size
      position.collateral = updatedPosition.collateral
      position.averagePrice = updatedPosition.averagePrice
      position.entryFundingRate = updatedPosition.entryFundingRate
    }

    let fundingFee = getFundingFee(position)
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0)
    position.collateralAfterFee = position.collateral.sub(position.fundingFee)

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR)
    position.totalFees = position.positionFee.add(position.fundingFee)

    position.pendingDelta = position.delta

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50)

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice)
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice)

        position.delta = position.pendingDelta

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice)
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice)
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral)

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      })

      position.deltaStr = deltaStr
      position.deltaPercentageStr = deltaPercentageStr
      position.deltaBeforeFeesStr = deltaStr

      let hasProfitAfterFees
      let pendingDeltaAfterFees

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees)
        } else {
          hasProfitAfterFees = false
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta)
        }
      } else {
        hasProfitAfterFees = false
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees)
      }

      position.hasProfitAfterFees = hasProfitAfterFees
      position.pendingDeltaAfterFees = pendingDeltaAfterFees
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral)

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      })

      position.deltaAfterFeesStr = deltaAfterFeesStr
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta)

      netValue = netValue.sub(position.fundingFee)

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee)
      }

      position.netValue = netValue
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    })

    positionsMap[key] = position

    applyPendingChanges(position, pendingPositions)

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position)
    }
  }

  return { positions, positionsMap }
}

export function deserialize(data) {
  for (const [key, value] of Object.entries(data)) {
    if (value && value._type === 'BigNumber') {
      data[key] = bigNumberify(value.value)
    }
  }
  return data
}

export function getLiquidationPriceFromDelta({ liquidationAmount, size, collateral, averagePrice, isLong }) {
  if (!size || size.eq(0)) {
    return
  }

  if (liquidationAmount.gt(collateral)) {
    const liquidationDelta = liquidationAmount.sub(collateral)
    const priceDelta = liquidationDelta.mul(averagePrice).div(size)
    return isLong ? averagePrice.add(priceDelta) : averagePrice.sub(priceDelta)
  }

  const liquidationDelta = collateral.sub(liquidationAmount)
  const priceDelta = liquidationDelta.mul(averagePrice).div(size)

  return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta)
}

export function getMarginFee(sizeDelta) {
  if (!sizeDelta) {
    return bigNumberify(0)
  }
  const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
  return sizeDelta.sub(afterFeeUsd)
}

export function isTriggerRatioInverted(fromTokenInfo, toTokenInfo) {
  if (!toTokenInfo || !fromTokenInfo) return false
  if (toTokenInfo.isStable || toTokenInfo.isUsdg) return true
  if (toTokenInfo.maxPrice) return toTokenInfo.maxPrice.lt(fromTokenInfo.maxPrice)
  return false
}

export function getExchangeRate(tokenAInfo, tokenBInfo, inverted) {
  if (!tokenAInfo || !tokenAInfo.minPrice || !tokenBInfo || !tokenBInfo.maxPrice) {
    return
  }
  if (inverted) {
    return tokenAInfo.minPrice.mul(PRECISION).div(tokenBInfo.maxPrice)
  }
  return tokenBInfo.maxPrice.mul(PRECISION).div(tokenAInfo.minPrice)
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
  if (tokenB.isStable || tokenB.isUsd) return true
  if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true
  return false
}

export function getExchangeRateDisplay(rate, tokenA, tokenB, opts = {}) {
  if (!rate || !tokenA || !tokenB) return '...'
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    ;[tokenA, tokenB] = [tokenB, tokenA]
    rate = rate.gt(0) ? PRECISION.mul(PRECISION).div(rate) : rate
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdg ? 2 : 4, true)
  if (opts.omitSymbols) {
    return rateValue
  }
  return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`
}

const adjustForDecimalsFactory = (n) => (number) => {
  if (n === 0) {
    return number
  }
  if (n > 0) {
    return number.mul(expandDecimals(1, n))
  }
  return number.div(expandDecimals(1, -n))
}

export function adjustForDecimals(amount, divDecimals, mulDecimals) {
  return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals))
}

export function getTargetUsdgAmount(token, usdgSupply, totalTokenWeights) {
  if (!token || !token.weight || !usdgSupply) {
    return
  }

  if (usdgSupply.eq(0)) {
    return bigNumberify(0)
  }

  return token.weight.mul(usdgSupply).div(totalTokenWeights)
}

export function getFeeBasisPoints(
  token,
  usdgDelta,
  feeBasisPoints,
  taxBasisPoints,
  increment,
  usdgSupply,
  totalTokenWeights
) {
  if (!token || !token.usdgAmount || !usdgSupply || !totalTokenWeights) {
    return 0
  }

  feeBasisPoints = bigNumberify(feeBasisPoints)
  taxBasisPoints = bigNumberify(taxBasisPoints)

  const initialAmount = token.usdgAmount
  let nextAmount = initialAmount.add(usdgDelta)
  if (!increment) {
    nextAmount = usdgDelta.gt(initialAmount) ? bigNumberify(0) : initialAmount.sub(usdgDelta)
  }

  const targetAmount = getTargetUsdgAmount(token, usdgSupply, totalTokenWeights)
  if (!targetAmount || targetAmount.eq(0)) {
    return feeBasisPoints.toNumber()
  }

  const initialDiff = initialAmount.gt(targetAmount) ? initialAmount.sub(targetAmount) : targetAmount.sub(initialAmount)
  const nextDiff = nextAmount.gt(targetAmount) ? nextAmount.sub(targetAmount) : targetAmount.sub(nextAmount)

  if (nextDiff.lt(initialDiff)) {
    const rebateBps = taxBasisPoints.mul(initialDiff).div(targetAmount)
    return rebateBps.gt(feeBasisPoints) ? 0 : feeBasisPoints.sub(rebateBps).toNumber()
  }

  let averageDiff = initialDiff.add(nextDiff).div(2)
  if (averageDiff.gt(targetAmount)) {
    averageDiff = targetAmount
  }
  const taxBps = taxBasisPoints.mul(averageDiff).div(targetAmount)
  return feeBasisPoints.add(taxBps).toNumber()
}

export function getBuyGlpToAmount(fromAmount, swapTokenAddress, infoTokens, flpPrice, usdfSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 }
  if (!fromAmount || !swapTokenAddress || !infoTokens || !flpPrice || !usdfSupply || !totalTokenWeights) {
    return defaultValue
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress)
  if (!swapToken || !swapToken.minPrice) {
    return defaultValue
  }

  let flpAmount = fromAmount.mul(swapToken.minPrice).div(flpPrice)
  flpAmount = adjustForDecimals(flpAmount, swapToken.decimals, USDG_DECIMALS)

  let usdfAmount = fromAmount.mul(swapToken.minPrice).div(PRECISION)
  usdfAmount = adjustForDecimals(usdfAmount, swapToken.decimals, USDG_DECIMALS)
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdfAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    true,
    usdfSupply,
    totalTokenWeights
  )

  flpAmount = flpAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)

  return { amount: flpAmount, feeBasisPoints }
}

export function getSellGlpFromAmount(toAmount, swapTokenAddress, infoTokens, flpPrice, usdfSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 }
  if (!toAmount || !swapTokenAddress || !infoTokens || !flpPrice || !usdfSupply || !totalTokenWeights) {
    return defaultValue
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress)
  if (!swapToken || !swapToken.maxPrice) {
    return defaultValue
  }

  let flpAmount = toAmount.mul(swapToken.maxPrice).div(flpPrice)
  flpAmount = adjustForDecimals(flpAmount, swapToken.decimals, USDG_DECIMALS)

  let usdfAmount = toAmount.mul(swapToken.maxPrice).div(PRECISION)
  usdfAmount = adjustForDecimals(usdfAmount, swapToken.decimals, USDG_DECIMALS)
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdfAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    false,
    usdfSupply,
    totalTokenWeights
  )

  flpAmount = flpAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)

  return { amount: flpAmount, feeBasisPoints }
}

export function getBuyGlpFromAmount(toAmount, fromTokenAddress, infoTokens, flpPrice, usdfSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!toAmount || !fromTokenAddress || !infoTokens || !flpPrice || !usdfSupply || !totalTokenWeights) {
    return defaultValue
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  if (!fromToken || !fromToken.minPrice) {
    return defaultValue
  }

  let fromAmount = toAmount.mul(flpPrice).div(fromToken.minPrice)
  fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals)

  const usdfAmount = toAmount.mul(flpPrice).div(PRECISION)
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdfAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    true,
    usdfSupply,
    totalTokenWeights
  )

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)

  return { amount: fromAmount, feeBasisPoints }
}

export function getSellGlpToAmount(toAmount, fromTokenAddress, infoTokens, flpPrice, usdfSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!toAmount || !fromTokenAddress || !infoTokens || !flpPrice || !usdfSupply || !totalTokenWeights) {
    return defaultValue
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  if (!fromToken || !fromToken.maxPrice) {
    return defaultValue
  }

  let fromAmount = toAmount.mul(flpPrice).div(fromToken.maxPrice)
  fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals)

  const usdfAmount = toAmount.mul(flpPrice).div(PRECISION)
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdfAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    false,
    usdfSupply,
    totalTokenWeights
  )

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)

  return { amount: fromAmount, feeBasisPoints }
}

export function getNextFromAmount(
  chainId,
  toAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdgSupply,
  totalTokenWeights,
  forSwap
) {
  const defaultValue = { amount: bigNumberify(0) }

  if (!toAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: toAmount }
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: toAmount }
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: toAmount }
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice
  }

  let toTokenMaxPrice
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice
  }

  if (!fromToken || !fromTokenMinPrice || !toToken || !toTokenMaxPrice) {
    return defaultValue
  }

  const adjustDecimals = adjustForDecimalsFactory(fromToken.decimals - toToken.decimals)

  let fromAmountBasedOnRatio
  if (ratio && !ratio.isZero()) {
    fromAmountBasedOnRatio = toAmount.mul(ratio).div(PRECISION)
  }

  const fromAmount =
    ratio && !ratio.isZero() ? fromAmountBasedOnRatio : toAmount.mul(toTokenMaxPrice).div(fromTokenMinPrice)

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, toToken.decimals, USDG_DECIMALS)
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdgSupply,
    totalTokenWeights
  )
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdgSupply,
    totalTokenWeights
  )
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1

  return {
    amount: adjustDecimals(fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)),
    feeBasisPoints,
  }
}

export function getNextToAmount(
  chainId,
  fromAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdgSupply,
  totalTokenWeights,
  forSwap
) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!fromAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: fromAmount }
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: fromAmount }
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: fromAmount }
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice
  }

  let toTokenMaxPrice
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice
  }

  if (!fromTokenMinPrice || !toTokenMaxPrice) {
    return defaultValue
  }

  const adjustDecimals = adjustForDecimalsFactory(toToken.decimals - fromToken.decimals)

  let toAmountBasedOnRatio = bigNumberify(0)
  if (ratio && !ratio.isZero()) {
    toAmountBasedOnRatio = fromAmount.mul(PRECISION).div(ratio)
  }

  if (toTokenAddress === USDG_ADDRESS) {
    const feeBasisPoints = getSwapFeeBasisPoints(fromToken.isStable)

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const toAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
    return {
      amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
      feeBasisPoints,
    }
  }

  if (fromTokenAddress === USDG_ADDRESS) {
    const redemptionValue = toToken.redemptionAmount
      .mul(toTokenPriceUsd || toTokenMaxPrice)
      .div(expandDecimals(1, toToken.decimals))

    if (redemptionValue.gt(THRESHOLD_REDEMPTION_VALUE)) {
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable)

      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals))

      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const expectedAmount = fromAmount

    const stableToken = getMostAbundantStableToken(chainId, infoTokens)
    if (!stableToken || stableToken.availableAmount.lt(expectedAmount)) {
      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals))
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable)
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const feeBasisPoints0 = getSwapFeeBasisPoints(true)
    const feeBasisPoints1 = getSwapFeeBasisPoints(false)

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
        .mul(BASIS_POINTS_DIVISOR - feeBasisPoints0 - feeBasisPoints1)
        .div(BASIS_POINTS_DIVISOR)
      return {
        amount: adjustDecimals(toAmount),
        path: [USDG_ADDRESS, stableToken.address, toToken.address],
        feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
      }
    }

    // get toAmount for USDG => stableToken
    let toAmount = fromAmount.mul(PRECISION).div(stableToken.maxPrice)
    // apply USDG => stableToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints0).div(BASIS_POINTS_DIVISOR)

    // get toAmount for stableToken => toToken
    toAmount = toAmount.mul(stableToken.minPrice).div(toTokenPriceUsd || toTokenMaxPrice)
    // apply stableToken => toToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints1).div(BASIS_POINTS_DIVISOR)

    return {
      amount: adjustDecimals(toAmount),
      path: [USDG_ADDRESS, stableToken.address, toToken.address],
      feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
    }
  }

  const toAmount =
    ratio && !ratio.isZero()
      ? toAmountBasedOnRatio
      : fromAmount.mul(fromTokenMinPrice).div(toTokenPriceUsd || toTokenMaxPrice)

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, fromToken.decimals, USDG_DECIMALS)
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdgSupply,
    totalTokenWeights
  )
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdgSupply,
    totalTokenWeights
  )
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1

  return {
    amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
    feeBasisPoints,
  }
}

export function getProfitPrice(closePrice, position) {
  let profitPrice
  if (position && position.averagePrice && closePrice) {
    profitPrice = position.isLong
      ? position.averagePrice.mul(BASIS_POINTS_DIVISOR + MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
      : position.averagePrice.mul(BASIS_POINTS_DIVISOR - MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
  }
  return profitPrice
}

export function calculatePositionDelta(
  price,
  { size, collateral, isLong, averagePrice, lastIncreasedTime },
  sizeDelta
) {
  if (!sizeDelta) {
    sizeDelta = size
  }
  const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice)
  let delta = sizeDelta.mul(priceDelta).div(averagePrice)
  const pendingDelta = delta

  const minProfitExpired = lastIncreasedTime + MIN_PROFIT_TIME < Date.now() / 1000
  const hasProfit = isLong ? price.gt(averagePrice) : price.lt(averagePrice)
  if (!minProfitExpired && hasProfit && delta.mul(BASIS_POINTS_DIVISOR).lte(size.mul(MIN_PROFIT_BIPS))) {
    delta = bigNumberify(0)
  }

  const deltaPercentage = delta.mul(BASIS_POINTS_DIVISOR).div(collateral)
  const pendingDeltaPercentage = pendingDelta.mul(BASIS_POINTS_DIVISOR).div(collateral)

  return {
    delta,
    pendingDelta,
    pendingDeltaPercentage,
    hasProfit,
    deltaPercentage,
  }
}

export function getDeltaStr({ delta, deltaPercentage, hasProfit }) {
  let deltaStr
  let deltaPercentageStr

  if (delta.gt(0)) {
    deltaStr = hasProfit ? '+' : '-'
    deltaPercentageStr = hasProfit ? '+' : '-'
  } else {
    deltaStr = ''
    deltaPercentageStr = ''
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`

  return { deltaStr, deltaPercentageStr }
}

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}) {
  if (!size && !sizeDelta) {
    return
  }
  if (!collateral && !collateralDelta) {
    return
  }

  let nextSize = size ? size : bigNumberify(0)
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta)
    } else {
      if (sizeDelta.gte(size)) {
        return
      }
      nextSize = size.sub(sizeDelta)
    }
  }

  let remainingCollateral = collateral ? collateral : bigNumberify(0)
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta)
    } else {
      if (collateralDelta.gte(collateral)) {
        return
      }
      remainingCollateral = collateral.sub(collateralDelta)
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.add(delta)
    } else {
      if (delta.gt(remainingCollateral)) {
        return
      }

      remainingCollateral = remainingCollateral.sub(delta)
    }
  }

  if (remainingCollateral.eq(0)) {
    return
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    : remainingCollateral
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
    remainingCollateral = remainingCollateral.sub(fundingFee)
  }

  return nextSize.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral)
}

export function getLiquidationPrice(data) {
  let {
    isLong,
    size,
    collateral,
    averagePrice,
    entryFundingRate,
    cumulativeFundingRate,
    sizeDelta,
    collateralDelta,
    increaseCollateral,
    increaseSize,
    delta,
    hasProfit,
    includeDelta,
  } = data
  if (!size || !collateral || !averagePrice) {
    return
  }

  let nextSize = size ? size : bigNumberify(0)
  let remainingCollateral = collateral

  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta)
    } else {
      if (sizeDelta.gte(size)) {
        return
      }
      nextSize = size.sub(sizeDelta)
    }

    if (includeDelta && !hasProfit) {
      const adjustedDelta = sizeDelta.mul(delta).div(size)
      remainingCollateral = remainingCollateral.sub(adjustedDelta)
    }
  }

  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = remainingCollateral.add(collateralDelta)
    } else {
      if (collateralDelta.gte(remainingCollateral)) {
        return
      }
      remainingCollateral = remainingCollateral.sub(collateralDelta)
    }
  }

  let positionFee = getMarginFee(size).add(LIQUIDATION_FEE)
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
    positionFee = positionFee.add(fundingFee)
  }

  const liquidationPriceForFees = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  })

  const liquidationPriceForMaxLeverage = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  })

  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage
  }
  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees
  }

  if (isLong) {
    // return the higher price
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
      ? liquidationPriceForFees
      : liquidationPriceForMaxLeverage
  }

  // return the lower price
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
    ? liquidationPriceForFees
    : liquidationPriceForMaxLeverage
}

export function getPositionKey(account, collateralTokenAddress, indexTokenAddress, isLong, nativeTokenAddress) {
  const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress
  const tokenAddress1 = indexTokenAddress === AddressZero ? nativeTokenAddress : indexTokenAddress
  return account + ':' + tokenAddress0 + ':' + tokenAddress1 + ':' + isLong
}

export function getPositionContractKey(account, collateralToken, indexToken, isLong) {
  return ethers.utils.solidityKeccak256(
    ['address', 'address', 'address', 'bool'],
    [account, collateralToken, indexToken, isLong]
  )
}

export function getSwapFeeBasisPoints(isStable) {
  return isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS
}

export function shortenAddress(address, length) {
  if (!length) {
    return ''
  }
  if (!address) {
    return address
  }
  if (address.length < 10) {
    return address
  }
  let left = Math.floor((length - 3) / 2) + 1
  return address.substring(0, left) + '...' + address.substring(address.length - (length - (left + 3)), address.length)
}

export function useENS(address) {
  const [ensName, setENSName] = useState()

  useEffect(() => {
    async function resolveENS() {
      if (address) {
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/eth')
        const name = await provider.lookupAddress(address.toLowerCase())
        if (name) setENSName(name)
      }
    }
    resolveENS()
  }, [address])

  return { ensName }
}

export function getOrderKey(order) {
  return `${order.type}-${order.account}-${order.index}`
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      id
      type
      account
      status
      createdTimestamp
    }
  }`)

  const [res, setRes] = useState()

  useEffect(() => {
    getStatsGraphClient(chainId).query({ query }).then(setRes)
  }, [setRes, query, chainId])

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.id}`) : null
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId)
    const orderBookAddress = getContract(chainId, 'OrderBook')
    const contract = new ethers.Contract(orderBookAddress, OrderBook, provider)
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1)
          const method = `get${type}Order`
          const orderFromChain = await contract[method](order.account, order.index)
          const ret = {}
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val
          }
          if (order.type === 'swap') {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero)
          }
          ret.type = type
          ret.index = order.index
          ret.account = order.account
          ret.createdTimestamp = order.createdTimestamp
          return ret
        } catch (ex) {
          console.error(ex)
        }
      })
    )
  })

  return orders.filter(Boolean)
}

/* export function useAccountOrders(flagOrdersEnabled, overrideAccount) {
  const { library, account: connectedAccount } = useWeb3React()
  const active = true // this is used in Actions.js so set active to always be true
  const account = overrideAccount || connectedAccount
  const [ordersIndexes, setOrdersIndexes] = useState({ swap: [], increase: [], decrease: [] })

  const { chainId } = useChainId()
  const shouldRequest = active && account && flagOrdersEnabled

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const orderBookReaderAddress = getContract(chainId, 'OrderBookReader')
  const key = shouldRequest ? [active, chainId, orderBookAddress, account] : false

  const apiUrl = SUBGRAPH_URLS[chainId].stats

  useEffect(() => {
    request(
      apiUrl,
      gql`
        query GetUserOrders($address: String!) {
          orders(
            first: 100
            orderBy: createdTimestamp
            orderDirection: desc
            where: { status: "open", account: $address }
          ) {
            id
            type
            account
            status
            createdTimestamp
          }
        }
      `,
      {
        address: account?.toLowerCase(),
      }
    )
      .then(({ orders }) => {
        if (orders.length > 0) {
          const data = orders
          const ret = { swap: [], increase: [], decrease: [] }
          for (var i = 0; i < data.length; i++) {
            if (data[i].type === 'swap') {
              ret.swap.push(data[0].id)
            } else if (data[i].type === 'increase') {
              ret.increase.push(data[0].id)
            } else {
              ret.decrease.push(data[0].id)
            }
          }
          setOrdersIndexes(ret)
        }
      })
      .catch(({ error }) => {
        console.log(error)
      })
  }, [account, apiUrl])
  const {
    data: orders = [],
    mutate: updateOrders,
    error: ordersError,
  } = useSWR(key, {
    dedupingInterval: 10000,
    fetcher: async (active, chainId, orderBookAddress, account) => {
      const provider = getProvider(library, chainId)
      const orderBookContract = new ethers.Contract(orderBookAddress, OrderBook, provider)
      const orderBookReaderContract = new ethers.Contract(orderBookReaderAddress, OrderBookReader, provider)

      const getOrders = async (method, keys, parseFunc) => {
        if (!keys || !keys.length) {
          return []
        }
        const ordersData = await orderBookReaderContract[method](orderBookAddress, keys)
        const orders = parseFunc(chainId, ordersData, account, keys)

        return orders
      }

      try {
        const [swapOrders = [], increaseOrders = [], decreaseOrders = []] = await Promise.all([
          getOrders('getSwapOrders', ordersIndexes.swap, parseSwapOrdersData),
          getOrders('getIncreaseOrders', ordersIndexes.increase, parseIncreaseOrdersData),
          getOrders('getDecreaseOrders', ordersIndexes.decrease, parseDecreaseOrdersData),
        ])
        return [...swapOrders, ...increaseOrders, ...decreaseOrders]
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  return [orders, updateOrders, ordersError]
} */

export function isMobileDevice(navigator) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const CHART_PERIODS = {
  '5m': 60 * 5,
  '15m': 60 * 15,
  '1h': 60 * 60,
  '4h': 60 * 60 * 4,
  '1d': 60 * 60 * 24,
}

export function getTotalVolumeSum(volumes) {
  if (!volumes || volumes.length === 0) {
    return
  }

  let volume = bigNumberify(0)

  for (let i = 0; i < volumes.length; i++) {
    volume = volume.add(volumes[i].data.volume)
  }

  return volume
}

export function getBalanceAndSupplyData(balances) {
  if (!balances || balances.length === 0) {
    return {}
  }

  const keys = ['falcon', 'flp']
  const balanceData = {}
  const supplyData = {}
  const propsLength = 2

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    balanceData[key] = balances[i * propsLength]
    supplyData[key] = balances[i * propsLength + 1]
  }

  return { balanceData, supplyData }
}

export function getProcessedData(
  balanceData,
  supplyData,
  aum,
  nativeTokenPrice,
  falconPrice,
  flpStaked,
  falconStaked,
  falconTotalStaked,
  falconEarns,
  flpEarns,
  blocksXyear,
  FalconRewardsXblock,
  FlpRewardsXblock
) {
  if (
    !balanceData ||
    !supplyData ||
    !aum ||
    !nativeTokenPrice ||
    !falconPrice ||
    !flpStaked ||
    !falconStaked ||
    !falconTotalStaked ||
    !falconEarns ||
    !flpEarns ||
    !blocksXyear ||
    !FalconRewardsXblock ||
    !FlpRewardsXblock
  ) {
    return {}
  }

  const data = {}

  data.falconEarns = falconEarns
  data.falconPrice = falconPrice
  data.falconPriceUsd = data.falconPrice.mul(nativeTokenPrice).div(expandDecimals(1, USDG_DECIMALS))
  data.falconEarnsUsd = data.falconEarns.mul(data.falconPriceUsd).div(expandDecimals(1, USDG_DECIMALS))
  data.falconBalance = balanceData.falcon
  data.falconBalanceUsd = data.falconBalance.mul(data.falconPriceUsd).div(expandDecimals(1, USDG_DECIMALS))
  data.falconSupply = supplyData.falcon
  data.falconSupplyUsd = data.falconSupply.mul(data.falconPriceUsd).div(expandDecimals(1, USDG_DECIMALS))
  data.falconStaked = falconStaked
  data.falconStakedUsd = data.falconStaked.mul(data.falconPriceUsd).div(expandDecimals(1, USDG_DECIMALS))
  data.falconTotalStaked = falconTotalStaked
  data.falconTotalStakedUsd = data.falconTotalStaked.mul(data.falconPriceUsd).div(expandDecimals(1, USDG_DECIMALS))

  const totalRewardPricePerYear = data.falconPriceUsd.mul(FalconRewardsXblock).mul(blocksXyear)
  data.FalconApr = data.falconTotalStakedUsd.eq(0)
    ? bigNumberify(0)
    : totalRewardPricePerYear.div(data.falconTotalStakedUsd).mul(100)

  data.flpSupply = supplyData.flp
  data.flpPrice =
    data.flpSupply && data.flpSupply.gt(0)
      ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(data.flpSupply)
      : bigNumberify(0)

  data.flpSupplyUsd = supplyData.flp.mul(data.flpPrice).div(expandDecimals(1, 18))
  data.flpBalance = balanceData.flp
  data.flpBalanceUsd = balanceData.flp.mul(data.flpPrice).div(expandDecimals(1, GLP_DECIMALS))
  data.flpStaked = flpStaked
  data.flpStakedUsd = data.flpStaked.mul(data.flpPrice).div(expandDecimals(1, GLP_DECIMALS))
  data.flpEarns = flpEarns
  data.flpEarnsUsd = data.flpEarns.mul(nativeTokenPrice).div(expandDecimals(1, USDG_DECIMALS))

  const totalRewardPricePerYearLP = nativeTokenPrice.mul(FlpRewardsXblock).mul(blocksXyear)

  data.FlpApr = data.flpSupplyUsd.eq(0) ? bigNumberify(0) : totalRewardPricePerYearLP.div(data.flpSupplyUsd).mul(100)

  return data
}

export function getPageTitle(data) {
  return `${data} | Decentralized
  Perpetual Exchange | SpaceDex`
}

export function isHashZero(value) {
  return value === ethers.constants.HashZero
}
export function isAddressZero(value) {
  return value === ethers.constants.AddressZero
}

export function isDevelopment() {
  return !window.location.host?.includes('space-dex.io') && !window.location.host?.includes('ipfs.io')
}

export function isLocal() {
  return window.location.host?.includes('localhost')
}

export function getHomeUrl() {
  if (isLocal()) {
    return 'http://localhost:3000'
  }

  return 'https://app.space-dex.io'
}

export function getAppBaseUrl() {
  if (isLocal()) {
    return 'http://localhost:3000/#'
  }

  return 'https://app.space-dex.io/#'
}

export function getRootShareApiUrl() {
  if (isLocal()) {
    return 'https://spcdexio-shared.vercel.app'
  }

  return 'https://share.space-dex.io'
}

export function getTradePageUrl() {
  if (isLocal()) {
    return 'http://localhost:3000/#/trade'
  }

  return 'https://app.space-dex.io/#/trade'
}

export function importImage(name) {
  let tokenImage = null
  try {
    tokenImage = require('img/' + name)
  } catch (error) {
    console.error(error)
  }
  return tokenImage.default
}

export function getTwitterIntentURL(text, url = '', hashtag = '') {
  let finalURL = 'https://twitter.com/intent/tweet?text='
  if (text.length > 0) {
    finalURL += Array.isArray(text) ? text.map((t) => encodeURIComponent(t)).join('%0a%0a') : encodeURIComponent(text)
    if (hashtag.length > 0) {
      finalURL += '&hashtags=' + encodeURIComponent(hashtag.replace(/#/g, ''))
    }
    if (url.length > 0) {
      finalURL += '&url=' + encodeURIComponent(url)
    }
  }
  return finalURL
}

export function getPositionForOrder(account, order, positionsMap) {
  const key = getPositionKey(account, order.collateralToken, order.indexToken, order.isLong)
  const position = positionsMap[key]
  return position && position.size && position.size.gt(0) ? position : null
}

export function getOrderError(account, order, positionsMap, position) {
  if (order.type !== DECREASE) {
    return
  }

  const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap)

  if (!positionForOrder) {
    return 'No open position, order cannot be executed unless a position is opened'
  }
  if (positionForOrder.size.lt(order.sizeDelta)) {
    return 'Order size is bigger than position, will only be executable if position increases'
  }

  if (positionForOrder.size.gt(order.sizeDelta)) {
    if (positionForOrder.size.sub(order.sizeDelta).lt(positionForOrder.collateral.sub(order.collateralDelta))) {
      return "Order cannot be executed as it would reduce the position's leverage below 1"
    }
    if (positionForOrder.size.sub(order.sizeDelta).lt(expandDecimals(5, USD_DECIMALS))) {
      return 'Order cannot be executed as the remaining position would be smaller than $2.00'
    }
  }
}

export function arrayURLFetcher(...urlArr) {
  const fetcher = (url) => fetch(url).then((res) => res.json())
  return Promise.all(urlArr.map(fetcher))
}

export function shouldShowRedirectModal(timestamp) {
  const thirtyDays = 1000 * 60 * 60 * 24 * 30
  const expiryTime = timestamp + thirtyDays
  return !isValidTimestamp(timestamp) || Date.now() > expiryTime
}
