import { Percent } from '@jamonswap/sdk-core'
import { Trans } from '@lingui/macro'
import { ButtonText } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import SettingsTab from 'components/Settings'
import { ThemedText } from 'theme'

interface TapProps {
  options: string[]
  option: any
  onChange: any
}

export function TapOrders(props: TapProps) {
  const { options, option, onChange } = props

  const DEFAULT_MIGRATE_SLIPPAGE_TOLERANCE = new Percent(30, 10_000)

  const notSwap = options.includes('Stop')
  return (
    <AutoRow gap="0.2rem" style={{ marginBottom: '0.2rem' }}>
      <AutoColumn>
        <ButtonText width="fit-content" onClick={() => onChange('Market')}>
          <ThemedText.Body color={option === 'Market' ? 'secondary2' : 'text1'}>
            <Trans>Market</Trans>
          </ThemedText.Body>
        </ButtonText>
      </AutoColumn>
      <AutoColumn style={{ flexGrow: notSwap ? '0' : '1' }}>
        <ButtonText width="fit-content" onClick={() => onChange('Limit')}>
          <ThemedText.Body color={option === 'Limit' ? 'secondary2' : 'text1'}>
            <Trans>Limit</Trans>
          </ThemedText.Body>
        </ButtonText>
      </AutoColumn>
      {notSwap && (
        <AutoColumn style={{ flexGrow: '1' }}>
          <ButtonText width="fit-content" onClick={() => onChange('Stop')}>
            <ThemedText.Body color={option === 'Stop' ? 'secondary2' : 'text1'}>
              <Trans>Trigger</Trans>
            </ThemedText.Body>
          </ButtonText>
        </AutoColumn>
      )}
      <AutoColumn>
        <SettingsTab placeholderSlippage={DEFAULT_MIGRATE_SLIPPAGE_TOLERANCE} />
      </AutoColumn>
    </AutoRow>
  )
}
