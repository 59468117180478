import SocialNetworks from 'components/Header/SocialNetworks'
import Loader from 'components/Loader'
import TopLevelModals from 'components/TopLevelModals'
import { useActiveLocale } from 'hooks/useActiveLocale'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { SWRConfig } from 'swr'
import { isMobile } from 'utils/userAgent'

import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { Analytics } from './Analytics'
import { CopyTrading } from './CopyTrading'
import { Profile } from './CopyTrading/Profile'
import { TopTraders } from './CopyTrading/TopTraders'
import { Earn } from './Earn'
import { BuySellLP } from './Earn/components/BuySellLP'
import { Home } from './Home'
import { RedirectPathToHomeOnly, RedirectToHome } from './Home/redirects'
import { Referrals } from './Referrals'
import ReferralGetter from './Referrals/getter'
import { Trade } from './Trade'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const history = useHistory()
  useAnalyticsReporter(useLocation())

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  const currentLocation = useLocation()

  const currentLanguage = useActiveLocale()

  return (
    <SWRConfig value={{ refreshInterval: 10000 }}>
      <ErrorBoundary>
        <Route component={DarkModeQueryParamReader} />
        <Route component={ApeModeQueryParamReader} />
        <Route component={ReferralGetter} />
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Polling location={currentLocation.pathname} />
            {!isMobile && <SocialNetworks language={currentLanguage} />}
            <TopLevelModals />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact strict path="/home" component={Home} />
                <Route exact strict path="/home" component={RedirectPathToHomeOnly} />
                <Route exact strict path="/home/:outputCurrency" component={RedirectToHome} />

                <Route exact strict path="/trade" component={Trade} />
                {/* <Route exact strict path="/competition" component={Competition} /> */}

                <Route exact strict path="/copytrading" component={CopyTrading} />
                <Route exact strict path="/copytrading/profile" component={Profile} />
                <Route exact strict path="/copytrading/traders" component={TopTraders} />

                <Route exact strict path="/earn" component={Earn} />
                <Route exact strict path="/buy" component={BuySellLP} />

                <Route exact strict path="/referrals" component={Referrals} />
                <Route exact path="/referrals/:account" component={Referrals} />

                <Route exact strict path="/analytics" component={Analytics} />

                <Route component={RedirectPathToHomeOnly} />
              </Switch>
            </Suspense>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </ErrorBoundary>
    </SWRConfig>
  )
}
