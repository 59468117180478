/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonLight } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween, RowCenter } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { Token, TokenInfo } from 'domain/tokens'
import { BigNumber } from 'ethers'
import { USD_DECIMALS } from 'lib/legacy'
import { formatAmount, formatKeyAmount } from 'lib/numbers'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.primary1};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`

const ResponsiveGrid = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 0.8fr 0.8fr 1fr 1.5fr 0.5fr 0.8fr;
  padding: 1rem;
  :hover,
  :focus {
    background: ${({ theme }) => theme.bg2};
    border-radius: 8px;
  }
`
const Row = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

interface FeesRowProps {
  isBuying: boolean
  token: Token
  tokenInfo: TokenInfo
  amountLeftToDeposit?: BigNumber
  availableAmountUsd?: BigNumber
  balanceUsd?: BigNumber
  managedUsd: BigNumber
  renderFees: any
  selectToken: any
}

export function FeesRow({
  isBuying,
  token,
  tokenInfo,
  amountLeftToDeposit,
  availableAmountUsd,
  balanceUsd,
  managedUsd,
  renderFees,
  selectToken,
}: FeesRowProps) {
  return (
    <>
      {isMobile ? (
        <DarkCard height="fit-content">
          <AutoRow justify="center" gap="0.5rem">
            <img src={`${process.env.PUBLIC_URL}/images/tokens/${token.symbol}.png`} alt={token.symbol} width={36} />
            <ThemedText.MediumHeader>
              {token.name} ({token.symbol})
            </ThemedText.MediumHeader>
          </AutoRow>
          <Divider />
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              ${formatKeyAmount(tokenInfo, 'minPrice', USD_DECIMALS, 2, true)}
            </ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>Available amount to deposit into FLP</Trans>
                </ThemedText.SubHeader>
              }
            >
              <Row>
                <ThemedText.SubHeader color="text2">
                  <Trans>Available</Trans>
                </ThemedText.SubHeader>
                <HelpCircle width={16} />
              </Row>
            </MouseoverTooltip>
            <ThemedText.SubHeader>
              <MouseoverTooltip
                text={
                  <AutoColumn gap="0.2rem" justify="center">
                    <ThemedText.SubHeader>
                      <Trans>Current Pool Amount</Trans>
                    </ThemedText.SubHeader>
                    <ThemedText.Small>
                      {formatAmount(managedUsd, USD_DECIMALS, 2, true)} (
                      {formatKeyAmount(tokenInfo, 'poolAmount', token.decimals, 4, true)} {token.symbol})
                    </ThemedText.Small>

                    <ThemedText.SubHeader>
                      <Trans>Max Pool Capacity</Trans>
                    </ThemedText.SubHeader>
                    <ThemedText.Small>${formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)}</ThemedText.Small>
                  </AutoColumn>
                }
              >
                <Row>
                  {isBuying && (
                    <ThemedText.SubHeader>
                      {amountLeftToDeposit && `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`}
                    </ThemedText.SubHeader>
                  )}
                  {!isBuying && (
                    <ThemedText.SubHeader>
                      {availableAmountUsd && availableAmountUsd.lt(0)
                        ? '$0.00'
                        : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`}
                    </ThemedText.SubHeader>
                  )}
                  <Wrapper>
                    <HelpCircle width={16} />
                  </Wrapper>
                </Row>
              </MouseoverTooltip>
            </ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Wallet</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              {formatKeyAmount(tokenInfo, 'balance', tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
              {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
            </ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>Fees will be shown once you have entered an amount in the order form</Trans>
                </ThemedText.SubHeader>
              }
            >
              <Row>
                <ThemedText.SubHeader color="text2">
                  <Trans>Fees</Trans>
                </ThemedText.SubHeader>
                <HelpCircle width={16} />
              </Row>
            </MouseoverTooltip>
            <ThemedText.SubHeader>
              {renderFees === '-' ? (
                '-'
              ) : (
                <MouseoverTooltip
                  text={
                    <ThemedText.SubHeader>
                      <Trans>Fee</Trans>
                    </ThemedText.SubHeader>
                  }
                >
                  <Row>
                    <ThemedText.SubHeader>{renderFees}</ThemedText.SubHeader>
                    <Wrapper>
                      <HelpCircle width={16} />
                    </Wrapper>
                  </Row>
                </MouseoverTooltip>
              )}
            </ThemedText.SubHeader>
          </RowBetween>
          <Divider />
          <RowCenter mt="1rem">
            {isBuying && (
              <ButtonLight width="fit-content" size="10px" onClick={() => selectToken(token)}>
                <Trans>Buy with</Trans> {token.symbol}
              </ButtonLight>
            )}
            {!isBuying && (
              <ButtonLight width="fit-content" size="10px" onClick={() => selectToken(token)}>
                <Trans>Sell for</Trans> {token.symbol}
              </ButtonLight>
            )}
          </RowCenter>
        </DarkCard>
      ) : (
        <ResponsiveGrid>
          <AutoRow gap="0.2rem">
            <img src={`${process.env.PUBLIC_URL}/images/tokens/${token.symbol}.png`} alt={token.symbol} width={24} />
            <ThemedText.SubHeader>{token.symbol}</ThemedText.SubHeader>
          </AutoRow>

          <ThemedText.SubHeader>${formatKeyAmount(tokenInfo, 'minPrice', USD_DECIMALS, 2, true)}</ThemedText.SubHeader>

          <ThemedText.SubHeader>
            <MouseoverTooltip
              text={
                <AutoColumn gap="0.2rem" justify="center">
                  <ThemedText.SubHeader>
                    <Trans>Current Pool Amount</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.Small>
                    {formatAmount(managedUsd, USD_DECIMALS, 2, true)} (
                    {formatKeyAmount(tokenInfo, 'poolAmount', token.decimals, 4, true)} {token.symbol})
                  </ThemedText.Small>

                  <ThemedText.SubHeader>
                    <Trans>Max Pool Capacity</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.Small>${formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)}</ThemedText.Small>
                </AutoColumn>
              }
            >
              <Row>
                {isBuying && (
                  <ThemedText.SubHeader>
                    {amountLeftToDeposit && `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`}
                  </ThemedText.SubHeader>
                )}
                {!isBuying && (
                  <ThemedText.SubHeader>
                    {availableAmountUsd && availableAmountUsd.lt(0)
                      ? '$0.00'
                      : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`}
                  </ThemedText.SubHeader>
                )}
                <Wrapper>
                  <HelpCircle width={16} />
                </Wrapper>
              </Row>
            </MouseoverTooltip>
          </ThemedText.SubHeader>

          <ThemedText.SubHeader>
            {formatKeyAmount(tokenInfo, 'balance', tokenInfo.decimals, 2, true)} {tokenInfo.symbol} ($
            {formatAmount(balanceUsd, USD_DECIMALS, 2, true)})
          </ThemedText.SubHeader>
          <ThemedText.SubHeader>
            {renderFees === '-' ? (
              '-'
            ) : (
              <MouseoverTooltip
                text={
                  <AutoColumn>
                    {renderFees === 'NA' ? (
                      <ThemedText.SubHeader>
                        <Trans>Max pool capacity reached for</Trans> {tokenInfo.symbol}.{' '}
                        <Trans>Please mint FLP using another token</Trans>
                      </ThemedText.SubHeader>
                    ) : (
                      <ThemedText.SubHeader>
                        <Trans>Fee</Trans>
                      </ThemedText.SubHeader>
                    )}
                  </AutoColumn>
                }
              >
                <Row>
                  <ThemedText.SubHeader>{renderFees}</ThemedText.SubHeader>
                  <Wrapper>
                    <HelpCircle width={16} />
                  </Wrapper>
                </Row>
              </MouseoverTooltip>
            )}
          </ThemedText.SubHeader>

          {isBuying && (
            <ButtonLight width="10rem" size="10px" onClick={() => selectToken(token)}>
              <ThemedText.SubHeader fontSize={12}>
                <Trans>Buy with</Trans> {token.symbol}
              </ThemedText.SubHeader>
            </ButtonLight>
          )}
          {!isBuying && (
            <ButtonLight width="10rem" size="10px" onClick={() => selectToken(token)}>
              <ThemedText.SubHeader fontSize={12}>
                <Trans>Sell for</Trans> {token.symbol}
              </ThemedText.SubHeader>
            </ButtonLight>
          )}
        </ResponsiveGrid>
      )}
    </>
  )
}
