// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowAround, RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { USD_DECIMALS } from 'lib/legacy'
import { formatAmount } from 'lib/numbers'
import { useState } from 'react'
import { ChevronDown, ChevronUp, Edit, Eye, MoreVertical, MousePointer, Share2 } from 'react-feather'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  width: 100%;
`

const ResponsiveGrid = styled.div<{ isMobile?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? '1fr 1.2fr 0fr' : '0.8fr 1fr 0.8fr 1fr 1fr 1fr 0.8fr 0.6fr 0.3fr'};
  padding: 8px;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;

  left: -3px;
  top: -3px;
`

const FlyoutMenu = styled.div`
  width: 210px;
  max-height: 350px;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 100;
  padding: 12px;
  gap: 0.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const ButtonOptions = styled(ButtonEmpty)<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.bg4)};
  cursor: pointer;
  padding: 8px;
  border-radius: 24px;

  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    background-color: ${({ theme, isMobile }) => (isMobile ? 'transparent' : theme.primary1)};
    color: ${({ theme, isMobile }) => (isMobile ? theme.text1 : theme.white)};
  }
  &:active {
    text-decoration: none;
  }
`

const ButtonClose = styled(ButtonEmpty)`
  width: fit-content;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;

  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary1};
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 16px;
  margin: auto;
  padding: 16px;
  gap: 1rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

interface PositionsProps {
  position: any
  showPnlAfterFees: boolean
  positionDelta: any
  hasPositionProfit: boolean
  borrowFeeUSD: any
  liquidationPrice: any
  onPositionClick: any
  sellPosition: any
  editPosition: any
  sharePosition: any
}

export function Positions({
  position,
  showPnlAfterFees,
  positionDelta,
  hasPositionProfit,
  borrowFeeUSD,
  liquidationPrice,
  onPositionClick,
  sellPosition,
  editPosition,
  sharePosition,
}: PositionsProps) {
  const [showMore, setShowMore] = useState(false)

  return (
    <Container>
      <ResponsiveGrid isMobile={isMobile}>
        <AutoColumn>
          <AutoRow align="center">
            <ThemedText.SubHeader mr="0.5rem">{position.indexToken.symbol}</ThemedText.SubHeader>
            {position.hasPendingChanges && <Spinner />}
          </AutoRow>
          <AutoRow gap="0.1rem">
            {position.leverage && (
              <ThemedText.SubHeader color="primary1" fontSize={14}>
                {formatAmount(position.leverage, 4, 2, true)}x&nbsp;
              </ThemedText.SubHeader>
            )}
            <ThemedText.SubHeader>{position.isLong ? t`Long` : t`Short`}</ThemedText.SubHeader>
          </AutoRow>
        </AutoColumn>
        {!position.netValue && (
          <ThemedText.SubHeader>
            <Trans>Opening</Trans>...
          </ThemedText.SubHeader>
        )}
        {position.netValue && (
          <ThemedText.SubHeader>
            <MouseoverTooltip
              text={
                <AutoColumn>
                  <ThemedText.SubHeader mb="0.5rem">
                    {showPnlAfterFees ? (
                      <Trans>Net Value: Initial Collateral - Fees + PnL</Trans>
                    ) : (
                      <Trans>Net Value: Initial Collateral - Borrow Fee + PnL</Trans>
                    )}
                  </ThemedText.SubHeader>

                  <ThemedText.SubHeader color="text2">
                    <Trans>Initial Collateral</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>

                  <ThemedText.SubHeader color="text2">
                    <Trans>PnL</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{position.deltaBeforeFeesStr}</ThemedText.SubHeader>

                  <ThemedText.SubHeader color="text2">
                    <Trans>Borrow Fee</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{`-$${formatAmount(
                    position.fundingFee,
                    USD_DECIMALS,
                    2,
                    true
                  )}`}</ThemedText.SubHeader>

                  <ThemedText.SubHeader color="text2">
                    <Trans>Open + Close fee</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{`-$${formatAmount(
                    position.positionFee,
                    USD_DECIMALS,
                    2,
                    true
                  )}`}</ThemedText.SubHeader>

                  <ThemedText.SubHeader color="text2">
                    <Trans>PnL After Fees</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{`${position.deltaAfterFeesStr} (${position.deltaAfterFeesPercentageStr})`}</ThemedText.SubHeader>
                </AutoColumn>
              }
            >
              <AutoColumn>
                <AutoRow>
                  {`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                  <WrapperIcon>
                    <Eye width={14} />
                  </WrapperIcon>
                </AutoRow>
                <ThemedText.SubHeader
                  fontSize={12}
                  color={positionDelta.eq(0) ? 'grey' : hasPositionProfit && positionDelta.gt(0) ? 'green' : 'red'}
                >
                  {position.deltaStr} ({position.deltaPercentageStr})
                </ThemedText.SubHeader>
              </AutoColumn>
            </MouseoverTooltip>
          </ThemedText.SubHeader>
        )}
        {!isMobile && (
          <>
            <ThemedText.SubHeader>${formatAmount(position.size, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
            <ThemedText.SubHeader>
              <MouseoverTooltip
                text={
                  <AutoColumn>
                    <ThemedText.SubHeader mb="0.5rem">
                      {position.hasLowCollateral && (
                        <div>
                          <Trans>
                            WARNING: This position has a low amount of collateral after deducting borrowing fees,
                            deposit more collateral to reduce the positions liquidation risk.
                          </Trans>
                          <br />
                          <br />
                        </div>
                      )}
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader color="text2">
                      <Trans>Initial Collateral</Trans>:
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader>
                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader color="text2">
                      <Trans>Borrow Fee</Trans>:
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader>{`-$${formatAmount(
                      position.fundingFee,
                      USD_DECIMALS,
                      2,
                      true
                    )}`}</ThemedText.SubHeader>
                    <ThemedText.SubHeader color="text2">
                      <Trans>Borrow Fee / Day</Trans>:
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader>{`-$${borrowFeeUSD}`}</ThemedText.SubHeader>

                    <ThemedText.SubHeader mb="0.5rem">
                      {position.hasLowCollateral && (
                        <Trans>Use the Edit Collateral icon to deposit or withdraw collateral.</Trans>
                      )}
                    </ThemedText.SubHeader>
                  </AutoColumn>
                }
              >
                <AutoColumn>
                  <AutoRow>
                    {`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                    <WrapperIcon>
                      <Eye width={14} />
                    </WrapperIcon>
                  </AutoRow>
                </AutoColumn>
              </MouseoverTooltip>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader onClick={() => onPositionClick(position)}>
              <MouseoverTooltip
                text={
                  <AutoColumn gap="0.5rem">
                    <ThemedText.SubHeader>
                      <Trans>
                        Click on a row to select the positions market, then use the swap box to increase your position
                        size if needed
                      </Trans>
                      .
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader>
                      <Trans>
                        Use the Close button to reduce your position size, or to set stop-loss / take-profit orders
                      </Trans>
                      .
                    </ThemedText.SubHeader>
                  </AutoColumn>
                }
              >
                <AutoColumn>
                  <AutoRow>
                    {`$${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}`}
                    <WrapperIcon>
                      <Eye width={14} />
                    </WrapperIcon>
                  </AutoRow>
                </AutoColumn>
              </MouseoverTooltip>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader onClick={() => onPositionClick(position)}>
              ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}
            </ThemedText.SubHeader>
            <ThemedText.SubHeader onClick={() => onPositionClick(position)}>
              ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
            </ThemedText.SubHeader>
            <ButtonClose onClick={() => sellPosition(position)} disabled={position.size.eq(0)}>
              <Trans>Close</Trans>
            </ButtonClose>
          </>
        )}
        {!isMobile ? (
          <AutoColumn>
            <ButtonOptions onClick={() => setShowMore(true)} disabled={position.size.eq(0)}>
              <MoreVertical />
            </ButtonOptions>
            <AutoColumn justify="center">
              {showMore && (
                <FlyoutMenu onMouseLeave={() => setShowMore(false)}>
                  <Row onClick={() => editPosition(position)}>
                    <Edit width={18} />
                    <ThemedText.SubHeader>
                      <Trans>Edit Collateral</Trans>
                    </ThemedText.SubHeader>
                  </Row>
                  <Row
                    onClick={() => {
                      onPositionClick(position)
                      setShowMore(false)
                    }}
                  >
                    <MousePointer width={18} />
                    <ThemedText.SubHeader>
                      <Trans>Select Market</Trans>
                    </ThemedText.SubHeader>
                  </Row>
                  <Row onClick={() => sharePosition(position)}>
                    <Share2 width={18} />
                    <ThemedText.SubHeader>
                      <Trans>Share Position</Trans>
                    </ThemedText.SubHeader>
                  </Row>
                </FlyoutMenu>
              )}
            </AutoColumn>
          </AutoColumn>
        ) : (
          <ButtonOptions isMobile={isMobile} onClick={() => setShowMore(!showMore)} disabled={position.size.eq(0)}>
            {showMore ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
          </ButtonOptions>
        )}
      </ResponsiveGrid>
      {isMobile && showMore && (
        <Wrapper>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Size</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>${formatAmount(position.size, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <MouseoverTooltip
              text={
                <AutoColumn>
                  <ThemedText.SubHeader mb="0.5rem">
                    {showPnlAfterFees ? (
                      <Trans>Net Value: Initial Collateral - Fees + PnL</Trans>
                    ) : (
                      <Trans>Net Value: Initial Collateral - Borrow Fee + PnL</Trans>
                    )}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Initial Collateral</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Borrow Fee</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{`-$${formatAmount(
                    position.fundingFee,
                    USD_DECIMALS,
                    2,
                    true
                  )}`}</ThemedText.SubHeader>
                  <ThemedText.SubHeader color="text2">
                    <Trans>Borrow Fee / Day</Trans>:
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>{`-$${borrowFeeUSD}`}</ThemedText.SubHeader>
                </AutoColumn>
              }
            >
              <AutoColumn>
                <AutoRow>
                  <ThemedText.SubHeader color="text2" mr="4px">
                    <Trans>Collateral</Trans>
                  </ThemedText.SubHeader>
                  <Eye width={14} height={14} />
                </AutoRow>
              </AutoColumn>
            </MouseoverTooltip>
            <ThemedText.SubHeader>{`$${formatAmount(
              position.collateralAfterFee,
              USD_DECIMALS,
              2,
              true
            )}`}</ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <MouseoverTooltip
              text={
                <AutoColumn gap="0.5rem">
                  <ThemedText.SubHeader>
                    <Trans>
                      Click on a row to select the positions market, then use the swap box to increase your position
                      size if needed
                    </Trans>
                    .
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    <Trans>
                      Use the Close button to reduce your position size, or to set stop-loss / take-profit orders
                    </Trans>
                    .
                  </ThemedText.SubHeader>
                </AutoColumn>
              }
            >
              <AutoColumn>
                <AutoRow>
                  <ThemedText.SubHeader color="text2" mr="4px">
                    <Trans>Mark Price</Trans>
                  </ThemedText.SubHeader>
                  <Eye width={14} height={14} />
                </AutoRow>
              </AutoColumn>
            </MouseoverTooltip>
            <ThemedText.SubHeader>{`$${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}`}</ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Entry Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader> ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Liq Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
          </RowBetween>
          <RowAround mt="1rem">
            <ButtonPrimary size="10px" width="fit-content" onClick={() => editPosition(position)}>
              <Edit width={18} />
            </ButtonPrimary>
            <ButtonPrimary size="10px" width="fit-content" onClick={() => onPositionClick(position)}>
              <MousePointer width={18} />
            </ButtonPrimary>
            <ButtonPrimary size="10px" width="fit-content" onClick={() => sharePosition(position)}>
              <Share2 width={18} />
            </ButtonPrimary>
          </RowAround>
        </Wrapper>
      )}
    </Container>
  )
}
