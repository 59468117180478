// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowCenter } from 'components/Row'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWalletModalToggle } from 'state/application/hooks'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { AffiliateCodeForm } from './AffiliateCodeForm'

interface AddAffiliateCodeProps {
  handleCreateReferralCode: any
  recentlyAddedCodes: any
  setRecentlyAddedCodes: any
}

export function AddAffiliateCode({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
}: AddAffiliateCodeProps) {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <RowCenter mt="2rem">
      <DarkCard width={isMobile ? '100%' : '50%'}>
        <AutoColumn gap="1rem">
          <ThemedText.LargeHeader textAlign="center">
            <Trans>Generate Referral Code</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.Body textAlign="center">
            <Trans>Looks like you don&apos;t have a referral code to share</Trans>.
            <br />
            <Trans>Create one now and start earning rebates</Trans>!
          </ThemedText.Body>
          {account ? (
            <AffiliateCodeForm
              handleCreateReferralCode={handleCreateReferralCode}
              recentlyAddedCodes={recentlyAddedCodes}
              setRecentlyAddedCodes={setRecentlyAddedCodes}
              callAfterSuccess={false}
            />
          ) : (
            <ButtonPrimary onClick={toggleWalletModal}>
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          )}
        </AutoColumn>
      </DarkCard>
    </RowCenter>
  )
}
