/* eslint-disable no-restricted-imports */
import { Percent } from '@jamonswap/sdk-core'
import { BigNumber, BigNumberish } from 'ethers'
import JSBI from 'jsbi'
import { bigNumberify, expandDecimals } from 'lib/numbers'

import { DEFAULT_CHAIN_ID } from './chains'
import { getContract } from './contracts'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const ZERO_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000'

export const NetworkContextName = 'NETWORK'

export const IS_IN_IFRAME = window.parent !== window

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000
export const L2_TXN_DISMISS_MS = 5000

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const TWO_PERCENT = new Percent(JSBI.BigInt(200), BIPS_BASE)
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const USDG_DECIMALS = 18
export const USD_DECIMALS = 30
export const USDG_ADDRESS = getContract(DEFAULT_CHAIN_ID, 'USDF')
export const BASIS_POINTS_DIVISOR = 10000
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18)
export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750

export const DUST_BNB = '2000000000000000'
export const SWAP = 'Swap'
export const INCREASE = 'Increase'
export const MARKET = 'Market'
export const MINT_BURN_FEE_BASIS_POINTS = 25
export const PRECISION = expandDecimals(1, 30)
export const TAX_BASIS_POINTS = 50

export const CHART_PERIODS = {
  '5m': 60 * 5,
  '15m': 60 * 15,
  '1h': 60 * 60,
  '4h': 60 * 60 * 4,
  '1d': 60 * 60 * 24,
}

export function adjustForDecimals(amount: BigNumber, divDecimals: number, mulDecimals: number) {
  return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals))
}

export function getTargetUsdgAmount(
  token: { weight: { mul: (arg0: any) => { (): any; new (): any; div: { (arg0: any): any; new (): any } } } },
  usdgSupply: { eq: (arg0: number) => any },
  totalTokenWeights: any
) {
  if (!token || !token.weight || !usdgSupply) {
    return
  }

  if (usdgSupply.eq(0)) {
    return bigNumberify(0)
  }

  return token.weight.mul(usdgSupply).div(totalTokenWeights)
}

export function getFeeBasisPoints(
  token: any,
  usdgDelta: { gt: (arg0: any) => any },
  feeBasisPoints: BigNumberish | undefined,
  taxBasisPoints: BigNumberish | undefined,
  increment: any,
  usdgSupply: { eq: (arg0: number) => any },
  totalTokenWeights: any
) {
  if (!token || !token.usdgAmount || !usdgSupply || !totalTokenWeights) {
    return 0
  }

  feeBasisPoints = bigNumberify(feeBasisPoints ?? 0)
  taxBasisPoints = bigNumberify(taxBasisPoints ?? 0)

  const initialAmount = token.usdgAmount
  let nextAmount = initialAmount.add(usdgDelta)
  if (!increment) {
    nextAmount = usdgDelta.gt(initialAmount) ? bigNumberify(0) : initialAmount.sub(usdgDelta)
  }

  const targetAmount = getTargetUsdgAmount(token, usdgSupply, totalTokenWeights)
  if (!targetAmount || targetAmount.eq(0)) {
    return feeBasisPoints?.toNumber()
  }

  const initialDiff = initialAmount.gt(targetAmount) ? initialAmount.sub(targetAmount) : targetAmount.sub(initialAmount)
  const nextDiff = nextAmount.gt(targetAmount) ? nextAmount.sub(targetAmount) : targetAmount.sub(nextAmount)

  if (nextDiff.lt(initialDiff)) {
    const rebateBps = taxBasisPoints?.mul(initialDiff).div(targetAmount)
    return rebateBps?.gt(feeBasisPoints ?? 0) ? 0 : feeBasisPoints?.sub(rebateBps ?? 0).toNumber()
  }

  let averageDiff = initialDiff.add(nextDiff).div(2)
  if (averageDiff.gt(targetAmount)) {
    averageDiff = targetAmount
  }
  const taxBps = taxBasisPoints?.mul(averageDiff).div(targetAmount)
  return feeBasisPoints?.add(taxBps ?? 0).toNumber()
}
