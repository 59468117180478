import { ButtonDropdownText } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { getTokens, getWhitelistedTokens } from 'constants/tokens'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { LONG, SHORT, SWAP } from '../config'

const FlyoutMenu = styled.div`
  width: 186px;
  max-height: 350px;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 100;
  padding: 12px;
  gap: 0.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

interface ChartProps {
  chainId: number
  swapOption: any
  selectedToken: any
  onSelectToken: any
}

export default function ChartTokenSelector(props: ChartProps) {
  const { chainId, selectedToken, onSelectToken, swapOption } = props
  const [openDropdown, setOpenDropdown] = useState(false)
  const isLong = swapOption === LONG
  const isShort = swapOption === SHORT
  const isSwap = swapOption === SWAP

  let options = getTokens(chainId)
  const whitelistedTokens = getWhitelistedTokens(chainId)
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped)
  const shortableTokens = indexTokens.filter((token) => token.isShortable)

  if (isLong) {
    options = indexTokens
  }
  if (isShort) {
    options = shortableTokens
  }

  const onSelect = async (token: any) => {
    onSelectToken(token)
  }

  const value = selectedToken

  return (
    <AutoColumn style={{ width: isMobile ? '7.5rem' : '10rem' }} onMouseLeave={() => setOpenDropdown(false)}>
      <ButtonDropdownText onClick={() => setOpenDropdown(!openDropdown)} disabled={isSwap}>
        <ThemedText.LargeHeader fontSize={isMobile ? 16 : 22}>{value.symbol} / USD</ThemedText.LargeHeader>
      </ButtonDropdownText>
      <AutoColumn>
        {openDropdown && (
          <FlyoutMenu onMouseLeave={() => setOpenDropdown(false)}>
            {options.map((option, index) => (
              <Row
                key={index}
                onClick={() => {
                  onSelect(option)
                  setOpenDropdown(false)
                }}
              >
                <ThemedText.Body>{option.symbol} / USD</ThemedText.Body>
              </Row>
            ))}
          </FlyoutMenu>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
