/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import ReferralStorageAbi from 'abis/ReferralStorage.json'
import { ButtonPrimary, ButtonTap } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import LocalLoader from 'components/PageLoader'
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from 'components/Referrals/referralsHelper'
import Row, { RowBetween, RowCenter } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getContract } from 'constants/contracts'
import {
  encodeReferralCode,
  useCodeOwner,
  useReferralsData,
  useReferrerTier,
  useUserReferralCode,
} from 'domain/referrals'
import { BigNumber, ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useReferralStorageContract } from 'hooks/useContract'
import { useChainId } from 'lib/chains'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import { isHashZero, PLACEHOLDER_ACCOUNT } from 'lib/legacy'
import { useLocalStorageSerializeKey } from 'lib/localStorage'
import { formatAmount } from 'lib/numbers'
import { useState } from 'react'
import { HelpCircle } from 'react-feather'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { CustomLightSpinner, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { AddAffiliateCode } from './components/Affiliates/AddAffiliateCode'
import { AffiliatesStats } from './components/Affiliates/AffiliatesStats'
import { JoinReferralCode } from './components/Traders/JoinReferralCode'
import { TradersStats } from './components/Traders/TradersStats'

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`

const ResponsiveRowBetween = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 0.5rem;
  `};
`

export function Referrals() {
  const { account: walletAccount, provider, isActive } = useActiveWeb3React()
  const { chainId } = useChainId()
  //@ts-ignore
  const { account: queryAccount } = useParams()
  const [claiming, setClaiming] = useState(false)

  const minClaim = BigNumber.from(5).mul(BigNumber.from(10).pow(18))

  const referralStorageAddress = getContract(chainId, 'ReferralStorage')

  let account: any
  let rewards = BigNumber.from(0)
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount)
  } else {
    account = walletAccount
  }

  const { data: rebates, mutate: mutateRewards }: any = useSWR(
    [
      `ReferralStorage:rebates:${isActive}`,
      chainId,
      referralStorageAddress,
      'rebates',
      walletAccount || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, ReferralStorageAbi),
    }
  )
  const { data: userCanClaim, mutate: mutateCanclaim }: any = useSWR(
    [
      `ReferralStorage:canClaim:${isActive}`,
      chainId,
      referralStorageAddress,
      'canClaim',
      walletAccount || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(provider, ReferralStorageAbi),
    }
  )

  if (rebates) {
    rewards = BigNumber.from(rebates)
  }
  const hasRewards = rewards.gt(0)
  const hasMinClaim = rewards.gte(minClaim)
  const canClaim = Boolean(userCanClaim)

  const [option, setOption] = useState(0)

  const { data: referralsData, loading }: any = useReferralsData(chainId, account)
  //@ts-ignore
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, 'REFERRAL', account], [], {
    deserializer: deserializeSampleStats,
  })
  const { userReferralCode, userReferralCodeString } = useUserReferralCode(provider, chainId, account)
  const { codeOwner, mutateCodeOwner } = useCodeOwner(provider, chainId, account, userReferralCode)
  const { referrerTier: traderTier } = useReferrerTier(provider, chainId, codeOwner)

  const referralStorageContract = useReferralStorageContract()

  async function handleCreateReferralCode(referralCode: any) {
    const referralCodeHex = encodeReferralCode(referralCode)
    try {
      const tx = await referralStorageContract?.registerCode(referralCodeHex)
      helperToast.success(t`✔️ Referral code submitted!`)
      await tx?.wait()
      mutateCodeOwner()
      helperToast.success(t`✔️ Referral code created!`)
    } catch (error) {
      helperToast.error(t`❗ Referral code creation failed`)
    }
  }

  async function handleClaimRewards() {
    setClaiming(true)
    try {
      const tx = await referralStorageContract?.claim()
      helperToast.success(t`✔️ Claim submitted!`)
      await tx?.wait()
      mutateCanclaim()
      mutateRewards()
      helperToast.success(t`✔️ Rewards claimed!`)
      setClaiming(false)
    } catch (error) {
      helperToast.error(t`❗ Claim error`)
      setClaiming(false)
    }
  }

  function renderAffiliates() {
    const isReferralCodeAvailable =
      //@ts-ignore
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0
    if (loading) return <LocalLoader mobile={isMobile} />
    if (isReferralCodeAvailable) {
      return (
        <AffiliatesStats
          referralsData={referralsData}
          handleCreateReferralCode={handleCreateReferralCode}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          recentlyAddedCodes={recentlyAddedCodes}
          chainId={chainId}
        />
      )
    } else {
      return (
        <AddAffiliateCode
          handleCreateReferralCode={handleCreateReferralCode}
          recentlyAddedCodes={recentlyAddedCodes}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
        />
      )
    }
  }

  function renderTraders() {
    if (loading) return <LocalLoader mobile={isMobile} />
    if (isHashZero(userReferralCode) || !account || !userReferralCode) {
      return <JoinReferralCode />
    }
    return (
      <TradersStats
        userReferralCodeString={userReferralCodeString ?? ''}
        chainId={chainId}
        referralsData={referralsData}
        traderTier={traderTier}
      />
    )
  }
  return (
    <PageWrapper>
      <ThemedText.TitlePage textAlign="center">
        <Trans>Referrals</Trans>
      </ThemedText.TitlePage>
      <ThemedText.SubTitle mb="2rem" textAlign="center">
        <Trans>Get fee discounts and earn rebates through the FALCON referral program</Trans>.
      </ThemedText.SubTitle>
      <Container>
        <ButtonTap width="fit-content" onClick={() => setOption(0)} active={option === 0}>
          Traders
        </ButtonTap>
        <ButtonTap width="fit-content" onClick={() => setOption(1)} active={option === 1}>
          <Trans>Affiliates</Trans>
        </ButtonTap>
      </Container>
      {hasRewards && (
        <RowCenter marginY="1rem">
          <DarkCard width={isMobile ? '100%' : '325px'} minHeight={hasMinClaim ? '150px' : 'fit-content'}>
            <AutoColumn justify="center">
              <AutoColumn>
                <ThemedText.Body textAlign="center" fontSize={isMobile ? 14 : 16} color="#fff" ml="4px">
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader>
                        <Trans>You will be able to claim the rewards once they reach the minimum of 5 USD</Trans>
                      </ThemedText.SubHeader>
                    }
                  >
                    <Row>
                      <Trans>Unclaimed Rewards</Trans>

                      <WrapperIcon>
                        <HelpCircle width={16} />
                      </WrapperIcon>
                    </Row>
                  </MouseoverTooltip>
                </ThemedText.Body>
                <ThemedText.LargeHeader textAlign="center">$ {formatAmount(rewards, 18, 2)}</ThemedText.LargeHeader>
              </AutoColumn>
              {hasMinClaim && (
                <ButtonPrimary
                  width="fit-content"
                  size="10px"
                  disabled={claiming || !canClaim}
                  onClick={handleClaimRewards}
                  fontSize={14}
                  mt="1rem"
                >
                  {claiming ? (
                    <>
                      <Trans>Claiming</Trans>
                      <CustomLightSpinner
                        src="/images/blue-loader.svg"
                        alt="loader"
                        size={'16px'}
                        style={{ marginLeft: '0.5rem' }}
                      />
                    </>
                  ) : canClaim ? (
                    <Trans>Claim</Trans>
                  ) : (
                    <Trans>Waiting distribution</Trans>
                  )}
                </ButtonPrimary>
              )}
            </AutoColumn>
          </DarkCard>
        </RowCenter>
      )}

      {option === 0 && renderTraders()}

      {option === 1 && renderAffiliates()}
    </PageWrapper>
  )
}
