import { AutoColumn } from 'components/Column'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

export function TopTraders() {
  return (
    <PageWrapper>
      <ThemedText.LargeHeader>Top Traders</ThemedText.LargeHeader>
    </PageWrapper>
  )
}
