// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { RowBetween } from 'components/Row'
import { getContract } from 'constants/contracts'
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from 'constants/ui'
import { getTokenInfo } from 'domain/tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { useChainId } from 'lib/chains'
import { formatDateTime, getTimeRemaining } from 'lib/dates'
import { helperToast } from 'lib/helperToast'
import {
  calculatePositionDelta,
  DECREASE,
  getDeltaStr,
  getExchangeRate,
  getExchangeRateDisplay,
  getLiquidationPrice,
  getNextToAmount,
  getProfitPrice,
  INCREASE,
  isTriggerRatioInverted,
  MIN_PROFIT_TIME,
  PRECISION,
  SWAP,
  USD_DECIMALS,
} from 'lib/legacy'
import { bigNumberify, formatAmount, formatAmountFree, parseValue } from 'lib/numbers'
import { useMemo, useState } from 'react'
import { ArrowRight, X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

interface EditOrderModalProps {
  account: any
  order: any | null
  infoTokens: any
  getPositionForOrder: any
  positionsMap: any
  totalTokenWeights: any
  usdfSupply: any
  fetchOrdersData: any
  onDismiss: () => void
  isOpen: boolean
}

export function EditOrderModal({
  account,
  order,
  infoTokens,
  getPositionForOrder,
  positionsMap,
  totalTokenWeights,
  usdfSupply,
  fetchOrdersData,
  onDismiss,
  isOpen,
}: EditOrderModalProps) {
  const { chainId } = useChainId()

  const position = order && order.type !== SWAP ? getPositionForOrder(account, order, positionsMap) : null
  const liquidationPrice = order && order.type === DECREASE && position ? getLiquidationPrice(position) : null

  const orderBookContract = useOrderBookContract()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const fromTokenInfo =
    order && order.type === SWAP ? getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress) : null
  const toTokenInfo =
    order && order.type === SWAP
      ? getTokenInfo(infoTokens, order.path[order.path.length - 1], order.shouldUnwrap, nativeTokenAddress)
      : null

  const triggerRatioInverted = useMemo(() => {
    if (!order) {
      return null
    }
    if (order.type !== SWAP) {
      return null
    }

    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo)
  }, [order, fromTokenInfo, toTokenInfo])

  let initialRatio = 0
  if (order && order.triggerRatio) {
    initialRatio = triggerRatioInverted ? PRECISION.mul(PRECISION).div(order.triggerRatio) : order.triggerRatio
  }
  const [triggerRatioValue, setTriggerRatioValue] = useState(
    initialRatio ? formatAmountFree(initialRatio, USD_DECIMALS, 6) : ''
  )

  const [triggerPriceValue, setTriggerPriceValue] = useState(
    order?.triggerPrice ? formatAmountFree(order?.triggerPrice, USD_DECIMALS, 4) : ''
  )
  const triggerPrice = useMemo(() => {
    return triggerPriceValue ? parseValue(triggerPriceValue, USD_DECIMALS) : bigNumberify(0)
  }, [triggerPriceValue])

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0)
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS)
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio ?? 1)
    }
    return ratio
  }, [triggerRatioValue, triggerRatioInverted])

  const indexTokenMarkPrice = useMemo(() => {
    if (order?.type === SWAP) {
      return
    }
    const toTokenInfo = getTokenInfo(infoTokens, order?.indexToken)
    return order?.isLong ? toTokenInfo?.maxPrice : toTokenInfo?.minPrice
  }, [infoTokens, order])

  let toAmount: any
  if (order?.type === SWAP) {
    const { amount } = getNextToAmount(
      chainId,
      order?.amountIn,
      order?.path[0],
      order?.path[order.path.length - 1],
      infoTokens,
      undefined,
      triggerRatio,
      usdfSupply,
      totalTokenWeights
    )
    toAmount = amount
  }

  const onClickPrimary = async () => {
    setIsSubmitting(true)

    if (order.type === SWAP) {
      try {
        const tx = await orderBookContract?.updateSwapOrder(
          order.index,
          toAmount,
          triggerRatio ?? 0,
          order.triggerAboveThreshold
        )
        helperToast.success('✔️ Order update submitted!')
        await tx?.wait()
        helperToast.success('✔️ Order updated!')
        fetchOrdersData()
        onDismiss()
        setIsSubmitting(false)
      } catch (error) {
        helperToast.error(error.message)
        setIsSubmitting(false)
      }
      /* func = updateSwapOrder
      params = [chainId, library, order.index, toAmount, triggerRatio, order.triggerAboveThreshold] */
    } else if (order.type === DECREASE) {
      try {
        const tx = await orderBookContract?.updateDecreaseOrder(
          order.index,
          order.collateralDelta,
          order.sizeDelta,
          triggerPrice ?? 0,
          order.triggerAboveThreshold
        )
        helperToast.success('✔️ Order update submitted!')
        await tx?.wait()
        helperToast.success('✔️ Order updated!')
        fetchOrdersData()
        onDismiss()
        setIsSubmitting(false)
      } catch (error) {
        helperToast.error(error.message)
        setIsSubmitting(false)
      }
      /* func = updateDecreaseOrder
      params = [
        chainId,
        library,
        order.index,
        order.collateralDelta,
        order.sizeDelta,
        triggerPrice,
        order.triggerAboveThreshold,
      ] */
    } else if (order.type === INCREASE) {
      try {
        const tx = await orderBookContract?.updateIncreaseOrder(
          order.index,
          order.sizeDelta,
          triggerPrice ?? 0,
          order.triggerAboveThreshold
        )
        helperToast.success('✔️ Order update submitted!')
        await tx?.wait()
        helperToast.success('✔️ Order updated!')
        fetchOrdersData()
        onDismiss()
        setIsSubmitting(false)
      } catch (error) {
        helperToast.error(error.message)
        setIsSubmitting(false)
      }
      /*  func = updateIncreaseOrder
      params = [chainId, library, order.index, order.sizeDelta, triggerPrice, order.triggerAboveThreshold] */
    }

    /* params.push({
      successMsg: 'Order updated!',
      failMsg: 'Order update failed.',
      sentMsg: 'Order update submitted!',
      pendingTxns,
      setPendingTxns,
    })

    return func(...params)
      .then(() => {
        setEditingOrder(null)
      })
      .finally(() => {
        setIsSubmitting(false)
      }) */
  }

  const onTriggerRatioChange = (value: any) => {
    setTriggerRatioValue(value)
  }

  const onTriggerPriceChange = (value: any) => {
    setTriggerPriceValue(value)
  }

  const getError = () => {
    if (!order) {
      return null
    }
    if ((!triggerRatio || triggerRatio.eq(0)) && (!triggerPrice || triggerPrice.eq(0))) {
      return t`Enter Price`
    }
    if (order.type === SWAP && triggerRatio?.eq(order.triggerRatio)) {
      return t`Enter new Price`
    }
    if (order.type !== SWAP && triggerPrice?.eq(order.triggerPrice)) {
      return t`Enter new Price`
    }
    if (position) {
      if (order.type === DECREASE) {
        if (position.isLong && triggerPrice?.lte(liquidationPrice)) {
          return t`Price below Liq. Price`
        }
        if (!position.isLong && triggerPrice?.gte(liquidationPrice)) {
          return t`Price above Liq. Price`
        }
      }

      const { delta, hasProfit } = calculatePositionDelta(triggerPrice, position)
      if (hasProfit && delta.eq(0)) {
        return t`Invalid price, see warning`
      }
    }

    if (order.type !== SWAP && indexTokenMarkPrice && triggerPrice) {
      if (order.triggerAboveThreshold && indexTokenMarkPrice.gt(triggerPrice)) {
        return t`Price below Mark Price`
      }
      if (!order.triggerAboveThreshold && indexTokenMarkPrice.lt(triggerPrice)) {
        return t`Price above Mark Price`
      }
    }

    if (order.type === SWAP) {
      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo)
      if (currentRate && !currentRate.gte(triggerRatio)) {
        return `${t`Price is`} ${triggerRatioInverted ? t`below` : t`above`} ${t`Mark Price`}`
      }
    }
    return null
  }

  const renderMinProfitWarning = () => {
    if (MIN_PROFIT_TIME === 0 || order?.type === SWAP || !position || !triggerPrice || triggerPrice.eq(0)) {
      return null
    }

    const { delta, pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(triggerPrice, position)
    if (hasProfit && delta.eq(0)) {
      const { deltaStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      })
      const profitPrice = getProfitPrice(triggerPrice, position)
      const minProfitExpiration = parseInt(position.lastIncreasedTime) + MIN_PROFIT_TIME
      return (
        <ThemedText.Yellow>
          <Trans>This order will forfeit a profit of</Trans> {deltaStr}. <br />
          <Trans>Profit price</Trans>: {position.isLong ? '>' : '<'} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}
          . <Trans>This rule only applies for the next</Trans> {getTimeRemaining(minProfitExpiration)},{' '}
          <Trans>until</Trans> {formatDateTime(minProfitExpiration)}.
        </ThemedText.Yellow>
      )
    }
    return null
  }

  const isPrimaryEnabled = () => {
    if (isSubmitting) {
      return false
    }
    const error = getError()
    if (error) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    const error = getError()
    if (error) {
      return error
    }

    if (isSubmitting) {
      return t`Updating Order...`
    }
    return t`Update Order`
  }

  if (order?.type !== SWAP) {
    const triggerPricePrefix = order?.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss}>
        <ModalWrapper>
          <RowBetween>
            <ThemedText.MediumHeader>
              <Trans>Edit Order</Trans>
            </ThemedText.MediumHeader>
            <StyledClosed>
              <X size={24} onClick={onDismiss} />
            </StyledClosed>
          </RowBetween>
          <Divider />
          <LightCard $borderRadius="8px" mt="1rem" mb="1rem">
            {renderMinProfitWarning()}
            <RowBetween mt="0.5rem">
              <ThemedText.Body>
                <Trans>Price</Trans>
              </ThemedText.Body>
              <ThemedText.Body
                onClick={() => {
                  if (indexTokenMarkPrice) {
                    setTriggerPriceValue(formatAmountFree(indexTokenMarkPrice, USD_DECIMALS, 2))
                  }
                }}
              >
                <Trans>Mark</Trans>: {formatAmount(indexTokenMarkPrice, USD_DECIMALS, 2)}
              </ThemedText.Body>
            </RowBetween>
            <RowBetween>
              <StyledInput
                className="amount"
                type="number"
                step={0.0}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                prefix=" USD"
                value={triggerPriceValue}
                onUserInput={(val) => {
                  onTriggerPriceChange(val)
                }}
              />
            </RowBetween>
          </LightCard>
          <RowBetween mt="0.5rem">
            <ThemedText.SubHeader color="text2">
              <Trans>Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              <Row>
                {order && triggerPrice && !triggerPrice.eq(order.triggerPrice) ? (
                  <>
                    <span className="muted">
                      {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
                    </span>
                    &nbsp;
                    {triggerPrice.gt(0) && (
                      <>
                        <ArrowRight width={16} color="grey" />
                        &nbsp;
                        {triggerPricePrefix} {formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                      </>
                    )}
                  </>
                ) : (
                  <span>
                    {triggerPricePrefix} {formatAmount(order?.triggerPrice, USD_DECIMALS, 2, true)}
                  </span>
                )}
              </Row>
            </ThemedText.SubHeader>
          </RowBetween>
          <ButtonPrimary mt="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </ButtonPrimary>
        </ModalWrapper>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween mb="1rem">
          <ThemedText.MediumHeader>
            <Trans>Edit Order</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <LightCard $borderRadius="8px" mt="1rem" mb="1rem">
          <RowBetween mt="0.5rem">
            <ThemedText.Body>
              <Trans>Price</Trans>
            </ThemedText.Body>
            {fromTokenInfo && toTokenInfo && (
              <ThemedText.Body
                onClick={() => {
                  setTriggerRatioValue(
                    formatAmountFree(
                      getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted),
                      USD_DECIMALS,
                      10
                    )
                  )
                }}
              >
                <Trans>Mark Price</Trans>:{' '}
                {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 2)}
              </ThemedText.Body>
            )}
          </RowBetween>
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              prefix=" USD"
              value={triggerRatioValue}
              onUserInput={(val) => {
                onTriggerRatioChange(val)
              }}
            />
            {(() => {
              if (!toTokenInfo) return
              if (!fromTokenInfo) return
              const [tokenA, tokenB] = triggerRatioInverted
                ? [toTokenInfo, fromTokenInfo]
                : [fromTokenInfo, toTokenInfo]
              return (
                <ThemedText.MediumHeader mr="2rem">
                  {tokenA.symbol}&nbsp;/&nbsp;{tokenB.symbol}
                </ThemedText.MediumHeader>
              )
            })()}
          </RowBetween>
        </LightCard>
        <AutoColumn gap="0.2rem">
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Min received</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              <Row>
                {triggerRatio && !triggerRatio.eq(order.triggerRatio) ? (
                  <>
                    {formatAmount(order.minOut, toTokenInfo?.decimals ?? 18, 4, true)}
                    <ArrowRight width={16} />
                    {formatAmount(toAmount, toTokenInfo?.decimals ?? 18, 4, true)}
                  </>
                ) : (
                  formatAmount(order.minOut, toTokenInfo?.decimals ?? 18, 4, true)
                )}
                &nbsp;{toTokenInfo?.symbol}
              </Row>
            </ThemedText.SubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.SubHeader color="text2">
              <Trans>Price</Trans>
            </ThemedText.SubHeader>
            <ThemedText.SubHeader>
              {triggerRatio && !triggerRatio.eq(0) && !triggerRatio.eq(order.triggerRatio) ? (
                <Row>
                  <span className="muted">
                    {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, {
                      omitSymbols: !triggerRatio || !triggerRatio.eq(order.triggerRatio),
                    })}
                  </span>
                  &nbsp;
                  <ArrowRight width={16} />
                  &nbsp;
                  {getExchangeRateDisplay(triggerRatio, fromTokenInfo, toTokenInfo)}
                </Row>
              ) : (
                getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, {
                  omitSymbols: !triggerRatio || !triggerRatio.eq(order.triggerRatio),
                })
              )}
            </ThemedText.SubHeader>
          </RowBetween>
          {fromTokenInfo && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                {fromTokenInfo.symbol} <Trans>price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)} USD
              </ThemedText.SubHeader>
            </RowBetween>
          )}
          {toTokenInfo && (
            <RowBetween>
              <ThemedText.SubHeader color="text2">
                {toTokenInfo.symbol} <Trans>price</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)} USD
              </ThemedText.SubHeader>
            </RowBetween>
          )}
        </AutoColumn>
        <ButtonPrimary mt="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  )
}
