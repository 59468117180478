/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { RowCenter } from 'components/Row'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { COLORS, tooltipFormatterNumber, tooltipLabelFormatter, yaxisFormatterNumber } from '../helpers'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function FlpPriceChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }
  const csvFields = [
    { key: 'syntheticPrice' },
    { key: 'flpPrice' },
    { key: 'flpPlusFees' },
    { key: 'lpBtcPrice' },
    { key: 'lpEthPrice' },
    { key: 'lpBnbPrice' },
  ]
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>FLP Price Comparison</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink fields={csvFields} name="FLP Price Comparison" data={data} />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          {' '}
          <ResponsiveContainer width="100%" height={chartHeight}>
            <LineChart data={data} syncId="syncFlp">
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
              <YAxis dataKey="flpPrice" domain={[0.3, 1.4]} tickFormatter={yaxisFormatterNumber} width={yaxisWidth} />
              <Tooltip
                formatter={tooltipFormatterNumber}
                labelFormatter={tooltipLabelFormatter}
                contentStyle={contentStyle}
              />
              <Legend />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={1}
                dot={false}
                dataKey="syntheticPrice"
                name="Index Price"
                stroke={COLORS[2]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={1}
                dot={false}
                dataKey="flpPrice"
                name="Flp Price"
                stroke={COLORS[1]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={2}
                dot={false}
                dataKey="flpPlusFees"
                name="Flp w/ fees"
                stroke={COLORS[3]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={1}
                dot={false}
                dataKey="lpBtcPrice"
                name="LP BTC-USD"
                stroke={COLORS[2]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={1}
                dot={false}
                dataKey="lpEthPrice"
                name="LP ETH-USD"
                stroke={COLORS[4]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                unit="$"
                strokeWidth={1}
                dot={false}
                dataKey="lpBnbPrice"
                name="LP BNB-USD"
                stroke={COLORS[5]}
              />
            </LineChart>
          </ResponsiveContainer>
          <RowCenter mt="1rem">
            <ThemedText.SubHeader>
              <span style={{ color: COLORS[3] }}>
                <Trans>Flp with fees</Trans>
              </span>{' '}
              <Trans>is based on FLP share of fees</Trans>
              <br />
              <span style={{ color: COLORS[2] }}>
                <Trans>Index Price</Trans>
              </span>{' '}
              <Trans>is a basket 20% BNB, 20% BTC, 20% ETH and 50% USD rebalanced once&nbsp;a&nbsp;day</Trans>
            </ThemedText.SubHeader>
          </RowCenter>
        </>
      )}
    </DarkCard>
  )
}
