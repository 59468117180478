/* eslint-disable simple-import-sort/imports */
/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { COLORS, tooltipFormatterNumber, tooltipLabelFormatter, yaxisFormatter } from '../helpers'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function FlpSupplyChart(props: Props) {
  const { data, loading, chartHeight, yaxisWidth } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>AUM & Flp Supply</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink fields={[{ key: 'aum' }, { key: 'flpSupply' }]} name="AUM & Flp Supply" data={data} />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <LineChart data={data} syncId="syncFlp">
            <CartesianGrid strokeDasharray="10 10" />
            <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
            <YAxis dataKey="flpSupply" tickFormatter={yaxisFormatter} width={yaxisWidth} />
            <Tooltip
              formatter={tooltipFormatterNumber}
              labelFormatter={tooltipLabelFormatter}
              contentStyle={contentStyle}
            />
            <Legend />
            <Line
              isAnimationActive={false}
              type="monotone"
              strokeWidth={2}
              unit="$"
              dot={false}
              dataKey="aum"
              name="AUM"
              stroke={COLORS[0]}
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              strokeWidth={2}
              dot={false}
              dataKey="flpSupply"
              name="Flp Supply"
              stroke={COLORS[1]}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </DarkCard>
  )
}
