import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x6A8CD69eb658Bfe55340588dDa298F7ec4299985',
  [SupportedChainId.BSC_TESTNET]: '0x6A8CD69eb658Bfe55340588dDa298F7ec4299985',
}

export const MULTICALLV2_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  [SupportedChainId.BSC_TESTNET]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
}

export const VAULT_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x9463Cf31F3869c0a040e06447Ca7fdE2abED3a13',
  [SupportedChainId.BSC_TESTNET]: '0xb3D02d1599678925D94514e163893196F417C2d9',
}

export const ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x86E5EDb8D2dF1d5c101a6cd8d3256e659EBC2ccC',
  [SupportedChainId.BSC_TESTNET]: '0x48715E2e7511657Da3F02a39064ff676af148914',
}

export const VAULT_READER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xB015d8cDB8dD58279Dc0ADdEDFDfd32AA02B789b',
  [SupportedChainId.BSC_TESTNET]: '0x9Ee0F5BA0CabB344B6581A66880103E7bD158412',
}

export const READER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xB805540d2AdE4A39A39b55f5e8351e76d5254019',
  [SupportedChainId.BSC_TESTNET]: '0xa4Fa8A8089C1CDd79F0B53190965DA61a45ACADd',
}

export const FLP_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x17C1245688f04380EA753125C970761dE1aC49cb',
  [SupportedChainId.BSC_TESTNET]: '0xE5B2d2EaC43e905c8ca2Cd499aacF0AA71993699',
}

export const FLP_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xe9E603d037AF43293DD7df55DA3D6022c05f0Df4',
  [SupportedChainId.BSC_TESTNET]: '0x6cAa7dF53504d2860DdB5DD3F17C626ecd513247',
}

export const USDF_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xD842A5FBE51Cb3e79367f0208c57eDA4D26F7B9c',
  [SupportedChainId.BSC_TESTNET]: '0x2eA9BCbb1337fAD763C71BCe60C4D20f3F1c3453',
}

export const ORDER_BOOK_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xa5d1C6176BeAff4Cc8d2F68f69221813B4c705e8',
  [SupportedChainId.BSC_TESTNET]: '0xD8f4e6F5D026391A0239F76bD4A1ac14bF3B7B0A',
}

export const ORDER_BOOK_READER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x6BbE2782d7334205320C6F4E1811E948BE1328F4',
  [SupportedChainId.BSC_TESTNET]: '0x87d8059c6fFD7B68e6239Bd3eE8AA944AeF10FDa',
}

export const POSITION_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x0966C6f41605bb5D663E7401eF94944797dA398b',
  [SupportedChainId.BSC_TESTNET]: '0x0B28f2E367C867a4E5780BC48DAf5dF44342A2E3',
}

export const POSITION_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xD72fc4358a3683e8dc5588db56Cf2755E0a16aEd',
  [SupportedChainId.BSC_TESTNET]: '0x118835c7A29aBe10f73Bcc005c3D06a4Ff848D64',
}

export const REFERRAL_STORAGE_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xB9336d33e3eA0E5C028bf8f01bB89B964E97B834',
  [SupportedChainId.BSC_TESTNET]: '0xe29F5Afe9ABA68DeCecb247ed90C0c7B47670FD0',
}

export const REFERRAL_READER_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xf5D5ee2deb8a67647A3cE62e4d8d7e5B6f2bdA88',
  [SupportedChainId.BSC_TESTNET]: '0x3A919DdcC55FC2Ccd4d81A2D0B96080CF3026024',
}

export const FLN_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0x582d107CEf18DA60A607cA4b72B9570E11332dB8',
  [SupportedChainId.BSC_TESTNET]: '0x2635B6A5Df785c1048e41Db253AdF9095C4eb159',
}

export const FLP_POOL_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xf5D5ee2deb8a67647A3cE62e4d8d7e5B6f2bdA88',
  [SupportedChainId.BSC_TESTNET]: '0x9B4e40C8D8bd5291732157470458ae90Bc29460e',
}

export const DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.BSC]: '0xf5D5ee2deb8a67647A3cE62e4d8d7e5B6f2bdA88',
  [SupportedChainId.BSC_TESTNET]: '0x7DfF3078a5775f3A923C66269A171E9EAeFD5ABd',
}
