// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { AutoRow, RowBetween } from 'components/Row'
import { getToken } from 'constants/tokens'
import { Token } from 'domain/tokens'
import { bigNumberify, expandDecimals, formatAmount } from 'lib/numbers'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.primary5};
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

interface TokenModalProps {
  label: string
  chainId: number
  tokenAddress: string
  onSelectToken: (token: any) => void
  tokens: Token[]
  infoTokens?: any
  onDismiss: () => void
  isOpen: boolean
  getTokenState?: () => { disabled: boolean; message: any }
  showMintingCap?: boolean
  mintingCap?: any
  showBalances?: boolean
}

export function TokenModal({
  label,
  chainId,
  tokenAddress,
  onSelectToken,
  tokens,
  infoTokens,
  onDismiss,
  isOpen,
  getTokenState = () => ({ disabled: false, message: null }),
  showMintingCap,
  mintingCap,
  showBalances = true,
}: TokenModalProps) {
  const visibleTokens = tokens.filter((t) => !t.isTempHidden)
  const tokenInfo = getToken(chainId, tokenAddress)

  const onTokenSelect = (token: any) => {
    onDismiss()
    onSelectToken(token)
  }

  if (!tokenInfo) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>{label}</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        {visibleTokens.map((token, index) => {
          const info = infoTokens ? infoTokens[token.address] : {}
          let mintAmount
          const balance = info.balance
          if (showMintingCap && mintingCap && info.usdgAmount) {
            mintAmount = mintingCap.sub(info.usdgAmount)
          }
          if (mintAmount && mintAmount.lt(0)) {
            mintAmount = bigNumberify(0)
          }
          let balanceUsd
          if (balance && info.maxPrice) {
            balanceUsd = balance.mul(info.maxPrice).div(expandDecimals(1, token.decimals))
          }
          /* const tokenState = getTokenState(info) || {} */
          return (
            <Box key={index} onClick={() => /* !tokenState.disabled && */ onTokenSelect(token)}>
              <AutoRow gap="0.2rem">
                <img
                  src={`${process.env.PUBLIC_URL}/images/tokens/${token.symbol}.png`}
                  alt={`${token.symbol}-logo`}
                  width={48}
                  height={48}
                />
                <AutoColumn>
                  <ThemedText.Body>{token.symbol}</ThemedText.Body>
                  <ThemedText.Small>{token.name}</ThemedText.Small>
                </AutoColumn>
              </AutoRow>
              <Column>
                {showBalances && balance && (
                  <ThemedText.Body textAlign="right">
                    {balance.gt(0) && formatAmount(balance, token.decimals, 4, true)}
                    {balance.eq(0) && '-'}
                  </ThemedText.Body>
                )}

                <ThemedText.Small textAlign="right">
                  {!showMintingCap && showBalances && balanceUsd && balanceUsd.gt(0) && (
                    <div>${formatAmount(balanceUsd, 30, 2, true)}</div>
                  )}
                </ThemedText.Small>
              </Column>
            </Box>
          )
        })}
      </ModalWrapper>
    </Modal>
  )
}
