import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'

export function useLocalStorageByChainId<T>(chainId: number, key: string, defaultValue: T): [T, (value: T) => void] {
  const [internalValue, setInternalValue] = useLocalStorage<Record<keyof any, T>>(key, {})

  const setValue = useCallback(
    (value) => {
      setInternalValue((internalValue: any) => {
        if (typeof value === 'function') {
          value = value(internalValue?.[chainId] || defaultValue)
        }

        const newInternalValue = {
          ...internalValue,
          [chainId]: value,
        }
        return newInternalValue
      })
    },
    [chainId, setInternalValue, defaultValue]
  )

  let value

  if (internalValue && chainId in internalValue) {
    value = internalValue[chainId]
  } else {
    value = defaultValue
  }

  return [value, setValue]
}

export function useLocalStorageSerializeKey<T>(
  key: string,
  value: T,
  opts?: {
    raw: boolean
    serializer: (val: T) => string
    deserializer: (value: string) => T
  }
) {
  key = JSON.stringify(key)

  return useLocalStorage<T>(key, value, opts)
}
