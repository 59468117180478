import { faArrowRightArrowLeft, faArrowTrendDown, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { ButtonTap } from 'components/Button'
import { AutoRow } from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

interface TapProps {
  options: any
  option: any
  onChange: any
}

export function TapSwap(props: TapProps) {
  const { options, option, onChange } = props
  return (
    <Container>
      <ButtonTap onClick={() => onChange('Long')} active={option === 'Long'}>
        <AutoRow gap="0.25rem" justify="center">
          <FontAwesomeIcon icon={faArrowTrendUp} width={isMobile ? 12 : 14} />
          <ThemedText.SubHeader fontSize={isMobile ? 12 : 16}>
            <Trans>Long</Trans>
          </ThemedText.SubHeader>
        </AutoRow>
      </ButtonTap>
      <ButtonTap onClick={() => onChange('Short')} active={option === 'Short'}>
        <AutoRow gap="0.25rem" justify="center">
          <FontAwesomeIcon icon={faArrowTrendDown} width={isMobile ? 12 : 14} />
          <ThemedText.SubHeader fontSize={isMobile ? 12 : 16}>
            <Trans>Short</Trans>
          </ThemedText.SubHeader>
        </AutoRow>
      </ButtonTap>
      <ButtonTap onClick={() => onChange('Swap')} active={option === 'Swap'}>
        <AutoRow gap="0.25rem" justify="center">
          <FontAwesomeIcon icon={faArrowRightArrowLeft} width={isMobile ? 12 : 14} />
          <ThemedText.SubHeader fontSize={isMobile ? 12 : 16}>Swap</ThemedText.SubHeader>
        </AutoRow>
      </ButtonTap>
    </Container>
  )
}
