import { SupportedChainId } from 'constants/chains'
import { SUBGRAPH_URLS } from 'constants/endPoints'

import { createClient } from './utils'

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink)

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[SupportedChainId.BSC].stats)
export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[SupportedChainId.BSC].referrals)
export const nissohGraphClient = createClient(SUBGRAPH_URLS[SupportedChainId.BSC].nissohVault)

export const bsctestnetGraphClient = createClient(SUBGRAPH_URLS[SupportedChainId.BSC_TESTNET].stats)
export const bsctestnetReferralsGraphClient = createClient(SUBGRAPH_URLS[SupportedChainId.BSC_TESTNET].referrals)

export function getStatsGraphClient(chainId: number) {
  if (chainId === SupportedChainId.BSC) {
    return arbitrumGraphClient
  } else if (chainId === SupportedChainId.BSC_TESTNET) {
    return bsctestnetGraphClient
  }

  throw new Error(`Unsupported chain ${chainId}`)
}
