/* eslint-disable no-restricted-imports */
import MULTICALLV2_ABI from 'abis/multicall.json'
import { MULTICALLV2_ADDRESS } from 'constants/addresses'
import { ethers } from 'ethers'

import { getRpcUrls } from './switchChain'

export type MultiCallResponse<T> = T | null

const getContract = (
  chainId: number,
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const rpcUrl = getRpcUrls(chainId)
  const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(rpcUrl[0])

  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

const getMulticallContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainId, MULTICALLV2_ABI, MULTICALLV2_ADDRESS[chainId], signer)
}

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  requireSuccess?: boolean
}

const Multicall = async <T = any>(chainId: number, abi: any[], calls: Call[]): Promise<T> => {
  try {
    const multi = getMulticallContract(chainId)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi?.aggregate(calldata)

    const res = returnData.map((call: ethers.utils.BytesLike, i: number) =>
      itf.decodeFunctionResult(calls[i].name, call)
    )

    return res
  } catch (error: any) {
    throw new Error(error)
  }
}

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
export const Multicallv2 = async <T = any>(
  chainId: number,
  abi: any[],
  calls: Call[],
  options: MulticallOptions = { requireSuccess: true }
): Promise<MultiCallResponse<T>> => {
  const { requireSuccess } = options
  const multi = getMulticallContract(chainId)
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const returnData = await multi?.tryAggregate(requireSuccess, calldata)
  const res = returnData.map((call: [any, any], i: number) => {
    const [result, data] = call
    return result ? itf.decodeFunctionResult(calls[i].name, data) : null
  })

  return res
}

export default Multicall
