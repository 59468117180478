/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import Erc20Abi from 'abis/erc20.json'
import { ButtonLight, ButtonPrimary, ButtonTap } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { AutoRow, RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getChainName, getConstant, IS_NETWORK_DISABLED, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { getTokenInfo, shouldRaiseGasError } from 'domain/tokens'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { usePositionManagerContract, useTokenContract } from 'hooks/useContract'
import { contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import { BASIS_POINTS_DIVISOR, DEPOSIT_FEE, DUST_BNB, getLiquidationPrice, USD_DECIMALS } from 'lib/legacy'
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from 'lib/numbers'
import { usePrevious } from 'lib/usePrevious'
import { useEffect, useState } from 'react'
import { ArrowRight, Eye, X } from 'react-feather'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  background: ${({ theme }) => theme.bg3};
  border-radius: 20px;
  margin-bottom: 1rem;
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const DEPOSIT = t`Deposit`
const WITHDRAW = t`Withdraw`

interface EditPositionModalProps {
  pendingPositions: any
  setPendingPositions: any
  positionsMap: any
  positionKey: any
  infoTokens: any
  account: any
  collateralTokenAddress: any
  getUsd: any
  getLeverage: any
  savedIsPnlInLeverage: any
  positionRouterApproved: any
  isWaitingForPositionRouterApproval: any
  isPositionRouterApproving: any
  approvePositionRouter: any
  chainId: any
  minExecutionFee: any
  minExecutionFeeUSD: any
  minExecutionFeeErrorMessage: any
  onDismiss: () => void
  isOpen: boolean
}

export function EditPositionModal(props: EditPositionModalProps) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    infoTokens,
    account,
    collateralTokenAddress,
    getUsd,
    getLeverage,
    savedIsPnlInLeverage,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    chainId,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    onDismiss,
    isOpen,
  } = props

  const { isActive, provider } = useActiveWeb3React()
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined
  const [option, setOption] = useState(DEPOSIT)
  const [fromValue, setFromValue] = useState('')
  const [isApproving, setIsApproving] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const prevIsVisible = usePrevious(isOpen)

  const routerAddress = getContract(chainId, 'Router')

  /* const positionRouterContract = usePositionRouterContract() */
  const positionRouterContract = usePositionManagerContract()

  const { data: tokenAllowance, mutate: updateAllowance } = useSWR(
    [isActive, chainId, collateralTokenAddress, 'allowance', account, routerAddress],
    {
      fetcher: contractFetcher(provider, Erc20Abi),
    }
  )

  const tokenContract = useTokenContract(collateralTokenAddress)

  const isDeposit = option === DEPOSIT
  const isWithdrawal = option === WITHDRAW

  const needPositionRouterApproval = !positionRouterApproved

  let collateralToken: any
  let maxAmount: any
  let maxAmountFormatted: any
  let maxAmountFormattedFree: any
  let fromAmount: any
  let needApproval: any

  let convertedAmount: any
  let convertedAmountFormatted: any

  let nextLeverage: any
  let nextLeverageExcludingPnl: any
  let liquidationPrice: any
  let nextLiquidationPrice: any
  let nextCollateral: any

  let title: any
  let collateralDelta: any
  if (position) {
    title = `${t`Edit`} ${position.isLong ? t`Long` : t`Short`} ${position.indexToken.symbol}`
    collateralToken = position.collateralToken
    liquidationPrice = getLiquidationPrice(position)

    if (isDeposit) {
      fromAmount = parseValue(fromValue, collateralToken.decimals)
      maxAmount = collateralToken ? collateralToken.balance : bigNumberify(0)
      maxAmountFormatted = formatAmount(maxAmount, collateralToken.decimals, 4, true)
      maxAmountFormattedFree = formatAmountFree(maxAmount, collateralToken.decimals, 8)
      if (fromAmount) {
        convertedAmount = getUsd(fromAmount, position.collateralToken.address, false, infoTokens)
        convertedAmountFormatted = formatAmount(convertedAmount, USD_DECIMALS, 2)
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS)
      maxAmount = position.collateral
      maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true)
      maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2)
      if (fromAmount) {
        convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice)
        convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true)
      }
    }
    needApproval = isDeposit && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance)

    if (fromAmount) {
      collateralDelta = isDeposit ? convertedAmount : fromAmount
      if (position.isLong) {
        collateralDelta = collateralDelta.mul(BASIS_POINTS_DIVISOR - DEPOSIT_FEE).div(BASIS_POINTS_DIVISOR)
      }
      nextLeverage = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: savedIsPnlInLeverage,
      })
      nextLeverageExcludingPnl = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: false,
      })

      nextLiquidationPrice = getLiquidationPrice({
        isLong: position.isLong,
        size: position.size,
        collateral: position.collateral,
        averagePrice: position.averagePrice,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        collateralDelta,
        increaseCollateral: isDeposit,
      })

      nextCollateral = isDeposit ? position.collateral.add(collateralDelta) : position.collateral.sub(collateralDelta)
    }
  }

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      if (isDeposit) return [t`Deposit disabled, pending ${getChainName(chainId)} upgrade`]
      return [t`Withdraw disabled, pending ${getChainName(chainId)} upgrade`]
    }
    if (!fromAmount) {
      return t`Enter an amount`
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return t`Enter an amount`
    }

    if (!isDeposit && fromAmount) {
      if (fromAmount.gte(position.collateral)) {
        return t`Min order: 10 USD`
      }
      if (position.collateral.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return t`Min order: 10 USD`
      }
    }

    if (!isDeposit && fromAmount && nextLiquidationPrice) {
      if (position.isLong && position.markPrice.lt(nextLiquidationPrice)) {
        return t`Invalid liq. price`
      }
      if (!position.isLong && position.markPrice.gt(nextLiquidationPrice)) {
        return t`Invalid liq. price`
      }
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return t`Min leverage: 1.1x`
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.gt(30.5 * BASIS_POINTS_DIVISOR)) {
      return t`Max leverage: 30x`
    }

    return null
  }

  const isPrimaryEnabled = () => {
    const error = getError()
    if (error) {
      return false
    }
    if (isSwapping) {
      return false
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false
    }
    if (isPositionRouterApproving) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    const error = getError()
    if (error) {
      return error
    }
    if (isSwapping) {
      if (isDeposit) {
        return t`Depositing...`
      }
      return t`Withdrawing...`
    }

    if (isApproving) {
      return t`Approving ${position.collateralToken.symbol}...`
    }
    if (needApproval) {
      return t`Approve ${position.collateralToken.symbol}`
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage`
    }

    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`
    }

    if (needPositionRouterApproval) {
      return t`Enable Leverage`
    }

    if (isDeposit) {
      return t`Deposit`
    }

    return t`Withdraw`
  }

  const resetForm = () => {
    setFromValue('')
  }

  useEffect(() => {
    if (prevIsVisible !== isOpen) {
      resetForm()
    }
  }, [prevIsVisible, isOpen])

  const depositCollateral = async () => {
    setIsSwapping(true)
    const tokenAddress0 = collateralTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : collateralTokenAddress
    const path = [tokenAddress0]
    const indexTokenAddress =
      position.indexToken.address === ZERO_ADDRESS ? nativeTokenAddress : position.indexToken.address

    const priceBasisPoints = position.isLong ? 11000 : 9000
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000)

    const referralCode = ethers.constants.HashZero

    if (shouldRaiseGasError(getTokenInfo(infoTokens, collateralTokenAddress), fromAmount)) {
      setIsSwapping(false)
      helperToast.error(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} BNB for gas`)
      return
    }

    if (collateralTokenAddress === ZERO_ADDRESS) {
      const value = fromAmount /* .add(minExecutionFee) */
      try {
        const tx = await positionRouterContract?.increasePositionETH(
          path, // _path
          indexTokenAddress, // _indexToken
          0, // _minOut
          0, // _sizeDelta
          position.isLong, // _isLong
          priceLimit, // _acceptablePrice
          /* minExecutionFee, // _executionFee */
          referralCode, // _referralCode
          /* ZERO_ADDRESS, // _callbackTarget */
          { value }
        )
        helperToast.success(t`✔️ Deposit submitted`)
        await tx?.wait()
        helperToast.success(
          `${t`✔️ Requested deposit of`} ${formatAmount(fromAmount, position.collateralToken.decimals, 4)} ${
            position.collateralToken.symbol
          } ${t`into`} ${position.indexToken.symbol} ${position.isLong ? t`Long` : t`Short`}.`
        )
        setFromValue('')
        setIsSwapping(false)
        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        }

        setPendingPositions({ ...pendingPositions })
        onDismiss()
      } catch (e) {
        console.error(e)
        let failMsg
        if (
          [t`❗ not enough funds for gas`, t`❗ failed to execute call with revert code InsufficientGasFunds`].includes(
            e.data?.message
          )
        ) {
          failMsg = t`There is not enough BNB in your account to send this transaction`
        } else if (e.message?.includes(t`User denied transaction signature`)) {
          failMsg = t`❗ Deposit was cancelled`
        } else {
          failMsg = t`❗ Deposit failed`
        }
        helperToast.error(failMsg)
        setIsSwapping(false)
      }
    } else {
      try {
        const tx = await positionRouterContract?.increasePosition(
          path, // _path
          indexTokenAddress, // _indexToken
          fromAmount, // _amountIn
          0, // _minOut
          0, // _sizeDelta
          position.isLong, // _isLong
          priceLimit, // _acceptablePrice
          /* minExecutionFee, // _executionFee */
          referralCode // _referralCode
          /* ZERO_ADDRESS, // _callbackTarget
          { value: minExecutionFee } */
        )
        helperToast.success(t`✔️ Deposit submitted`)
        await tx?.wait()
        helperToast.success(
          `${t`✔️ Requested deposit of`} ${formatAmount(fromAmount, position.collateralToken.decimals, 4)} ${
            position.collateralToken.symbol
          } ${t`into`} ${position.indexToken.symbol} ${position.isLong ? t`Long` : t`Short`}.`
        )
        setFromValue('')
        setIsSwapping(false)
        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        }

        setPendingPositions({ ...pendingPositions })
        onDismiss()
      } catch (e) {
        console.error(e)
        let failMsg
        if (
          [t`❗ not enough funds for gas`, t`❗ failed to execute call with revert code InsufficientGasFunds`].includes(
            e.data?.message
          )
        ) {
          failMsg = t`There is not enough BNB in your account to send this transaction`
        } else if (e.message?.includes(t`User denied transaction signature`)) {
          failMsg = t`❗ Deposit was cancelled`
        } else {
          failMsg = t`❗ Deposit failed`
        }
        helperToast.error(failMsg)
        setIsSwapping(false)
      }
    }
  }

  const withdrawCollateral = async () => {
    setIsSwapping(true)
    const tokenAddress0 = collateralTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : collateralTokenAddress
    const indexTokenAddress =
      position.indexToken.address === ZERO_ADDRESS ? nativeTokenAddress : position.indexToken.address
    const priceBasisPoints = position.isLong ? 9000 : 11000
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000)

    const withdrawETH = collateralTokenAddress === ZERO_ADDRESS || collateralTokenAddress === nativeTokenAddress

    try {
      if (collateralTokenAddress === ZERO_ADDRESS) {
        const tx = await positionRouterContract?.decreasePositionETH(
          /* [tokenAddress0] */ tokenAddress0, // _path
          indexTokenAddress, // _indexToken
          fromAmount, // _collateralDelta
          0, // _sizeDelta
          position.isLong, // _isLong
          account, // _receiver
          priceLimit // _acceptablePrice
          /*   0, // _minOut
          minExecutionFee, // _executionFee
          withdrawETH, // _withdrawETH
          ZERO_ADDRESS, // _callbackTarget
          { value: minExecutionFee } */
        )
        helperToast.success(t`✔️ Withdrawal submitted`)
        await tx?.wait()
      } else {
        const tx = await positionRouterContract?.decreasePosition(
          /* [tokenAddress0] */ tokenAddress0, // _path
          indexTokenAddress, // _indexToken
          fromAmount, // _collateralDelta
          0, // _sizeDelta
          position.isLong, // _isLong
          account, // _receiver
          priceLimit // _acceptablePrice
          /*   0, // _minOut
          minExecutionFee, // _executionFee
          withdrawETH, // _withdrawETH
          ZERO_ADDRESS, // _callbackTarget
          { value: minExecutionFee } */
        )
        helperToast.success(t`✔️ Withdrawal submitted`)
        await tx?.wait()
      }

      helperToast.success(
        `${t`✔️ Requested withdrawal of`} ${formatAmount(fromAmount, USD_DECIMALS, 2)} USD ${t`from`} ${
          position.indexToken.symbol
        } ${position.isLong ? t`Long` : t`Short`}.`
      )
      setFromValue('')
      setIsSwapping(false)
      pendingPositions[position.key] = {
        updatedAt: Date.now(),
        pendingChanges: {
          collateralSnapshot: position.collateral,
          expectingCollateralChange: true,
        },
      }
      onDismiss()
    } catch (e) {
      console.error(e)
      let failMsg
      if (
        [t`❗ not enough funds for gas`, t`❗ failed to execute call with revert code InsufficientGasFunds`].includes(
          e.data?.message
        )
      ) {
        failMsg = t`There is not enough BNB in your account to send this transaction`
      } else if (e.message?.includes(t`User denied transaction signature`)) {
        failMsg = t`❗ Withdrawal was cancelled`
      } else {
        failMsg = t`❗ Withdrawal failed`
      }
      helperToast.error(failMsg)
      setIsSwapping(false)
    }
  }

  const approveCollateralToken = async () => {
    setIsApproving(true)
    const token = getTokenInfo(infoTokens, collateralTokenAddress)
    try {
      const tx = await tokenContract?.approve(routerAddress, ethers.constants.MaxUint256)
      helperToast.success(t`✔️ Approval submitted`)
      await tx?.wait()
      helperToast.success(t`✔️ Approved ${token.symbol}`)
      updateAllowance()
      setIsApproving(false)
    } catch (e) {
      console.error(e)
      let failMsg
      if (
        [t`❗ not enough funds for gas`, t`❗ failed to execute call with revert code InsufficientGasFunds`].includes(
          e.data?.message
        )
      ) {
        failMsg = t`There is not enough BNB in your account to send this transaction`
      } else if (e.message?.includes(t`User denied transaction signature`)) {
        failMsg = t`❗ Approval was cancelled`
      } else {
        failMsg = t`❗ Approval failed`
      }
      helperToast.error(failMsg)
      setIsApproving(false)
    }
  }

  const onClickPrimary = () => {
    if (needApproval) {
      approveCollateralToken()
      return
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: isDeposit ? t`Enable deposit sent.` : t`Enable withdraw sent.`,
        failMsg: isDeposit ? t`Enable deposit failed.` : t`Enable withdraw failed.`,
      })
      return
    }

    if (isDeposit) {
      depositCollateral()
      return
    }

    withdrawCollateral()
  }
  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')

  return (
    <>
      {position && (
        <Modal isOpen={isOpen} onDismiss={onDismiss}>
          <ModalWrapper>
            <RowBetween>
              <ThemedText.MediumHeader>
                <Trans>{title}</Trans>
              </ThemedText.MediumHeader>
              <StyledClosed>
                <X size={24} onClick={onDismiss} />
              </StyledClosed>
            </RowBetween>
            <Divider />
            <Container>
              <ButtonTap
                onClick={() => {
                  setOption(DEPOSIT)
                  resetForm()
                }}
                active={option === DEPOSIT}
              >
                <Trans>Deposit</Trans>
              </ButtonTap>
              <ButtonTap
                onClick={() => {
                  setOption(WITHDRAW)
                  resetForm()
                }}
                active={option === WITHDRAW}
              >
                <Trans>Withdraw</Trans>
              </ButtonTap>
            </Container>
            {(isDeposit || isWithdrawal) && (
              <LightCard $borderRadius="8px" mb="1rem">
                <RowBetween>
                  <StyledInput
                    className="amount"
                    type="number"
                    step={0.0}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={fromValue}
                    onUserInput={(val) => {
                      setFromValue(val)
                    }}
                  />
                  {fromValue !== maxAmountFormattedFree && (
                    <ButtonLight
                      width="fit-content"
                      padding="10px"
                      height="10px"
                      mr="0.2rem"
                      onClick={() => setFromValue(maxAmountFormattedFree)}
                    >
                      Max
                    </ButtonLight>
                  )}
                  <ThemedText.MediumHeader>
                    {isDeposit ? position.collateralToken.symbol : 'USD'}
                  </ThemedText.MediumHeader>
                </RowBetween>
                <RowBetween mt="0.5rem">
                  {convertedAmountFormatted && (
                    <ThemedText.Body>
                      {isDeposit ? t`Deposit` : t`Withdraw`}: {convertedAmountFormatted}{' '}
                      {isDeposit ? 'USD' : position.collateralToken.symbol}
                    </ThemedText.Body>
                  )}
                  {!convertedAmountFormatted && (
                    <ThemedText.Body>{`${isDeposit ? t`Deposit` : t`Withdraw`}`}</ThemedText.Body>
                  )}
                  {maxAmount && (
                    <ThemedText.Body onClick={() => setFromValue(maxAmountFormattedFree)}>
                      Max: {maxAmountFormatted}
                    </ThemedText.Body>
                  )}
                </RowBetween>
              </LightCard>
            )}
            <AutoColumn gap="0.5rem">
              {minExecutionFeeErrorMessage && (
                <ThemedText.SubHeader textAlign="justify">{minExecutionFeeErrorMessage}</ThemedText.SubHeader>
              )}
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Size</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>{formatAmount(position.size, USD_DECIMALS, 2, true)} USD</ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Collateral</Trans>
                </ThemedText.SubHeader>
                {nextCollateral ? (
                  <Row>
                    <ThemedText.SubHeader color="text2">
                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                    <ArrowRight width={16} color="grey" />
                    <ThemedText.SubHeader> ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                  </Row>
                ) : (
                  <ThemedText.SubHeader>
                    ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                )}
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Leverage</Trans>
                </ThemedText.SubHeader>
                {nextLeverage ? (
                  <Row>
                    <ThemedText.SubHeader color="text2">
                      {formatAmount(position.leverage, 4, 2, true)}x
                    </ThemedText.SubHeader>
                    <ArrowRight width={16} color="grey" />
                    <ThemedText.SubHeader>{formatAmount(nextLeverage, 4, 2, true)}x</ThemedText.SubHeader>
                  </Row>
                ) : (
                  <ThemedText.SubHeader>{formatAmount(position.leverage, 4, 2, true)}x</ThemedText.SubHeader>
                )}
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Mark Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader color="text2">
                  <Trans>Liq. Price</Trans>
                </ThemedText.SubHeader>
                {nextLiquidationPrice ? (
                  <Row>
                    <ThemedText.SubHeader color="text2">
                      ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                    <ArrowRight width={16} color="grey" />
                    <ThemedText.SubHeader>
                      ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                  </Row>
                ) : (
                  <ThemedText.SubHeader>
                    {!fromAmount && `$${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}`}
                    {fromAmount && '-'}
                  </ThemedText.SubHeader>
                )}
              </RowBetween>
              <RowBetween>
                <MouseoverTooltip
                  text={
                    <AutoColumn gap="0.5rem">
                      <AutoColumn>
                        <ThemedText.SubHeader color="text2">
                          <Trans>Network Fee</Trans>:
                        </ThemedText.SubHeader>
                        <ThemedText.SubHeader>{`${formatAmountFree(
                          minExecutionFee,
                          18,
                          5
                        )} ${nativeTokenSymbol} ($${formatAmount(
                          minExecutionFeeUSD,
                          USD_DECIMALS,
                          2
                        )})`}</ThemedText.SubHeader>
                      </AutoColumn>
                      <ThemedText.SubHeader>
                        <Trans>This is the network cost required to execute the</Trans>{' '}
                        {isDeposit ? t`deposit` : t`withdrawal`}.
                      </ThemedText.SubHeader>
                    </AutoColumn>
                  }
                >
                  <AutoRow align="center">
                    <ThemedText.SubHeader color="text2" mr="4px">
                      <Trans>Execution Fee</Trans>
                    </ThemedText.SubHeader>
                    <Eye width={14} />
                  </AutoRow>
                </MouseoverTooltip>

                <ThemedText.SubHeader>{`${formatAmountFree(
                  minExecutionFee,
                  18,
                  5
                )} ${nativeTokenSymbol}`}</ThemedText.SubHeader>
              </RowBetween>
            </AutoColumn>
            <ButtonPrimary mt="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </ButtonPrimary>
          </ModalWrapper>
        </Modal>
      )}
    </>
  )
}
