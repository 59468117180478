/* eslint-disable no-restricted-imports */
import { ADDRESS_ZERO } from '@jamonswap/v3-sdk'
import { ethers } from 'ethers'

import { SupportedChainId } from './chains'
import { Token } from './types'

export const TOKENS: { [chainId: number]: Token[] } = {
  [SupportedChainId.BSC]: [
    {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
    },
    {
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 18,
      address: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
      isWrapped: true,
      baseSymbol: 'BNB',
      imageUrl: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
    },
    {
      name: 'Ethereum (WETH)',
      symbol: 'ETH',
      address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      decimals: 18,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    },
    {
      name: 'Bitcoin (WBTC)',
      symbol: 'WBTC',
      address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      decimals: 18,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
    {
      name: 'Binance USD',
      symbol: 'BUSD',
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      isStable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766',
    },
  ],
  [SupportedChainId.BSC_TESTNET]: [
    {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
      address: ADDRESS_ZERO,
      isNative: true,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
    },
    {
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 18,
      address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      isWrapped: true,
      baseSymbol: 'BNB',
      imageUrl: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
    },
    {
      name: 'Ethereum (WETH)',
      symbol: 'WETH',
      address: '0xD5a513E85ADB5545d8aCf600fd2BacF84E861A1d',
      decimals: 18,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    },
    {
      name: 'Bitcoin (WBTC)',
      symbol: 'WBTC',
      address: '0x0A2E97563cbb06d59176FDB4E49FAaf9102907Ca',
      decimals: 18,
      isShortable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
    {
      name: 'Binance USD',
      symbol: 'BUSD',
      address: '0x27067f1C61e6778D1Dc5Ea5057885334C366016e',
      decimals: 18,
      isStable: true,
      imageUrl: 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766',
    },
  ],
}

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [SupportedChainId.BSC]: [
    {
      name: 'GMX',
      symbol: 'GMX',
      address: '',
      decimals: 18,
      imageUrl: 'https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468',
    },
    {
      name: 'Escrowed GMX',
      symbol: 'esGMX',
      address: '',
      decimals: 18,
    },
    {
      name: 'GMX LP',
      symbol: 'GLP',
      address: '',
      decimals: 18,
      imageUrl: 'https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true',
    },
  ],
  [SupportedChainId.BSC_TESTNET]: [
    {
      name: 'GMX',
      symbol: 'GMX',
      address: '',
      decimals: 18,
      imageUrl: 'https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468',
    },
    {
      name: 'Escrowed GMX',
      symbol: 'esGMX',
      address: '',
      decimals: 18,
    },
    {
      name: 'GMX LP',
      symbol: 'GLP',
      address: '',
      decimals: 18,
      imageUrl: 'https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true',
    },
  ],
}

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [SupportedChainId.BSC]: {
    Falcon: {
      name: 'Falcon',
      symbol: 'GMX',
      decimals: 18,
      address: '',
      imageUrl: 'https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468',
    },
    GLP: {
      name: 'GMX LP',
      symbol: 'GLP',
      decimals: 18,
      address: '',
      imageUrl: 'https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true',
    },
  },
}

export const ICONLINKS = {
  [SupportedChainId.BSC]: {
    GMX: {
      coingecko: 'https://www.coingecko.com/en/coins/gmx',
      avalanche: 'https://snowtrace.io/address/0x62edc0692bd897d2295872a9ffcac5425011c661',
    },
    GLP: {
      avalanche: 'https://snowtrace.io/address/0x9e295B5B976a184B14aD8cd72413aD846C299660',
    },
    BNB: {
      coingecko: 'https://www.coingecko.com/en/coins/bnb',
    },
    ETH: {
      coingecko: 'https://www.coingecko.com/en/coins/weth',
      avalanche: 'https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    },
    WBTC: {
      coingecko: 'https://www.coingecko.com/en/coins/wrapped-bitcoin',
      avalanche: 'https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218',
    },
    USDC: {
      coingecko: 'https://www.coingecko.com/en/coins/usd-coin',
      avalanche: 'https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    },
  },
}

export const GLP_POOL_COLORS = {
  ETH: '#6062a6',
  BTC: '#F7931A',
  WBTC: '#F7931A',
  USDC: '#2775CA',
  'USDC.e': '#2A5ADA',
  USDT: '#67B18A',
  MIM: '#9695F8',
  FRAX: '#000',
  DAI: '#FAC044',
  UNI: '#E9167C',
  AVAX: '#E84142',
  LINK: '#3256D6',
  MATIC: '#8247e5',
}

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {}
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {}
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {}
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {}

const CHAIN_IDS = [SupportedChainId.BSC, SupportedChainId.BSC_TESTNET]

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j]
  TOKENS_MAP[chainId] = {}
  TOKENS_BY_SYMBOL_MAP[chainId] = {}
  let tokens = TOKENS[chainId]
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId])
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    TOKENS_MAP[chainId][token.address] = token
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId]
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId]
}

export function getTokens(chainId: number) {
  return TOKENS[chainId]
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`)
  }
  return address in TOKENS_MAP[chainId]
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`)
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`)
  }
  return TOKENS_MAP[chainId][address]
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol]
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`)
  }
  return token
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== 'USDF')
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden)
}
