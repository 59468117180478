/* eslint-disable no-restricted-imports */
import { Web3Provider } from '@ethersproject/providers'
import { ADDRESS_ZERO } from '@jamonswap/v3-sdk'
import { t } from '@lingui/macro'
import PositionRouter from 'abis/PositionRouter.json'
import { getConstant, getHighExecutionFee, SupportedChainId } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { InfoTokens, Token } from 'constants/types'
import { BigNumber, ethers } from 'ethers'
import { contractFetcher } from 'lib/contracts'
import { bigNumberify, expandDecimals, formatAmount } from 'lib/numbers'
import { getProvider } from 'lib/rpc'
import { getUsd } from 'lib/tokens'
import useSWR from 'swr'

import {
  BASIS_POINTS_DIVISOR,
  FUNDING_RATE_PRECISION,
  MARGIN_FEE_BASIS_POINTS,
  PENDING_POSITION_VALID_DURATION,
  UPDATED_POSITION_VALID_DURATION,
  USD_DECIMALS,
} from './config'
import { Position, PositionQuery } from './types'

export function getPageTitle(data: any) {
  return `${data} | SpaceDex | Decentralized
  Perpetual Exchange`
}

export function getFundingFee(data: Position): BigNumber | undefined {
  const { entryFundingRate, cumulativeFundingRate, size } = data
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
  }
  return
}

export const getTokenAddress = (token: Token, nativeTokenAddress: string): string => {
  if (token.address === ZERO_ADDRESS) {
    return nativeTokenAddress
  }
  return token.address
}

export function getPositionQuery(tokens: Token[], nativeTokenAddress: string): PositionQuery {
  const collateralTokens = []
  const indexTokens = []
  const isLong = []

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    if (token.isStable) {
      continue
    }
    if (token.isWrapped) {
      continue
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress))
    indexTokens.push(getTokenAddress(token, nativeTokenAddress))
    isLong.push(true)
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i]
    if (!stableToken.isStable) {
      continue
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j]
      if (token.isStable) {
        continue
      }
      if (token.isWrapped) {
        continue
      }
      collateralTokens.push(stableToken.address)
      indexTokens.push(getTokenAddress(token, nativeTokenAddress))
      isLong.push(false)
    }
  }

  return { collateralTokens, indexTokens, isLong }
}

export function getTokenInfo(
  infoTokens: InfoTokens,
  tokenAddress: string,
  replaceNative?: boolean,
  nativeTokenAddress?: string
) {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[ADDRESS_ZERO]
  }

  return infoTokens[tokenAddress]
}

export function getPositionKey(
  account: string | undefined | null,
  collateralTokenAddress: string,
  indexTokenAddress: string,
  isLong: boolean,
  nativeTokenAddress: string
) {
  const tokenAddress0 = collateralTokenAddress === ADDRESS_ZERO ? nativeTokenAddress : collateralTokenAddress
  const tokenAddress1 = indexTokenAddress === ADDRESS_ZERO ? nativeTokenAddress : indexTokenAddress
  return account + ':' + tokenAddress0 + ':' + tokenAddress1 + ':' + isLong
}

export function getPositionContractKey(account: string, collateralToken: string, indexToken: string, isLong: boolean) {
  return ethers.utils.solidityKeccak256(
    ['address', 'address', 'address', 'bool'],
    [account, collateralToken, indexToken, isLong]
  )
}

export function getDeltaStr({
  delta,
  deltaPercentage,
  hasProfit,
}: {
  delta: BigNumber
  deltaPercentage: BigNumber
  hasProfit: boolean
}) {
  let deltaStr
  let deltaPercentageStr

  if (delta.gt(0)) {
    deltaStr = hasProfit ? '+' : '-'
    deltaPercentageStr = hasProfit ? '+' : '-'
  } else {
    deltaStr = ''
    deltaPercentageStr = ''
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`

  return { deltaStr, deltaPercentageStr }
}

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}: {
  size: BigNumber
  sizeDelta?: BigNumber
  increaseSize?: BigNumber
  collateral: BigNumber
  collateralDelta?: BigNumber
  increaseCollateral?: BigNumber
  entryFundingRate: BigNumber
  cumulativeFundingRate: BigNumber
  hasProfit: boolean
  delta: BigNumber
  includeDelta: boolean
}) {
  if (!size && !sizeDelta) {
    return
  }
  if (!collateral && !collateralDelta) {
    return
  }

  let nextSize = size ? size : bigNumberify(0)
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta)
    } else {
      if (sizeDelta.gte(size)) {
        return
      }
      nextSize = size.sub(sizeDelta)
    }
  }

  let remainingCollateral = collateral ? collateral : bigNumberify(0)
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta)
    } else {
      if (collateralDelta.gte(collateral)) {
        return
      }
      remainingCollateral = collateral.sub(collateralDelta)
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral?.add(delta)
    } else {
      if (remainingCollateral && delta.gt(remainingCollateral)) {
        return
      }

      remainingCollateral = remainingCollateral?.sub(delta)
    }
  }

  if (remainingCollateral?.eq(0)) {
    return
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral?.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    : remainingCollateral
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
    remainingCollateral = remainingCollateral?.sub(fundingFee)
  }

  return nextSize?.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral ?? 0)
}

function applyPendingChanges(position: Position, pendingPositions: any) {
  if (!pendingPositions) {
    return
  }
  const { key } = position

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key]
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return
    }

    position.hasPendingChanges = true
    position.pendingChanges = pendingChanges
  }
}

export function useMinExecutionFee(
  library: Web3Provider | undefined,
  active: any,
  chainId: number,
  infoTokens: InfoTokens
) {
  const positionRouterAddress = getContract(chainId, 'PositionRouter')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')

  const { data: minExecutionFee } = useSWR<BigNumber>([active, chainId, positionRouterAddress, 'minExecutionFee'], {
    // @ts-ignore
    fetcher: contractFetcher(library, PositionRouter),
  })

  const { data: gasPrice } = useSWR<BigNumber>(['gasPrice', chainId], {
    // @ts-ignore
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(library, chainId)
        if (!provider) {
          resolve(undefined)
          return
        }

        try {
          const gasPrice = await provider.getGasPrice()
          resolve(gasPrice)
        } catch (e) {
          console.error(e)
        }
      })
    },
  })

  let multiplier = 0

  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  if (chainId === SupportedChainId.BSC || chainId === SupportedChainId.BSC_TESTNET) {
    multiplier = 65000
  }
  let finalExecutionFee = minExecutionFee ?? BigNumber.from(0)

  if (gasPrice && minExecutionFee) {
    const estimatedExecutionFee = gasPrice.mul(multiplier)
    if (estimatedExecutionFee.gt(minExecutionFee)) {
      finalExecutionFee = estimatedExecutionFee
    }
  }

  const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens)
  const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS))
  const errorMessage =
    isFeeHigh &&
    t`The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`

  return {
    minExecutionFee: finalExecutionFee,
    minExecutionFeeUSD: finalExecutionFeeUSD,
    minExecutionFeeErrorMessage: errorMessage,
  }
}

export function getPositions(
  chainId: any,
  positionQuery: any,
  positionData: any,
  infoTokens: any,
  includeDelta: boolean,
  showPnlAfterFees: boolean,
  account: string | undefined | null,
  pendingPositions: any,
  updatedPositions: any
) {
  const propsLength = getConstant(chainId, 'positionReaderPropsLength')
  const positions: Position[] = []
  const positionsMap: any = {}
  if (!positionData) {
    return { positions, positionsMap }
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, 'NATIVE_TOKEN'))
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, 'NATIVE_TOKEN'))
    const key = getPositionKey(
      account,
      collateralTokens[i],
      indexTokens[i],
      isLong[i],
      getContract(chainId, 'NATIVE_TOKEN')
    )
    let contractKey
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i])
    }

    const position: Position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i].size,
      collateral: positionData[i].collateral,
      averagePrice: positionData[i].averagePrice,
      entryFundingRate: positionData[i].entryFundingRate,
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i].hasRealisedProfit.eq(1),
      realisedPnl: positionData[i].realisedPnl,
      lastIncreasedTime: positionData[i].lastIncreasedTime,
      hasProfit: positionData[i].hasProfit,
      delta: positionData[i].size,
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    }

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key]
      position.size = updatedPosition.size
      position.collateral = updatedPosition.collateral
      position.averagePrice = updatedPosition.averagePrice
      position.entryFundingRate = updatedPosition.entryFundingRate
    }

    const fundingFee = getFundingFee(position)
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0)
    position.collateralAfterFee = position.collateral.sub(position.fundingFee ?? 0)

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR)
    position.totalFees = position.positionFee.add(position.fundingFee ?? 0)

    position.pendingDelta = position.delta

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50)

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice)
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice)

        position.delta = position.pendingDelta

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice)
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice)
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral)

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      })

      position.deltaStr = deltaStr
      position.deltaPercentageStr = deltaPercentageStr
      position.deltaBeforeFeesStr = deltaStr

      let hasProfitAfterFees
      let pendingDeltaAfterFees

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees)
        } else {
          hasProfitAfterFees = false
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta)
        }
      } else {
        hasProfitAfterFees = false
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees)
      }

      position.hasProfitAfterFees = hasProfitAfterFees
      position.pendingDeltaAfterFees = pendingDeltaAfterFees
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral)

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      })

      position.deltaAfterFeesStr = deltaAfterFeesStr
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta)

      netValue = netValue.sub(position.fundingFee ?? 0)

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee)
      }

      position.netValue = netValue
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate ?? BigNumber.from(0),
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    })

    positionsMap[key] = position

    applyPendingChanges(position, pendingPositions)

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position)
    }
  }

  return { positions, positionsMap }
}
