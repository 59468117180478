/* eslint-disable no-restricted-imports */
import { ethers } from 'ethers'
const { parseEther } = ethers.utils
/**
 * List of all the networks supported by the SpaceDex Interface
 */
export enum SupportedChainId {
  BSC = 56,
  BSC_TESTNET = 97,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.BSC]: 'binance',
  [SupportedChainId.BSC_TESTNET]: 'binance_testnet',
}

export const DEFAULT_CHAIN_ID = SupportedChainId.BSC_TESTNET

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = []

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [SupportedChainId.BSC, SupportedChainId.BSC_TESTNET] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

const constants: any = {
  [SupportedChainId.BSC]: {
    nativeTokenSymbol: 'BNB',
    wrappedTokenSymbol: 'WBNB',
    defaultCollateralSymbol: 'BUSD',
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
  },

  [SupportedChainId.BSC_TESTNET]: {
    nativeTokenSymbol: 'BNB',
    wrappedTokenSymbol: 'WBNB',
    defaultCollateralSymbol: 'BUSD',
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther('0.01'),
  },
}

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`)
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`)
  }

  return constants[chainId][key]
}

// BSC TESTNET
const BSC_TESTNET_RPC_PROVIDERS = [
  'https://data-seed-prebsc-1-s1.binance.org:8545',
  'https://data-seed-prebsc-2-s1.binance.org:8545',
  'https://data-seed-prebsc-1-s2.binance.org:8545',
  'https://data-seed-prebsc-2-s2.binance.org:8545',
  'https://data-seed-prebsc-1-s3.binance.org:8545',
  'https://data-seed-prebsc-2-s3.binance.org:8545',
]

export const BSC_RPC_PROVIDERS = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed1.ninicoin.io',
  'https://bsc-dataseed2.defibit.io',
  'https://bsc-dataseed3.defibit.io',
  'https://bsc-dataseed4.defibit.io',
  'https://bsc-dataseed2.ninicoin.io',
  'https://bsc-dataseed3.ninicoin.io',
  'https://bsc-dataseed4.ninicoin.io',
  'https://bsc-dataseed1.binance.org',
  'https://bsc-dataseed2.binance.org',
  'https://bsc-dataseed3.binance.org',
  'https://bsc-dataseed4.binance.org',
]
export const RPC_PROVIDERS = {
  [SupportedChainId.BSC]: BSC_RPC_PROVIDERS,
  [SupportedChainId.BSC_TESTNET]: BSC_TESTNET_RPC_PROVIDERS,
}

export const FALLBACK_PROVIDERS = {
  [SupportedChainId.BSC]: BSC_RPC_PROVIDERS,
  [SupportedChainId.BSC_TESTNET]: ['https://rpc.ankr.com/bsc_testnet_chapel'],
}

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [SupportedChainId.BSC]: '0',
  [SupportedChainId.BSC_TESTNET]: '3000000000', // 3 gwei
}

export const MAX_GAS_PRICE_MAP = {
  [SupportedChainId.BSC]: '50000000000', // 50 gwei
  [SupportedChainId.BSC_TESTNET]: '50000000000', // 50 gwei
}

export const HIGH_EXECUTION_FEES_MAP = {
  [SupportedChainId.BSC]: 3, // 3 USD
  [SupportedChainId.BSC_TESTNET]: 3, // 3 USD
}

export function getHighExecutionFee(chainId: number) {
  // @ts-ignore
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3
}

export const IS_NETWORK_DISABLED = {
  [SupportedChainId.BSC]: false,
  [SupportedChainId.BSC_TESTNET]: false,
}

export const CHAIN_NAMES_MAP = {
  [SupportedChainId.BSC]: 'BSC',
  [SupportedChainId.BSC_TESTNET]: 'BSC Testnet',
}

export const BLOCKS_PER_YEAR = {
  [SupportedChainId.BSC]: (60 / 3) * 60 * 24 * 365, // 10512000
  [SupportedChainId.BSC_TESTNET]: (60 / 3) * 60 * 24 * 365, // 10512000
}

export function getBlocksPerYear(chainId: number): number {
  return BLOCKS_PER_YEAR[chainId as SupportedChainId]
}

export function getChainName(chainId: number): string {
  return CHAIN_NAMES_MAP[chainId as SupportedChainId]
}

export function isSupportedChain(chainId: number) {
  return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
}
