export const PENDING_POSITION_VALID_DURATION = 600 * 1000
export const UPDATED_POSITION_VALID_DURATION = 60 * 1000
export const FUNDING_RATE_PRECISION = 1000000
export const MARGIN_FEE_BASIS_POINTS = 10
export const BASIS_POINTS_DIVISOR = 10000
export const USD_DECIMALS = 30
export const SWAP = 'Swap'
export const INCREASE = 'Increase'
export const DECREASE = 'Decrease'
export const LONG = 'Long'
export const SHORT = 'Short'

export const MARKET = 'Market'
export const LIMIT = 'Limit'
export const STOP = 'Stop'
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP]
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT]
export const SWAP_OPTIONS = [LONG, SHORT, SWAP]
export const DEFAULT_SLIPPAGE_AMOUNT = 30
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100
