/* eslint-disable no-restricted-imports */
import { JsonRpcProvider } from '@ethersproject/providers'
import { FALLBACK_PROVIDERS, RPC_PROVIDERS } from 'constants/chains'
import { ethers } from 'ethers'
import _ from 'lodash'

export function getProvider(library: JsonRpcProvider | undefined, chainId: number): JsonRpcProvider {
  if (library) {
    return library
  }

  // @ts-ignore incorrect Network param types
  const provider = _.sample(RPC_PROVIDERS[chainId])

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  )
}

export function getFallbackProvider(chainId: number) {
  // @ts-ignore incorrect Network param types
  if (!FALLBACK_PROVIDERS[chainId]) {
    return
  }

  // @ts-ignore incorrect Network param types
  const provider = _.sample(FALLBACK_PROVIDERS[chainId])

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  )
}
