// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { REFERRAL_CODE_REGEX } from 'components/Referrals/referralsHelper'
import { encodeReferralCode, validateReferralCodeExists } from 'domain/referrals'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useReferralStorageContract } from 'hooks/useContract'
import { helperToast } from 'lib/helperToast'
import { isAddressZero } from 'lib/legacy'
import { useDebounce } from 'lib/useDebounce'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const StyledInput = styled.input`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 20px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.primary1};
  outline: none;
  color: ${({ theme }) => theme.text1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `};

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text2};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.secondary3};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.secondary3};
  }
`

interface ReferralCodeFormProps {
  callAfterSuccess?: any
  userReferralCode?: string
  type?: string
}

export function ReferralCodeForm({ callAfterSuccess, userReferralCode = '', type = 'join' }: ReferralCodeFormProps) {
  const { account, chainId } = useActiveWeb3React()
  const [referralCode, setReferralCode] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [referralCodeExists, setReferralCodeExists] = useState(true)
  const debouncedReferralCode = useDebounce(referralCode, 300)

  const referralStorageContract = useReferralStorageContract()

  function getPrimaryText() {
    const isEdit = type === 'edit'
    if (isEdit && debouncedReferralCode === userReferralCode) {
      return t`Same as current active code`
    }
    if (isEdit && isSubmitting) {
      return t`Updating...`
    }

    if (isSubmitting) {
      return t`Adding...`
    }
    if (debouncedReferralCode === '') {
      return t`Enter Referral Code`
    }
    if (isValidating) {
      return t`Checking code...`
    }
    if (!referralCodeExists) {
      return t`Referral Code does not exist`
    }

    return isEdit ? t`Update` : t`Submit`
  }
  function isPrimaryEnabled() {
    if (
      debouncedReferralCode === '' ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists ||
      debouncedReferralCode === userReferralCode
    ) {
      return false
    }
    return true
  }

  async function handleSubmit(event: any) {
    const isEdit = type === 'edit'
    event.preventDefault()
    setIsSubmitting(true)
    const referralCodeHex = encodeReferralCode(referralCode)

    try {
      const codeOwner = await referralStorageContract?.codeOwners(referralCodeHex)
      if (isAddressZero(codeOwner)) {
        const errorMsg = '❗ Referral code does not exist'
        helperToast.error(errorMsg)
        return Promise.reject(errorMsg)
      }
      const tx = await referralStorageContract?.setTraderReferralCodeByUser(referralCodeHex)
      const receipt = await tx?.wait()
      if (callAfterSuccess) {
        callAfterSuccess()
      }
      helperToast.success(isEdit ? t`✔️ Referral code updated!` : t`✔️ Referral code added!`)
      if (receipt?.status === 1) {
        setReferralCode('')
      }
    } catch (error) {
      console.error(error)
      helperToast.error(isEdit ? t`❗ Referral code updated failed` : t`❗ Adding referral code failed`)
    } finally {
      setIsSubmitting(false)
      setIsValidating(false)
    }
    return
  }

  useEffect(() => {
    let cancelled = false
    async function checkReferralCode() {
      if (debouncedReferralCode === '' || !REFERRAL_CODE_REGEX.test(debouncedReferralCode)) {
        setIsValidating(false)
        setReferralCodeExists(false)
        return
      }

      setIsValidating(true)
      const codeExists = await validateReferralCodeExists(debouncedReferralCode, chainId)
      if (!cancelled) {
        setReferralCodeExists(codeExists)
        setIsValidating(false)
      }
    }
    checkReferralCode()
    return () => {
      cancelled = true
    }
  }, [debouncedReferralCode, chainId])

  /* useEffect(() => {
    inputRef.current.focus()
  }, []) */

  return (
    <form onSubmit={handleSubmit}>
      <StyledInput
        /* ref={inputRef as HTMLInputElement} */
        disabled={isSubmitting}
        type="text"
        placeholder={t`Enter Referral Code`}
        className="text-input mb-sm"
        value={referralCode}
        onChange={({ target }) => {
          const { value } = target
          setReferralCode(value)
        }}
      />
      <ButtonPrimary mt="1rem" type="submit" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </ButtonPrimary>
    </form>
  )
}
