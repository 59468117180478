/* eslint-disable no-restricted-imports */
import { t, Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Modal from 'components/Modal'
import { Input as NumericalInput } from 'components/NumericalInput'
import { RowBetween } from 'components/Row'
import { IS_NETWORK_DISABLED, SupportedChainId } from 'constants/chains'
import { BigNumber } from 'ethers'
import { useFalconPoolContract } from 'hooks/useContract'
import { helperToast } from 'lib/helperToast'
import { formatAmountFree, formatKeyAmount, parseValue } from 'lib/numbers'
import { useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 16px;
  `};
`

interface UnstakeModalProps {
  chainId: number
  data: any
  onDismiss: () => void
  isOpen: boolean
}

export function UnstakeModal({ chainId, data, onDismiss, isOpen }: UnstakeModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [amount, setAmount] = useState('')
  const amountMax = formatAmountFree(data.falconStaked ?? BigNumber.from(0), 18, 18)
  const max = formatKeyAmount(data, 'falconStaked', 18, 4, true)

  const flnAmount = parseValue(amount, 18)
  const flnMax = parseValue(amountMax, 18)

  const falconPoolContract = useFalconPoolContract()

  const unStake = async () => {
    setIsSubmitting(true)
    try {
      const tx = await falconPoolContract?.withdrawn(flnAmount ?? 0)
      await tx?.wait()
      setIsSubmitting(false)
      helperToast.success(t`✔️ Unstaked FLN`)
      onDismiss()
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      helperToast.error(t`❗ Unstake error`)
    }
  }

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId as SupportedChainId]) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (!amount) {
      return false
    }
    if (flnAmount?.eq(0)) {
      return false
    }
    if (flnAmount?.gt(flnMax ?? 0)) {
      return false
    }
    return true
  }

  const getPrimaryText = () => {
    if (!amount) {
      return t`Enter Amount`
    }
    if (flnAmount?.eq(0)) {
      return t`Enter Amount`
    }
    if (flnAmount?.gt(flnMax ?? 0)) {
      return t`Insufficient funds`
    }

    if (isSubmitting) {
      return t`Unstaking...`
    }

    return t`Unstake`
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Unstake</Trans>
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <Divider />
        <LightCard $borderRadius="8px" mt="1rem" mb="1rem">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={amount}
              onUserInput={(val) => {
                setAmount(val)
              }}
            />
            <ThemedText.MediumHeader mr="2rem">FLN</ThemedText.MediumHeader>
          </RowBetween>
          <RowBetween mt="0.5rem">
            <ThemedText.Body>
              <Trans>Unstake</Trans>
            </ThemedText.Body>
            <ThemedText.Body onClick={() => setAmount(amountMax)} style={{ cursor: 'pointer' }}>
              <Trans>Max</Trans>: {max}
            </ThemedText.Body>
          </RowBetween>
        </LightCard>
        <ButtonPrimary onClick={unStake} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  )
}
