/* eslint-disable prefer-const */
/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { CandleChartLoader } from 'components/Loader/ChartLoaders'
import { LoadingRows } from 'components/Loader/styled'
import { RowCenter } from 'components/Row'
import Tab from 'components/Tab/Tab'
import { CHART_PERIODS, INCREASE, SWAP, USD_DECIMALS } from 'constants/misc'
import { getToken, getTokens } from 'constants/tokens'
import { useChartPrices } from 'domain/prices'
import { BigNumber } from 'ethers/lib/ethers'
import { createChart } from 'krasulya-lightweight-charts'
import { formatDateTime } from 'lib/dates'
import { getLiquidationPrice } from 'lib/legacy'
import { useLocalStorageSerializeKey } from 'lib/localStorage'
import { formatAmount, numberWithCommas } from 'lib/numbers'
import { getTokenInfo } from 'lib/tokens'
import { usePrevious } from 'lib/usePrevious'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import ChartTokenSelector from './ChartTokenSelector'

const ChartHeaderWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: 75%;
  `};
`
const ChartWrapper = styled.div`
  position: absolute;
  bottom: 0.8rem;
  left: 0;
  right: 0;
  top: 0.8rem;
`
const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  padding: 0.4rem 0;
  margin-left: 2rem;
  white-space: nowrap;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 0;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
    font-size: 0.7rem;
  `};
`
const StatsLabelWrapper = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
`
const StatsValueWrapper = styled.div<{ positive: boolean; length: number }>`
  color: ${({ positive }) => (positive ? 'green' : 'red')};
  margin-left: 0.31rem;
  margin-right: 0.8rem;
  width: ${({ length }) => `${length}rem`};
`

const TapWrapper = styled.div`
  display: inline-block;
`

const Wrapper = styled(DarkCard)`
  position: relative;
  width: 100%;
  height: 30rem;
`

const Row = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ isMobile }) => (isMobile ? '1rem' : '5rem')};
`

const PRICE_LINE_TEXT_WIDTH = 15

const timezoneOffset = -new Date().getTimezoneOffset() * 60

export function getChartToken(swapOption: any, fromToken: any, toToken: any, chainId: any) {
  if (!fromToken || !toToken) {
    return
  }

  if (swapOption !== SWAP) {
    return toToken
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable)
  }
  if (fromToken.isUsdg) {
    return toToken
  }
  if (toToken.isUsdg) {
    return fromToken
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken
  }
  if (fromToken.isStable) {
    return toToken
  }
  if (toToken.isStable) {
    return fromToken
  }

  return toToken
}

const DEFAULT_PERIOD = '4h'

const getSeriesOptions = () => ({
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
  lineColor: '#5472cc',
  topColor: 'rgba(49, 69, 131, 0.4)',
  bottomColor: 'rgba(42, 64, 103, 0.0)',
  lineWidth: 2,
  priceLineColor: '#3a3e5e',
  downColor: '#fa3c58',
  wickDownColor: '#fa3c58',
  upColor: '#0ecc83',
  wickUpColor: '#0ecc83',
  borderVisible: false,
})

const getChartOptions = (width: any, height: any) => ({
  width,
  height,
  layout: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    textColor: '#ccc',
    fontFamily: 'Relative',
  },
  localization: {
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
    timeFormatter: (businessDayOrTimestamp: number) => {
      return formatDateTime(businessDayOrTimestamp - timezoneOffset)
    },
  },
  grid: {
    vertLines: {
      visible: true,
      color: 'rgba(35, 38, 59, 1)',
      style: 2,
    },
    horzLines: {
      visible: true,
      color: 'rgba(35, 38, 59, 1)',
      style: 2,
    },
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
  timeScale: {
    rightOffset: 5,
    borderVisible: false,
    barSpacing: 5,
    timeVisible: true,
    fixLeftEdge: true,
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
  priceScale: {
    borderVisible: false,
  },
  crosshair: {
    horzLine: {
      color: '#aaa',
    },
    vertLine: {
      color: '#aaa',
    },
    mode: 0,
  },
})

interface ChartProps {
  swapOption: any
  fromTokenAddress: any
  toTokenAddress: any
  infoTokens: any
  chainId: number
  positions: any
  savedShouldShowPositionLines: boolean
  orders: any
  setToTokenAddress: any
}

export function Chart({
  swapOption,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  chainId,
  positions,
  savedShouldShowPositionLines,
  orders,
  setToTokenAddress,
}: ChartProps) {
  const [currentChart, setCurrentChart] = useState<any>()
  const [currentSeries, setCurrentSeries] = useState<any>()

  // @ts-ignore
  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, 'Chart-period'], DEFAULT_PERIOD)
  // @ts-ignore
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD
  }

  const [hoveredCandlestick, setHoveredCandlestick] = useState<any>()

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  const [chartToken, setChartToken] = useState({
    maxPrice: BigNumber.from(0),
    minPrice: null,
    isWrapped: null,
    baseSymbol: null,
    symbol: null,
    address: null,
    isNative: null,
    isStable: null,
  })
  useEffect(() => {
    const tmp = getChartToken(swapOption, fromToken, toToken, chainId)
    setChartToken(tmp)
  }, [swapOption, fromToken, toToken, chainId])

  const symbol = chartToken ? (chartToken.isWrapped ? chartToken.baseSymbol : chartToken.symbol) : undefined
  const marketName = chartToken ? symbol + '_USD' : undefined
  const previousMarketName = usePrevious(marketName)

  const currentOrders = useMemo(() => {
    if (swapOption === SWAP || !chartToken) {
      return []
    }

    return orders.filter((order: any) => {
      if (order.type === SWAP) {
        // we can't show non-stable to non-stable swap orders with existing charts
        // so to avoid users confusion we'll show only long/short orders
        return false
      }

      const indexToken = getToken(chainId, order.indexToken)
      return order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped)
    })
  }, [orders, chartToken, swapOption, chainId])

  const ref = useRef(null)
  const chartRef = useRef<any>(null)

  const currentAveragePrice =
    chartToken.maxPrice && chartToken.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null
  const [priceData, updatePriceData] = useChartPrices(
    chainId,
    chartToken.symbol,
    chartToken.isStable,
    period,
    currentAveragePrice
  )

  const [chartInited, setChartInited] = useState(false)
  useEffect(() => {
    if (marketName !== previousMarketName) {
      setChartInited(false)
    }
  }, [marketName, previousMarketName])

  const scaleChart = useCallback(() => {
    // @ts-ignore
    const from = Date.now() / 1000 - (7 * 24 * CHART_PERIODS[period]) / 2 + timezoneOffset
    const to = Date.now() / 1000 + timezoneOffset
    currentChart?.timeScale().setVisibleRange({ from, to })
  }, [currentChart, period])

  const onCrosshairMove = useCallback(
    (evt) => {
      if (!evt.time) {
        setHoveredCandlestick(undefined)
        return
      }

      for (const point of evt.seriesPrices.values()) {
        setHoveredCandlestick((hoveredCandlestick: any) => {
          if (hoveredCandlestick && hoveredCandlestick.time === evt.time) {
            // rerender optimisations
            return hoveredCandlestick
          }
          return {
            time: evt.time,
            ...point,
          }
        })
        break
      }
    },
    [setHoveredCandlestick]
  )

  useEffect(() => {
    if (!ref.current || !priceData || !priceData.length || currentChart) {
      return
    }

    const chart = createChart(
      chartRef.current,
      getChartOptions(chartRef.current.offsetWidth, chartRef.current.offsetHeight)
    )

    chart.subscribeCrosshairMove(onCrosshairMove)

    const series = chart.addCandlestickSeries(getSeriesOptions())

    setCurrentChart(chart)
    setCurrentSeries(series)
  }, [ref, priceData, currentChart, onCrosshairMove])

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true)
    }, 60 * 1000)
    return () => clearInterval(interval)
  }, [updatePriceData])

  useEffect(() => {
    if (!currentChart) {
      return
    }
    const resizeChart = () => {
      currentChart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight)
    }
    window.addEventListener('resize', resizeChart)
    return () => window.removeEventListener('resize', resizeChart)
  }, [currentChart])

  useEffect(() => {
    if (currentSeries && priceData && priceData.length) {
      currentSeries.setData(priceData)

      if (!chartInited) {
        scaleChart()
        setChartInited(true)
      }
    }
  }, [priceData, currentSeries, chartInited, scaleChart])

  useEffect(() => {
    const lines: any[] = []
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order: any) => {
          const indexToken = getToken(chainId, order.indexToken)
          let tokenSymbol
          if (indexToken && indexToken.symbol) {
            tokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol
          }
          const title = `${order.type === INCREASE ? 'Inc.' : 'Dec.'} ${tokenSymbol} ${
            order.isLong ? t`Long` : t`Short`
          }`
          const color = '#3a3e5e'
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, ' '),
            })
          )
        })
      }
      if (positions && positions.length > 0) {
        const color = '#3a3e5e'

        positions.forEach((position: any) => {
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
              color,
              title: `${t`Open`} ${position.indexToken.symbol} ${position.isLong ? t`Long` : t`Short`}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                ' '
              ),
            })
          )

          const liquidationPrice = getLiquidationPrice(position)
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 2)),
              color,
              title: `Liq. ${position.indexToken.symbol} ${position.isLong ? t`Long` : t`Short`}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                ' '
              ),
            })
          )
        })
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line))
    }
  }, [currentOrders, positions, currentSeries, chainId, savedShouldShowPositionLines])

  const candleStatsHtml = useMemo(() => {
    if (!priceData) {
      return null
    }
    const candlestick = hoveredCandlestick || priceData[priceData.length - 1]
    if (!candlestick) {
      return null
    }

    /* const className = cx({
      'ExchangeChart-bottom-stats': true,
      positive: candlestick.open <= candlestick.close,
      negative: candlestick.open > candlestick.close,
      [`length-${String(parseInt(candlestick.close)).length}`]: true,
    }) */

    const toFixedNumbers = 2

    const isPositive = candlestick.open <= candlestick.close
    let valueLength = String(parseInt(candlestick.close)).length
    switch (valueLength) {
      case 1:
        valueLength = 1.75
        break
      case 2:
        valueLength = 2.25
        break
      case 3:
        valueLength = 2.75
        break
      case 4:
        valueLength = 3.25
        break
      case 5:
        valueLength = 3.75
        break
      default:
        valueLength = 2.48
        break
    }

    return (
      <StatsWrapper>
        <AutoColumn>
          <RowCenter>
            <StatsLabelWrapper>O</StatsLabelWrapper>
            <StatsValueWrapper positive={isPositive} length={valueLength}>
              {candlestick.open.toFixed(toFixedNumbers)}
            </StatsValueWrapper>
          </RowCenter>
        </AutoColumn>
        <AutoColumn>
          <RowCenter>
            <StatsLabelWrapper>H</StatsLabelWrapper>
            <StatsValueWrapper positive={isPositive} length={valueLength}>
              {candlestick.high.toFixed(toFixedNumbers)}
            </StatsValueWrapper>
          </RowCenter>
        </AutoColumn>
        <AutoColumn>
          <RowCenter>
            <StatsLabelWrapper>L</StatsLabelWrapper>
            <StatsValueWrapper positive={isPositive} length={valueLength}>
              {candlestick.low.toFixed(toFixedNumbers)}
            </StatsValueWrapper>
          </RowCenter>
        </AutoColumn>
        <AutoColumn>
          <RowCenter>
            <StatsLabelWrapper>C</StatsLabelWrapper>
            <StatsValueWrapper positive={isPositive} length={valueLength}>
              {candlestick.close.toFixed(toFixedNumbers)}
            </StatsValueWrapper>
          </RowCenter>
        </AutoColumn>
      </StatsWrapper>
    )
  }, [hoveredCandlestick, priceData])

  let high: any
  let low: any
  let deltaPrice: any
  let delta: any
  let deltaPercentage: any
  let deltaPercentageStr: any

  const now = Date.now() / 1000
  const timeThreshold = now - 24 * 60 * 60

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i]
      if (price.time < timeThreshold) {
        break
      }
      if (!low) {
        low = price.low
      }
      if (!high) {
        high = price.high
      }

      if (price.high > high) {
        high = price.high
      }
      if (price.low < low) {
        low = price.low
      }

      deltaPrice = price.open
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2))
    delta = average - deltaPrice
    deltaPercentage = (delta * 100) / average
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = '0.00'
    }
  }

  if (!chartToken) {
    return null
  }

  const onSelectToken = (token: any) => {
    const tmp = getTokenInfo(infoTokens, token.address)
    // @ts-ignore
    setChartToken(tmp)
    setToTokenAddress(swapOption, token.address)
  }

  const renderHeader = () => {
    return (
      <DarkCard>
        <Row isMobile={isMobile}>
          <ChartTokenSelector
            chainId={chainId}
            selectedToken={chartToken}
            swapOption={swapOption}
            onSelectToken={onSelectToken}
          />
          <AutoColumn>
            <ThemedText.Body>
              {chartToken.maxPrice ? (
                formatAmount(chartToken.maxPrice, USD_DECIMALS, 2, true)
              ) : (
                <LoadingRows>
                  <div style={{ width: '6rem', height: '1.2rem', marginBottom: '0.25rem' }} />
                </LoadingRows>
              )}
            </ThemedText.Body>
            <ThemedText.SubHeader>
              {' '}
              {chartToken.minPrice ? (
                `$ ${formatAmount(chartToken.minPrice, USD_DECIMALS, 2, true)}`
              ) : (
                <LoadingRows>
                  <div style={{ width: '6rem', height: '1.2rem' }} />
                </LoadingRows>
              )}
            </ThemedText.SubHeader>
          </AutoColumn>
          <AutoColumn>
            <ThemedText.Italic>
              24h <Trans>Change</Trans>
            </ThemedText.Italic>
            <ThemedText.SubHeader color={deltaPercentage >= 0 ? 'green' : 'red'}>
              {!deltaPercentageStr && (
                <LoadingRows>
                  <div style={{ width: '6rem', height: '1.2rem' }} />
                </LoadingRows>
              )}
              {deltaPercentageStr && deltaPercentageStr}
            </ThemedText.SubHeader>
          </AutoColumn>
          {!isMobile && (
            <>
              <AutoColumn>
                <ThemedText.Italic>
                  24h <Trans>High</Trans>
                </ThemedText.Italic>
                <ThemedText.SubHeader>
                  {!high && (
                    <LoadingRows>
                      <div style={{ width: '6rem', height: '1.2rem' }} />
                    </LoadingRows>
                  )}
                  {high && numberWithCommas(high.toFixed(2))}
                </ThemedText.SubHeader>
              </AutoColumn>
              <AutoColumn>
                <ThemedText.Italic>
                  24h <Trans>Low</Trans>
                </ThemedText.Italic>
                <ThemedText.SubHeader>
                  {!low && (
                    <LoadingRows>
                      <div style={{ width: '6rem', height: '1.2rem' }} />
                    </LoadingRows>
                  )}
                  {low && numberWithCommas(low.toFixed(2))}
                </ThemedText.SubHeader>
              </AutoColumn>
              {/* <AutoColumn>
                <ThemedText.Italic>
                  24H <Trans>Volume</Trans>({token[0]})
                </ThemedText.Italic>
                <ThemedText.SubHeader>0.0000</ThemedText.SubHeader>
              </AutoColumn> */}
            </>
          )}
        </Row>
      </DarkCard>
    )
  }

  return (
    <>
      {renderHeader()}
      <Wrapper ref={ref}>
        <ChartHeaderWrapper>
          <TapWrapper>
            <Tab options={Object.keys(CHART_PERIODS)} option={period} setOption={setPeriod} />
          </TapWrapper>
          {candleStatsHtml}
        </ChartHeaderWrapper>
        <ChartWrapper ref={chartRef}>{!currentChart && <CandleChartLoader />}</ChartWrapper>
      </Wrapper>
    </>
  )
}
