/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { CSSProperties, useMemo } from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import {
  COLORS,
  tooltipFormatterNumber,
  tooltipLabelFormatter as tooltipLabelFormatter_,
  tooltipLabelFormatterUnits,
  yaxisFormatterNumber,
} from '../helpers'
import { useChartViewState } from '../hooks/useChartViewState'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}
export function UserActionsChart(props: Props) {
  const { data, loading, chartHeight } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }

  const yaxisDataKey = 'actionCount'

  const items = [
    { key: 'actionSwapCount', name: 'Swaps' },
    { key: 'actionMarginCount', name: 'Margin trading' },
    { key: 'actionMintBurnCount', name: 'Mint & Burn FLP' },
  ]

  const csvFields = items.map((item) => ({ key: item.key, name: item.name }))

  const truncateYThreshold = 25000
  const yaxisDomain = [0, 'auto']

  const controls = {}

  const {
    viewState,
    togglePercentView,
    formattedData,
    yaxisTickFormatter: defaultYaxisTickFormatter,
    itemsUnit: defaultItemUnit,
  } = useChartViewState({ controls, data })

  const truncatedYDomain: any = useMemo(() => {
    if (!data) {
      return null
    }
    if (Math.max(...data.map((item: any) => item[yaxisDataKey])) > truncateYThreshold) {
      // Bug in recharts: dataMax number values applies via function syntax only
      // eslint-disable-next-line no-unused-vars
      return [yaxisDomain[0], (_: any) => truncateYThreshold]
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, truncateYThreshold, yaxisDomain?.join('-')])

  const htmlItems = (items || []).map((item, i) => {
    const props = {
      dataKey: item.key,
      stackId: 'a',
      name: item.name || item.key,
      fill: COLORS[i % COLORS.length],
      stroke: COLORS[i % COLORS.length],
      dot: false,
      key: 'item-' + i,
      unit: defaultItemUnit,
    }

    return <Bar type="monotone" {...props} isAnimationActive={false} />
  })
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>User Actions</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink fields={csvFields} name="User Actions" data={formattedData} />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart data={formattedData} syncId="syncFlp">
              <CartesianGrid strokeDasharray="10 10" key="a" />,
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter_} minTickGap={30} key="b" />,
              <YAxis
                domain={truncatedYDomain || yaxisDomain}
                dataKey={yaxisDataKey}
                tickFormatter={yaxisFormatterNumber}
                key="c"
              />
              <Tooltip
                formatter={tooltipFormatterNumber}
                labelFormatter={tooltipLabelFormatterUnits}
                contentStyle={contentStyle}
                key="d"
              />
              <Legend key="e" />
              {htmlItems}
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </DarkCard>
  )
}
