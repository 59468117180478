/* eslint-disable prefer-const */
/* eslint-disable no-restricted-imports */
//@ts-nocheck
import { BigNumber } from '@ethersproject/bignumber'
import { t, Trans } from '@lingui/macro'
import Token from 'abis/erc20.json'
/* import PositionRouter from 'abis/PositionRouter.json' */
import PositionManager from 'abis/PositionManager.json'
import Router from 'abis/Router.json'
import WETH from 'abis/weth.json'
import { ButtonDropdownText, ButtonPrimary } from 'components/Button'
import { DarkCard, LightCard, LightGreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { Input as NumericalInput } from 'components/NumericalInput'
import Row, { RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { getChainName, getConstant, IS_NETWORK_DISABLED, isSupportedChain } from 'constants/chains'
import { getContract } from 'constants/contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from 'constants/tokens'
import * as Api from 'domain/legacy'
import { useUserReferralCode } from 'domain/referrals'
import {
  approveTokens,
  getMostAbundantStableToken,
  getTokenInfo,
  getUsd,
  replaceNativeTokenAddress,
  shouldRaiseGasError,
} from 'domain/tokens'
import { ethers } from 'ethers'
import { callContract, contractFetcher } from 'lib/contracts'
import { helperToast } from 'lib/helperToast'
import {
  adjustForDecimals,
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  getExchangeRate,
  getExchangeRateDisplay,
  getLeverage,
  getLiquidationPrice,
  getNextFromAmount,
  getNextToAmount,
  getPositionKey,
  isTriggerRatioInverted,
  LEVERAGE_ORDER_OPTIONS,
  LIMIT,
  LONG,
  MARGIN_FEE_BASIS_POINTS,
  MARKET,
  PRECISION,
  SHORT,
  STOP,
  SWAP,
  SWAP_OPTIONS,
  SWAP_ORDER_OPTIONS,
  USD_DECIMALS,
  USDG_ADDRESS,
  USDG_DECIMALS,
} from 'lib/legacy'
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from 'lib/localStorage'
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from 'lib/numbers'
import { usePrevious } from 'lib/usePrevious'
import Slider from 'rc-slider'
import { useEffect, useMemo, useState } from 'react'
import { CheckSquare, HelpCircle, Square } from 'react-feather'
import { ArrowRight } from 'react-feather'
import { useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import { ThemedText } from 'theme'

import ConfirmationModal from './Modals/ConfirmationModal'
import { OrdersToS } from './Modals/OrdersToS'
import { TokenModal } from './Modals/TokenModal'
import { FromAndToSection } from './SwapBox/FromAndToSection'
import { TapOrders } from './SwapBox/TapOrders'
import { TapSwap } from './SwapBox/TapSwap'

const Wrapper = styled(DarkCard)`
  width: 100%;
  padding: 16px;
`

const Container = styled.div`
  width: 100%;
`

const Divider = styled.div`
  bwidth: 100&;
  border: 1px solid ${({ theme }) => theme.bg5};
  margin-bottom: 1rem;
`

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

function getNextAveragePrice({
  size,
  sizeDelta,
  hasProfit,
  delta,
  nextPrice,
  isLong,
}: {
  size: any
  sizeDelta: any
  hasProfit: any
  delta: any
  nextPrice: any
  isLong: boolean
}) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return
  }
  const nextSize = size.add(sizeDelta)
  let divisor
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta)
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta)
  }
  if (!divisor || divisor.eq(0)) {
    return
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor)
  return nextAveragePrice
}

interface SwapBoxProps {
  pendingPositions: any
  setPendingPositions: any
  infoTokens: any
  active: boolean
  library: any
  account: any
  fromTokenAddress: any
  setFromTokenAddress: any
  toTokenAddress: any
  setToTokenAddress: any
  swapOption: any
  setSwapOption: any
  positionsMap: any
  pendingTxns: any
  setPendingTxns: any
  tokenSelection: any
  setTokenSelection: any
  setIsConfirming: any
  isConfirming: any
  isPendingConfirmation: any
  setIsPendingConfirmation: any
  flagOrdersEnabled: any
  chainId: any
  nativeTokenAddress: any
  savedSlippageAmount: any
  totalTokenWeights: any
  usdgSupply: any
  orders: any
  savedIsPnlInLeverage: any
  orderBookApproved: any
  positionRouterApproved: any
  isWaitingForPluginApproval: any
  approveOrderBook: any
  approvePositionRouter: any
  setIsWaitingForPluginApproval: any
  isWaitingForPositionRouterApproval: any
  setIsWaitingForPositionRouterApproval: any
  isPluginApproving: any
  isPositionRouterApproving: any
  savedShouldDisableValidationForTesting: any
  minExecutionFee: any
  minExecutionFeeUSD: any
  minExecutionFeeErrorMessage: any
}

export function SwapBox(props: SwapBoxProps) {
  const {
    pendingPositions,
    setPendingPositions,
    infoTokens,
    active,
    library,
    account,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    totalTokenWeights,
    usdgSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableValidationForTesting,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props
  const [openModal, setOpenModal] = useState(0)
  const [fromValue, setFromValue] = useState('')
  const [toValue, setToValue] = useState('')
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true)
  const [isApproving, setIsApproving] = useState(false)
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false)
  const [ordersToaOpen, setOrdersToaOpen] = useState(false)
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account)
  const [triggerRatioValue, setTriggerRatioValue] = useState('')

  const toggleWalletModal = useWalletModalToggle()

  let allowedSlippage = savedSlippageAmount
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT
  }

  const defaultCollateralSymbol = getConstant(chainId, 'defaultCollateralSymbol')
  // TODO hack with useLocalStorageSerializeKey
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    'Short-Collateral-Address',
    getTokenBySymbol(chainId, defaultCollateralSymbol).address
  )
  const isLong = swapOption === LONG
  const isShort = swapOption === SHORT
  const isSwap = swapOption === SWAP

  function getTokenLabel() {
    switch (true) {
      case isLong:
        return t`Long`
      case isShort:
        return t`Short`
      case isSwap:
        return t`Receive`
      default:
        return ''
    }
  }

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, 'Exchange-swap-leverage-option'],
    '2'
  )
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    // @ts-ignore
    [chainId, 'Exchange-swap-leverage-slider-enabled'],
    true
  )

  const hasLeverageOption = isLeverageSliderEnabled && !isNaN(parseFloat(leverageOption))

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, 'Order-option'], MARKET)
  if (!flagOrdersEnabled) {
    orderOption = MARKET
  }

  const onOrderOptionChange = (option: any) => {
    setOrderOption(option)
  }

  const isMarketOrder = orderOption === MARKET
  const orderOptions = isSwap ? SWAP_ORDER_OPTIONS : LEVERAGE_ORDER_OPTIONS

  const [triggerPriceValue, setTriggerPriceValue] = useState('')
  const triggerPriceUsd: any = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS)

  const onTriggerPriceChange = (value: string) => {
    setTriggerPriceValue(value)
  }

  const onTriggerRatioChange = (value: string) => {
    setTriggerRatioValue(value)
  }

  let positionKey
  if (isLong) {
    positionKey = getPositionKey(account, toTokenAddress, toTokenAddress, true, nativeTokenAddress)
  }
  if (isShort) {
    positionKey = getPositionKey(account, shortCollateralAddress, toTokenAddress, false, nativeTokenAddress)
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined
  const hasExistingPosition = existingPosition && existingPosition.size && existingPosition.size.gt(0)

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const tokens = getTokens(chainId)
  const fromTokens = tokens
  const stableTokens = tokens.filter((token) => token.isStable)
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped)
  const shortableTokens = indexTokens.filter((token) => token.isShortable)

  let toTokens = tokens
  if (isLong) {
    toTokens = indexTokens
  }
  if (isShort) {
    toTokens = shortableTokens
  }

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval)

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval)

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false)
      helperToast.success(t`✔️ Orders enabled!`)
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval])

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false)
      helperToast.success(t`✔️ Leverage enabled!`)
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ])

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false)
      helperToast.success(t`✔️ Orders enabled!`)
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval])

  const routerAddress = getContract(chainId, 'Router')
  const tokenAllowanceAddress: any = fromTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : fromTokenAddress
  const { data: tokenAllowance }: any = useSWR(
    active && [active, chainId, tokenAllowanceAddress, 'allowance', account, routerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  )

  const { data: hasOutdatedUi }: any = Api.useHasOutdatedUi()

  const fromToken: any = getToken(chainId, fromTokenAddress)
  const toToken: any = getToken(chainId, toTokenAddress)
  const shortCollateralToken: any = getTokenInfo(infoTokens, shortCollateralAddress)

  const fromTokenInfo: any = getTokenInfo(infoTokens, fromTokenAddress)
  const toTokenInfo: any = getTokenInfo(infoTokens, toTokenAddress)

  const renderAvailableLongLiquidity = () => {
    if (!isLong) {
      return null
    }

    return (
      <RowBetween>
        <MouseoverTooltip
          text={
            <AutoColumn justify="center">
              <ThemedText.SubHeader color="text2">
                <Trans>{t`Max ${toTokenInfo.symbol} long capacity`}</Trans>:
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(toTokenInfo.maxLongCapacity, USD_DECIMALS, 0, true)}
              </ThemedText.SubHeader>
              <ThemedText.SubHeader color="text2">
                <Trans>{t`Current ${toTokenInfo.symbol} long`}</Trans>:
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {formatAmount(toTokenInfo.guaranteedUsd, USD_DECIMALS, 0, true)}
              </ThemedText.SubHeader>
            </AutoColumn>
          }
        >
          <Row align="center">
            <ThemedText.SubHeader mr="0.2rem">
              <Trans>Available Liquidity</Trans>
            </ThemedText.SubHeader>
            <HelpCircle width={16} />
          </Row>
        </MouseoverTooltip>
        <ThemedText.SubHeader>{`$${formatAmount(
          toTokenInfo.maxAvailableLong,
          USD_DECIMALS,
          2,
          true
        )}`}</ThemedText.SubHeader>
      </RowBetween>
    )
  }

  const fromBalance: any = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0)
  const toBalance: any = toTokenInfo ? toTokenInfo.balance : bigNumberify(0)

  const fromAmount: any = parseValue(fromValue, fromToken && fromToken.decimals)
  const toAmount: any = parseValue(toValue, toToken && toToken.decimals)

  const isPotentialWrap = (fromToken.isNative && toToken.isWrapped) || (fromToken.isWrapped && toToken.isNative)
  const isWrapOrUnwrap = isSwap && isPotentialWrap
  const needApproval =
    fromTokenAddress !== ZERO_ADDRESS &&
    tokenAllowance &&
    fromAmount &&
    fromAmount.gt(tokenAllowance) &&
    !isWrapOrUnwrap
  const prevFromTokenAddress = usePrevious(fromTokenAddress)
  const prevNeedApproval = usePrevious(needApproval)
  const prevToTokenAddress = usePrevious(toTokenAddress)

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens)
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd)

  const indexTokenAddress = toTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : toTokenAddress
  const collateralTokenAddress = isLong ? indexTokenAddress : shortCollateralAddress
  const collateralToken = getToken(chainId, collateralTokenAddress)

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo)
  }, [toTokenInfo, fromTokenInfo])

  const maxToTokenOut: any = useMemo(() => {
    const value = toTokenInfo.availableAmount?.gt(toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount))
      ? toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount)
      : toTokenInfo.availableAmount

    if (!value) {
      return bigNumberify(0)
    }

    return value.gt(0) ? value : bigNumberify(0)
  }, [toTokenInfo])

  const maxToTokenOutUSD = useMemo(() => {
    return getUsd(maxToTokenOut, toTokenAddress, false, infoTokens)
  }, [maxToTokenOut, toTokenAddress, infoTokens])

  const maxFromTokenInUSD = useMemo(() => {
    const value = fromTokenInfo.maxUsdgAmount
      ?.sub(fromTokenInfo.usdgAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDG_DECIMALS))

    if (!value) {
      return bigNumberify(0)
    }

    return value.gt(0) ? value : bigNumberify(0)
  }, [fromTokenInfo])

  const maxFromTokenIn = useMemo(() => {
    if (!fromTokenInfo.maxPrice) {
      return bigNumberify(0)
    }
    return maxFromTokenInUSD?.mul(expandDecimals(1, fromTokenInfo.decimals)).div(fromTokenInfo.maxPrice).toString()
  }, [maxFromTokenInUSD, fromTokenInfo])

  let maxSwapAmountUsd = bigNumberify(0)

  if (maxToTokenOutUSD && maxFromTokenInUSD) {
    maxSwapAmountUsd = maxToTokenOutUSD.lt(maxFromTokenInUSD) ? maxToTokenOutUSD : maxFromTokenInUSD
  }

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0)
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS)
    if (ratio?.eq(0)) {
      return bigNumberify(0)
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio)
    }
    return ratio
  }, [triggerRatioValue, triggerRatioInverted])

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false)
      /* helperToast.success(<div>{fromToken.symbol} approved!</div>) */
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromToken.symbol,
    isWaitingForApproval,
    fromToken,
  ])

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress)) {
      setToTokenAddress(swapOption, toTokens[0].address)
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress])

  useEffect(() => {
    if (swapOption !== SHORT) {
      return
    }
    if (toTokenAddress === prevToTokenAddress) {
      return
    }
    for (let i = 0; i < stableTokens.length; i++) {
      const stableToken = stableTokens[i]
      const key = getPositionKey(account, stableToken.address, toTokenAddress, false, nativeTokenAddress)
      const position = positionsMap[key]
      if (position && position.size && position.size.gt(0)) {
        setShortCollateralAddress(position.collateralToken.address)
        return
      }
    }
  }, [
    account,
    toTokenAddress,
    prevToTokenAddress,
    swapOption,
    positionsMap,
    stableTokens,
    nativeTokenAddress,
    shortCollateralAddress,
    setShortCollateralAddress,
  ])

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue('')
          return
        }
        if (toToken) {
          const { amount: nextToAmount } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            toTokenAddress,
            infoTokens,
            undefined,
            !isMarketOrder && triggerRatio,
            usdgSupply,
            totalTokenWeights,
            isSwap
          )

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals)
          setToValue(nextToValue)
        }
        return
      }

      if (!toAmount) {
        setFromValue('')
        return
      }
      if (fromToken) {
        const { amount: nextFromAmount } = getNextFromAmount(
          chainId,
          toAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          !isMarketOrder && triggerRatio,
          usdgSupply,
          totalTokenWeights,
          isSwap
        )
        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals)
        setFromValue(nextFromValue)
      }
    }

    const updateLeverageAmounts = () => {
      if (!hasLeverageOption) {
        return
      }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue('')
          return
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)
        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = parseInt(leverageOption) * BASIS_POINTS_DIVISOR
          const toTokenPriceUsd =
            !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice

          const { feeBasisPoints } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdgSupply,
            totalTokenWeights,
            isSwap
          )

          let fromUsdMinAfterFee = fromUsdMin
          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)
          }

          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR)
          const toDenominator = bigNumberify(MARGIN_FEE_BASIS_POINTS)
            .mul(leverageMultiplier)
            .add(bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR))

          const nextToUsd = toNumerator.div(toDenominator)

          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd)

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals)

          setToValue(nextToValue)
        }
        return
      }

      if (!toAmount) {
        setFromValue('')
        return
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
      if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = parseInt(leverageOption) * BASIS_POINTS_DIVISOR

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier)

        let fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)

        const { feeBasisPoints } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        )

        if (feeBasisPoints) {
          const swapFees = baseFromAmountUsd.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
          fees = fees.add(swapFees)
        }

        const nextFromUsd = baseFromAmountUsd.add(fees)

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice)

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals)

        setFromValue(nextFromValue)
      }
    }

    if (isSwap) {
      updateSwapAmounts()
    }

    if (isLong || isShort) {
      updateLeverageAmounts()
    }
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isSwap,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    triggerRatio,
    hasLeverageOption,
    usdgSupply,
    totalTokenWeights,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
  ])

  let entryMarkPrice: BigNumber | undefined
  let exitMarkPrice
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice
  }

  let leverage = bigNumberify(0)
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees))
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit

    if (isMarketOrder) {
      nextDelta = existingPosition.delta
      nextHasProfit = existingPosition.hasProfit
    } else {
      const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), existingPosition)
      nextDelta = data.delta
      nextHasProfit = data.hasProfit
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    })
  }

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : bigNumberify(0),
    collateral: hasExistingPosition ? existingPosition.collateral : bigNumberify(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : bigNumberify(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : bigNumberify(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  })

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : bigNumberify(0)
    const sizeDelta = toUsdMax ? toUsdMax : bigNumberify(0)
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    })
  } else if (hasLeverageOption) {
    leverage = bigNumberify(parseInt(leverageOption) * BASIS_POINTS_DIVISOR)
  }

  const getSwapError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Swaps disabled, pending ${getChainName(chainId)} upgrade`]
    }

    if (fromTokenAddress === toTokenAddress) {
      return [t`Select different tokens`]
    }

    if (!isMarketOrder) {
      if ((toToken.isStable || toToken.isUsdg) && (fromToken.isStable || fromToken.isUsdg)) {
        return [t`Select different tokens`]
      }

      if (fromToken.isNative && toToken.isWrapped) {
        return [t`Select different tokens`]
      }

      if (toToken.isNative && fromToken.isWrapped) {
        return [t`Select different tokens`]
      }
    }

    if (!fromAmount || fromAmount.eq(0)) {
      return [t`Enter an amount`]
    }
    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`]
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return [t`Incorrect network`]
    }
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`]
    }

    const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)

    if (!isMarketOrder) {
      if (!triggerRatioValue || triggerRatio?.eq(0)) {
        return [t`Enter a price`]
      }

      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo)
      if (currentRate && currentRate.lt(triggerRatio)) {
        return triggerRatioInverted ? [t`Price below Mark Price`] : [t`Price above Mark Price`]
      }
    }

    if (!isMarketOrder && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return [t`Min order: 10 USD`]
    }

    if (
      !isWrapOrUnwrap &&
      toToken &&
      toTokenAddress !== USDG_ADDRESS &&
      toTokenInfo &&
      toTokenInfo.availableAmount &&
      toAmount.gt(toTokenInfo.availableAmount)
    ) {
      return [t`Insufficient liquidity`]
    }
    if (
      !isWrapOrUnwrap &&
      toAmount &&
      toTokenInfo.bufferAmount &&
      toTokenInfo.poolAmount &&
      toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(toAmount))
    ) {
      return [t`Insufficient liquidity`]
    }

    if (
      fromUsdMin &&
      fromTokenInfo.maxUsdgAmount &&
      fromTokenInfo.maxUsdgAmount.gt(0) &&
      fromTokenInfo.usdgAmount &&
      fromTokenInfo.maxPrice
    ) {
      const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS)
      const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)

      if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
        return [`${fromTokenInfo.symbol} pool exceeded`]
      }
    }

    return [false]
  }

  const getLeverageError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Leverage disabled, pending ${getChainName(chainId)} upgrade`]
    }
    if (hasOutdatedUi) {
      return [t`Page outdated, please refresh`]
    }

    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`]
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)
    if (toTokenInfo && toTokenInfo.isStable) {
      if (swapOption === LONG) {
        return [t`Longing ${toTokenInfo.symbol} not supported`]
      } else {
        return [t`Shorting ${toTokenInfo.symbol} not supported`]
      }
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenInfo.symbol} balance`]
    }

    if (leverage && leverage.eq(0)) {
      return [t`Enter an amount`]
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd?.eq(0))) {
      return [t`Enter a price`]
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return [t`Min order: 10 USD`]
    }

    if (leverage && leverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: 1.1x`]
    }

    if (leverage && leverage.gt(10.5 * BASIS_POINTS_DIVISOR)) {
      return [t`Max leverage: 10.5x`]
    }

    if (!isMarketOrder && entryMarkPrice && triggerPriceUsd && !savedShouldDisableValidationForTesting) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return [t`Price above Mark Price`]
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return [t`Price below Mark Price`]
      }
    }

    if (isLong) {
      let requiredAmount = toAmount
      if (fromTokenAddress !== toTokenAddress) {
        const { amount: swapAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        )
        requiredAmount = requiredAmount.add(swapAmount)

        if (toToken && toTokenAddress !== USDG_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return [t`Liquidity data not loaded`]
          }
          if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return [t`Insufficient liquidity`]
          }
        }

        if (
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return [t`Insufficient liquidity`, true, 'BUFFER']
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS)
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, 'MAX_USDG']
          }
        }
      }

      if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
        return [t`Insufficient liquidity`]
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals))
        if (
          toTokenInfo.maxGlobalLongSize &&
          toTokenInfo.maxGlobalLongSize.gt(0) &&
          toTokenInfo.maxAvailableLong &&
          sizeUsd.gt(toTokenInfo.maxAvailableLong)
        ) {
          return [t`Max ${toTokenInfo.symbol} long exceeded`]
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = bigNumberify(0)
      if (fromTokenAddress !== shortCollateralAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          shortCollateralAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        )
        stableTokenAmount = nextToAmount
        if (stableTokenAmount?.gt(shortCollateralToken.availableAmount)) {
          return [t`Insufficient liquidity, change "Collateral In"`]
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [t`Insufficient liquidity, change "Collateral In"`, true, 'BUFFER']
        }

        if (
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS)
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [t`${fromTokenInfo.symbol} pool exceeded, try different token`, true, 'MAX_USDG']
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [t`Fetching token info...`]
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals))
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice)

      if (!toTokenInfo.maxAvailableShort) {
        return [t`Liquidity data not loaded`]
      }

      if (
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [t`Max ${toTokenInfo.symbol} short exceeded`]
      }

      stableTokenAmount = stableTokenAmount?.add(sizeTokens)
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [t`Insufficient liquidity, change "Collateral In"`]
      }
    }

    return [false]
  }

  const getToLabel = () => {
    if (isSwap) {
      return t`Receive`
    }
    if (isLong) {
      return t`Long`
    }
    return t`Short`
  }

  const getError = () => {
    if (isSwap) {
      return getSwapError()
    }
    return getLeverageError()
  }

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false
    }
    if (isStopOrder) {
      return true
    }
    if (!active) {
      return true
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return false
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false
    }
    if (isPositionRouterApproving) {
      return false
    }
    if (isApproving) {
      return false
    }
    if (isSubmitting) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    if (isStopOrder) {
      return t`Open a position`
    }
    if (!active) {
      return t`Connect Wallet`
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return error
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage...`
    }
    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`
    }
    if (needPositionRouterApproval) {
      return t`Enable Leverage`
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`
    }
    if (isApproving) {
      return t`Approving ${fromToken.symbol}...`
    }
    if (needApproval) {
      return t`Approve ${fromToken.symbol}`
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling Orders...`
    }
    if (isPluginApproving) {
      return t`Enabling Orders...`
    }
    if (needOrderBookApproval) {
      return t`Enable Orders`
    }

    if (!isMarketOrder) return `Create ${orderOption?.charAt(0) + orderOption?.substring(1).toLowerCase()} Order`

    if (isSwap) {
      if (toUsdMax && toUsdMax.lt(fromUsdMin?.mul(95).div(100))) {
        return t`High Slippage, Swap Anyway`
      }
      return 'Swap'
    }

    if (isLong) {
      const indexTokenInfo = getTokenInfo(infoTokens, toTokenAddress)
      if (indexTokenInfo && indexTokenInfo.minPrice) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          indexTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          isSwap
        )
        const nextToAmountUsd = nextToAmount
          .mul(indexTokenInfo.minPrice)
          .div(expandDecimals(1, indexTokenInfo.decimals))
        if (fromTokenAddress === USDG_ADDRESS && nextToAmountUsd.lt(fromUsdMin?.mul(98).div(100))) {
          return t`High FLP Slippage, Long Anyway`
        }
      }
      return t`Long ${toToken.symbol}`
    }

    return t`Short ${toToken.symbol}`
  }

  const onSelectFromToken = (token: any) => {
    setFromTokenAddress(swapOption, token.address)
    setIsWaitingForApproval(false)

    if (isShort && token.isStable) {
      setShortCollateralAddress(token.address)
    }
  }

  const onSelectShortCollateralAddress = (token: any) => {
    setShortCollateralAddress(token.address)
  }

  const onSelectToToken = (token: any) => {
    setToTokenAddress(swapOption, token.address)
  }

  const onFromValueChange = (value: string) => {
    setAnchorOnFromAmount(true)
    setFromValue(value)
  }

  const onToValueChange = (value: string) => {
    setAnchorOnFromAmount(false)
    setToValue(value)
  }

  const switchTokens = () => {
    if (fromAmount && toAmount) {
      if (anchorOnFromAmount) {
        setToValue(formatAmountFree(fromAmount, fromToken.decimals, 8))
      } else {
        setFromValue(formatAmountFree(toAmount, toToken.decimals, 8))
      }
      setAnchorOnFromAmount(!anchorOnFromAmount)
    }
    setIsWaitingForApproval(false)

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
    updatedTokenSelection[swapOption] = {
      from: toTokenAddress,
      to: fromTokenAddress,
    }
    setTokenSelection(updatedTokenSelection)
  }

  const wrap = async () => {
    setIsSubmitting(true)

    const contract = new ethers.Contract(nativeTokenAddress, WETH, library.getSigner())
    callContract(chainId, contract, 'deposit', {
      value: fromAmount,
      sentMsg: t`Swap submitted!`,
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } ${t`for`} ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: t`Swap failed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsSubmitting(false)
      })
      .catch(() => {
        setIsSubmitting(false)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const unwrap = async () => {
    setIsSubmitting(true)

    const contract = new ethers.Contract(nativeTokenAddress, WETH, library.getSigner())
    callContract(chainId, contract, 'withdraw', [fromAmount], {
      sentMsg: t`Swap submitted!`,
      failMsg: t`Swap failed.`,
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } ${t`for`} ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsSubmitting(false)
      })
      .catch(() => {
        setIsSubmitting(false)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const swap = async () => {
    if (fromToken.isNative && toToken.isWrapped) {
      wrap()
      return
    }

    if (fromTokenAddress.isWrapped && toToken.isNative) {
      unwrap()
      return
    }

    setIsSubmitting(true)
    let path = [fromTokenAddress, toTokenAddress]
    if (anchorOnFromAmount) {
      const { path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      )
      if (multiPath) {
        path = multiPath
      }
    } else {
      const { path: multiPath } = getNextFromAmount(
        chainId,
        toAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      )
      if (multiPath) {
        path = multiPath
      }
    }

    let method
    let contract
    let value
    let params
    let minOut
    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false)
      setIsPendingConfirmation(true)
      helperToast.error(
        `${t`❗ Leave at least`} ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(
          chainId,
          'nativeTokenSymbol'
        )} ${t`for gas`}`
      )
      return
    }

    if (!isMarketOrder) {
      minOut = toAmount
      Api.createSwapOrder(chainId, library, path, fromAmount, minOut, triggerRatio, nativeTokenAddress, {
        sentMsg: t`Swap Order submitted!`,
        successMsg: t`Swap Order created!`,
        failMsg: t`Swap Order creation failed!`,
        pendingTxns,
        setPendingTxns,
      })
        .then(() => {
          setIsSubmitting(false)
          setIsConfirming(false)
        })
        .catch(() => {
          setIsSubmitting(false)
        })
        .finally(() => {
          setIsPendingConfirmation(false)
        })
      return
    }

    path = replaceNativeTokenAddress(path, nativeTokenAddress)
    method = 'swap'
    value = bigNumberify(0)
    if (toTokenAddress === ZERO_ADDRESS) {
      method = 'swapTokensToETH'
    }

    minOut = toAmount?.mul(BASIS_POINTS_DIVISOR - allowedSlippage).div(BASIS_POINTS_DIVISOR)
    params = [path, fromAmount, minOut, account]
    if (fromTokenAddress === ZERO_ADDRESS) {
      method = 'swapETHToTokens'
      value = fromAmount
      params = [path, minOut, account]
    }
    contract = new ethers.Contract(routerAddress, Router, library.getSigner())

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Swap ${!isMarketOrder ? ` ${t`order`} ` : ''} ${t`submitted`}!`,
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } ${t`for`} ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: t`Swap failed!`,
      setPendingTxns,
    })
      .then(() => {
        setIsSubmitting(false)
        setIsConfirming(false)
      })
      .catch(() => {
        setIsSubmitting(false)
      })
      .finally(() => {
        setIsPendingConfirmation(false)
      })
  }

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress]

    if (path[0] === USDG_ADDRESS) {
      if (isLong) {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens)
        path.push(stableToken?.address)
      } else {
        path.push(shortCollateralAddress)
      }
    }

    const minOut = 0
    const indexToken = getToken(chainId, indexTokenAddress)
    const successMsg = `
    ${t`Created limit order for`} ${indexToken.symbol} ${isLong ? t`Long` : t`Short`}: ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD!
    `
    return Api.createIncreaseOrder(
      chainId,
      library,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      minOut,
      toUsdMax,
      collateralTokenAddress,
      isLong,
      triggerPriceUsd,
      {
        pendingTxns,
        setPendingTxns,
        sentMsg: t`Limit order submitted!`,
        successMsg,
        failMsg: t`Limit order creation failed!`,
      }
    )
      .then(() => {
        setIsConfirming(false)
        setIsSubmitting(false)
      })
      .finally(() => {
        setIsPendingConfirmation(false)
      })
  }

  let referralCode = ethers.constants.HashZero
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode
  }

  const increasePosition = async () => {
    setIsSubmitting(true)
    const tokenAddress0 = fromTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : fromTokenAddress
    const indexTokenAddress = toTokenAddress === ZERO_ADDRESS ? nativeTokenAddress : toTokenAddress
    let path = [indexTokenAddress] // assume long
    if (toTokenAddress !== fromTokenAddress) {
      path = [tokenAddress0, indexTokenAddress]
    }

    if (fromTokenAddress === ZERO_ADDRESS && toTokenAddress === nativeTokenAddress) {
      path = [nativeTokenAddress]
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === ZERO_ADDRESS) {
      path = [nativeTokenAddress]
    }

    if (isShort) {
      path = [shortCollateralAddress]
      if (tokenAddress0 !== shortCollateralAddress) {
        path = [tokenAddress0, shortCollateralAddress]
      }
    }

    const refPrice = isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage
    const priceLimit = refPrice?.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR)

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0)

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDG_ADDRESS && isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      )
      if (nextToAmount.eq(0)) {
        helperToast.error(t`Insufficient liquidity`)
        return
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath)
      }
    }

    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      0, // _minOut
      toUsdMax, // _sizeDelta
      isLong, // _isLong
      priceLimit, // _acceptablePrice
      /* minExecutionFee, // _executionFee */
      referralCode, // _referralCode
      /* ZERO_ADDRESS, // _callbackTarget */
    ]

    /* let method = 'createIncreasePosition' */
    let method = 'increasePosition'
    let value /* = minExecutionFee */
    if (fromTokenAddress === ZERO_ADDRESS) {
      /* method = 'createIncreasePositionETH' */
      method = 'increasePositionETH'
      value = boundedFromAmount /* ?.add(minExecutionFee) */
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        toUsdMax, // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        /* minExecutionFee, // _executionFee */
        referralCode, // _referralCode
        /*  ZERO_ADDRESS, // _callbackTarget */
      ]
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false)
      setIsPendingConfirmation(false)
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, 'nativeTokenSymbol')} for gas`
      )
      return
    }

    /* const contractAddress = getContract(chainId, 'PositionRouter') */
    const contractAddress = getContract(chainId, 'PositionManager')
    /* const contract = new ethers.Contract(contractAddress, PositionRouter, library.getSigner()) */
    const contract = new ethers.Contract(contractAddress, PositionManager, library.getSigner())
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress)
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexToken.symbol
    const successMsg = `${t`Requested increase of`} ${tokenSymbol} ${isLong ? t`Long` : t`Short`} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD.`

    callContract(chainId, contract, method, params, {
      value,
      setPendingTxns,
      sentMsg: t`${isLong ? 'Long' : 'Short'} submitted!`,
      failMsg: t`${isLong ? 'Long' : 'Short'} failed!`,
      successMsg,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async () => {
        setIsConfirming(false)

        const key = getPositionKey(account, path[path.length - 1], indexTokenAddress, isLong)
        let nextSize = toUsdMax
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax)
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        }

        setPendingPositions({ ...pendingPositions })
      })
      .finally(() => {
        setIsSubmitting(false)
        setIsPendingConfirmation(false)
      })
  }

  const onSwapOptionChange = (opt: any) => {
    setSwapOption(opt)
    if (orderOption === STOP) {
      setOrderOption(MARKET)
    }
    setAnchorOnFromAmount(true)
    setFromValue('')
    setToValue('')
    setTriggerPriceValue('')
    setTriggerRatioValue('')

    if (opt === SHORT && infoTokens) {
      const fromToken = getToken(chainId, tokenSelection[opt].from)
      if (fromToken && fromToken.isStable) {
        setShortCollateralAddress(fromToken.address)
      } else {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens)
        setShortCollateralAddress(stableToken?.address)
      }
    }
  }

  const onConfirmationClick = () => {
    if (!account) {
      toggleWalletModal()
      return
    }

    if (needOrderBookApproval) {
      approveOrderBook()
      return
    }

    setIsPendingConfirmation(true)

    if (isSwap) {
      swap()
      return
    }

    if (orderOption === LIMIT) {
      createIncreaseOrder()
      return
    }

    increasePosition()
  }

  function approveFromToken() {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true)
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    })
  }

  const onClickPrimary = () => {
    if (isStopOrder) {
      setOrderOption(MARKET)
      return
    }

    if (!account) {
      toggleWalletModal()
      return
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent`,
        failMsg: t`Enable leverage failed`,
      })
      return
    }

    if (needApproval) {
      approveFromToken()
      return
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true)
      return
    }

    const [, modal, errorCode] = getError()

    if (modal) {
      console.log(modal)
      setModalError(errorCode)
      return
    }

    if (isSwap) {
      if (fromTokenAddress === ZERO_ADDRESS && toTokenAddress === nativeTokenAddress) {
        wrap()
        return
      }

      if (fromTokenAddress === nativeTokenAddress && toTokenAddress === ZERO_ADDRESS) {
        unwrap()
        return
      }
    }

    setIsConfirming(true)
    setIsHigherSlippageAllowed(false)
  }

  const isStopOrder = orderOption === STOP
  const showFromAndToSection = !isStopOrder
  const showTriggerPriceSection = !isSwap && !isMarketOrder && !isStopOrder
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder

  let fees
  let feesUsd
  let feeBps
  let swapFees
  let positionFee
  if (isSwap) {
    if (fromAmount) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        isSwap
      )
      if (feeBasisPoints !== undefined) {
        fees = fromAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
        const feeTokenPrice =
          fromTokenInfo.address === USDG_ADDRESS ? expandDecimals(1, USD_DECIMALS) : fromTokenInfo.maxPrice
        feesUsd = fees.mul(feeTokenPrice).div(expandDecimals(1, fromTokenInfo.decimals))
      }
      feeBps = feeBasisPoints
    }
  } else if (toUsdMax) {
    positionFee = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    feesUsd = positionFee

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdgSupply,
      totalTokenWeights,
      isSwap
    )
    if (feeBasisPoints) {
      swapFees = fromUsdMin?.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
      feesUsd = feesUsd.add(swapFees)
    }
    feeBps = feeBasisPoints
  }

  const leverageMarks = {
    2: '2x',
    4: '4x',
    6: '6x',
    8: '8x',
    10: '10x',
    /* 15: '15x',
    20: '20x',
    25: '25x',
    30: '30x', */
  }

  if (!fromToken || !toToken) {
    return null
  }

  let hasZeroBorrowFee = false
  let borrowFeeText
  if (isLong && toTokenInfo && toTokenInfo.fundingRate) {
    borrowFeeText = formatAmount(toTokenInfo.fundingRate, 4, 4) + '% / 1h'
    if (toTokenInfo.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }
  if (isShort && shortCollateralToken && shortCollateralToken.fundingRate) {
    borrowFeeText = formatAmount(shortCollateralToken.fundingRate, 4, 4) + '% / 1h'
    if (shortCollateralToken.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromBalance) {
      return
    }

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance
    setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals))
    setAnchorOnFromAmount(true)
  }

  function shouldShowMaxButton() {
    if (!fromToken || !fromBalance) {
      return false
    }
    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals)
  }

  return (
    <Wrapper>
      <TapSwap options={SWAP_OPTIONS} option={swapOption} onChange={onSwapOptionChange} />
      {flagOrdersEnabled && <TapOrders options={orderOptions} option={orderOption} onChange={onOrderOptionChange} />}
      <Container>
        <AutoColumn gap="1rem">
          {showFromAndToSection && (
            <FromAndToSection
              fromUsdMin={fromUsdMin}
              fromBalance={fromBalance}
              fromToken={fromToken}
              fromValue={fromValue}
              onFromValueChange={onFromValueChange}
              shouldShowMaxButton={shouldShowMaxButton()}
              setFromValueToMaximumAvailable={setFromValueToMaximumAvailable}
              switchTokens={switchTokens}
              toUsdMax={toUsdMax}
              ToLabel={getToLabel()}
              toBalance={toBalance}
              toToken={toToken}
              isSwap={isSwap}
              isLong={isLong}
              isShort={isShort}
              hasLeverageOption={hasLeverageOption}
              leverageOption={leverageOption}
              toValue={toValue}
              onToValueChange={onToValueChange}
              chainId={chainId}
              onSelectFromToken={onSelectFromToken}
              fromTokens={fromTokens}
              infoTokens={infoTokens}
              onSelectToToken={onSelectToToken}
              toTokens={toTokens}
              tokenLabel={getTokenLabel()}
            />
          )}
          {showTriggerRatioSection && (
            <LightCard $borderRadius="8px">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={triggerRatioValue}
                  onUserInput={(val) => {
                    onTriggerRatioChange(val)
                  }}
                />
                {(() => {
                  if (!toTokenInfo) return
                  if (!fromTokenInfo) return
                  const [tokenA, tokenB] = triggerRatioInverted
                    ? [toTokenInfo, fromTokenInfo]
                    : [fromTokenInfo, toTokenInfo]
                  return (
                    <ThemedText.MediumHeader mr="4rem">
                      {tokenA.symbol}&nbsp;per&nbsp;{tokenB.symbol}
                    </ThemedText.MediumHeader>
                  )
                })()}
              </RowBetween>
              <RowBetween mt="0.5rem">
                <ThemedText.Body>
                  <Trans>Price</Trans>
                </ThemedText.Body>
                <ThemedText.Body>
                  {fromTokenInfo && toTokenInfo && (
                    <div
                      onClick={() => {
                        setTriggerRatioValue(
                          formatAmountFree(
                            getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted),
                            USD_DECIMALS,
                            10
                          )
                        )
                      }}
                    >
                      {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 4)}
                    </div>
                  )}
                </ThemedText.Body>
              </RowBetween>
            </LightCard>
          )}
          {showTriggerPriceSection && (
            <LightCard $borderRadius="8px">
              <RowBetween>
                <StyledInput
                  className="amount"
                  type="number"
                  step={0.0}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={triggerPriceValue}
                  onUserInput={(val) => {
                    onTriggerPriceChange(val)
                  }}
                />
                <ThemedText.MediumHeader>USD</ThemedText.MediumHeader>
              </RowBetween>
              <RowBetween mt="0.5rem">
                <ThemedText.Body>
                  <Trans>Price</Trans>
                </ThemedText.Body>
                <ThemedText.Body>
                  <div
                    onClick={() => {
                      setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2))
                    }}
                  >
                    <Trans>Mark</Trans>: {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}
                  </div>
                </ThemedText.Body>
              </RowBetween>
            </LightCard>
          )}
          {isSwap && (
            <RowBetween>
              <ThemedText.SubHeader>
                <Trans>Fees</Trans>
              </ThemedText.SubHeader>
              <ThemedText.SubHeader>
                {!fees && '-'}
                {fees && (
                  <div>
                    {formatAmount(feeBps, 2, 2, false)}%&nbsp; ({formatAmount(fees, fromToken.decimals, 4, true)}{' '}
                    {fromToken.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                  </div>
                )}
              </ThemedText.SubHeader>
            </RowBetween>
          )}
          {(isLong || isShort) && !isStopOrder && (
            <>
              <RowBetween>
                <ThemedText.Body>
                  <Trans>Leverage slider</Trans>
                </ThemedText.Body>
                <ThemedText.Body
                  width="fit-content"
                  onClick={() => setIsLeverageSliderEnabled(!isLeverageSliderEnabled)}
                  color="primary1"
                >
                  {isLeverageSliderEnabled ? <CheckSquare /> : <Square />}
                </ThemedText.Body>
              </RowBetween>
              {isLeverageSliderEnabled && (
                <Row mb="1rem">
                  <Slider
                    min={1.1}
                    max={10}
                    step={0.1}
                    marks={leverageMarks}
                    /* handle={leverageSliderHandle} */
                    onChange={(value) => setLeverageOption(value)}
                    value={leverageOption}
                    defaultValue={leverageOption}
                  />
                </Row>
              )}
              {isShort && (
                <RowBetween>
                  <ThemedText.SubHeader mr="0.2rem">
                    <Trans>Collateral In</Trans>
                  </ThemedText.SubHeader>
                  <ButtonDropdownText onClick={() => setOpenModal(1)}>
                    <ThemedText.SubHeader>USD</ThemedText.SubHeader>
                  </ButtonDropdownText>
                </RowBetween>
              )}
              {isLong && (
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <AutoColumn gap="0.5rem">
                        <ThemedText.SubHeader justifyContent="justify">
                          <Trans>
                            A snapshot of the USD value of your {existingPosition?.collateralToken?.symbol} collateral
                            is taken when the position is opened
                          </Trans>
                          .
                        </ThemedText.SubHeader>
                        <ThemedText.SubHeader justifyContent="justify">
                          <Trans>
                            When closing the position, you can select which token you would like to receive the profits
                            in
                          </Trans>
                          .
                        </ThemedText.SubHeader>
                      </AutoColumn>
                    }
                  >
                    <Row align="center">
                      <ThemedText.SubHeader mr="0.2rem">
                        <Trans>Collateral In</Trans>
                      </ThemedText.SubHeader>
                      <HelpCircle width={16} />
                    </Row>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>USD</ThemedText.SubHeader>
                </RowBetween>
              )}
              <RowBetween>
                <ThemedText.SubHeader>
                  <Trans>Leverage</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  <ArrowWrapper>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <>
                        {formatAmount(existingPosition.leverage, 4, 2)}x
                        <ArrowRight width={16} color="grey" />
                      </>
                    )}
                    {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                    {!toAmount && leverage && leverage.gt(0) && `-`}
                    {leverage && leverage.eq(0) && `-`}
                  </ArrowWrapper>
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader>
                  <Trans>Entry Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  <ArrowWrapper>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <>
                        ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                        <ArrowRight width={16} color="grey" />
                      </>
                    )}
                    {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                    {!nextAveragePrice && `-`}
                  </ArrowWrapper>
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <ThemedText.SubHeader>
                  <Trans>Liq Price</Trans>
                </ThemedText.SubHeader>
                <ThemedText.SubHeader>
                  <ArrowWrapper>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <>
                        ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                        <ArrowRight width={16} color="grey" />
                      </>
                    )}
                    {toAmount &&
                      displayLiquidationPrice &&
                      `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                    {!toAmount && displayLiquidationPrice && `-`}
                    {!displayLiquidationPrice && `-`}
                  </ArrowWrapper>
                </ThemedText.SubHeader>
              </RowBetween>
              <RowBetween>
                <MouseoverTooltip
                  text={
                    <AutoColumn>
                      {swapFees && (
                        <div>
                          <ThemedText.SubHeader>
                            <Trans>{collateralToken.symbol} is required for collateral</Trans>
                          </ThemedText.SubHeader>{' '}
                          <RowBetween>
                            <ThemedText.SubHeader>
                              Swap {fromToken.symbol} <Trans>to</Trans> {collateralToken.symbol} <Trans>Fee</Trans>
                            </ThemedText.SubHeader>
                            <ThemedText.SubHeader>{formatAmount(swapFees, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                          </RowBetween>
                        </div>
                      )}
                      <RowBetween>
                        <ThemedText.SubHeader justifyContent="justify">
                          <Trans>Position Fee</Trans>
                          <br />
                          <Trans>(0.1% of position size)</Trans>
                        </ThemedText.SubHeader>
                        {feesUsd && (
                          <ThemedText.SubHeader>
                            {formatAmount(positionFee, USD_DECIMALS, 2, true)}
                          </ThemedText.SubHeader>
                        )}
                      </RowBetween>
                    </AutoColumn>
                  }
                >
                  <Row align="center">
                    <ThemedText.SubHeader mr="0.2rem">
                      <Trans>Fees</Trans>
                    </ThemedText.SubHeader>
                    <HelpCircle width={16} />
                  </Row>
                </MouseoverTooltip>
                <ThemedText.SubHeader>
                  {!feesUsd && '-'}
                  {feesUsd && `$${formatAmount(feesUsd, USD_DECIMALS, 2, true)}`}
                </ThemedText.SubHeader>
              </RowBetween>
            </>
          )}
          {isStopOrder && (
            <Container>
              <LightCard $borderRadius="8px" mt="1rem">
                <ThemedText.SubHeader>
                  <Trans>Take-profit and stop-loss orders can be set after opening a position</Trans>.
                </ThemedText.SubHeader>
                <ThemedText.SubHeader mt="0.5rem">
                  <Trans>
                    There will be a Close button on each position row, clicking this will display the option to set
                    trigger orders
                  </Trans>
                  .
                </ThemedText.SubHeader>
              </LightCard>
            </Container>
          )}
          <ButtonPrimary mt="1rem" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </ButtonPrimary>
          {isSwap && (
            <LightGreyCard mt="1rem">
              <ThemedText.MediumHeader>Swap</ThemedText.MediumHeader>
              <Divider />
              <AutoColumn gap="0.2rem">
                <RowBetween>
                  <ThemedText.SubHeader mr="0.2rem">
                    {fromToken.symbol} <Trans>Price</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    ${fromTokenInfo && formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                </RowBetween>
                <RowBetween>
                  <ThemedText.SubHeader mr="0.2rem">
                    {toToken.symbol} <Trans>Price</Trans>
                  </ThemedText.SubHeader>
                  <ThemedText.SubHeader>
                    ${toTokenInfo && formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)}
                  </ThemedText.SubHeader>
                </RowBetween>
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <AutoColumn justify="center">
                        <ThemedText.SubHeader color="text2">
                          Max {fromTokenInfo.symbol} <Trans>in</Trans>:
                        </ThemedText.SubHeader>
                        <ThemedText.SubHeader>
                          <AutoColumn>
                            {formatAmount(maxFromTokenIn, fromTokenInfo.decimals, 0, true)} ${fromTokenInfo.symbol}
                            {`($${formatAmount(maxFromTokenInUSD, USD_DECIMALS, 0, true)})`}
                          </AutoColumn>
                        </ThemedText.SubHeader>

                        <ThemedText.SubHeader color="text2">
                          Max {toTokenInfo.symbol} <Trans>out</Trans>:
                        </ThemedText.SubHeader>
                        <ThemedText.SubHeader>
                          <AutoColumn>
                            {formatAmount(maxToTokenOut, toTokenInfo.decimals, 0, true)} ${toTokenInfo.symbol}
                            {`($${formatAmount(maxToTokenOutUSD, USD_DECIMALS, 0, true)})`}
                          </AutoColumn>
                        </ThemedText.SubHeader>
                      </AutoColumn>
                    }
                  >
                    <Row align="center">
                      <ThemedText.SubHeader mr="0.2rem">
                        <Trans>Available Liquidity</Trans>
                      </ThemedText.SubHeader>
                      <HelpCircle width={16} />
                    </Row>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>${formatAmount(maxSwapAmountUsd, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                </RowBetween>
                {!isMarketOrder && (
                  <RowBetween>
                    <ThemedText.SubHeader mr="0.2rem">
                      <Trans>Price</Trans>
                    </ThemedText.SubHeader>
                    <ThemedText.SubHeader>
                      {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromToken, toToken)}
                    </ThemedText.SubHeader>
                  </RowBetween>
                )}
              </AutoColumn>
            </LightGreyCard>
          )}
          {(isLong || isShort) && (
            <LightGreyCard mt="1rem">
              <ThemedText.MediumHeader>
                {isLong ? t`Long` : t`Short`}&nbsp;{toToken.symbol}
              </ThemedText.MediumHeader>
              <Divider />
              <AutoColumn gap="0.2rem">
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader justifyContent="justify">
                        <Trans>The position will be opened at</Trans>{' '}
                        {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD <Trans>with a max slippage of</Trans>{' '}
                        {parseFloat(savedSlippageAmount / 100.0).toFixed(2)}% .
                        <br />
                        <Trans>The slippage amount can be configured on nut icon</Trans>.
                      </ThemedText.SubHeader>
                    }
                  >
                    <Row align="center">
                      <ThemedText.SubHeader mr="0.2rem">
                        <Trans>Entry Price</Trans>
                      </ThemedText.SubHeader>
                      <HelpCircle width={16} />
                    </Row>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>${formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                </RowBetween>
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader justifyContent="justify">
                        <Trans>
                          If you have an existing position, the position will be closed at{' '}
                          {formatAmount(exitMarkPrice, USD_DECIMALS, 2, true)} USD
                        </Trans>
                        .
                        <br />
                        <Trans>This exit price will change with the price of the asset</Trans>.
                      </ThemedText.SubHeader>
                    }
                  >
                    <Row align="center">
                      <ThemedText.SubHeader mr="0.2rem">
                        <Trans>Exit Price</Trans>
                      </ThemedText.SubHeader>
                      <HelpCircle width={16} />
                    </Row>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>${formatAmount(exitMarkPrice, USD_DECIMALS, 2, true)}</ThemedText.SubHeader>
                </RowBetween>
                <RowBetween>
                  <MouseoverTooltip
                    text={
                      <ThemedText.SubHeader justifyContent="justify">
                        {hasZeroBorrowFee && (
                          <div>
                            {isLong && (
                              <Trans>There are more shorts than longs, borrow fees for longing is currently zero</Trans>
                            )}
                            {isShort && (
                              <Trans>
                                There are more longs than shorts, borrow fees for shorting is currently zero
                              </Trans>
                            )}
                          </div>
                        )}
                        {!hasZeroBorrowFee && (
                          <div>
                            <Trans>
                              The borrow fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per
                              hour
                            </Trans>
                            .
                            <br />
                            {isShort && <Trans>You can change the Collateral In token above to find lower fees.</Trans>}
                          </div>
                        )}
                      </ThemedText.SubHeader>
                    }
                  >
                    <Row align="center">
                      <ThemedText.SubHeader mr="0.2rem">
                        <Trans>Borrow Fee</Trans>
                      </ThemedText.SubHeader>
                      <HelpCircle width={16} />
                    </Row>
                  </MouseoverTooltip>
                  <ThemedText.SubHeader>{borrowFeeText}</ThemedText.SubHeader>
                </RowBetween>
                {renderAvailableLongLiquidity()}
                {isShort && toTokenInfo.hasMaxAvailableShort && (
                  <RowBetween>
                    <MouseoverTooltip
                      text={
                        <AutoColumn justify="center">
                          <ThemedText.SubHeader color="text2">
                            <Trans>Max {toTokenInfo.symbol} short capacity</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>
                            {formatAmount(toTokenInfo.maxGlobalShortSize, USD_DECIMALS, 0, true)}
                          </ThemedText.SubHeader>

                          <ThemedText.SubHeader color="text2">
                            <Trans>Current {toTokenInfo.symbol} shorts</Trans>:
                          </ThemedText.SubHeader>
                          <ThemedText.SubHeader>
                            {formatAmount(toTokenInfo.globalShortSize, USD_DECIMALS, 0, true)}
                          </ThemedText.SubHeader>
                        </AutoColumn>
                      }
                      show={showTooltip === 6}
                    >
                      <Row align="center">
                        <ThemedText.SubHeader mr="0.2rem">
                          <Trans>Available Liquidity</Trans>
                        </ThemedText.SubHeader>
                        <HelpCircle
                          width={16}
                          onMouseEnter={() => handleShowTooltip(6)}
                          onMouseLeave={() => handleShowTooltip(0)}
                        />
                      </Row>
                    </MouseoverTooltip>
                    <ThemedText.SubHeader>
                      ${formatAmount(toTokenInfo.maxAvailableShort, USD_DECIMALS, 2, true)}
                    </ThemedText.SubHeader>
                  </RowBetween>
                )}
              </AutoColumn>
            </LightGreyCard>
          )}
        </AutoColumn>
      </Container>
      <TokenModal
        label="Collateral In"
        chainId={chainId}
        tokenAddress={shortCollateralAddress}
        onSelectToken={onSelectShortCollateralAddress}
        tokens={stableTokens}
        isOpen={openModal === 1}
        onDismiss={() => setOpenModal(0)}
      />
      {isConfirming && (
        <ConfirmationModal
          library={library}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isSwap={isSwap}
          isLong={isLong}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          isShort={isShort}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          hasExistingPosition={hasExistingPosition}
          shortCollateralAddress={shortCollateralAddress}
          shortCollateralToken={shortCollateralToken}
          leverage={leverage}
          existingPosition={existingPosition}
          existingLiquidationPrice={existingLiquidationPrice}
          displayLiquidationPrice={displayLiquidationPrice}
          nextAveragePrice={nextAveragePrice}
          triggerPriceUsd={triggerPriceUsd}
          triggerRatio={triggerRatio}
          fees={fees}
          feesUsd={feesUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          collateralTokenAddress={collateralTokenAddress}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          isOpen={isConfirming}
          onDismiss={() => setIsConfirming(false)}
        />
      )}
      {ordersToaOpen && (
        <OrdersToS
          isOpen={ordersToaOpen}
          onDismiss={() => setOrdersToaOpen(false)}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
    </Wrapper>
  )
}
