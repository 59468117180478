import { SupportedChainId } from './chains'

export const SUBGRAPH_EXCHANGE_URL: { [chainId: number]: string } = {
  [SupportedChainId.BSC]: 'https://api.thegraph.com/subgraphs/name/jamonswap/matic-v2',
  [SupportedChainId.BSC_TESTNET]: 'https://api.thegraph.com/subgraphs/name/jamonswap/matic-v2',
}

export const SUBGRAPH_URLS = {
  [SupportedChainId.BSC]: {
    stats: 'https://api.thegraph.com/subgraphs/name/gmx-io/gmx-stats',
    referrals: 'https://api.thegraph.com/subgraphs/name/gmx-io/gmx-arbitrum-referrals',
    nissohVault: 'https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault',
  },

  [SupportedChainId.BSC_TESTNET]: {
    stats: 'https://api.thegraph.com/subgraphs/name/ticowork/spcdex-chapel',
    referrals: 'https://api.thegraph.com/subgraphs/name/ticowork/spcdex-chapel-referrals',
  },

  common: {
    chainLink: 'https://api.thegraph.com/subgraphs/name/deividask/chainlink',
  },
}
