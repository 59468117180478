/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/macro'
import { ButtonEmpty } from 'components/Button'
import { DarkCard } from 'components/Card'
import { RowCenter } from 'components/Row'
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { CSSProperties, keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import {
  COLORS,
  tooltipFormatterPercent,
  tooltipLabelFormatter as tooltipLabelFormatter_,
  yaxisFormatterPercent,
} from '../helpers'
import { useChartViewState } from '../hooks/useChartViewState'
import CsvLink from './CsvLink'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.primary1};
  background: transparent;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
`

interface Props {
  data: any
  loading: any
  chartHeight: any
  yaxisWidth: any
}

export function PerformanceAnnualizedChart(props: Props) {
  const { data, loading, chartHeight } = props
  const [darkMode] = useDarkModeManager()
  const bg = darkMode ? '#0C162D' : '#FFF'

  const contentStyle: CSSProperties = { textAlign: 'left', backgroundColor: bg, borderRadius: '16px', borderColor: bg }

  const yaxisDataKey = 'apr'

  const items = [{ key: 'apr', name: 'APR', color: COLORS[2] }]

  const csvFields = items.map((item) => ({ key: item.key, name: item.name }))

  const controls = {}

  const {
    viewState,
    togglePercentView,
    formattedData,
    yaxisTickFormatter: defaultYaxisTickFormatter,
    itemsUnit: defaultItemUnit,
  } = useChartViewState({ controls, data })

  const htmlItems = (items || []).map((item, i) => {
    const props = {
      dataKey: item.key,
      stackId: 'a',
      name: item.name || item.key,
      fill: item.color || COLORS[i % COLORS.length],
      stroke: item.color || COLORS[i % COLORS.length],
      key: 'item-' + i,
      unit: defaultItemUnit,
    }

    return <Bar type="monotone" {...props} isAnimationActive={false} />
  })
  return (
    <DarkCard minHeight={600}>
      <Row>
        <ThemedText.Body>
          <Trans>AUM Performance Annualized</Trans>
        </ThemedText.Body>
        <ButtonEmpty width="fit-content">
          <CsvLink fields={csvFields} name="AUM Performance Annualized" data={formattedData} />
        </ButtonEmpty>
      </Row>
      {loading ? (
        <Column>
          <Spinner />
        </Column>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart data={formattedData} syncId="syncFlp">
              <CartesianGrid strokeDasharray="10 10" key="a" />,
              <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter_} minTickGap={30} key="b" />,
              <YAxis domain={[0, 'auto']} dataKey={yaxisDataKey} tickFormatter={yaxisFormatterPercent} key="c" />
              <Tooltip
                formatter={tooltipFormatterPercent}
                labelFormatter={tooltipLabelFormatter_}
                contentStyle={contentStyle}
                key="d"
              />
              <Legend key="e" />
              {htmlItems}
            </ComposedChart>
          </ResponsiveContainer>
          <RowCenter mt="1rem">
            <ThemedText.SubHeader>Formula = Daily Fees / FLP Pool * 365 days * 100</ThemedText.SubHeader>
          </RowCenter>
        </>
      )}
    </DarkCard>
  )
}
